<div class="header-container">

    <mat-toolbar class=" header-toolbar" color=primary>

        <div class="menu-container">
            <button mat-icon-button (click)="toggleSidebar()">
                <mat-icon>menu</mat-icon>
            </button>
            <!--<img class="logo" src="/assets/images/logo.png" (click)="abreInstitucional()">-->
            Verde Manzana
        </div>

        
        <div class="busqueda-container">
            <!-- Busqueda
            <app-searchbar *ngIf="busqueda"></app-searchbar>
            <button class="apps" mat-icon-button *ngIf="busqueda" (click)="busqueda = !busqueda">
                <mat-icon>close</mat-icon>                
            </button>
            <button class="apps" mat-icon-button *ngIf="!busqueda" (click)="busqueda = !busqueda">
                <mat-icon>search</mat-icon>
            </button>
             -->
            
            <div class="text-sucursal" (dblclick)="cambiarSucursal()" matTooltip="Sucursal" style="margin-right: 8px;">
                {{sucursal.sucursalNombre}}
            </div>

            <button mat-icon-button matTooltip="Notas" matTooltipPosition="below" style="margin-right: 8px;" (click)="clickNotas()" *ngIf="tienenRolNotas()">
                <mat-icon>summarize</mat-icon>
            </button>
            
        </div>

        <div class="opciones-container">

            <!-- Menu notificaciones -->
            <button class="notificaciones icono-opcion" mat-icon-button [matMenuTriggerFor]="notificaciones" matTooltip="Notificaciones" matTooltipPosition="below" style="margin-right: 8px;">
                <mat-icon matBadge="0">notifications</mat-icon>
            </button>
            <mat-menu #notificaciones="matMenu" [overlapTrigger]="false">
                <div class="item-notificacion-vacio">
                    Sin Notificaciones
                </div>
                <div style="display: flex; align-items: center; justify-content: center; height: 30px; border-top: 1px solid #eaeaea; min-width: 280px;">
                    <div class="link-notif-todas" (click)="clickVerTodas()">Ver todas</div>
                </div>
                <!--<p class="mat-button">Sin notificaciones</p>-->
            </mat-menu>

            <!-- Menu opciones -->
            <!-- <button class="icono-opcion" mat-icon-button matTooltip="Opciones" matTooltipPosition="below" (click)="abrirDialogPlantilla()">
                <mat-icon>settings</mat-icon>
            </button> -->
                       
            <!-- <mat-menu #opciones="matMenu" [overlapTrigger]="false">
                <p class="mat-button">Opciones</p>
            </mat-menu> -->

            <!-- Menu usuario -->
            <button class="user" mat-button [matMenuTriggerFor]="perfil" matTooltip="Usuario" matTooltipPosition="below">
                <!-- <mat-icon>person</mat-icon> <span>{{ userApellido }}</span> -->
                <mat-icon>person</mat-icon> <span>{{userName}}</span>
            </button>
            <mat-menu #perfil="matMenu" [overlapTrigger]="false">
                <app-profilemenu></app-profilemenu>
            </mat-menu>
        </div>

        <div class="menuSetMob">
            <button class="menuApp" mat-icon-button [matMenuTriggerFor]="menuApp">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuApp="matMenu" [overlapTrigger]="false">
                <button mat-menu-item class="text" (click)="logOut()">Log Out</button>
            </mat-menu>
        </div>
    </mat-toolbar>

</div>