import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { last } from 'lodash';
import { combineLatest } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-comisiones-abm',
  templateUrl: './comisiones-abm.component.html',
  styleUrls: ['./comisiones-abm.component.scss']
})
export class ComisionesAbmComponent implements OnInit {

  displayedColumns: string[] = ['vendedor'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  cargando = false;

  columnas: any[] = [
    {nombre: "vendedor", descripcion: "Vendedor"},
  ]

  vendedores: any[] = [];

  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    public router: Router
  ) {
    
  }

  ngOnInit(): void {
    this.cargarTabla();
  }

  cargarTabla(){

    let obsVendedoresList = this.apiService.getVendedoresList();
    let obsComisionesList = this.apiService.getComisionesListSimple();
    let obsRubrosList = this.apiService.getRubrosList();

    this.cargando = true;

    combineLatest([obsVendedoresList, obsComisionesList, obsRubrosList]).subscribe((hh: any[]) =>{

      let data = hh;
      console.log("data", data);

      //rubros

      let r = hh[2];

      r.forEach(rr => {
        this.columnas.push({nombre: `id_${rr.idrubroproducto}`, descripcion: rr.descripcion});
        this.displayedColumns.push(`id_${rr.idrubroproducto}`);
      })

      

      let v = hh[0];
      let c = hh[1];

      this.vendedores = v;

      let d = []
      v.forEach(vv => {
        let cc: any[] = c.filter(x => x.idvendedor == vv.idvendedor);
        let valor: any = {vendedor: vv.nombre, idvendedor: vv.idvendedor};
        cc.forEach(xx => {
          valor[`id_${xx.idrubro}`] = xx.comision;
        });

        d.push(valor)
      });

      //comisiones

      

      

      this.dataSource.data = d;

      console.log("datasource", this.dataSource);

      this.cargando = false;
    }, e => {
      this.cargando = false;
    });
   
    /*
    this.apiService.getRubrosList().subscribe( (o: any[]) => {
      this.dataSource.data = o;
    });
    */
  }

  clickNuevo(){
    this.router.navigateByUrl('/rubros/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/rubros/editar/${row.idrubroproducto}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/rubros/editar/${element.idrubroproducto}`,);
  }

  clickEliminar(element: any){

    console.log(element);
    
    this.apiService.deleteRubros(element).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    }, e => {
      this.snackBarService.errorMessage("Error al eliminar el Rubro. " + e.message);
    });
    
    //this.router.navigateByUrl(`/productos/editar/${element.idproducto}`,);
  }

  changeComision(element, columna, v, event){
    console.log("change element", element);
    console.log("change", `columna: ${columna} | valor: ${v}`);
    console.log("change", `id columna: ${columna.substring(3)}`);

    let lastComision = "0.0";
    if(!(typeof element[columna] === 'undefined')) {
      lastComision = Number(JSON.parse(JSON.stringify(element[columna]))).toString();
    }

    var comision = Number(v);

    if (comision == null){
      comision = 0.0;
    }

    console.log("last comision", lastComision);

    if (comision > 100){
      //element[columna] = lastComision;
      this.snackBarService.errorMessage("La comisión no puede ser mayor del 100%.");

      event.target.value = lastComision;

      return;
    }
    
    var idRubro = Number(columna.substring(3))

    if (idRubro == null){
      this.snackBarService.errorMessage("No se puede encontrar el rubro.");
      return;
    }

    element[columna] = v;

    this.cargando = true;
    this.apiService.postComisionesActualizar({idvendedor: element.idvendedor, idrubro: idRubro, comision: comision}).subscribe(x => {
      this.snackBarService.successMessage("Comisión Modificada");
      this.cargando = false;
      console.log("result", x);
    }, e => {
      this.cargando = false;
      this.snackBarService.errorMessage("Error al guardar la comisión.");
    });

  }

}

const ELEMENT_DATA: any[] = [];

