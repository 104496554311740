<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2>{{nuevo.idcompra == 0 ? 'Nuevo Gasto' : ('Editar Gasto #' + nuevo.idcompra) }}</h2>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="nuevo.descripcion" name="descripcion">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Sucursal</mat-label>
                <mat-select [(ngModel)]="nuevo.idsucursal">
                    <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Proveedor</mat-label>
                    <input matInput type="text" [(ngModel)]="nuevo.proveedorNombre" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarProveedor()">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Importe</mat-label>
                <input matInput placeholder="Importe" value=""  type="text" [(ngModel)]="nuevo.importe" name="descripcion">
            </mat-form-field>

        </div>

        <div style="margin-top: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Forma de Pago</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar y Editar Formas de Pago</button>
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">

                <mat-card  *ngFor="let fp of formasDePago; let i = index" style="margin: 4px;">
                    <div style="display: flex; flex-direction: column; font-size: 10pt;">
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Forma de Pago: <b>{{fp.tipoPrecioDescripcion}}</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Importe: <b>$ {{fp.importe}}</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Observaciones: <b>{{fp.observaciones}}</b>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <div *ngIf="formasDePago.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Formas de Pago Agregadas</div>
                </div>
            </div>

        </div>
        
    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>