import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ProductosModel } from '../models/productos-model';
import { catchError, tap, map, filter, retry, combineLatest, share } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ProveedoresModel } from '../models/proveedores-model';
import { SucursalesModel } from '../models/sucursales-model';
import { VendedoresModel } from '../models/vendedores-model';
import { TiposDePrecioModel } from '../models/tipos-de-precio-model';
import { RelacionProductosProductosPreciosModel } from '../models/relacion-productos-productos-precios';
import { LocalidadesModel } from '../models/localidades-model';
import { ProveedoresCompletoModel } from '../models/proveedores-completo-model';
import { SucursalesCompletoModel } from '../models/sucursales-completo-model';
import { VendedoresCompletoModel } from '../models/vendedores-completo-model';
import { RubrosProductoModel } from '../models/rubros-producto-model';
import { SubRubrosProductoModel } from '../models/subrubros-producto-model';
import { ColoresProductoModel } from '../models/colores-producto-model';
import { MedidasProductoModel } from '../models/medidas-producto-model';
import { PreventaModel } from '../models/preventa-model';
import { PreventasProductosModel } from '../models/preventas-productos-model';
import { ProductosPresentacionesModel } from '../models/productos-presentaciones-model';
import { PreventasFormaPagoModel } from '../models/preventas-forma-pago-model';
import { ClientesModel } from '../models/clientes-model';
import { ClientesCompletoModel } from '../models/clientes-completo-model';
import { PresentacionesSucursalesModel } from '../models/presentaciones-sucursales-model';
import { UsuariosModel } from '../models/usuarios-model';
import { ApiRequestService } from '../shared/utiles/core';
import { SessionCacheHandler } from '../shared/utiles/cache';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private TAG = 'ApiService';
  protected apiUrl = environment.urlApi;

  constructor(
    private http: HttpClient,
    private __apiRequestService: ApiRequestService,
    private __sessionCache: SessionCacheHandler
    ) {

  }

  //productos

  getProductos(id: number): Observable<ProductosModel>{
    const url = `${this.apiUrl}/productos/${id}`;

    return this.http.get<ProductosModel>(url, httpOptions).pipe(
      tap(v => console.log('getProductos', v)),
      catchError(this.handleError<any>('addTareaTrabajo'))
    );

  }

  getProductosList(): Observable<ProductosModel[]>{
    const url = `${this.apiUrl}/productos`;

    return this.http.get<ProductosModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosList', v)),
    );

  }

  postProductos(item: ProductosModel){
    const url = `${this.apiUrl}/productos`;

    let nuevoItem = {
      descripcion: item.descripcion,
      idrubroproducto: item.idrubroproducto,
      idsubrubroproducto: item.idsubrubroproducto,
      activo: item.activo
    }

    console.log("nuevo item", nuevoItem);

    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putProductos(item: ProductosModel){
    const url = `${this.apiUrl}/productos/${item.idproducto}`;

    console.log("item", item);

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  deleteProducto(item: ProductosModel){
    console.log("item", item);
    const url = `${this.apiUrl}/productos/${item.idproducto}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
      catchError(this.handleError<any>('deleteProducto'))
    );
  }

  getProductosListByRubroSubrubro(idrubro: number, idsubrubro: number): Observable<ProductosModel[]>{
    //productos/rubro/{idrubro}/subrubro/{idsubrubro}
    const url = `${this.apiUrl}/productos/rubro/${idrubro}/subrubro/${idsubrubro}`;

    return this.http.get<ProductosModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosListByRubroSubrubro', v)),
    );

  }

  getRelProductosPresentaciones(): Observable<any[]>{
    ///productos/presentaciones
    const url = `${this.apiUrl}/productos/presentaciones`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getRelProductosPresentaciones', v)),
    );
  }

  getRelProductosPresentacionesById(id: number): Observable<any[]>{
    // /productos/presentaciones/{id}
    const url = `${this.apiUrl}/productos/presentaciones/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getRelProductosPresentacionesById', v)),
    );
  }


  
  postProductosUpdatePrecioByRubroSubRubro(idrubro: number, idsubrubro: number, porcentaje: number){
    const url = `${this.apiUrl}/productos-update-precio-rubro-subrubro`;
    let param = {
      porcentaje: porcentaje,
      idrubroproducto: idrubro,
      idsubrubroproducto: idsubrubro
    }
    return this.http.post<any>(url, param, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  postProductosUpdatePrecioTodo(porcentaje: number){
    const url = `${this.apiUrl}/productos-update-todo`;
    let param = {
      porcentaje: porcentaje,
    }
    return this.http.post<any>(url, param, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  postProductosUpdatePrecioPorcentaje(porcentaje: number, id: number){
    const url = `${this.apiUrl}/productos-update-precio-porcentaje/${id}`;
    let param = {
      porcentaje: porcentaje,
    }
    return this.http.post<any>(url, param, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  postProductosUpdatePrecioFijo(precioFijo: number, id: number){
    const url = `${this.apiUrl}/productos-update-precio-fijo/${id}`;
    let param = {
      precio: precioFijo,
    }
    return this.http.post<any>(url, param, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  getProductosAgrupadoMedidas(){
    ///productos/presentaciones-por-medidas
    const url = `${this.apiUrl}/productos/presentaciones-por-medidas`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosAgrupadoMedidas', v)),
    );
  }

  getProductosListCompleto(){ //resuelto con rubro y rubro en texto
    // /productos/completo
    const url = `${this.apiUrl}/productos/completo`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosCompleto', v)),
    );
  }


  ///proveedores

  getProveedores(id: number): Observable<ProveedoresModel>{
    const url = `${this.apiUrl}/proveedores/${id}`;
    return this.http.get<ProveedoresModel>(url, httpOptions).pipe(
      tap(v => console.log('getProveedores', v)),
      catchError(this.handleError<any>(''))
    );
  }

  getProveedoresList(): Observable<ProveedoresModel[]>{
    const url = `${this.apiUrl}/proveedores`;

    
    return this.http.get<ProveedoresModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getProveedoresList', v)),
    );
    

    //return this._apiRequestService.get<ProveedoresModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });

  }

  getProveedoresCompletoList(): Observable<ProveedoresCompletoModel[]>{
    const url = `${this.apiUrl}/proveedores/completo`;

    
    return this.http.get<ProveedoresCompletoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getProveedoresList', v)),
    );
    

    //return this._apiRequestService.get<ProveedoresCompletoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });

  }

  postProveedores(item: ProveedoresModel){
    const url = `${this.apiUrl}/proveedores`;

    let nuevoItem = {
      nombre: item.nombre,
      domicilio: item.domicilio,
      telefono: item.telefono,
      correo_electronico: item.correo_electronico,
      codigo_localidad: item.codigo_localidad,
      CUIT: item.CUIT,
      CBU: item.CBU
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putProveedores(item: ProveedoresModel){
    const url = `${this.apiUrl}/proveedores/${item.idproveedor}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v)),
      catchError(this.handleError<any>('putProveedores'))
    );
  }

  deleteProveedores(item: ProveedoresModel){
    console.log("item", item);
    const url = `${this.apiUrl}/proveedores/${item.idproveedor}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
      catchError(this.handleError<any>('deleteProveedores'))
    );
  }

  //// sucursales


  getSucursales(id: number): Observable<SucursalesModel>{
    const url = `${this.apiUrl}/sucursales/${id}`;

    return this.http.get<SucursalesModel>(url, httpOptions).pipe(
      tap(v => console.log('getSucursales', v)),
      catchError(this.handleError<any>(''))
    );

  }

  getSucursalesList(): Observable<SucursalesModel[]>{
    const url = `${this.apiUrl}/sucursales`;

    
    return this.http.get<SucursalesModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getSucursalesList', v)),
    );
    

    //return this._apiRequestService.get<SucursalesModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });

  }

  getSucursalesCompletoList(): Observable<SucursalesCompletoModel[]>{
    const url = `${this.apiUrl}/sucursales/completo`;

    
    return this.http.get<SucursalesCompletoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getSucursalesCompletoList', v)),
    );
    

    //return this._apiRequestService.get<SucursalesCompletoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });

  }

  postSucursales(item: SucursalesModel){
    const url = `${this.apiUrl}/sucursales`;

    let nuevoItem = {
      descripcion: item.descripcion,
      domicilio: item.domicilio,
      telefono: item.telefono,
      codigo_localidad: item.codigo_localidad,
      observaciones: item.observaciones
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putSucursales(item: SucursalesModel){
    const url = `${this.apiUrl}/sucursales/${item.idsucursal}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v)),
      catchError(this.handleError<any>('putSucursales'))
    );
  }

  deleteSucursales(item: SucursalesModel){
    console.log("item", item);
    const url = `${this.apiUrl}/sucursales/${item.idsucursal}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
      catchError(this.handleError<any>('deleteSucursales'))
    );
  }

  /// vendedores

  getVendedores(id: number): Observable<VendedoresModel>{
    const url = `${this.apiUrl}/vendedores/${id}`;

    return this.http.get<VendedoresModel>(url, httpOptions).pipe(
      tap(v => console.log('getVendedores', v)),
      catchError(this.handleError<any>(''))
    );

  }

  getVendedoresList(): Observable<VendedoresModel[]>{
    const url = `${this.apiUrl}/vendedores`;

    
    return this.http.get<VendedoresModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getVendedoresList', v)),
    );
    

    //return this._apiRequestService.get<VendedoresModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  getVendedoresCompletoList(): Observable<VendedoresCompletoModel[]>{
    const url = `${this.apiUrl}/vendedores/completo`;

    
    return this.http.get<VendedoresCompletoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getVendedoresCompletoList', v)),
    );
    

    //return this._apiRequestService.get<VendedoresCompletoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });

  }

  postVendedores(item: VendedoresModel){
    const url = `${this.apiUrl}/vendedores`;

    let nuevoItem = {
      nombre: item.nombre,
      domicilio: item.domicilio,
      codigo_localidad: item.codigo_localidad,
      telefono: item.telefono,
      correo_electronico: item.correo_electronico,
      observaciones: item.observaciones,
      porcentaje_comision: item.porcentaje_comision,
      idsucursal: item.idsucursal
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putVendedores(item: VendedoresModel){
    const url = `${this.apiUrl}/vendedores/${item.idvendedor}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteVendedores(item: VendedoresModel){
    console.log("item", item);
    const url = `${this.apiUrl}/vendedores/${item.idvendedor}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
      //catchError(this.handleError<any>('deleteVendedores'))
    );
  }


  /////// TIPO DE PRECIO

  getTiposDePrecio(id: number): Observable<TiposDePrecioModel>{
    const url = `${this.apiUrl}/tipos-de-precios/${id}`;

    return this.http.get<TiposDePrecioModel>(url, httpOptions).pipe(
      tap(v => console.log('getTiposDePrecio', v))
    );

  }

  getTiposDePrecioList(): Observable<TiposDePrecioModel[]>{
    const url = `${this.apiUrl}/tipos-de-precios`;

    
    return this.http.get<TiposDePrecioModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getTiposDePrecioList', v)),
    );
    

    //return this._apiRequestService.get<TiposDePrecioModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postTiposDePrecio(item: TiposDePrecioModel){
    const url = `${this.apiUrl}/tipos-de-precios`;

    let nuevoItem = {
      descripcion: item.descripcion,
      recargo: item.recargo
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putTiposDePrecio(item: TiposDePrecioModel){
    const url = `${this.apiUrl}/tipos-de-precios/${item.idtipo}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteTiposDePrecio(item: TiposDePrecioModel){
    console.log("item", item);
    const url = `${this.apiUrl}/tipos-de-precios/${item.idtipo}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  // RELACION PRODUCTOS - PRODUCTOS PRECIOS

  getListProdutosProductosPrecios(): Observable<RelacionProductosProductosPreciosModel[]>{
    const url = `${this.apiUrl}/relacion-productosprecios/listRelacionProductosProductosPrecios`;

    return this.http.get<RelacionProductosProductosPreciosModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getListProdutosProductosPrecios', v)),
    );

  }

  // LOCALIDADES

  getLocalidades(id: number): Observable<LocalidadesModel>{
    const url = `${this.apiUrl}/localidades/${id}`;

    return this.http.get<LocalidadesModel>(url, httpOptions).pipe(
      tap(v => console.log('getLocalidades', v)),
    );

  }

  getLocalidadesList(): Observable<LocalidadesModel[]>{
    const url = `${this.apiUrl}/localidades`;

    
    return this.http.get<LocalidadesModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getLocalidadesList', v)),
    );
    

    //return this._apiRequestService.get<LocalidadesModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postLocalidades(item: LocalidadesModel){
    const url = `${this.apiUrl}/localidades`;

    let nuevoItem = {
      nombre: item.nombre,
      codigo_postal: item.codigo_postal
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putLocalidades(item: LocalidadesModel){
    const url = `${this.apiUrl}/localidades/${item.idlocalidad}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteLocalidades(item: LocalidadesModel){
    console.log("item", item);
    const url = `${this.apiUrl}/localidades/${item.idlocalidad}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  ///// rubros

  getRubros(id: number): Observable<RubrosProductoModel>{
    const url = `${this.apiUrl}/rubros-productos/${id}`;

    return this.http.get<RubrosProductoModel>(url, httpOptions).pipe(
      tap(v => console.log('getRubros', v)),
    );

  }

  getRubrosList(): Observable<RubrosProductoModel[]>{
    const url = `${this.apiUrl}/rubros-productos`;

    
    return this.http.get<RubrosProductoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getRubrosList', v)),
    );
    

    //return this._apiRequestService.get<RubrosProductoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postRubros(item: RubrosProductoModel){
    const url = `${this.apiUrl}/rubros-productos`;

    let nuevoItem = {
      descripcion: item.descripcion,
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putRubros(item: RubrosProductoModel){
    const url = `${this.apiUrl}/rubros-productos/${item.idrubroproducto}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteRubros(item: RubrosProductoModel){
    console.log("item", item);
    const url = `${this.apiUrl}/rubros-productos/${item.idrubroproducto}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }


  ///// sub rubros

  getSubRubros(id: number): Observable<SubRubrosProductoModel>{
    const url = `${this.apiUrl}/subrubros-productos/${id}`;

    return this.http.get<SubRubrosProductoModel>(url, httpOptions).pipe(
      tap(v => console.log('getSubRubros', v)),
    );

  }

  getSubRubrosList(): Observable<SubRubrosProductoModel[]>{
    const url = `${this.apiUrl}/subrubros-productos`;

    
    return this.http.get<SubRubrosProductoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getSubRubrosList', v)),
    );
    

    //return this._apiRequestService.get<SubRubrosProductoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postSubRubros(item: SubRubrosProductoModel){
    const url = `${this.apiUrl}/subrubros-productos`;

    let nuevoItem = {
      idrubroproducto: item.idrubroproducto,
      descripcion: item.descripcion,
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putSubRubros(item: SubRubrosProductoModel){
    const url = `${this.apiUrl}/subrubros-productos/${item.idsubrubroproducto}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteSubRubros(item: SubRubrosProductoModel){
    console.log("item", item);
    const url = `${this.apiUrl}/subrubros-productos/${item.idsubrubroproducto}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }


  ///// colores

  getColores(id: number): Observable<ColoresProductoModel>{
    const url = `${this.apiUrl}/colores-productos/${id}`;

    return this.http.get<ColoresProductoModel>(url, httpOptions).pipe(
      tap(v => console.log('getColores', v)),
    );

  }

  getColoresList(): Observable<ColoresProductoModel[]>{
    const url = `${this.apiUrl}/colores-productos`;

    
    return this.http.get<ColoresProductoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getColoresList', v)),
    );
    

    //return this._apiRequestService.get<ColoresProductoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postColores(item: ColoresProductoModel){
    const url = `${this.apiUrl}/colores-productos`;

    let nuevoItem = {
      nombre: item.nombre,
      codigo_color: item.codigo_color,
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putColores(item: ColoresProductoModel){
    const url = `${this.apiUrl}/colores-productos/${item.idcolorproducto}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteColores(item: ColoresProductoModel){
    console.log("item", item);
    const url = `${this.apiUrl}/colores-productos/${item.idcolorproducto}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }



  ///// medidas

  getMedidas(id: number): Observable<MedidasProductoModel>{
    const url = `${this.apiUrl}/medidas-productos/${id}`;

    return this.http.get<MedidasProductoModel>(url, httpOptions).pipe(
      tap(v => console.log('getMedidas', v)),
    );

  }

  getMedidasList(): Observable<MedidasProductoModel[]>{
    const url = `${this.apiUrl}/medidas-productos`;

    
    return this.http.get<MedidasProductoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getMedidasList', v)),
    );
    

    //return this._apiRequestService.get<MedidasProductoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postMedidas(item: MedidasProductoModel){
    const url = `${this.apiUrl}/medidas-productos`;

    let nuevoItem = {
      descripcion: item.descripcion,
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putMedidas(item: MedidasProductoModel){
    const url = `${this.apiUrl}/medidas-productos/${item.idmedidaproducto}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteMedidas(item: MedidasProductoModel){
    console.log("item", item);
    const url = `${this.apiUrl}/medidas-productos/${item.idmedidaproducto}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  ///// productos presentaciones

  getProductosPresentaciones(id: number): Observable<ProductosPresentacionesModel>{
    const url = `${this.apiUrl}/productos-presentaciones/${id}`;

    return this.http.get<ProductosPresentacionesModel>(url, httpOptions).pipe(
      tap(v => console.log('getProductosPresentaciones', v)),
    );

  }

  getProductosPresentacionesList(): Observable<ProductosPresentacionesModel[]>{
    const url = `${this.apiUrl}/productos-presentaciones`;

    return this.http.get<ProductosPresentacionesModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosPresentacionesList', v)),
    );

  }

  postProductosPresentaciones(item: ProductosPresentacionesModel){
    const url = `${this.apiUrl}/productos-presentaciones`;

    let nuevoItem = {
      //idproductopresentacion: item.idproductopresentacion,
      idproducto: item.idproducto,
      idcolorproducto: item.idcolorproducto,
      idmedidaproducto: item.idmedidaproducto,
      cantidad: item.cantidad,
      precio: item.precio
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putProductosPresentaciones(item: ProductosPresentacionesModel){
    const url = `${this.apiUrl}/productos-presentaciones/${item.idproductopresentacion}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  patchProductosPresentaciones(item: Partial<ProductosPresentacionesModel>){
    const url = `${this.apiUrl}/productos-presentaciones/${item.idproductopresentacion}`;

    return this.http.patch<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteProductosPresentaciones(item: ProductosPresentacionesModel){
    console.log("item a borrar", item);
    const url = `${this.apiUrl}/productos-presentaciones/${item.idproductopresentacion}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  getProductoPresentacionesListByIdProducto(idProducto: number): Observable<any[]>{
    const url = `${this.apiUrl}/productos-presentaciones/idproducto/${idProducto}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductoPresentacionesListByIdProducto', v)),
    );
  }

  deleteSeguro(idProducto: number){
    // /productos/borrarSeguro/{id}
    const url = `${this.apiUrl}/productos/borrarSeguro/${idProducto}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('deleteSeguro', v)),
    );
  }


  /////preventas

  getPreventasList(): Observable<PreventaModel[]>{
    const url = `${this.apiUrl}/preventas`;

    return this.http.get<PreventaModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasList', v)),
    );

  }

  getPreventa(id: number){
    //@get('/preventas/{id}')
    const url = `${this.apiUrl}/preventas/${id}`;

    return this.http.get<PreventaModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventa', v)),
    );

  }

  getPreventasProductoCompleto(id: number, idsucursal: number){
    //preventas-producto-completo/{id}
    const url = `${this.apiUrl}/preventas-producto-completo/${id}/${idsucursal}`;

    console.log("url", url);

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasProductoCompleto', v)),
    );
  }

  //preventas-forma-pago-completo/{id}
  getPreventasFormaPagoPropuestaCompleto(id: number){
    //preventas-forma-pago-completo/{id}
    const url = `${this.apiUrl}/preventas-forma-pago-completo/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasFormaPagoPropuestaCompleto', v)),
    );
  }

  getPreventasFormaPagoConfirmadaCompleto(idpreventa: number){
    //preventas-pagos-confirmados/{idpreventa}
    const url = `${this.apiUrl}/preventas-pagos-confirmados/${idpreventa}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasFormaPagoConfirmadaCompleto', v)),
    );
  }


  postPreventa(item: PreventaModel){
    const url = `${this.apiUrl}/preventas`;

    let nuevoItem = {
      idvendedor: item.idvendedor,
      flete: item.flete,
      observaciones: item.observaciones,
      senia: item.senia,
      idcliente: item.idcliente != null ? item.idcliente : 0,
      estado: item.estado,
      domicilio: item.domicilio,
      idlocalidad: item.idlocalidad,
      idsucursal: item.idsucursal
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  postPreventaConfirmada(data: any){
    const url = `${this.apiUrl}/preventas/confirmar`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postPreventaConfirmada', v)),
    );
  }

  /*
  postPreventaEditarConfirmada(data: any){
    const url = `${this.apiUrl}/preventas/editar-confirmada`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postPreventaEditarConfirmada', v)),
    );
  }
  */

  postPreventaNueva(data: any){

    console.log("apiservice data", data);

    const url = `${this.apiUrl}/preventas/nueva`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postPreventaNueva', v)),
    );
  }

  postPreventaEditar(data: any, tipo: number){
    const url = `${this.apiUrl}/preventas/editar/${tipo}`;
    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postPreventaEditar', v)),
    );
  }

  ///////// @put('/preventas/{id}')
  putPreventas(item: any, id: any){
    const url = `${this.apiUrl}/preventas/${id}`;

    console.log("item", item);
    console.log("item id", id)

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  updatePreventaConfirmado(id: number){
    const url = `${this.apiUrl}/preventas-confirmar/${id}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  updatePreventaCancelado(id: number){
    const url = `${this.apiUrl}/preventas-cancelar/${id}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  postProcesarStockVenta(id: number){
    const url = `${this.apiUrl}/procesar-stock-venta/${id}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }


  postPreventasProductos(item: PreventasProductosModel){
    const url = `${this.apiUrl}/preventas-productos`;

    let nuevoItem = {
      idpreventa: item.idpreventa,
      idproducto: item.idproducto,
      observaciones: item.observaciones,
      cantidad: item.cantidad,
      precio_unitario: item.precio_unitario,
      idproductopresentacion: item.idproductopresentacion
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  postPreventasProductosBulk(items: PreventasProductosModel[]){

    console.log("items serv", items);

    const url = `${this.apiUrl}/preventas-productos/bulk`;

    return this.http.post<any>(url, items, httpOptions).pipe(
      tap(v => console.log('test', v)),
    ); 
  }

  getPreventasProductosListCompleto(estado?: number, idsucursal?: number, desde?: string, hasta?: string): Observable<any[]>{
    let url = `${this.apiUrl}/preventas-productos-completo`;

    let params: any[] = []
    let query = ``;

    if (estado != null){
      params.push(`estado=${estado}`)
    }

    if (idsucursal != null){
      params.push(`idsucursal=${idsucursal}`);
    }

    if (desde != null){
      params.push(`desde=${desde}`);
    }

    if (hasta != null){
      params.push(`hasta=${hasta}`)
    }

    if (params.length > 0){
      query += `?`;
      params.forEach(p => {
        query += p + "&"
      });
      query = query.slice(0, -1);
    }
    

    return this.http.get<any[]>(url + query, httpOptions).pipe(
      tap(v => console.log('getPreventasProductosListCompleto', v)),
    );

  }

  getPreventaRecibo(idVenta: number){
    let url = `${this.apiUrl}/preventas/recibopago/${idVenta}`;
    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventaRecibo', v)),
    );
  }

  getPreventaReciboPropuesta(idVenta: number){
    let url = `${this.apiUrl}/preventas/recibopagopropuesta/${idVenta}`;
    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventaReciboPropuesta', v)),
    );
  }

  postPreventasFormaPagoBulk(items: PreventasFormaPagoModel[]){

    console.log("items serv", items);

    const url = `${this.apiUrl}/preventas-forma-pago/bulk`;

    return this.http.post<any>(url, items, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  getPreventaCancelar(id: number){
    let url = `${this.apiUrl}/preventas-cancelar/${id}`;
    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventaCancelar', v)),
    );
  }

  patchPreventa(id: number, data: any){
    let url = `${this.apiUrl}/preventas/${id}`;
    return this.http.patch<any[]>(url, data, httpOptions).pipe(
      tap(v => console.log('patchPreventa', v)),
    );
  }

  postActualizarPrecioVenta(venta){
    // preventas/actualizar-precios/{id}

    let url = `${this.apiUrl}/preventas/actualizar-precios/${venta.idpreventa}`;
    let body = venta;

    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('test', v))
    );

  }


  /// clientes

  getClientes(id: number): Observable<ClientesModel>{
    const url = `${this.apiUrl}/clientes/${id}`;

    return this.http.get<ClientesModel>(url, httpOptions).pipe(
      tap(v => console.log('getClientes', v)),
      catchError(this.handleError<any>(''))
    );

  }

  getClientesList(): Observable<ClientesModel[]>{
    const url = `${this.apiUrl}/clientes`;

    
    return this.http.get<ClientesModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getClientesList', v)),
    );
    

    //return this._apiRequestService.get<ClientesModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  getClientesCompletoList(): Observable<ClientesCompletoModel[]>{
    const url = `${this.apiUrl}/clientes/completo`;

    
    return this.http.get<ClientesCompletoModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getClientesCompletoList', v)),
    );
    

    //return this._apiRequestService.get<ClientesCompletoModel[]>(url, {}, { cacheDriver: this._sessionCache, showPreviousCached: false });


  }

  postClientes(item: ClientesModel){
    const url = `${this.apiUrl}/clientes`;

    let nuevoItem = {
      nombre: item.nombre,
      domicilio: item.domicilio,
      codigo_localidad: item.codigo_localidad,
      telefono: item.telefono,
      correo_electronico: item.correo_electronico,
      observaciones: item.observaciones,
    }

    console.log("nuevo item", nuevoItem);
    
    return this.http.post<any>(url, nuevoItem, httpOptions).pipe(
      tap(v => console.log('test', v)),
    );
  }

  putClientes(item: ClientesModel){
    const url = `${this.apiUrl}/clientes/${item.idcliente}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteClientes(item: ClientesModel){
    console.log("item", item);
    const url = `${this.apiUrl}/clientes/${item.idcliente}`;

    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('test', v)),
      catchError(this.handleError<any>('deleteClientes'))
    );
  }

  //

  getPresentacionesSucursales(){
    const url = `${this.apiUrl}/presentaciones-sucursales`;
    return this.http.get<PresentacionesSucursalesModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getPresentacionesSucursales', v)),
    );
  }

  //preventas formas de pago

  getPreventasFormaPago(){
    const url = `${this.apiUrl}/preventas-forma-pagos`;
    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasFormaPago', v)),
    );
  }

  getPreventasPago(){
    const url = `${this.apiUrl}/preventas-pagos`;
    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasPago', v)),
    );
  }

  cancelarPreventaConfirmada(id: number){
    // preventas/cancelar-confirmada/{id}
    const url = `${this.apiUrl}/preventas/cancelar-confirmada/${id}`;
    return this.http.post<any[]>(url, httpOptions).pipe(
      tap(v => console.log('cancelarPreventaConfirmada', v)),
    );
  }

  //usuarios

  getUsuarios(id: string): Observable<UsuariosModel>{
    const url = `${this.apiUrl}/usuarios/${id}`;

    console.log("url", url);

    return this.http.get<UsuariosModel>(url, httpOptions).pipe(
      tap(v => console.log('getUsuarios', v)),
      catchError(this.handleError<any>(''))
    );

  }

  getUsuariosLoginCompleto(id: string){
    // usuario-login-completo/{id}
    const url = `${this.apiUrl}/usuario-login-completo/${id}`;

    console.log("url", url);

    return this.http.get<any>(url, httpOptions).pipe(
      tap(v => console.log('getUsuariosLoginCompleto', v)),
      catchError(this.handleError<any>(''))
    );
  }

  getUsuariosPromise(id: string): Promise<UsuariosModel>{
    const url = `${this.apiUrl}/usuarios/${id}`;

    console.log("url", url);

    return this.http.get<UsuariosModel>(url, httpOptions).pipe(
      tap(v => console.log('getUsuarios', v)),
      catchError(this.handleError<any>(''))
    ).toPromise();

  }

  getUsuariosList(): Observable<UsuariosModel[]>{
    const url = `${this.apiUrl}/usuarios`;

    return this.http.get<UsuariosModel[]>(url, httpOptions).pipe(
      tap(v => console.log('getUsuariosList', v)),
    );

  }

  getUsuariosCompleto(){
    const url = `${this.apiUrl}/usuarios-completo`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getUsuariosCompletoList', v)),
    );
  }

  postUsuarios(item: UsuariosModel){
    const url = `${this.apiUrl}/usuarios`;
    return this.http.post<any>(url, item, httpOptions).pipe(
      tap(v => console.log('postUsuarios', v)),
    );
  }

  putUsuarios(item: UsuariosModel){
    const url = `${this.apiUrl}/usuarios/${item.uid}`;

    return this.http.put<any>(url, item, httpOptions).pipe(
      tap(v => console.log('putUsuarios', v)),
    );

  }

  getPresentacionProductoStock(idproductopresentacion: number): Observable<any>{
    const url = `${this.apiUrl}/presentaciones-productos-stock/${idproductopresentacion}`;

    console.log("url", url);  

    return this.http.get<any>(url, httpOptions).pipe(
      tap(v => console.log('getPresentacionProductoStock', v))
    );

  }

  postPresentacionesSucursalesRestaStock(items: any[]): Observable<any>{
    const url = `${this.apiUrl}/presentaciones-sucursales-resta-stock`;

    return this.http.post<any>(url, items, httpOptions).pipe(
      tap(v => console.log('postPresentacionesSucursalesRestaStock', v)),
    );
  }

  postPresentacionesSucursalesSumaStock(items: any[]): Observable<any>{
    const url = `${this.apiUrl}/presentaciones-sucursales-suma-stock`;

    return this.http.post<any>(url, items, httpOptions).pipe(
      tap(v => console.log('postPresentacionesSucursalesSumaStock', v)),
    );
  }

  postPreventasReporte(fechas: any){
    const url = `${this.apiUrl}/preventas-reporte`;
    const body = fechas;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postPreventasReporte', v))
    );
  }

  //preventas-forma-pago-reporte
  postPreventaReporteFormaPago(fechas: any){
    const url = `${this.apiUrl}/preventas-forma-pago-reporte`;
    const body = fechas;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postPreventaReporteFormaPago', v))
    );
  }

  //

  getComprasTipoPrecioList(){
    ///compras-tipo-precios
    const url = `${this.apiUrl}/compras-tipo-precios`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasTipoPrecioList', v)),
    );

  }

  postCompras(item: any){
    const url = `${this.apiUrl}/compras`;
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postCompras', v))
    );
  }

  postComprasCompletoNuevo(item: any){
    const url = `${this.apiUrl}/compras-nuevo-completo`;
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasCompletoNuevo', v))
    );
  }

  postComprasEgresoCompletoNuevo(item: any){
    const url = `${this.apiUrl}/compras-egreso-nuevo-completo`;
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasEgresoCompletoNuevo', v))
    );
  }


  

  postComprasCompletoEditar(item: any){
    const url = `${this.apiUrl}/compras-editar-completo`;
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasCompletoEditar', v))
    );
  }

  postComprasCompletoConfirmar(item: any){
    //compras-confirmar-completo
    const url = `${this.apiUrl}/compras-confirmar-completo`;
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasCompletoConfirmar', v))
    );

  }

  putCompras(item: any, id: number){
    //'/compras/{id}'
    const url = `${this.apiUrl}/compras/${id}`;
    const body = item;
    return this.http.put<any>(url, body, httpOptions).pipe(
      tap(v => console.log('putCompras', v))
    );
  }

  postComprasProductosBulk(idcompra: number, items: any[]){
    const url = `${this.apiUrl}/compras-productos-bulk`;
    let item = {
      idcompra: idcompra,
      items: items
    }
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasProductosBulk', v))
    );
  }

  postComprasProductosRecepcionBulk(idcompra: number, items: any[]){
    const url = `${this.apiUrl}/compras-productos-recepcion-bulk`;
    let item = {
      idcompra: idcompra,
      items: items
    }
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasProductosRecepcionBulk', v))
    );
  }

  //compras-recepcion-completo
  postComprasRecepcionCompleto(data: any){
    const url = `${this.apiUrl}/compras-recepcion-completo`;
    const body = data;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasRecepcionCompleto', v))
    );
  }

  postComprasFormaPagoBulk(idcompra: number, items: any[]){
    const url = `${this.apiUrl}/compras-forma-pago-bulk`;
    let item = {
      idcompra: idcompra,
      items: items
    }
    const body = item;
    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postComprasFormaPagoBulk', v))
    );
  }

  getComprasProductoCompleto(idsucursal?: number){
    let url = `${this.apiUrl}/compras-producto-completo`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasProductoCompleto', v)),
    );
  }

  getEgresosProductoCompleto(idsucursal?: number){
    let url = `${this.apiUrl}/egresos-producto-completo`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getEgresosProductoCompleto', v)),
    );
  }

  

  getComprasGastoCompleto(idsucursal?: number){
    let url = `${this.apiUrl}/compras-gasto-completo`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasGastoCompleto', v)),
    );
  }

  //compras-forma-pago-completo
  getComprasFormaPagoCompleto(idsucursal?: number){
    let url = `${this.apiUrl}/compras-forma-pago-completo`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`;
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasFormaPagoCompleto', v)),
    );
  }

  //productos-presentaciones-stock-sucursal
  getProductosPresentacionesStockSucursal(){
    const url = `${this.apiUrl}/productos-presentaciones-stock-sucursal`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosPresentacionesStockSucursal', v)),
    );
  }

  getProductosPresentacionesStockSucursalByProducto(idproducto){
    // productos-presentaciones-stock-sucursal/{idprod}
    const url = `${this.apiUrl}/productos-presentaciones-stock-sucursal/${idproducto}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosPresentacionesStockSucursalByProducto', v)),
    );
  }

  getPresentacionesExistentes(){
    //  @get('/productos/presentaciones-existentes')
    const url = `${this.apiUrl}/productos/presentaciones-existentes`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPresentacionesExistentes', v)),
    );
  }

  getProductosStock(idsucursal: number, idrubro?: number, idsubrubro?: number){
    //  @get('/productos/stock_completo')

    let idrubroOK = idrubro ? idrubro : 0;
    let idsubrubroOK = idsubrubro ? idsubrubro : 0;
    
    const url = `${this.apiUrl}/productos/stock_completo/${idsucursal}/${idrubroOK}/${idsubrubroOK}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosStock', v)),
    );
  }

  getCompras(idCompra: number){
    const url = `${this.apiUrl}/compras/${idCompra}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getCompras', v)),
    );
  }

  getComprasCompleto(idCompra: number){
    const url = `${this.apiUrl}/compras-completo/${idCompra}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getCompras', v)),
    );
  }

  getComprasProductoSemiCompleto(idCompra: number){
    const url = `${this.apiUrl}/compras-producto-semicompleto/${idCompra}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasProductoSemiCompleto', v)),
    );
  }

  getComprasProductoRecepcionSemiCompleto(idCompra: number){
    const url = `${this.apiUrl}/compras-producto-recepcion-semicompleto/${idCompra}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasProductoRecepcionSemiCompleto', v)),
    );
  }

  //compras-forma-pago-semicompleto/{id}
  getComprasFormaPagoSemiCompleto(idCompra: number){
    const url = `${this.apiUrl}/compras-forma-pago-semicompleto/${idCompra}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasFormaPagoSemiCompleto', v)),
    );
  }

  //compras-producto-recibido-completo
  getComprasProductoRecibidoCompleto(idsucursal?: number){
    let url = `${this.apiUrl}/compras-producto-recibido-completo`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`;
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComprasProductoRecibidoCompleto', v)),
    );
  }


  updateComprasConfirmacion(idcompra: any){
    //update-compras-confirmacion
    const url = `${this.apiUrl}/update-compras-confirmacion`;

    let item = {
      idcompra: idcompra,
    }

    return this.http.post<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
    
  }

  updateComprasRecepcion(idcompra: any){
    //update-compras-confirmacion
    const url = `${this.apiUrl}/update-compras-recepcion`;

    let item = {
      idcompra: idcompra,
    }

    return this.http.post<any>(url, item, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
    
  }

  postComprasPasarAPendiente(id: number){
    //compras-pasar-a-pendiente/{id}
    const url = `${this.apiUrl}/compras-pasar-a-pendiente/${id}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  postComprasCancelar(id: number){
    const url = `${this.apiUrl}/compras-cancelar/${id}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  //productos-presentaciones-stock-sucursal/{idsuc}/{idprod}
  getProductosPresentacionesStockSucursalSucProd(idSucursal: number, idProducto: number){
    const url = `${this.apiUrl}/productos-presentaciones-stock-sucursal/${idSucursal}/${idProducto}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getProductosPresentacionesStockSucursalSucProd', v)),
    );
  }

  postPreventasPago(id: number, data: any){
    //preventas-pago/{id}
    const url = `${this.apiUrl}/preventas-pago/${id}`;

    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postPreventasPago', v))
    );
  }

  // preventas-lineas-completo
  getPreventasLineasCompleto(){
    const url = `${this.apiUrl}/preventas-lineas-completo`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasLineasCompleto', v)),
    );
  }

  // /preventas-extendido/{estado}
  getPreventasExtendido(estado: number, idsucursal?: number){
    let url = `${this.apiUrl}/preventas-extendido/${estado}`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasExtendido', v)),
    );
  }

  //entrega-productos/bulk
  postEntregaProductosBulk(lote: any){
    //preventas-pago/{id}
    const url = `${this.apiUrl}/entrega-productos/bulk`;

    return this.http.post<any>(url, lote, httpOptions).pipe(
      tap(v => console.log('postEntregaProductosBulk', v))
    );
  }

  getLogisticaSolicitudes(idsucursal: number | null, origen: number | null, destino: number | null, desde: string | null, hasta: string | null){
    let url = `${this.apiUrl}/logistica-solicitudes`;

    let query = ""

    if (idsucursal){
      query += `idsucursal=${idsucursal}`
    }

    if (origen){
      if (query.length > 0){
        query += `&origen=${origen}`
      }else{
        query += `origen=${origen}`
      }
    }

    if (destino){
      if (query.length > 0){
        query += `&destino=${destino}`
      }else{
        query += `destino=${destino}`
      }
    }

    if (desde){
      if (query.length > 0) query += `&desde=${desde}`; else query += `desde=${desde}`;
    }

    if (hasta){
      if (query.length > 0) query += `&hasta=${hasta}`; else query += `hasta=${hasta}`;
    }

    if (query.length > 0){
      url += "?" + query;
    }

    console.log("query", url);

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaSolicitudes', v)),
    );
  }

  //logistica-movimientos
  getLogisticaMovimientos(idsucursal?: number){
    let url = `${this.apiUrl}/logistica-movimientos`;

    if (idsucursal != null){
      url += `?idsucursal=${idsucursal}`
    }

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientos', v)),
    );
  }

  getLogisticaMovimientosVenta(idventa: number){
    let url = `${this.apiUrl}/logistica-movimientos-idventa?idventa=${idventa}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosVenta', v)),
    );
  }
  
  //@get('/logistica-movimientos-presentaciones-sol')
  getLogisticaMovimientosPresentacionesSol(){
    const url = `${this.apiUrl}/logistica-movimientos-presentaciones-sol`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosPresentacionesSol', v)),
    );
  }

  getLogisticaMovimientosPresentacionesEnv(){
    const url = `${this.apiUrl}/logistica-movimientos-presentaciones-env`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosPresentacionesEnv', v)),
    );
  }

  getLogisticaMovimientosPresentacionesRec(){
    const url = `${this.apiUrl}/logistica-movimientos-presentaciones-rec`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosPresentacionesRec', v)),
    );
  }

   //logistica-movimientos
   getLogisticaMovimientosById(id: number){
    const url = `${this.apiUrl}/logistica-movimientos/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientos', v)),
    );
  }
  
  //@get('/logistica-movimientos-presentaciones-sol')
  getLogisticaMovimientosPresentacionesSolById(id: number){
    const url = `${this.apiUrl}/logistica-movimientos-presentaciones-sol/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosPresentacionesSolById', v)),
    );
  }

  getLogisticaMovimientosPresentacionesEnvById(id: number){
    const url = `${this.apiUrl}/logistica-movimientos-presentaciones-env/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosPresentacionesEnvById', v)),
    );
  }

  getLogisticaMovimientosPresentacionesRecById(id: number){
    const url = `${this.apiUrl}/logistica-movimientos-presentaciones-rec/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getLogisticaMovimientosPresentacionesRecById', v)),
    );
  }

  getClienteHistorialPagos(id){
    // /clientes/historial/pagos/{id}
    const url = `${this.apiUrl}/clientes/historial/pagos/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getClienteHistorialPagos', v)),
    );

  }

  getClienteHistorialVentas(id){
    // /clientes/historial/ventas/{id}
    const url = `${this.apiUrl}/clientes/historial/ventas/${id}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getClienteHistorialVentas', v)),
    );
    
  }

  // clientes/deuda_total
  postClientesDeudores(param){
    const url = `${this.apiUrl}/clientes/deuda_total`;

    return this.http.post<any[]>(url, param, httpOptions).pipe(
      tap(v => console.log('postClientesDeudores', v)),
    );
  }

  postClienteDeudorDetalle(idCliente: number, param){
    const url = `${this.apiUrl}/clientes/deuda_detalle/${idCliente}`;

    return this.http.post<any[]>(url, param, httpOptions).pipe(
      tap(v => console.log('postClienteDeudorDetalle', v)),
    );
  }

  //

  ///logistica-movimientos/estado/{id}/{estado}'
  postLogisticaMovimientosEstado(id: number, estado: number, autoRecibir?: boolean){
    const url = `${this.apiUrl}/logistica-movimientos/estado/${id}/${estado}`;

    let body = {
      autoRecibir: autoRecibir ? autoRecibir : false
    }

    return this.http.post<any>(url, body, httpOptions).pipe(
      tap(v => console.log('postLogisticaMovimientosEstado', v))
    );
  }


  ///logistica-solicitudes/estado/{id}/{estado}
  postLogisticaSolicitudesEstado(id: number, estado: number){
    const url = `${this.apiUrl}/logistica-solicitudes/estado/${id}/${estado}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('postLogisticaSolicitudesEstado', v))
    );
  }

  // logistica-movimiento-nuevo
  postLogisticaMovimientoNuevoSolicitud(data: any){

    console.log("data solicitud", data);

    const url = `${this.apiUrl}/logistica-movimiento-nuevo-solicitud`;

    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postLogisticaMovimientoNuevoSolicitud', v))
    );
  }

  postLogisticaMovimientoNuevoEnvio(data: any){

    console.log("data envio", data);

    const url = `${this.apiUrl}/logistica-movimiento-nuevo-envio`;

    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postLogisticaMovimientoNuevoEnvio', v))
    );
  }

  postLogisticaMovimientoSolicitar(data: any){
    const url = `${this.apiUrl}/logistica-movimiento-solicitar`;

    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postLogisticaMovimientoSolicitar', v))
    );
  }

  postLogisticaMovimientoEnviar(data: any){
    const url = `${this.apiUrl}/logistica-movimiento-enviar`;

    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postLogisticaMovimientoEnviar', v))
    );
  }

  postLogisticaMovimientoRecibir(data: any){
    const url = `${this.apiUrl}/logistica-movimiento-recibir`;

    return this.http.post<any>(url, data, httpOptions).pipe(
      tap(v => console.log('postLogisticaMovimientoRecibir', v))
    );
  }


  postLineaCambiarEstado(idlinea: number, estado: number){
    const url = `${this.apiUrl}/linea-cambiar-estado/${idlinea}/${estado}`;

    return this.http.post<any>(url, null, httpOptions).pipe(
      tap(v => console.log('postLineaCambiarEstado', v))
    );
  }

  postPreventasReporteGeneral(param: any){
    const url = `${this.apiUrl}/preventas-reporte-general`;
    return this.http.post<any>(url, param, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  postPreventasReporteFormaPago(param: any){
    const url = `${this.apiUrl}/preventas-reporte-forma-pago`;
    return this.http.post<any>(url, param, httpOptions).pipe(
      tap(v => console.log('test', v))
    );
  }

  deleteAllProductoPresentacionesFromProducto(idproducto: number){
    // /productos-presentaciones/todas/{idproducto}
    const url = `${this.apiUrl}/productos-presentaciones/todas/${idproducto}`;
    return this.http.delete<any>(url, httpOptions).pipe(
      tap(v => console.log('deleteAllProductoPresentacionesFromProducto', v)),
    );
  }

  getPreventasSolicitudes(idVenta: number){
    // /preventas/solicitudes/{idventa}
    const url = `${this.apiUrl}/preventas/solicitudes/${idVenta}`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getPreventasSolicitudes', v)),
    );
  }

  postReporteCajaDiaria(param: any){
    const url = `${this.apiUrl}/reporte-caja-diaria`;

    return this.http.post<any[]>(url, param, httpOptions).pipe(
      tap(v => console.log('postReporteCajaDiaria', v)),
    );
  }
  //reporte-caja-diaria

  postComisionesActualizar(data: any){
    // comisiones-actualizar
    const url = `${this.apiUrl}/comisiones-actualizar`;

    return this.http.post<any[]>(url, data, httpOptions).pipe(
      tap(v => console.log('postComisionesActualizar', v)),
    );
  }


  ///// comisiones/todas/simple
  getComisionesListSimple(){
    // /preventas/solicitudes/{idventa}
    const url = `${this.apiUrl}/comisiones/todas/simple`;

    return this.http.get<any[]>(url, httpOptions).pipe(
      tap(v => console.log('getComisionesListSimple', v)),
    );
  }


  ///////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////
  //otros utiles

  private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private log(message: string) {
    console.log(this.TAG, message);
  }

}


