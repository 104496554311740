import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-vendedores-abm',
  templateUrl: './vendedores-abm.component.html',
  styleUrls: ['./vendedores-abm.component.scss']
})
export class VendedoresAbmComponent implements OnInit {

  displayedColumns: string[] = ['idvendedor', 'nombre', 'domicilio', 'nombre_localidad', 'codigo_postal', 'telefono', 'correo_electronico', 'sucursal_descripcion', 'observaciones', 'porcentaje_comision', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog,
    private snackBarService: SnackBarService

  ) {
    
  }

  ngOnInit(): void {
    this.cargarTabla();
  }

  cargarTabla(){
    this.apiService.getVendedoresCompletoList().subscribe( (o: any[]) => {
      this.dataSource.data = o;
    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/vendedores/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/vendedores/editar/${row.idvendedor}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/vendedores/editar/${element.idvendedor}`,);
  }

  clickEliminar(element: any){
    this.dialogBorrar("Confirmar", `¿Esta seguro que desea eliminar el vendedor ${element.nombre}?`, element);

  }

  dialogBorrar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        this.borrar(elemento)
      } 
    });

  }

  borrar(element: any){
    console.log(element);
    this.apiService.deleteVendedores(element).subscribe(r => {
      console.log("result", r);
      // Cannot delete or update a parent row: a foreign key constraint fails
      this.cargarTabla();
    }, (error: any) => {
      console.log("error", error);
      this.snackBarService.errorMessage(`No es posible borrar este vendedor. Posiblemente haya referencias al mismo. ${error.message}`)
    });

  }


}

const ELEMENT_DATA: any[] = [];

