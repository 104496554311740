<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw; display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h2>{{nuevo.uid == "" ? 'Nuevo Usuario' : ('Editar Usuario #' + nuevo.email) }}</h2>
    </div>

    <div class="example-form" style="display: flex; flex-direction: column; max-width: 550px;">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" value="" [(ngModel)]="nuevo.nombre" name="nombre" autocomplete="nope">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Apellido</mat-label>
            <input matInput placeholder="Apellido" value="" [(ngModel)]="nuevo.apellido" name="apellido" autocomplete="nope">
        </mat-form-field>
        
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>email</mat-label>
            <input matInput placeholder="Telefono" value="" [(ngModel)]="nuevo.email" name="email" autocomplete="nope" [disabled]='edicion'>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Sucursal Por Defecto</mat-label>
            <mat-select [(ngModel)]="nuevo.idsucursal">
                <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
            <mat-form-field style="width: 100%;" appearance="outline" >
                <mat-label>Vendedor Por Defecto</mat-label>
                <input matInput type="text" [(ngModel)]="vendedor.nombre" readonly>
                <button matSuffix mat-icon-button (click)="clickSeleccionarVendedor()">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>


        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Observaciones</mat-label>
            <input matInput placeholder="Observaciones" value="" [(ngModel)]="nuevo.obs" name="obs" autocomplete="nope">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline" *ngIf="!edicion">
            <mat-label>Contraseña</mat-label>
            <input matInput placeholder="Contraseña" type="password" value="" [(ngModel)]="password.pass" name="password">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline" *ngIf="!edicion">
            <mat-label>Repita Contraseña</mat-label>
            <input matInput placeholder="Repita Contraseña" type="password" value="" [(ngModel)]="password.passrep" name="rpassword">
        </mat-form-field>

        

    </div>

    <div>
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>