<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
        
        <!--
        <ng-container matColumnDef="idrubroproducto">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idrubroproducto}} </td>
        </ng-container>
        -->
    
        <ng-container *ngFor="let col of columnas; let colIndex = index" matColumnDef="{{col.nombre}}">
            <th mat-header-cell *matHeaderCellDef>{{col.descripcion}}</th>
            <td mat-cell *matCellDef="let element ">

                <ng-container *ngIf="col.nombre == 'vendedor'">
                    {{element[col.nombre]}}
                </ng-container>
                
                <ng-container *ngIf="col.nombre != 'vendedor'">
                    <input type="number" class="input-cantidad"  min="0" matInput autocomplete="off" (change)='changeComision(element, col.nombre, $event.target.value, $event)' [value]="element[col.nombre]" placeholder="0">
                </ng-container>

            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>