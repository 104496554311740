<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>

<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 16px;">
        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; width: 100%;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; flex-grow: 1;">
                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Cliente</mat-label>
                        <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
            <!--
            <div>
                <button mat-raised-button color="primary" (click)="exportarExcel()">Exportar a Excel</button>
            </div>
            -->
        </div>
    </div>

    <mat-tab-group style="width: 100%; margin-bottom: 16px;" animationDuration="0ms">

        <mat-tab label="Ventas (Compra realizada por el Cliente)">
            <div style="margin-top: 16px;">
                <table mat-table [dataSource]="dataSourceVentas" class="mat-elevation-z8" style="width: 100%;">

                    <!-- Position Column -->
                    <ng-container matColumnDef="idventa">
                    <th mat-header-cell *matHeaderCellDef> Id Venta </th>
                    <td mat-cell *matCellDef="let element"> {{element.idpreventa}} </td>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="vendedor">
                    <th mat-header-cell *matHeaderCellDef> Vendedor </th>
                    <td mat-cell *matCellDef="let element"> {{element.vendedor_nombre}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="domicilio">
                    <th mat-header-cell *matHeaderCellDef> Domicilio </th>
                    <td mat-cell *matCellDef="let element"> {{element.domicilio}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="nombre_localidad">
                    <th mat-header-cell *matHeaderCellDef> Localidad </th>
                    <td mat-cell *matCellDef="let element"> {{element.localidad_nombre}} </td>
                    </ng-container>
           
                    <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef> Fecha </th>
                    <td mat-cell *matCellDef="let element"> {{element._fecha}} </td>
                    </ng-container>

                    <ng-container matColumnDef="sucursal">
                    <th mat-header-cell *matHeaderCellDef> Sucursal </th>
                    <td mat-cell *matCellDef="let element"> {{element.sucursal_nombre}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="observaciones">
                    <th mat-header-cell *matHeaderCellDef> Observaciones </th>
                    <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
                    </ng-container>
    

                    <ng-container matColumnDef="opciones">
                        <th mat-header-cell *matHeaderCellDef> Opciones </th>
                        <td mat-cell *matCellDef="let element">
                            
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsVentas"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsVentas;"></tr>
                </table>
            </div>
        </mat-tab>
        
        <mat-tab label="Pagos">
            <div style="margin-top: 16px;">
                <table mat-table [dataSource]="dataSourcePagos" class="mat-elevation-z8" style="width: 100%;">

                    <!-- Position Column -->
                    <ng-container matColumnDef="idventapago">
                    <th mat-header-cell *matHeaderCellDef> id venta pago </th>
                    <td mat-cell *matCellDef="let element"> {{element.id_venta_pago}} </td>
                    </ng-container>
                
                    <!-- Name Column -->
                    <ng-container matColumnDef="tipo">
                    <th mat-header-cell *matHeaderCellDef> Tipo </th>
                    <td mat-cell *matCellDef="let element"> {{element.tipo_descripcion}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="importe">
                    <th mat-header-cell *matHeaderCellDef> Importe </th>
                    <td mat-cell *matCellDef="let element"> {{fcNum(element.importe)}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="recargo">
                    <th mat-header-cell *matHeaderCellDef> Recargo </th>
                    <td mat-cell *matCellDef="let element"> {{fcNum(element.recargo)}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="es_senia">
                    <th mat-header-cell *matHeaderCellDef> Es Seña </th>
                    <td mat-cell *matCellDef="let element"> {{element.senia ? 'Si': 'No'}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="idventa">
                    <th mat-header-cell *matHeaderCellDef> Id Venta </th>
                    <td mat-cell *matCellDef="let element"> {{element.idpreventa}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef> Fecha </th>
                    <td mat-cell *matCellDef="let element"> {{element._fecha}} </td>
                    </ng-container>
            
                    <ng-container matColumnDef="opciones">
                        <th mat-header-cell *matHeaderCellDef> Opciones </th>
                        <td mat-cell *matCellDef="let element">
                            
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPagos"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsPagos;"></tr>
                </table>
            </div>
        </mat-tab>
    
    </mat-tab-group>

</div>