<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw; display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h2>{{nuevo.idlocalidad == 0 ? 'Nueva Localidad' : ('Editar Localidad #' + nuevo.idlocalidad) }}</h2>
    </div>

    <div style="display: flex; flex-direction: column; max-width: 400px;">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" value="" [(ngModel)]="nuevo.nombre" name="nombre">
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Codigo Postal</mat-label>
            <input matInput placeholder="Codigo Postal" value="" [(ngModel)]="nuevo.codigo_postal" name="codigo_postal">
          </mat-form-field>
    </div>

    <div>
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>