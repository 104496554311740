<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Agregar Presentaciones
    </div>
    <h3>{{descripcion}} <span class="tip-desactivado" *ngIf="!activo">DESACTIVADO</span></h3>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    <div style="margin-top: 8px; display: flex; flex-direction: row;">
        <mat-form-field appearance="outline" style="flex-grow: 1;" >
            <input type="text" placeholder="Filtrar" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
        </mat-form-field>
    </div>
  
    <mat-dialog-content style="min-height: 400px; max-height: 40vh; padding-top: 4px;">
  
      <div class="mat-elevation-z2" style="margin: 0px 0px 4px 0px; ">
        <table mat-table [dataSource]="dataSource" style="width: 100%;">
          
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">
              <div class="dato-tabla">
                {{element.idproductopresentacion}}
              </div>
            </td>
          </ng-container>
  
          <!-- Name Column -->
          <ng-container matColumnDef="color">
            <th mat-header-cell *matHeaderCellDef>Color</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.color_descripcion}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="medida">
            <th mat-header-cell *matHeaderCellDef>Medida</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.medida_descripcion}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="precio">
            <th mat-header-cell *matHeaderCellDef>Precio Unitario</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{fcNum(element.precio)}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="stock">
            <th mat-header-cell *matHeaderCellDef>Stock</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.stock}}
                </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef>Cantidad</th>
            <td mat-cell *matCellDef="let element">
              <div style="display: flex; flex-direction: row;">
                <div style="display: flex; flex-direction: row; margin-left: 4px; margin-right: 12px;">
                    <input type="number" class="input-cantidad"  min="0" [ngClass]="{'input-cantidad-ok': element.cantidad > 0, 'input-cantidad-cero': element.cantidad <= 0}" matInput autocomplete="nope" (change)='changeCantidad(element, $event.target.value)' [value]="element.cantidad">
                    <button (click)="clickIncrementar(element, $event)">+</button>
                    <button (click)="clickDecrementar(element, $event)">-</button>
                </div>
                <div style="display: flex; flex-direction: row;" *ngIf="muestraImporte">
                  <span class="textbox"> 
                    $ <input type="number" class="input-importe" [disabled]="element.cantidad == 0" min="0" matInput autocomplete="nope" (change)='changeImporte(element, $event.target.value)' [value]="element.importe">
                  </span>
                </div>
              </div>
            </td>
            </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            
        </table>
  
        <div class="info-vacio" *ngIf="dataSource.data.length === 0">
          <div style="font-size: 16px;">
            No hay información
          </div>
        </div>
        
      </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>