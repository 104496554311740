<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Usuario</button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="uid">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.uid}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="apellido">
        <th mat-header-cell *matHeaderCellDef> Apellido </th>
        <td mat-cell *matCellDef="let element"> {{element.apellido}} </td>
        </ng-container>

        <ng-container matColumnDef="rol">
        <th mat-header-cell *matHeaderCellDef> Rol </th>
        <td mat-cell *matCellDef="let element"> {{element.rol}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> e-mail </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="vendedor">
        <th mat-header-cell *matHeaderCellDef> Vendedor </th>
        <td mat-cell *matCellDef="let element"> {{element.vendedor_nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
        <th mat-header-cell *matHeaderCellDef> Sucursal </th>
        <td mat-cell *matCellDef="let element"> {{element.sucursal_nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
        <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" (click)="clickEditar(element)">Editar</button> 
                <!--<button mat-raised-button class="boton-celda" (click)="clickEliminar(element)">Eliminar</button> -->
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    

</div>