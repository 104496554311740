import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ProductosModel } from 'src/app/models/productos-model';
import { RubrosProductoModel } from 'src/app/models/rubros-producto-model';
import { SubRubrosProductoModel } from 'src/app/models/subrubros-producto-model';
import { ColoresProductoModel } from 'src/app/models/colores-producto-model';
import { MedidasProductoModel } from 'src/app/models/medidas-producto-model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PreventaNuevoDialogComponent } from '../preventa-nuevo-dialog/preventa-nuevo-dialog.component';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { PreventaModel } from 'src/app/models/preventa-model';
import { PreventasProductosModel } from 'src/app/models/preventas-productos-model';
import { Router } from '@angular/router';
import { PreventaConfirmarComponent } from '../preventa-confirmar/preventa-confirmar.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientesCompletoModel } from 'src/app/models/clientes-completo-model';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { SelectorGenericoComponent } from 'src/app/features/generico/selector-generico/selector-generico.component';


@Component({
  selector: 'app-preventa-nuevo',
  templateUrl: './preventa-nuevo.component.html',
  styleUrls: ['./preventa-nuevo.component.scss']
})
export class PreventaNuevoComponent implements OnInit {


  listItems: any[] = [];

  listVendedores: VendedoresModel[] = [];

  listLocalidades: any[] = [];

  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  localidad = {
    nombre: "",
    idlocalidad: 0
  };

  precioTotal = 0;

  observaciones = "";

  cargando = false;


  listCliente: ClientesCompletoModel[] = [];
  filteredClientes: Observable<ClientesCompletoModel[]> = new Observable();

  cliente:ClientesCompletoModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    codigo_localidad: 0,
    nombre_localidad: "",
    codigo_postal: "",
    telefono: "",
    correo_electronico: "",
    observaciones: "",
  };

  formGroup: FormGroup;


  constructor(
    private _location: Location,
    private dialog: MatDialog,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    public router: Router,
    private _formBuilder: FormBuilder,

  ) {

    this.formGroup = this._formBuilder.group({
      clienteCtrl: ['', Validators.required]
    });

   }

  ngOnInit(): void {
    this.apiService.getVendedoresList().subscribe(x => {
      this.listVendedores = x;
    })

    this.apiService.getClientesCompletoList().subscribe(x => {
      this.listCliente = [];
      this.listCliente = x;
    });

    this.apiService.getLocalidadesList().subscribe(x => {
      this.listLocalidades = [];
      this.listLocalidades = x;
    })

    this.filteredClientes = this.formGroup.get("clienteCtrl")!.valueChanges.pipe(
      tap(value => {
        this.cliente = value;
        console.log("cliente", this.cliente);
      }),
      startWith(''),
      map(value => this._filter(value)),
    );

  }

  clickGuardar(){

    if (!(this.vendedor.idvendedor > 0)){
      this.snackBarService.errorMessage("El vendedor no es valido.");
      return;
    }

    if (this.listItems.length == 0){
      this.snackBarService.errorMessage("Debe ingresar al menos un producto .");
      return;
    }

    let preventa: any = {
      idpreventa: 0,
      idvendedor: this.vendedor.idvendedor,
      observaciones: this.observaciones,
      listItems: this.listItems,
      cliente: this.cliente
    }

    this.abrirDialogConfirmar(preventa);

  }

  clickBack(){
    this._location.back();
  }

  clickAgregarProducto(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '90vw';
    dialogConfig.maxWidth = "560px;"
    dialogConfig.height = '90vh';

    dialogConfig.data = {
      items: this.listItems,
      edicion: false,
      item: null,
      index: -1,
      vendedor: this.listVendedores.find(x => x.idvendedor == this.vendedor.idvendedor)
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(PreventaNuevoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventas", rr);
      if (rr != null){
        if (rr.item != null){
          this.listItems.push(rr.item);
          this.calcularPrecios();
        }
      }
    });
  }

  abrirDialogConfirmar(preventa: any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    /*
    dialogConfig.width = '90vw';
    dialogConfig.maxWidth = "560px;"
    dialogConfig.height = '90vh';
    */

    dialogConfig.data = {
      preventa: preventa
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(PreventaConfirmarComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventa confirmar", rr);
    });
  }

  clickProducto(item: any, i: number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '90vw';
    dialogConfig.maxWidth = "560px;"
    dialogConfig.height = '90vh';

    dialogConfig.data = {
      items: this.listItems,
      edicion: true,
      item: item,
      index: i
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(PreventaNuevoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr != null){
        if (rr.item != null && rr.index != null){
          this.listItems[rr.index] = rr.item;
          this.calcularPrecios();
        }
      }
    });
  }

  clickEliminar(event: any, item: any, index: number){
    event.stopPropagation();
    console.log("borrar", item);

    this.listItems.splice(index,1);

    this.calcularPrecios();

  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){


          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }

        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }
        
      }
    });
  }

  clickSeleccionarCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listCliente.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: true,
      referenciaNuevo: "cliente"
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          let clienteSel = this.listCliente.find(x => x.idcliente == rr.seleccion.id);

          if (clienteSel == null){
            this.apiService.getClientesCompletoList().subscribe(x => {
              this.listCliente = [];
              this.listCliente = x;
              let cs = this.listCliente.find(x => x.idcliente == rr.seleccion.id);
              this.cliente = cs as ClientesCompletoModel;
            });
          }else{
            this.cliente = clienteSel as ClientesCompletoModel
          }

        }else{
          this.cliente = {
            idcliente: 0,
            nombre: "",
            domicilio: "",
            codigo_localidad: 0,
            nombre_localidad: "",
            codigo_postal: "",
            telefono: "",
            correo_electronico: "",
            observaciones: "",
          };
        }
        
      }
    });
  }

  clickSeleccionarLocalidad(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listLocalidades.forEach(x => {
      let item = {
        id: x.idlocalidad,
        nombre: `${x.nombre}(${x.codigo_postal})`
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Localidad",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Localidad"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){


          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }

        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }
        
      }
    });
  }

  calcularPrecios(){
    let sum = 0;
    this.listItems.forEach(p => {
      let sumProd = 0;
      p.items.forEach((i: any) => {
        let precioPresentacion = i.precio * i._agregados_cantidad;
        sumProd = sumProd + precioPresentacion;
      });
      p.totalPrecioFinal = sumProd;
      sum = sum + Number(p.totalPrecioFinal);
    });
    this.precioTotal = sum;
  }

  private _filter(value: string): ClientesCompletoModel[] {
    const filterValue = value.toLowerCase();
    return this.listCliente.filter(cliente => cliente.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayProperty(value: ClientesCompletoModel) {
    return value.nombre;
  }

}


