import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';

@Component({
  selector: 'app-productos-stock',
  templateUrl: './productos-stock.component.html',
  styleUrls: ['./productos-stock.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductosStockComponent implements OnInit {

  displayedColumns: string[] = ['idproductopresentacion', 'productoDescripcion', 'colorDescripcion', 'medidaDescripcion', 'rubroDescripcion', 'subrubroDescripcion', 'sucursalDescripcion', 'cantidad'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  listTipoPrecio: any[] = [];

  listSubRubro: any[] = [];
  listRubro: any[] = [];
  //listStock: any[] = [];
  listSucursales: any[] = [];
  listPresentaciones: any[] = [];

  mapStock: Map<number, any> = new Map();

  stockInit: any[] = [];

  tipoPrecio?: TiposDePrecioModel;

  rubroSelected = {
    id: 0,
    nombre: ""
  }

  subRubroSelected = {
    id: 0,
    nombre: ""
  }

  sucursalSelected = {
    id: 0,
    nombre: ""
  }



  dataCompleta: any[] = [];

  cargando = false;

  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog
  ) {

    let obsTiposDePrecio = this.apiService.getTiposDePrecioList();
    let obsSubRubros = this.apiService.getSubRubrosList();
    let obsRubros = this.apiService.getRubrosList();
    let obsSucursales = this.apiService.getSucursalesList();

    this.cargando = true;

    combineLatest([obsTiposDePrecio, obsSubRubros, obsRubros, obsSucursales]).subscribe(x => {
      this.listTipoPrecio = x[0];
      if(x[0].length > 0){
        this.tipoPrecio = x[0][0];
      }
      this.listSubRubro = x[1];
      this.listRubro = x[2];
      this.listSucursales = x[3];

      console.log("listSuc", this.listSucursales);

      this.listSucursales.forEach(xx => {
        this.stockInit.push({
          idsucursal: xx.idsucursal,
          descripcion: xx.descripcion,
          cantidad: 0
        });
      });

      this.cargando = false;

      this.dataCompleta = this.listPresentaciones;

      this.cargarTabla();

    });

  }

  ngOnInit(): void {
    
  }

  cargarTabla(){
    
    //this.cargarFiltradoRubroSubRubro();

    this.dataSource.data = this.dataCompleta;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }

  cargarPresentaciones(){

    this.cargando = true;

    this.listPresentaciones = [];
    this.dataSource.data = [];

    this.apiService.getProductosStock(this.sucursalSelected.id, this.rubroSelected.id, this.subRubroSelected.id).subscribe(x => {

      this.listPresentaciones = x;

      this.listPresentaciones = this.listPresentaciones.map(x => {
        x._busqueda = (x.producto_nombre + " " + x.producto_color + " " + x.producto_medida).toLocaleLowerCase();
        return x;
      })

      this.cargando = false;

      this.dataCompleta = this.listPresentaciones;

      this.cargarTabla();

    })

  }

  cargarTabla2(){
    this.cargando = true;
    this.apiService.getRelProductosPresentaciones().subscribe( (o: any[]) => {

      let mapList: Map<number, any> = new Map();

      o.forEach(x => {
        if (mapList.has(x.idproducto)){
          let contenido = mapList.get(x.idproducto);
          let presentacion: any = {
            idproductopresentacion: x.idproductopresentacion,
            colorDescripcion: x.color_descripcion,
            medidaDescripcion: x.medida_descripcion,
            idmedidaProducto: x.idmedidaproducto,
            idcolorproducto: x.idcolorproducto,
            precio: x.precio,
            cantidad: x.cantidad,
            stock: JSON.parse(JSON.stringify(this.stockInit))
          };
          contenido.presentaciones.push(presentacion);
          mapList.set(x.idproducto, contenido);
        }else{
          let contenido: any = {
            idproducto: x.idproducto,
            productoDescripcion: x.producto_descripcion,
            idrubroproducto: x.idrubroproducto,
            rubroDescripcion: x.rubro_descripcion,
            idsubrubroproducto: x.idsubrubroproducto,
            subrubroDescripcion: x.subrubro_descripcion,
            _busqueda: x.producto_descripcion.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US'),
            presentaciones: []
          };
          let presentacion: any = {
            idproductopresentacion: x.idproductopresentacion,
            colorDescripcion: x.color_descripcion,
            medidaDescripcion: x.medida_descripcion,
            idmedidaProducto: x.idmedidaproducto,
            idcolorproducto: x.idcolorproducto,
            precio: x.precio,
            cantidad: x.cantidad,
            stock: JSON.parse(JSON.stringify(this.stockInit))
          };

          if (x.idproductopresentacion != null){
            contenido.presentaciones.push(presentacion);
          }
          
          mapList.set(x.idproducto, contenido);
        }
      });

      let vals =[ ...mapList.values() ];

      vals.forEach(vv => {
        vv.presentaciones.forEach((vp: any) => {
          if (this.mapStock.has(vp.idproductopresentacion)){
            let st: any[] = this.mapStock.get(vp.idproductopresentacion);
            vp.stock.forEach((s: any) => {
              let existe = st.find(j => j.idsucursal == s.idsucursal);
              if (existe != null){
                s.cantidad = existe.cantidad;
              }
            })
          }
        })
      })

      /*
      let mapStock: Map<number, any> = new Map();
      vals.forEach(vv => {
        let stockProducto = this.listStock.filter(s => s.idproducto == vv.idproducto);
        stockProducto.forEach(xxx => {

          if (mapStock.has(xxx.idproductopresentacion)){

          }else{
            let stock = {
              idproductopresentacion: xxx.idproductopresentacion,
              productoDescripcion: xxx.producto_descripcion,
              medidaDescripcion: xxx.medida_descripcion,
              colorDescripcion: xxx.color_descripcion,
              stock: []
            }

            mapStock.set(xxx.idproductopresentacion, stock);
          }
        });
        vv.stock = stockProducto;
      });
      */
      

      console.log("vals", vals);

      this.dataCompleta = vals;

      this.cargando = false;

      this.cargarFiltradoRubroSubRubro();

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/productos/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/productos/editar/${row.idproducto}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/productos/editar/${element.idproducto}`,);
  }

  clickEliminar(element: any){
    console.log("element", element);
    this.dialogBorrar("Confirmar", `¿Esta seguro que desea eliminar el producto ${element.productoDescripcion}?`, element);
  }

  dialogBorrar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        this.borrar(elemento)
      } 
    });

  }

  borrar(elemento: any){
    console.log("borrar ->", elemento);

    this.apiService.deleteProducto(elemento).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    });

  }

  sortData(sort: any){
    console.log("event sort", sort);

    this.dataSource.data.sort((a: any, b: any) => {

      console.log("active", sort.active);
      console.log("a", a);
      console.log("b", b);

      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'idproducto': return compare(a.idproducto, b.idproducto, isAsc);
        case 'productoDescripcion': return compare(a.productoDescripcion, b.productoDescripcion, isAsc);
        case 'rubroDescripcion': return compare(a.rubroDescripcion, b.rubroDescripcion, isAsc);
        case 'subrubroDescripcion': return compare(a.subrubroDescripcion, b.subrubroDescripcion, isAsc);
        default: return 0;
      }
      
   });

  }

  filtroRapido(filterValue: any) {

    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
    
  }

  seleccionarRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listRubro.forEach(x => {
      let item = {
        id: x.idrubroproducto,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Rubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Rubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.rubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.rubroSelected.id != rr.seleccion.id){
            this.subRubroSelected.id = 0;
            this.subRubroSelected.nombre = "";
          }
          this.rubroSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.rubroSelected = {nombre: "", id: 0};
          this.subRubroSelected = { nombre: "", id: 0};
        }
        
      }
    });

  }

  seleccionarSubRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    if (this.rubroSelected.id == 0){
      return;
    }

    let list = this.listSubRubro.filter(x => x.idrubroproducto == this.rubroSelected.id);

    list.forEach(x => {
      let item = {
        id: x.idsubrubroproducto,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de SubRubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo SubRubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.subRubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.subRubroSelected = {
            nombre: rr.seleccion.nombre,
            id: rr.seleccion.id 
          }
        }else{
          this.subRubroSelected = {nombre: "", id: 0};
        }
        
      }
    });

  }

  seleccionarSucursal(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.sucursalSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.sucursalSelected.id != rr.seleccion.id){
            this.sucursalSelected.id = 0;
            this.sucursalSelected.nombre = "";
          }
          this.sucursalSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.sucursalSelected = {nombre: "", id: 0};
        }
      }
    });
  }

  cargarFiltradoRubroSubRubro(){

    let dataFiltrada: any[] = []

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id != 0){
      dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id && x.idsubrubroproducto == this.subRubroSelected.id);
    }

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id);
    }

    if (this.rubroSelected.id == 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.dataCompleta;
    }

    if (this.sucursalSelected.id != 0){
      dataFiltrada = dataFiltrada.filter(x => x.idsucursal == this.sucursalSelected.id);
    }

    this.dataSource.data = dataFiltrada;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }
  
  clickClearRubro(){
    this.rubroSelected = {id: 0, nombre: ""};
    this.subRubroSelected = {id: 0, nombre: ""};

  }

  clickClearSubRubro(){
    this.subRubroSelected = {id: 0, nombre: ""};

  }

  clickClearSucursal(){

    this.sucursalSelected = {id: 0, nombre: ""};

  }


  clickConsultar(){
    this.cargarPresentaciones();
  }

  
 

}
/*
function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};
*/

function compare(a: string, b: string, isAsc: boolean){
  return a.localeCompare(b)
}

const ELEMENT_DATA: any[] = [];


