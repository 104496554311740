import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-productos-precios',
  templateUrl: './productos-precios.component.html',
  styleUrls: ['./productos-precios.component.scss']
})
export class ProductosPreciosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
