<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>

<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Cliente</button>
    </div>

    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 16px;">
        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; width: 100%;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; flex-grow: 1;">
                <mat-form-field style="width: 60vw; max-width: 350px; margin-right: 8px;" appearance="outline" >
                    <mat-label>Localidad</mat-label>
                    <input matInput type="text" [(ngModel)]="localidad.nombre" readonly>
                    <button *ngIf="localidad.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearLocalidad()">
                        <mat-icon>close</mat-icon>
                    </button>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarLocalidad()">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button color="primary" (click)="exportarExcel()">Exportar a Excel</button>
            </div>
        </div>
    </div>

    <div style="display: flex; flex-direction: row;">
        <div style="flex-grow: 0.5;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event)" autocomplete="null">
            </mat-form-field>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idcliente">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idcliente}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="domicilio">
        <th mat-header-cell *matHeaderCellDef> Domicilio </th>
        <td mat-cell *matCellDef="let element"> {{element.domicilio}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre_localidad">
        <th mat-header-cell *matHeaderCellDef> Localidad </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre_localidad}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo_postal">
        <th mat-header-cell *matHeaderCellDef> Codigo Postal </th>
        <td mat-cell *matCellDef="let element"> {{element.codigo_postal}} </td>
        </ng-container>

        <ng-container matColumnDef="telefono">
        <th mat-header-cell *matHeaderCellDef> Telefono </th>
        <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
        </ng-container>

        <ng-container matColumnDef="correo_electronico">
        <th mat-header-cell *matHeaderCellDef> Correo Electronico </th>
        <td mat-cell *matCellDef="let element"> {{element.correo_electronico}} </td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
        <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" (click)="clickEditar(element)">Editar</button> 
                <button mat-raised-button class="boton-celda" (click)="clickEliminar(element)">Eliminar</button> 
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    

</div>