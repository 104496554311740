<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Gasto</button>
    </div>

    <!--
    <div style="display: flex; flex-direction: row;">
        <div style="flex-grow: 0.6; padding:6px;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
            </mat-form-field>
        </div>
    </div>
    -->

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idcompra">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idcompra}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="descripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Descripción </th>
        <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Sucursal </th>
        <td mat-cell *matCellDef="let element"> {{element.sucursalDescripcion}} </td>
        </ng-container>
        
        <ng-container matColumnDef="proveedor">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Proveedor </th>
        <td mat-cell *matCellDef="let element"> {{element.proveedorDescripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="importe">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Importe </th>
        <td mat-cell *matCellDef="let element"> {{element.importe}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element._fecha}} </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <!--
                    <button mat-raised-button class="boton-celda" (click)="clickEditar(element, $event)">Editar</button> 
                -->
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetalle">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div style="display: flex;flex-direction: column; margin-bottom: 24px; margin-top: 16px;">
                    <div>
                        <h3>Formas de Pago</h3>
                        <div *ngIf="element.formasPago.length > 0" style="width: 100%;">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Forma de Pago</th>
                                    <th>Importe</th>
                                    <th>Observaciones</th>
                                </tr>
                                <tr *ngFor="let item of element.formasPago">
                                    <td>{{item.tipoPrecioDescripcion}}</td>
                                    <td style="text-align: right;">{{item.importe}}</td>
                                    <td style="text-align: left;">{{item.observaciones}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.formasPago.length == 0" style="margin: 12px;">
                            No hay formas de pago
                        </div>
                    </div>
                </div>
            </div>
        </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>