import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { zipAll } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SharedService } from 'src/app/services/shared.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { toFechaApiRequest, toFechaCorta, toFechaLarga } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';

@Component({
  selector: 'app-logistica-movimiento',
  templateUrl: './logistica-movimiento.component.html',
  styleUrls: ['./logistica-movimiento.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LogisticaMovimientoComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }
  
  displayedColumns: string[] = ['id', 'fecha', 'origen', 'destino', 'estado', 'observaciones', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  // 0 pendiente, 1 confirmado, 2 pagados

  estados = [
    'Pendiente de Solicitar', 
    'Solicitado / Pendiente de Envio', 
    'Pendiente Recepcion / Enviado', 
    'Recibido / Finalizado', 
    '',
    '',
    '',
    '',
    '',
    '',
    'Solicitud Cancelada',
    'Envio Cancelado'];

  vals: any[] = [];

  filtro = {
    sucursalOrigenNombre: "",
    sucursalOrigenId: 0,
    sucursalDestinoNombre: "",
    sucursalDestinoId: 0,
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  listSucursales: any[] = [];

  param = {
    desde: "",
    hasta: "",
    origen: "",
    destino: ""
  }

  cargando = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private pdfService: PdfService

  ) {
    moment.locale('es');

    this.sucursalActual = this.sharedService.getSucursalActual();


    //this.sharedService.

  }

  ngOnInit(): void {
    this.cargarTabla(1);
  }

  cargarTabla(estado?: number){

    this.vals = [];
    this.dataSource.data = this.vals;


    let movimientos = this.apiService.getLogisticaMovimientos(this.sucursalActual.sucursalId);
    let solicitudes = this.apiService.getLogisticaMovimientosPresentacionesSol();
    let enviados = this.apiService.getLogisticaMovimientosPresentacionesEnv();
    let recibidos = this.apiService.getLogisticaMovimientosPresentacionesRec();
    let sucursales = this.apiService.getSucursalesList();

    this.cargando = true;

    combineLatest([movimientos, solicitudes, enviados, recibidos, sucursales]).subscribe((hh: any[]) =>{

      let p: any[] = hh[0];
      let s: any[] = hh[1];
      let e: any[] = hh[2];
      let r: any[] = hh[3];

      this.listSucursales = hh[4];

      p.forEach(x => {
        x._visible = (x.estado == 0 && x.origen == this.sucursalActual.sucursalId) ? false : true;
        x._estado = this.estados[x.estado];
        x._fecha = toFechaCorta(x.fecha);
        x._fechax = toFechaLarga(x.fecha);
        x.solicitados = s.filter(z => z.idlogisticamovimiento == x.idlogisticamovimiento);
        x.enviados = e.filter(z => z.idlogisticamovimiento == x.idlogisticamovimiento);
        x.recibidos = r.filter(z => z.idlogisticamovimiento == x.idlogisticamovimiento);
      });

      
      p = p.filter(x => x._visible)

      this.vals = p;

      console.log("data", this.vals);

      //this.dataSource.data = this.vals;

      this.aplicarFiltro();

      this.cargando = false;

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener datos de logistica. " + e.message);
      this.cargando = false;
    })

    
  }

  clickTest(e: any, event: any){
    console.log("test", e);
  }

  clickEditarSolicitud(e: any, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`logistica/movimiento/solicitar/${e.idlogisticamovimiento}`, {});
  }

  clickSolicitar(e: any, event: any){
    event.stopPropagation();

    let fc = () => {
      let nuevoEstado = 1
      this.apiService.postLogisticaMovimientosEstado(e.idlogisticamovimiento, nuevoEstado).subscribe(x => {
        if (x){
          e.estado = nuevoEstado;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a realizar la solicitud del movimiento. Se le notificara a la sucursal de origen el envio del producto a la sucursal destino. Desea continuar?", fc);

  }

  clickEnviar(e: any, event: any){
    event.stopPropagation();

    let fc = () => {
      let nuevoEstado = 2
      this.apiService.postLogisticaMovimientosEstado(e.idlogisticamovimiento, nuevoEstado).subscribe(x => {
        if (x){
          e.estado = nuevoEstado;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a confirmar el envío. Desea continuar?", fc);

  }

  clickEnviarVendidos(e: any, event: any){
    event.stopPropagation();

    let fc = () => {
      let nuevoEstado = 2
      this.apiService.postLogisticaMovimientosEstado(e.idlogisticamovimiento, nuevoEstado).subscribe(x => {
        if (x){
          e.estado = nuevoEstado;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a confirmar el envío de productos para completar la venta de estos productos vendidos en otra sucursal. Desea continuar?", fc);

  }

  clickRecibir(e: any, event: any){
    event.stopPropagation();

    let fc = () => {
      let nuevoEstado = 3
      this.apiService.postLogisticaMovimientosEstado(e.idlogisticamovimiento, nuevoEstado).subscribe(x => {
        if (x){
          e.estado = nuevoEstado;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a confirmar la recepción. Desea continuar?", fc);

  }

  clickRecibirVendidos(e: any, event: any){
    event.stopPropagation();

    let fc = () => {
      let nuevoEstado = 3
      this.apiService.postLogisticaMovimientosEstado(e.idlogisticamovimiento, nuevoEstado, true).subscribe(x => {
        if (x){
          e.estado = nuevoEstado;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a confirmar la recepción de estos productos que fueron vendidos aquí. Desea continuar?", fc);

  }

  clickCancelar(e: any, event: any, est: number){
    event.stopPropagation();

    let mensaje = ['la solicitud', 'el envío']

    let fc = (estado: number) => {

      let nuevoEstado = 10;
      
      switch(estado){
        case 0:
          nuevoEstado = 10;
          break;
        case 1:
          nuevoEstado = 11;
          break;
      }

      this.apiService.postLogisticaMovimientosEstado(e.idlogisticamovimiento, nuevoEstado).subscribe(x => {
        if (x){
          e.estado = nuevoEstado;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", `Va a cancelar ${mensaje[est]} . Desea continuar?`, fc, est);

  }

  clickEditarEnvio(e: any, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`logistica/movimiento/enviar/${e.idlogisticamovimiento}`, {});
  }

  clickEditarRecepcion(e: any, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`logistica/movimiento/recibir/${e.idlogisticamovimiento}`, {});
  }


  dialogAccion(titulo: string, mensaje: string, fc: any, param?: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        if (param != null){
          fc(param);
        }else{
          fc();
        }
        
      } 
    });

  }


  clickExportarPdf(e: any){

    console.log("element", e);

    let id = e.idlogisticamovimiento;
    let origen = `${e.origen_descripcion}`;
    let destino = `${e.destino_descripcion}`;
    let fecha = `${e._fecha}`;
    //let fecha = `${e._fecha}`;
    //let sucursal = `${e.preventa_sucursal}`;
    let estado = `${e._estado}`;

    let solicitados: any = [['Producto', 'Color', 'Medida', 'Cantidad']];
    let enviados: any = [['Producto', 'Color', 'Medida', 'Cantidad']];
    let recibidos: any = [['Producto', 'Color', 'Medida', 'Cantidad']];

    let ss: any = e.solicitados.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`
      ]
      return x;
    })

    ss.forEach((x: any) => {
      solicitados.push(x);
    })

    let ee: any = e.enviados.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`
      ]
      return x;
    });

    ee.forEach((x: any) => {
      enviados.push(x);
    });

    let rr: any = e.recibidos.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`
      ]
      return x;
    });

    rr.forEach((x: any) => {
      recibidos.push(x);
    });

    let content: any[] = [
      { text: `Movimiento - #${id}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Fecha: ${fecha}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Origen: ${origen}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Destino: ${destino}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Estado: ${estado}`, fontSize: 11, margin:[0, 0, 0, 10] },
      
      { text: `Solicitados: `, fontSize: 11, margin:[0, 0, 0, 10] },
      solicitados.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: solicitados
        }
      } : { text: `Sin Solicitados`, fontSize: 11, margin:[10, 0, 0, 10] },
      { text: `Enviados: `, fontSize: 11, margin:[0, 0, 0, 10] },
      enviados.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: enviados
        }
      }: { text: `Sin Enviados`, fontSize: 11, margin:[10, 0, 0, 10] },
      { text: `Recibidos: `, fontSize: 11, margin:[0, 0, 0, 10] },
      recibidos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: recibidos
        }
      }: { text: `Sin Recibidos`, fontSize: 11, margin:[10, 0, 0, 10] },
    ]

    this.pdfService.generarPDF(content);

  }

  clickClear(tipo: string){

    if (tipo == "origen"){
      this.filtro.sucursalOrigenId = 0;
      this.filtro.sucursalOrigenNombre = "";
    }

    if (tipo == "destino"){
      this.filtro.sucursalDestinoId = 0;
      this.filtro.sucursalDestinoNombre = "";
    }

  }


  clickConsultar(){

    this.dataSource.data = [];

    let origen = this.filtro.sucursalOrigenId == 0 ? null : this.filtro.sucursalOrigenId;
    let destino = this.filtro.sucursalDestinoId == 0 ? null : this.filtro.sucursalDestinoId;

    this.param.origen = this.filtro.sucursalOrigenNombre;
    this.param.destino = this.filtro.sucursalDestinoNombre;

    this.cargarTabla(1);

    /*

    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    let desde = null;
    let hasta = null;

    if (mDesde.isValid()){
      desde = toFechaApiRequest(mDesde.toDate());
      this.param.desde = toFechaCorta(mDesde.toDate());
    }else{
      this.param.desde = "";
    }

    if (mHasta.isValid()){
      hasta = toFechaApiRequest(mHasta.toDate());
      this.param.hasta = toFechaCorta(mHasta.toDate());
    }else{
      this.param.hasta = "";
    }
    */
    

    /*
    let solicitudes = this.apiService.getLogisticaSolicitudes(this.sucursalActual.sucursalId, origen, destino, desde, hasta );
    combineLatest([solicitudes]).subscribe((hh: any[]) =>{


      //this.procesarSolicitudes(hh[0]);

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener datos de logistica. " + e.message);
    })
    */

  }

  clickSeleccionarSucursal(tipo: string){

    let idSeleccionPrevia  = 0;
    if (tipo == 'origen'){
      idSeleccionPrevia = this.filtro.sucursalOrigenId;
    }
    if (tipo == 'destino'){
      idSeleccionPrevia = this.filtro.sucursalDestinoId;
    }


    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: idSeleccionPrevia,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          if (tipo == "origen"){
            if (this.filtro.sucursalOrigenId != rr.seleccion.id){
              this.filtro.sucursalOrigenId = 0;
              this.filtro.sucursalOrigenNombre = "";
            }
            this.filtro.sucursalOrigenId = rr.seleccion.id;
            this.filtro.sucursalOrigenNombre = rr.seleccion.nombre;
          }

          if (tipo == "destino"){
            if (this.filtro.sucursalDestinoId != rr.seleccion.id){
              this.filtro.sucursalDestinoId = 0;
              this.filtro.sucursalDestinoNombre = "";
            }
            this.filtro.sucursalDestinoId = rr.seleccion.id;
            this.filtro.sucursalDestinoNombre = rr.seleccion.nombre;
          }
          
        }else{

          if (tipo == "origen"){
            this.filtro.sucursalOrigenId = 0;
            this.filtro.sucursalOrigenNombre = "";
          }

          if (tipo == "destino"){
            this.filtro.sucursalDestinoId = 0;
            this.filtro.sucursalDestinoNombre = "";
          }
          
        }
        //this.cargarFiltradoRubroSubRubro();
      }
    });
  }

  aplicarFiltro(){

    let filtrado = [];

    let fc = (ido: number, idd: number) => {
      if (this.filtro.sucursalOrigenId != 0 && this.filtro.sucursalDestinoId != 0){
        return this.filtro.sucursalOrigenId == ido && this.filtro.sucursalDestinoId == idd;
      }else{
        if (this.filtro.sucursalOrigenId == 0 && this.filtro.sucursalDestinoId == 0){
          return true;
        }else{
          if (this.filtro.sucursalOrigenId != 0){
            return this.filtro.sucursalOrigenId == ido;
          }
          if (this.filtro.sucursalDestinoId != 0){
            return this.filtro.sucursalDestinoId == idd;
          }
          return true
        }
      }
    }
    
    filtrado = this.vals.filter(v => fc(v.origen, v.destino))
    
    this.dataSource.data = filtrado;
  }


}

const ELEMENT_DATA: any[] = [];

