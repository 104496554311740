<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw; display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h2>{{nuevo.idvendedor == 0 ? 'Nuevo Vendedor' : ('Editar Vendedor #' + nuevo.idvendedor) }}</h2>
    </div>

    <div style="display: flex; flex-direction: column; max-width: 400px;">
        <mat-form-field appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput placeholder="Nombre" value="" [(ngModel)]="nuevo.nombre" name="nombre">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Domicilio</mat-label>
            <input matInput placeholder="Domicilio" value="" [(ngModel)]="nuevo.domicilio" name="domicilio">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Localidad</mat-label>
            <mat-select [(ngModel)]="nuevo.codigo_localidad" name="localidad">
                <mat-option *ngFor="let localidad of listLocalidades" [value]="localidad.idlocalidad">{{localidad.nombre}}</mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="Telefono" value="" [(ngModel)]="nuevo.telefono" name="telefono">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Correo Electronico</mat-label>
            <input matInput placeholder="Correo Electronico" value="" [(ngModel)]="nuevo.correo_electronico" name="correo_electronico">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Observaciones</mat-label>
            <input matInput placeholder="Observaciones" value="" [(ngModel)]="nuevo.observaciones" name="observaciones">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>% Comision</mat-label>
            <input matInput placeholder="% Comision" value="" type="number" [(ngModel)]="nuevo.porcentaje_comision" name="porcentaje_comision">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Sucursal por Defecto</mat-label>
            <mat-select [(ngModel)]="nuevo.idsucursal" name="sucursal">
                <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
            </mat-select>
        </mat-form-field>
        
    </div>

    <div>
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>