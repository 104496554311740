import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subrubros',
  templateUrl: './subrubros.component.html',
  styleUrls: ['./subrubros.component.scss']
})
export class SubrubrosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
