<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      {{ventana.titulo}}
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    <div *ngIf="permitirNuevo" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-end; align-items: center;">
        <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickNuevo()">{{ventana.tituloBotonNuevo}}</button>
    </div>

    <div style="margin-top: 8px;">
      <mat-form-field appearance="outline" style="width: 100%;" >
        <input type="text" placeholder="Filtrar" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
      </mat-form-field>
    </div>
  
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
  
      <div class="mat-elevation-z2" style="margin: 0px 0px 4px 0px; ">
        <table mat-table [dataSource]="dataSource" style="width: 100%;">
  
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="onChangeSelectGlobal($event)"
                [checked]="checkedGlobal">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                (change)="onChangeSelectItem($event, element)"
                [checked]="element.selected">
              </mat-checkbox>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>Id</th>
            <td mat-cell *matCellDef="let element">
              <div class="dato-tabla">
                {{element.id}}
              </div>
            </td>
          </ng-container>
  
          <!-- Name Column -->
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef>{{ventana.columnaNombre}}</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.nombre}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cantidad">
            <th mat-header-cell *matHeaderCellDef>{{ventana.columnaCantidad}}</th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.cantidad}}
                </div>
              </div>
            </td>
          </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                class="element-row"
                [class.selected-row]="selectedElement != null ? (selectedElement.id == row.id) : false"
                (click)="selectRow(row)"
                >
            </tr>

            <!--
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>-->
          
        </table>
  
        <div class="info-vacio" *ngIf="dataSource.data.length === 0">
          <div class="sin-informacion">
            No hay información
          </div>
        </div>
        
      </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>