<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Tipo de Reporte a Imprimir
    </div>
    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
        <div>
            <span>Seleccione el tipo de reporte que desea Imprimir</span>
            <div style="display: flex; margin:4px 0px;">
                
                <mat-radio-group [(ngModel)]="tipoReporte" style="display: flex; flex-direction: column; margin: 8px 0px;">
                    <mat-radio-button value="vendedor" style="margin:4px 0px;">Reporte de Vendedores</mat-radio-button>
                    <div style="display: flex; flex-direction: column; margin-left: 32px;">
                        <mat-checkbox [(ngModel)]="detalleComision" style="margin:4px 0px;" [disabled]="tipoReporte != 'vendedor'">Incluir Detalle Comisión</mat-checkbox>
                        <mat-checkbox [(ngModel)]="detalleFormaPago" style="margin:4px 0px;" [disabled]="tipoReporte != 'vendedor'">Incluir Detalle Forma de Pago</mat-checkbox>
                        <mat-checkbox [(ngModel)]="detalleArticulo" style="margin:4px 0px;" [disabled]="tipoReporte != 'vendedor'">Incluir Detalle Articulo</mat-checkbox>
                    </div>
                    <mat-radio-button value="producto" style="margin:4px 0px;">Reporte de Productos</mat-radio-button>
                    <div style="display: flex; flex-direction: column; margin-left: 32px;">
                        <!--
                        <mat-checkbox [(ngModel)]="detalleAgrupar" style="margin:4px 0px;" [disabled]="tipoReporte != 'producto'">Agrupar por Rubro y SubRubro</mat-checkbox>
                        -->
                        <mat-radio-group [(ngModel)]="tipoReporteProducto" [disabled]="tipoReporte != 'producto'" style="display: flex; flex-direction: column; margin: 8px 0px;">
                            <mat-radio-button value="simple" style="margin:4px 0px;">Sin Agrupación</mat-radio-button>
                            <mat-radio-button value="sucursal" style="margin:4px 0px;">Agrupar por Sucursal</mat-radio-button>
                            <!--
                            <div style="display: flex; flex-direction: column; margin-left: 32px;">
                                <mat-checkbox [(ngModel)]="agruparSucursalYRubroSubRubro" style="margin:4px 0px;" [disabled]="tipoReporteProducto != 'sucursal' || tipoReporte != 'producto'">SubAgrupar por Rubro</mat-checkbox>
                                <mat-checkbox [(ngModel)]="agruparSucursalYRubro" style="margin:4px 0px;" [disabled]="tipoReporteProducto != 'sucursal' || tipoReporte != 'producto'">SubAgrupar por Rubro y SubRubro</mat-checkbox>
                            </div>
                            -->
                            <mat-radio-group [(ngModel)]="tipoSubAgrupacion" [disabled]="tipoReporteProducto != 'sucursal' || tipoReporte != 'producto'" style="display: flex; flex-direction: column; margin: 0px 0px 0px 32px;">
                                <mat-radio-button value="no_subagrupar" style="margin:4px 0px;">Sin Sub Agrupación</mat-radio-button>
                                <mat-radio-button value="subagrupar_rubro" style="margin:4px 0px;">SubAgrupar por Rubro</mat-radio-button>
                                <mat-radio-button value="subagrupar_rubro_subrubro" style="margin:4px 0px;">SubAgrupar por Rubro y Subrubro</mat-radio-button>
                            </mat-radio-group>

                            <mat-radio-button value="rubro" style="margin:4px 0px;">Agrupar por Rubro</mat-radio-button>
                            <div style="display: flex; flex-direction: column; margin-left: 32px;">
                                <mat-checkbox [(ngModel)]="r_agruparRubroYSucursal" style="margin:4px 0px;" [disabled]="tipoReporteProducto != 'rubro' || tipoReporte != 'producto'">SubAgrupar por Sucursal</mat-checkbox>
                            </div>
                            <mat-radio-button value="rubrosubrubro" style="margin:4px 0px;">Agrupar por Rubro y SubRubro</mat-radio-button>
                            <div style="display: flex; flex-direction: column; margin-left: 32px;">
                                <mat-checkbox [(ngModel)]="rs_agruparRubroYSucursal" style="margin:4px 0px;" [disabled]="tipoReporteProducto != 'rubrosubrubro' || tipoReporte != 'producto'">SubAgrupar por Sucursal</mat-checkbox>
                            </div>
                            
                        </mat-radio-group>
                    </div>
                </mat-radio-group>
                
            </div>
        </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCancelar()">Cancelar</button>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>