<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Confirmar Pago</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2 *ngIf="modo=='confirmar'">Confirmar Pago #{{preventa.idpreventa}}</h2>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Observaciones</mat-label>
                <input matInput placeholder="Observaciones" value=""  type="text" [(ngModel)]="preventa.observaciones" name="descripcion">
            </mat-form-field>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Vendedor</mat-label>
                    <input matInput type="text" [(ngModel)]="vendedor.nombre" readonly>
                </mat-form-field>
            </div>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" >
                <mat-label>Flete</mat-label>
                <input matInput placeholder="Flete" min="0" type="number" [(ngModel)]="preventa.flete" (ngModelChange)="onChangeFlete($event)" name="flete">
            </mat-form-field>

            <div>
                <h3 style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'normal'">Importe Total ${{fcNum(preventa.importe)}} (Incluye Flete)</h3>
                <h3 style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'descuento'">Importe Total ${{fcNum(preventa.importe)}} (Incluye Flete y Descuento General)</h3>
                <h3 style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'recargo'">Importe Total ${{fcNum(preventa.importe)}} (Incluye Flete y Recargo General)</h3>
                <div style="font-weight: bold;">Importe Solo Productos ${{fcNum(totalProductos)}}</div>
                <div style="font-weight: bold;">Importe Flete ${{fcNum(preventa.flete)}}</div>
                <div style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'recargo'">Importe Recargo General ${{fcNum(preventa.recargoGeneralImporte)}}</div>
                <div style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'descuento'">Importe Descuento General ${{fcNum(preventa.descuentoGeneralImporte)}}</div>
                <div style="font-weight: bold;">Importe Total Sin Descuento General ${{fcNum(preventa.flete +  totalProductos)}}</div>
                <div style="font-weight: bold;">Total en Formas de Pago ${{fcNum(totalFormaPago)}} (con Recargo ${{fcNum(totalFormaPagoConRecargo)}})</div>
            </div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px; margin-bottom: 16px;" *ngIf="estadoInicial == 2">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Pagos</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar y Editar Pagos</button>
            </div>
            <div style="display: flex; flex-direction: column;">

                <table mat-table [dataSource]="dataSourceFP" multiTemplateDataRows matSort class="mat-elevation-z4" style="width: 100%;" *ngIf="formasDePago.length > 0">

                    <ng-container matColumnDef="formaPago">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Forma de Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.tipoDescripcion}} ({{element.recargo}}%)</td>
                        <td mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
                    </ng-container>

                    <ng-container matColumnDef="senia">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Es Seña </th>
                        <td mat-cell *matCellDef="let element"> {{element.senia ? 'SI' : 'NO'}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="recargo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Recargo </th>
                        <td mat-cell *matCellDef="let element"> $ {{fcNum(((element.importe * element.recargo) / 100))}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                
                    <ng-container matColumnDef="importe">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Importe </th>
                        <td mat-cell *matCellDef="let element"> $ {{fcNum(element.importe)}} </td>
                        <td mat-footer-cell *matFooterCellDef> $ {{fcNum(totalFormaPago)}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="totalConRecargo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Total con Recargo </th>
                        <td mat-cell *matCellDef="let element"> $ {{fcNum((((element.importe * element.recargo) / 100) + element.importe))}}</td>
                        <td mat-footer-cell *matFooterCellDef> $ {{fcNum((totalFormaPagoConRecargo))}}</td>
                    </ng-container>

                    <ng-container matColumnDef="fechaPago">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Fecha de Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element._fecha}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="opciones">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let element"> <!--<button type="button" (click)="clickImprimirPago(element)">Imprimir Pago</button> --></td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    
                    
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsP"></tr>
                    <tr mat-row 
                        *matRowDef="let element; columns: displayedColumnsP;"
                        class="element-row"
                        [class.expanded-row]="expandedElement === element">
                    </tr>
            
                    <tr mat-footer-row *matFooterRowDef="displayedColumnsP; sticky: false"></tr>

                </table>

                <div *ngIf="formasDePago.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Pagos Agregados</div>
                </div>
            </div>
        </div>

        <div style="margin-top: 16px; margin-bottom: 16px;" *ngIf="estadoInicial == 1">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Forma de Pago (Autocompletado con la propuesta)</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar y Editar Pagos</button>
            </div>
            <div style="display: flex; flex-direction: column;">

                <table mat-table [dataSource]="dataSourceFP" multiTemplateDataRows matSort class="mat-elevation-z4" style="width: 100%;" *ngIf="formasDePago.length > 0">

                    <ng-container matColumnDef="formaPago">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Forma de Pago </th>
                        <td mat-cell *matCellDef="let element"> {{element.tipoDescripcion}} ({{element.recargo}}%)</td>
                        <td mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
                    </ng-container>

                    <ng-container matColumnDef="senia">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Es Seña </th>
                        <td mat-cell *matCellDef="let element"> {{element.senia ? 'SI' : 'NO'}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>

                    <ng-container matColumnDef="recargo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Recargo </th>
                        <td mat-cell *matCellDef="let element"> $ {{fcNum(((element.importe * element.recargo) / 100))}}</td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                
                    <ng-container matColumnDef="importe">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Importe </th>
                        <td mat-cell *matCellDef="let element"> $ {{fcNum(element.importe)}} </td>
                        <td mat-footer-cell *matFooterCellDef> $ {{fcNum(totalFormaPago)}}</td>
                    </ng-container>
            
                    <ng-container matColumnDef="totalConRecargo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef> Total con Recargo </th>
                        <td mat-cell *matCellDef="let element"> $ {{fcNum(((element.importe * element.recargo) / 100) + element.importe)}}</td>
                        <td mat-footer-cell *matFooterCellDef> $ {{fcNum(totalFormaPagoConRecargo)}}</td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsFP"></tr>
                    <tr mat-row 
                        *matRowDef="let element; columns: displayedColumnsFP;"
                        class="element-row"
                        [class.expanded-row]="expandedElement === element">
                    </tr>
            
                    <tr mat-footer-row *matFooterRowDef="displayedColumnsFP; sticky: false"></tr>

                </table>

                <div *ngIf="formasDePago.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Pagos Agregados</div>
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px;">
            <div style="display: flex; flex-direction: column; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Productos</h2>

                <div>
                    <h3>Entrega Inmediata</h3>
                    <div style="margin-left: 24px;">
                        <table mat-table [dataSource]="dataSourceEI" multiTemplateDataRows matSort class="mat-elevation-z4" style="width: 100%;" *ngIf="listInmediata.length > 0">

                            <ng-container matColumnDef="producto">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Producto </th>
                                <td mat-cell *matCellDef="let element"> {{element.productoDescripcion}} <span class="tip-desactivado" *ngIf="!element.productoActivo">DESACTIVADO</span></td>
                            </ng-container>
        
                            <ng-container matColumnDef="color">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Color </th>
                                <td mat-cell *matCellDef="let element"> {{element.colorDescripcion}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="medida">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Medida </th>
                                <td mat-cell *matCellDef="let element"> {{element.medidaDescripcion}}</td>
                            </ng-container>
                        
                            <ng-container matColumnDef="cantidad">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Cantidad </th>
                                <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="precioUnitario">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Precio Unitario </th>
                                <td mat-cell *matCellDef="let element"> $ {{fcNum(element.precio_unitario_venta)}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="descuentoUnitario">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Descuento Unitario </th>
                                <td mat-cell *matCellDef="let element"> $ {{fcNum(element.importe_descuento)}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="subTotal">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> SubTotal </th>
                                <td mat-cell *matCellDef="let element"> $ {{fcNum((element.precio_unitario_venta - element.importe_descuento) * element.cantidad)}}</td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsEI"></tr>
                            <tr mat-row 
                                *matRowDef="let element; columns: displayedColumnsEI;"
                                class="element-row"
                                [class.expanded-row]="expandedElement === element">
                            </tr>
      
                        </table>
                    </div>
                    <div *ngIf="listInmediata.length == 0">
                        <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos Para Entrega Inmediata</div>
                    </div>
                </div>

                <div *ngIf="listDemorada.length > 0">
                    <h3 style="margin-top: 16px;">Entrega Demorada</h3>
                    <div style="margin-left: 24px;">
                        <table mat-table [dataSource]="dataSourceED" multiTemplateDataRows matSort class="mat-elevation-z4" style="width: 100%;" *ngIf="listDemorada.length > 0">

                            <ng-container matColumnDef="producto">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Producto </th>
                                <td mat-cell *matCellDef="let element"> {{element.productoDescripcion}} </td>
                            </ng-container>
        
                            <ng-container matColumnDef="color">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Color </th>
                                <td mat-cell *matCellDef="let element"> {{element.colorDescripcion}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="medida">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Medida </th>
                                <td mat-cell *matCellDef="let element"> {{element.medidaDescripcion}}</td>
                            </ng-container>
                        
                            <ng-container matColumnDef="cantidad">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Cantidad </th>
                                <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
                            </ng-container>
                    
                            <ng-container matColumnDef="precioUnitario">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Precio Unitario </th>
                                <td mat-cell *matCellDef="let element"> $ {{fcNum(element.precio_unitario_venta)}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="descuentoUnitario">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Descuento Unitario </th>
                                <td mat-cell *matCellDef="let element"> $ {{fcNum(element.importe_descuento)}}</td>
                            </ng-container>
        
                            <ng-container matColumnDef="subTotal">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> SubTotal </th>
                                <td mat-cell *matCellDef="let element"> $ {{fcNum((element.precio_unitario_venta - element.importe_descuento) * element.cantidad)}}</td>
                            </ng-container>
                        
                            <tr mat-header-row *matHeaderRowDef="displayedColumnsED"></tr>
                            <tr mat-row 
                                *matRowDef="let element; columns: displayedColumnsED;"
                                class="element-row"
                                [class.expanded-row]="expandedElement === element">
                            </tr>
      
                        </table>
                    </div>
                    <div *ngIf="listDemorada.length == 0">
                        <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos En Entrega Demorada</div>
                    </div>
                </div>

            </div>
        </div>
        
    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Confirmar Pago</button>
    </div>

</div>