import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';


@Component({
  selector: 'app-vendedores-nuevo',
  templateUrl: './vendedores-nuevo.component.html',
  styleUrls: ['./vendedores-nuevo.component.scss']
})
export class VendedoresNuevoComponent implements OnInit {

  nuevo: VendedoresModel = {
    idvendedor: 0,
    nombre: "",
    domicilio: "",
    telefono: "",
    correo_electronico: "",
    codigo_localidad: 0,
    observaciones: "",
    porcentaje_comision: 0.0,
    idsucursal: 0
  }

  listLocalidades: any[] = [];
  listSucursales: any[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {
    this.nuevo.idvendedor = Number(this.activatedRoute.snapshot.paramMap.get('idvendedor'));

    if (this.nuevo.idvendedor > 0){
      this.apiService.getVendedores(this.nuevo.idvendedor).subscribe(x => {
        this.nuevo = x;
      })
    }

    this.apiService.getLocalidadesList().subscribe(x => {
      this.listLocalidades = x as any[];
    });

    this.apiService.getSucursalesCompletoList().subscribe(x => {
      this.listSucursales = x as any[];
    });

  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idvendedor == 0){
        this.apiService.postVendedores(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el vendedor correctamente");
          this.router.navigateByUrl('/vendedores/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        this.apiService.putVendedores(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el vendedor correctamente");
          this.router.navigateByUrl('/vendedores/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }

  }

  validar(item: any): boolean{
    console.log("validar", item);

    if (item.idsucursal == null || item.idsucursal == 0){
      this.snackBarService.errorMessage("Debe proporcionar una sucursal por defecto");
      return false
    }

    if (item.nombre.length <= 3){
      this.snackBarService.errorMessage("Debe proporcionar un nombre valido");
      return false
    }

    if (item.domicilio.length <= 3){
      this.snackBarService.errorMessage("Debe proporcionar un domicilio valido");
      return false
    }

    if (item.codigo_localidad == null || item.codigo_localidad == 0){
      this.snackBarService.errorMessage("Debe proporcionar una localidad");
      return false
    }

    if (item.correo_electronico == null || item.correo_electronico == 0){
      this.snackBarService.errorMessage("Debe proporcionar un correo electronico");
      return false
    }

    if (item.telefono == null || item.telefono == 0){
      this.snackBarService.errorMessage("Debe proporcionar un telefono");
      return false
    }

    return true
  }

  clickBack(){
    this._location.back();
  }


}
