import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PdfService } from 'src/app/services/pdf.service';
import { formatNumeroNacional } from 'src/app/utiles';
import { DialogImprimirEntregasComponent } from '../dialog-imprimir-entregas/dialog-imprimir-entregas.component';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-dialog-imprimir-mercaderia',
  templateUrl: './dialog-imprimir-mercaderia.component.html',
  styleUrls: ['./dialog-imprimir-mercaderia.component.scss']
})
export class DialogImprimirMercaderiaComponent implements OnInit {

  checkedGlobal = false;
  selectedElement: any | null;

  displayedColumns: string[] = ['select', 'idventa', 'producto', 'color', 'medida', 'cantidad', 'cliente', 'estado'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  cargando = false;

  dataImprimir = {
    items: [],
  }

  cantidadSeleccionados = 0;

  fcNum = formatNumeroNacional;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pdfService: PdfService,
    private auth: AuthService,
    private dialogRef: MatDialogRef<DialogImprimirEntregasComponent>
  ) { 
    

  }

  ngOnInit(): void {

    this.dataImprimir = {
      items: [],
    }

    console.log("data", this.data);

    let items = [];

    if (this.data.listVentas){

      this.data.listVentas.forEach(x => {
        let entregas = x.lineas.entregas;
        if (entregas){
          entregas.forEach(xx => {
            xx._restaAbonar = x._debe;
            xx._clienteNombre = x.cliente_descripcion,
            xx._clienteTelefono = x.cliente_telefono,
            xx._clienteDomicilio = x.cliente_domicilio,
            xx._clienteLocalidad = x.localidad_descripcion,
            xx._clienteCodigoPostal = x.localidad_codigo_postal
            items.push(xx);
          });
        }
        let logistica = x.lineas.logistica;
        if (logistica){
          logistica.forEach(xx => {
            xx._restaAbonar = x._debe;
            xx._clienteNombre = x.cliente_descripcion,
            xx._clienteTelefono = x.cliente_telefono,
            xx._clienteDomicilio = x.cliente_domicilio,
            xx._clienteLocalidad = x.localidad_descripcion,
            xx._clienteCodigoPostal = x.localidad_codigo_postal
            items.push(xx);
          });
        }
      })
    }

    let i = JSON.parse(JSON.stringify(items));
    i.forEach(x => x._seleccionado = false);

    console.log("i", i);

    this.dataSource.data = i;


  }

  onChangeSelectGlobal(event){
    
    this.dataSource.data.forEach(x => {
      x._seleccionado = event.checked;
    });
    this.setCantidadSeleccionados();

    

  }

  onChangeSelectItem(event, element){
    element._seleccionado = event.checked;
    this.setCantidadSeleccionados();

    
  }

  setCantidadSeleccionados(){
    let items = this.dataSource.data.filter(x => x._seleccionado == true);
    this.cantidadSeleccionados = items.length;
    if (this.cantidadSeleccionados == this.dataSource.data.length){
      this.checkedGlobal = true; 
    }else{
      this.checkedGlobal = false; 
    }
  }

  clickCerrar(){
    this.dialogRef.close();
  }

  clickConfirmar(){

    this.dataImprimir.items = [];

    this.dataSource.data.forEach(x => {
      if (x._seleccionado){
        this.dataImprimir.items.push(x);
      }
    })

    if (this.dataImprimir.items.length > 0){
      this.imprimirRecibo();
    }
    
  }

  imprimirRecibo(){

    let comp = function compare( a, b ) {
      if ( a._clienteNombre < b._clienteNombre ){
        return -1;
      }
      if ( a._clienteNombre > b._clienteNombre ){
        return 1;
      }
      return 0;
    }

    let list: any[] = JSON.parse(JSON.stringify(this.dataImprimir.items));
    list.sort(comp);
    
    let funcionImpresion = () => {

      /*
      let clienteNombre = `clienteNombre`;
      let clienteTelefono = `clienteTelefono`;
      let clienteDomicilio = `clienteDomicilio`;
      let clienteLocalidad = `clienteLocalidad (clienteCodigoPostal)`;
      */
      
      let items: any[] = [
        [
          {text: `Producto`, alignment: 'left', fontSize: 10}, 
          {text: `Color`, alignment: 'left', fontSize: 10}, 
          {text: `Medida`, alignment: 'left', fontSize: 10}, 
          {text: `Cantidad`, alignment: 'left', fontSize: 10}, 
          {text: `Cliente`, alignment: 'left', fontSize: 10}, 
          {text: ``, alignment: 'left', fontSize: 10}
        ]
      ];

      let usuario = this.auth.userInfo() ? this.auth.userInfo().email : "<Desconocido>";

      /// mejorar, lo hice rapido, es para ver lo que resta abonar pero eso va por venta y no por producto
      //

      list.forEach(x => {

        let idVenta = {text: `${x.idventa}`, alignment: 'left', fontSize: 8.5};
        let producto = {text: `${x.producto_descripcion}`, alignment: 'left', fontSize: 8.5};
        let color = {text: `${x.color_descripcion}`, alignment: 'left', fontSize: 8.5};
        let medida = {text: `${x.medida_descripcion}`, alignment: 'left', fontSize: 8.5};
        let cantidad = {text: `Cant. ${x.cantidad}`, alignment: 'left', fontSize: 8.5};
        let cliente = {text: `${x._clienteNombre}`, alignment: 'left', fontSize: 8.5, bold: true};
        let estado = {text: ``, alignment: 'left', fontSize: 9};
        
        let r = [producto, color, medida, cantidad, cliente, estado];
        items.push(r);
        
      });


      let content: any[] = [
        { text: `Listado de Mercaderia`, fontSize: 15, margin:[0, 0, 0, 10] },
        { text: `Usuario: ${usuario}`, fontSize: 11, margin:[0, 0, 0, 10] },
  
        { text: `Productos: `, fontSize: 11, margin:[0, 0, 0, 10] },
        items.length > 1 ? 
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto', 'auto', 15],
            body: items
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.2;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.2;
            }
          }
        } : { text: `Sin Productos`, fontSize: 11, margin:[10, 0, 0, 10] },
  
        
  
      ]

      this.pdfService.generarPDF(content);

    }

    funcionImpresion();

  }

}

const ELEMENT_DATA: any[] = [];

