import { Injectable } from '@angular/core';

export interface ICacheHandler {
  hasValue(key: string);
  get<T>(key: string): T;
  set<T>(key: string, value: T);
  remove<T>(key: string): T;
}

@Injectable({
  providedIn: 'root'
})
export class SessionCacheHandler implements ICacheHandler {
  
  public hasValue(key: string) {
    // TODO: Ver si se puede omitir el get, y comprobar x existencia de clave
    return this.get(key) !== null; 
  }

  public get<T>(key: string): T {
    const value: string = sessionStorage.getItem(key);
    return value !== 'undefined' ? JSON.parse(value) : null;
  }

  public set<T>(key: string, value: T) {
    const data: string = value !== undefined ? JSON.stringify(value) : undefined;
    sessionStorage.setItem(key, data);
  }

  public remove<T>(key: string): T {
    sessionStorage.removeItem(key);
    return null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class LocalStorageCacheHandler implements ICacheHandler {

  public hasValue(key: string) {
    // TODO: Ver si se puede omitir el get, y comprobar x existencia de clave
    return this.get(key) !== null; 
  }

  public get<T>(key: string): T {
    const value: string = localStorage.getItem(key);
    return value !== 'undefined' ? JSON.parse(value) : null;
  }

  public set<T>(key: string, value: T) {
    const data: string = value !== undefined ? JSON.stringify(value) : undefined;
    localStorage.setItem(key, data);
  }

  public remove<T>(key: string): T {
    localStorage.removeItem(key);
    return null;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ScopedCacheHandler implements ICacheHandler {

  private bag = {}; // OBS: Esto "podría" dividirse entre este handler y un manager independiente

  public hasValue(key: string)
  {
    return this.bag[key] !== undefined;
  }

  public get<T>(key: string): T {
    return this.bag[key];
  }

  public set<T>(key: string, value: T) {
    this.bag[key] = value;
  }

  public remove<T>(key: string): T {
    delete this.bag[key];
    return null;
  }
}