import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [

  //{ path: '', redirectTo: 'indicadores', pathMatch: 'full'},

  //{ path: 'indicadores', component: IndicadoresComponent, canActivate: [MsalGuard], data: { breadcrumb: 'Indicadores' } },

  /*
  { path: 'trabajos', component: TrabajosComponent, canActivate: [MsalGuard], data: { breadcrumb: 'Trabajos' },
    children: [
      { path: '', redirectTo: '404', pathMatch: 'full'},
      { path: 'trabajo-listar', component: TrabajoListarComponent, data: { breadcrumb: 'Trabajo Listar' }},
      { path: 'trabajo-listar/trabajo/:idt', component: TrabajoListarComponent },
      { path: 'trabajo-listar/lote/:idl', component: TrabajoListarComponent },
    ]
  },
  */


  
  

  //{ path: '**', redirectTo: '404'}

];

@NgModule({
  imports: [ RouterModule.forChild(routes)],
  exports: [ RouterModule ]
})
export class CoreRoutingModule {}

