import { Component, OnInit } from '@angular/core';

// Service
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
//import { SideBarService } from '../../servicios/side-bar.service';

import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SideBarService } from '../../services/side-bar.service';
import { AuthService } from '../../services/auth.service';
import { SharedService } from 'src/app/services/shared.service';
import { ApiService } from 'src/app/services/api.service';
import { SelectorGenericoComponent } from 'src/app/features/generico/selector-generico/selector-generico.component';
import { SucursalesModel } from 'src/app/models/sucursales-model';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  gruposEscucha = [];

  userName = '';
  userApellido = '';
  userEmail = '';

  userInfo: any;

  token: any;
  subsKey: any;
  isSearching = false;
  feedbackOn = false;

  sucursal = {
    sucursalNombre: "",
    sucursalId: 0
  }

  listSucursales: SucursalesModel[] = [];

  constructor(
    public dialog: MatDialog,
    private sideBarService: SideBarService,
    private apiService: ApiService,
    private sharedService: SharedService,
    private snackBar: MatSnackBar,
    private router: Router,
    private auth: AuthService,
    private _permissionsService: NgxPermissionsService
  ) {

  }

  // Plantilla que se esta viendo en este momento
  termsAcepted: any;
  userId: any;
  allowUserFeedBack: any = new Array;
  canFeedback: any = false;

  ngOnInit() {

    console.log("user info", this.auth.userInfo());

    this.userInfo = this.auth.userInfo();

    this.userName = this.userInfo.email;

    let sucursalObtenida = this.sharedService.getSucursalActual();

    console.log("sucursalActual", sucursalObtenida);

    if (sucursalObtenida.sucursalId != 0){
      this.sucursal = sucursalObtenida
    }

  }

  logOut() {

    console.log("logout");

    this.auth.SignOut();
  }

  openFeedBack() {
    this.feedbackOn = !this.feedbackOn;
  }

  toggleSidebar() {
    this.sideBarService.toggle();
  }

  abreInstitucional(){

  }

  clickVerTodas(){
    
  }

  clickNotas(){
    this.sideBarService.toggleNotas();
  }

  cambiarSucursal(){
    let p = this._permissionsService.getPermissions();
    if ('10' in p){
      this.apiService.getUsuarios(this.userInfo.uid).subscribe(u => {
        if (u.rol == 10){
          this.apiService.getSucursalesList().subscribe(ss => {
            this.listSucursales = ss;
            this.seleccionarSucursal();
          });
        }
      });
    }
  }

  tienenRolNotas(){
    switch(this.userInfo.uid){
      case "H8Lrt28VrtNvZJFit6ZE2W5GJ8N2": //benjamin
      case "J00mqq0gAWYgxIXDBJjQnzLsulw2": //maximiliano
      case "jLioA5iJK0dpPyNaVJCzGYw3zqo1": // yo gabibatta
      case "nwMTN6oG32gxqY2kUtn7HamU0Ez1": //carolina
      case "qC4iHcszYUWV68Eu67kRIOfa9xf1": //solana
      case "qdBgrgfgdzYph5RPoby9vp06Z6z1": //matias
      case "ZuyqQtYLmmcb1rj3rw3NJ0pgqjL2": //milagros
      case "AygS5P8IHbalX6G882taxyCq0DV2": // pagos neuquen
        return true;
      case "EgmKDi4sryfCJ5gyiRpCgiFPBVK2":
        if (environment.production){
          return false;
        }else{
          return true
        }
      default: return false;
    }
  }

  seleccionarSucursal(){

      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '45vw';
      dialogConfig.minWidth = '450px';
      dialogConfig.minHeight = '350px';
  
      let coleccion: any[] = [];
  
      this.listSucursales.forEach(x => {
        let item = {
          id: x.idsucursal,
          nombre: x.descripcion
        }
        coleccion.push(item);
      })
  
      dialogConfig.data = {
        ventana: {
          titulo: "Seleccionar Sucursal",
          columnaNombre: "Nombre",
          tituloBotonNuevo: "Nueva Sucursal"
        },
        muestraId: true,
        coleccion: coleccion,
        multiple: false,
        idSeleccionPrevia: null,
        permitirNuevo: false,
        referenciaNuevo: null
      };
  
      const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(rr => {
        if (rr.cierre){
          if (rr.seleccion != null){

            this.sharedService.setSucursalActual(rr.seleccion.id, rr.seleccion.nombre);

            this.sucursal = {
              sucursalNombre: rr.seleccion.nombre,
              sucursalId: rr.seleccion.id
            }

            this.reload();

          }
        }
      });
  }

  reload(){
    window.location.reload()
  }


}
