import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-seleccion-tipo-reporte',
  templateUrl: './dialog-seleccion-tipo-reporte.component.html',
  styleUrls: ['./dialog-seleccion-tipo-reporte.component.scss']
})
export class DialogSeleccionTipoReporteComponent implements OnInit {

  tipoReporte: 'vendedor' | 'producto' | 'ventas' = 'vendedor';

  detalleComision = false;
  detalleFormaPago = false;
  detalleArticulo = false;

  tipoReporteProducto: 'sucursal' | 'rubro' | 'rubrosubrubro' | 'simple' = 'simple';
  tipoSubAgrupacion: 'no_subagrupar' | 'subagrupar_rubro' | 'subagrupar_subrubro' = 'no_subagrupar';

  
  columnas = [];
 
  agruparSucursalYRubro = false;
  r_agruparRubroYSucursal = false;
  rs_agruparRubroYSucursal = false;

  constructor(
    private dialogRef: MatDialogRef<DialogSeleccionTipoReporteComponent>,
  ) { }

  ngOnInit(): void {
  }

  clickConfirmar() {

    let r = null;

    if (this.tipoReporte == 'producto'){
      r = {
        tipoReporte: this.tipoReporte,
        tipoReporteProducto: this.tipoReporteProducto,
      }

      switch (this.tipoReporteProducto) {
        case 'simple':
            r.subAgrupar = 'no_subagrupar'
          break;
        case 'rubro':
          if (this.r_agruparRubroYSucursal){
            r.subAgrupar = 'subagrupar_sucursal'
          }else{
            r.subAgrupar = 'no_subagrupar'
          }
          break;
        case 'rubrosubrubro':
          if (this.rs_agruparRubroYSucursal){
            r.subAgrupar = 'subagrupar_sucursal'
          }else{
            r.subAgrupar = 'no_subagrupar'
          }
          break;
        case 'sucursal':
          r.subAgrupar = this.tipoSubAgrupacion;
          break;
        default:
          break;
      }
     

    }

    if (this.tipoReporte == 'vendedor'){
      r = {
        tipoReporte: this.tipoReporte,
        detalleComision: this.detalleComision,
        detalleFormaPago: this.detalleFormaPago,
        detalleArticulo: this.detalleArticulo
      }
    }

    console.log("r", r);

    this.dialogRef.close(
      {
        cierre: true,
        seleccion: r
      });
  }

  clickCancelar(){
    this.dialogRef.close(
      {
        cierre: true,
        seleccion: null
      });
  }

}
