<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: column;">
        <div style="display:flex; flex-direction: row; align-items: baseline; justify-content: space-between;">

            <div style="display: flex; flex-direction: row;">
    
                <mat-form-field appearance="outline">
                    <mat-label>Ingrese un rango de fechas</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Desde">
                        <input matEndDate formControlName="end" placeholder="Hasta">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
    
                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha Desde Incorrecta</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha Hasta Incorrecta</mat-error>
                </mat-form-field>
            
                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Vendedor</mat-label>
                        <input matInput type="text" [(ngModel)]="vendedor.nombre" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarVendedor()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            
                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Cliente</mat-label>
                        <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                
                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                    <mat-form-field appearance="outline" style="margin-right: 8px;" >
                        <mat-label>Sucursal</mat-label>
                        <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="sucursalNombre" name="sucursal" readonly>
                        <button *ngIf="sucursalNombre != ''" matSuffix mat-icon-button (click)="clickClear()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarSucursal()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px; height: 58px;">
                    <!--
                    <mat-checkbox [(ngModel)]="detallePagoTotal" style="margin: 0px 8px;">Solo Ventas con Pago Completo</mat-checkbox>
                    -->
                    <mat-slide-toggle [(ngModel)]="detallePagoTotal">Solo Ventas con Pago Completo</mat-slide-toggle>
                </div>                    
    
            </div>
          
            <button mat-raised-button color="primary" (click)="clickConsultar()">Consultar</button>
    
        </div>

        <div style="display:flex; flex-direction: row; align-items: baseline; justify-content: space-between; margin-top: 8px; margin-bottom: 8px;">
            <div>
                
            </div>
            <div>

                <!--
                <mat-checkbox [(ngModel)]="detalleComision" style="margin: 0px 8px;">Incluir Detalle Comisión</mat-checkbox>
                <mat-checkbox [(ngModel)]="detalleFormaPago" style="margin: 0px 8px;">Incluir Detalle Forma de Pago</mat-checkbox>
                <mat-checkbox [(ngModel)]="detalleArticulos" style="margin: 0px 8px;">Incluir Detalle Articulos</mat-checkbox>
                <button mat-raised-button color="primary" (click)="exportarReporteProductosPorSucursal()" [disabled]="listProductosSucursal.length == 0" style="margin-right: 4px;">Imprimir Reporte Productos</button>
                <button mat-raised-button color="primary" (click)="clickExportar()" [disabled]="dataSource.data.length == 0">Imprimir</button>
                <button mat-raised-button color="primary" (click)="clickSeleccionReporte()" [disabled]="dataSource.data.length == 0">Imprimir 2</button>
                <button mat-raised-button color="primary" (click)="exportarReporteProductosPorSucursal2()" [disabled]="listProductosSucursal.length == 0" style="margin-right: 4px;">Imprimir Reporte Productos2</button>

                -->
                <button mat-raised-button color="primary" (click)="clickSeleccionReporte()" [disabled]="dataSource.data.length == 0">Imprimir Reporte</button>
                
            </div>
            
        </div>
    </div>
  
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z1" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idpreventa">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idpreventa}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
        <th mat-header-cell *matHeaderCellDef> Sucursal </th>
        <td mat-cell *matCellDef="let element"> {{element.datosGeneralesVenta.sucursalNombre}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element.datosGeneralesVenta._fecha}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="cliente">
        <th mat-header-cell *matHeaderCellDef> Cliente </th>
        <td mat-cell *matCellDef="let element"> {{element.datosGeneralesVenta.cliente}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="vendedor">
        <th mat-header-cell *matHeaderCellDef> Vendedor </th>
        <td mat-cell *matCellDef="let element"> {{element.datosGeneralesVenta.vendedor}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="preventaEstado">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element"> {{element.datosGeneralesVenta.preventaEstadoNombre}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="importeVenta">
            <th mat-header-cell *matHeaderCellDef> Importe Venta</th>
            <td mat-cell *matCellDef="let element"> {{fcNum(element.datosGeneralesVenta.importeFinalConDG)}} </td>
            <td mat-footer-cell *matFooterCellDef> {{fcNum(total)}} </td>
        </ng-container>

        <ng-container matColumnDef="totalPagado">
            <th mat-header-cell *matHeaderCellDef> Total Pagado (CR)</th>
            <td mat-cell *matCellDef="let element"> {{fcNum((element.datosGeneralesVenta.totalPagado + element.datosGeneralesVenta.totalRecargo))}} </td>
            <td mat-footer-cell *matFooterCellDef> {{fcNum(totalPagado)}} </td>
        </ng-container>

        

        <ng-container matColumnDef="importeSujetoComision">
            <th mat-header-cell *matHeaderCellDef> Importe Sujeto a Comisión</th>
            <td mat-cell *matCellDef="let element"> {{fcNum(element.datosGeneralesVenta.importeSujetoComision)}} </td>
            <td mat-footer-cell *matFooterCellDef> {{fcNum(totalSujetoComision)}}</td>
        </ng-container>

        <ng-container matColumnDef="comision">
            <th mat-header-cell *matHeaderCellDef> Comisión</th>
            <td mat-cell *matCellDef="let element"> {{fcNum(element.datosGeneralesVenta.comision)}} </td>
            <td mat-footer-cell *matFooterCellDef> {{fcNum(totalComisionAcumulada)}}</td>
        </ng-container>

        <ng-container matColumnDef="pagado">
            <th mat-header-cell *matHeaderCellDef> Pago</th>
            <td mat-cell *matCellDef="let element"> 
                <span>{{element.datosGeneralesVenta.pagoTotal ? "Completo" : "Incompleto"}} </span>
                <span *ngIf="element.datosGeneralesVenta.importeDescuento > 0" class="tip-dg" matTooltip="Se aplico un Descuento General" >DG</span>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container> 


        <ng-container matColumnDef="expandedDetalle">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                    <div *ngIf="element == expandedElement">

                        <div><b>Productos Vendidos</b></div>

                        <div style="display: flex;flex-direction: column; margin-bottom: 24px; margin-top: 16px;" *ngIf="element.articulos.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Articulo</th>
                                    <th>Rubro</th>
                                    <th>Cantidad</th>
                                    <th>Precio Unitario</th>
                                    <th>Descuento Unitario</th>
                                    <th>Descuento General Aplicado</th>
                                    <th>Precio Total</th>
                                    <th>Comision</th>
                                </tr>
                                <tr *ngFor="let item of element.articulos">
                                    <td>{{item.articulo}}</td>
                                    <td>{{item.rubroDescripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                    <td style="text-align: right;">{{fcNum(item.precioUnitario)}}</td>
                                    <td style="text-align: right;">{{fcNum(item.descuentoUnitario)}}</td>
                                    <td style="text-align: right;">{{fcNum(item.descuentoGeneralAplicado)}}</td>
                                    <td style="text-align: right;">{{fcNum(item.precioTotal)}}</td>
                                    <td style="text-align: right;">{{fcNum(item.montoComisionEspecial)}} ({{item.comisionEspecial}}%)</td>
                                </tr>
                                <tr class="row-total">
                                    <td colspan="6"><b>TOTAL</b></td>
                                    <td style="text-align: right;"><b>{{fcNum(element.datosGeneralesVenta.importeProductosFinalConDG)}}</b></td>
                                    <td style="text-align: right;"><b>{{fcNum(element.datosGeneralesVenta.comision)}}</b></td>
                                </tr>
                            </table>
                        </div>

                        <div class="grid-detalle">
                            <div class="celda-detalle-nombre"><b>Productos Vendidos</b></div><div class="celda-detalle-valor">$ {{fcNum(element.datosGeneralesVenta.importe)}}</div>
                            <div class="celda-detalle-nombre"><b>Flete</b></div><div class="celda-detalle-valor">$ {{fcNum(element.datosGeneralesVenta.flete)}}</div>
                            <div class="celda-detalle-nombre"><b>Descuento General</b></div><div class="celda-detalle-valor">$ {{fcNum(element.datosGeneralesVenta.importeDescuento)}} ({{element.datosGeneralesVenta.porcentajeDescuentoGeneral}}%)</div>
                            <div class="celda-detalle-nombre"><b>TOTAL VENTA</b></div><div class="celda-detalle-valor">$ {{fcNum(element.datosGeneralesVenta.importeFinalConDG)}}</div>
                            <div class="celda-detalle-nombre"><b>TOTAL PAGADO</b></div><div class="celda-detalle-valor">${{fcNum(element.datosGeneralesVenta.totalPagado)}}</div>
                            <div class="celda-detalle-nombre"><b>TOTAL PAGADO POR CLIENTE<br>(Incluye recargo de Forma de Pago)</b></div><div class="celda-detalle-valor">${{fcNum((element.datosGeneralesVenta.totalPagado + element.datosGeneralesVenta.totalRecargo))}}</div>
                            <div class="celda-detalle-nombre"><b>TOTAL SUJETO A COMISION</b></div><div class="celda-detalle-valor">${{fcNum(element.datosGeneralesVenta.importeSujetoComision)}}</div>
                        </div>

                        <div style="padding: 16px; margin: 8px; ">
                            El cálculo de las comisiones se realiza solamente sobre el valor de los productos (valor sin recargo). A dicho valor se le resta el descuento unitario (si existiese) y se le descuenta el porcentaje de descuento general (si existiese), el valor obtenido es el valor del producto sujeto a comisión, la comisión que se le aplica depende del rubro del producto y el vendedor (ver tabla de comisiones)
                        </div>

                    </div>

                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>

    </table>

      
  </div>