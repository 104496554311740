<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
        Agregar Productos {{sucursal.idsucursal != 0 ? '(Sucursal: ' + sucursal.nombre + ')' : ''}}
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
  
        <div style="display: grid; grid-template-columns: 1fr 1fr;">
            <div style="display: flex; flex-direction: column; flex-grow: 1; height: 60vh; overflow-y: scroll; padding: 4px;">
                <div>

                    <div style="display: flex; flex-direction: column;">
                        <mat-form-field appearance="outline">
                          <input type="text" placeholder="Filtrar" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
                        </mat-form-field>
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <div style="flex-grow: 1;">
                                <mat-form-field appearance="outline"  >
                                    <input type="text" placeholder="Rubro" matInput autocomplete="nope" [(ngModel)]="rubroSelected.nombre" readonly>
                                    <button *ngIf="rubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearRubro()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <button matSuffix mat-icon-button (click)="clickSeleccionarRubro()">
                                        <mat-icon>arrow_forward</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                            <div style="flex-grow: 1;">
                                <mat-form-field appearance="outline"  >
                                    <input type="text" placeholder="SubRubro" matInput autocomplete="nope" [(ngModel)]="subRubroSelected.nombre" readonly>
                                    <button *ngIf="subRubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearSubRubro()" >
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <button matSuffix mat-icon-button (click)="clickSeleccionarSubRubro()" [disabled]="rubroSelected.id == 0">
                                        <mat-icon>arrow_forward</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <table mat-table [dataSource]="dataSource" style="width: 100%;">
                      
                        <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>Id</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="dato-tabla">
                            {{element.idproducto}}
                            </div>
                        </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="producto">
                        <th mat-header-cell *matHeaderCellDef>Producto</th>
                        <td mat-cell *matCellDef="let element"> 
                            <div class="dato-tabla" style="display: flex;">
                            <div>
                                {{element.descripcion}} <span class="tip-desactivado" *ngIf="!element.activo">DESACTIVADO</span>
                            </div>
                            </div>
                        </td>
                        </ng-container>

                        <ng-container matColumnDef="opciones">
                            <th mat-header-cell *matHeaderCellDef>Opciones</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-raised-button  [ngClass]="{'boton-editar': element._contiene, 'boton-agregar': !element._contiene }" (click)="clickAgregar(element)">{{element._contiene ? 'Editar' : 'Agregar'}}</button> 
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                      
                    </table>
              
                    <div class="info-vacio" *ngIf="dataSource.data.length === 0">
                      <div style="font-size: 16px;">
                        No hay información
                      </div>
                    </div>
                    
                  </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow: 1; height: 60vh; overflow-y: scroll; padding: 4px;">
                <h2>Productos Agregados</h2>

                <div style="margin-left: 8px; margin-right: 8px;">
                    <mat-accordion multi>
                        <mat-expansion-panel [expanded]="true" *ngFor="let p of agregados" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="align-items: center;">
                                    <div>
                                        {{p.descripcion}} <span class="tip-desactivado" *ngIf="!p.activo">DESACTIVADO</span>
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description style="justify-content: flex-end;">
                                    <button mat-flat-button (click)="clickEditar(p, $event)">Editar</button>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div style="padding: 8px;">
                                <mat-card  *ngFor="let pp of p.presentaciones" style="margin: 4px;">
                                    <div style="display: flex; flex-direction: column; font-size: 10pt;">
                                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
                                            <div>Color: <b>{{pp.colorDescripcion}}</b> | Medida: <b>{{pp.medidaDescripcion}}</b></div>
                                            <button mat-flat-button (click)="clickBorrarPresentacion(pp, $event)">Quitar</button>
                                        </div>
                                        <div style="display: flex; flex-direction: row;">
                                            <div style="display: flex; flex-direction: row; margin-top: 4px; margin-right: 8px;">
                                                <div style="margin-right: 4px;">Cantidad</div>
                                                <input type="number" class="input-valores"  min="0" matInput autocomplete="nope" [(ngModel)]="pp.cantidad" (change)='changeCantidad(pp, $event.target.value)'>
                                                <button (click)="clickIncrementar(pp, $event)">+</button>
                                                <button (click)="clickDecrementar(pp, $event)">-</button>
                                            </div>
                                            <div style="display: flex; flex-direction: row; margin-top: 4px;" *ngIf="muestraImporte">
                                                <div style="margin-right: 4px;">Importe</div>
                                                <input type="number" class="input-valores"  min="0" matInput autocomplete="nope" [(ngModel)]="pp.importe" (change)='changeImporte(pp, $event.target.value)'>
                                            </div>
                                        </div>
                                        <div style="display: flex; flex-direction: row;"  *ngIf="muestraDescuento">
                                            <div style="display: flex; flex-direction: row; margin-top: 4px; margin-right: 8px;">
                                                <div style="margin-right: 4px;">Descuento</div>
                                                <div style="display: flex; margin-left: 8px;">
                                                    <input type="number" class="input-valores"  min="0" matInput autocomplete="off" [(ngModel)]="pp.porcentaje_descuento" (change)='changePorcentajeDescuento(pp, $event.target.value)'>
                                                    <div>%</div>
                                                </div>
                                                <div style="display: flex; margin-left: 8px;">
                                                    <input type="number" class="input-valores"  min="0" matInput autocomplete="off" [(ngModel)]="pp.importe_descuento" (change)='changeImporteDescuento(pp, $event.target.value)'>
                                                    <div>$</div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div style="display: flex; flex-direction: row;" *ngIf="!muestraDescuento">
                                            <div style="display: flex; flex-direction: row; margin-top: 4px; flex-grow: 1; justify-content: space-between;" *ngIf="muestraPrecio">
                                                <div style="margin-right: 4px;">Precio Unitario <span style="font-weight: bolder;">${{fcNum(pp.precio)}}</span></div>
                                                <div style="margin-right: 4px;">Precio <span style="font-weight: bolder;">{{fcNum((pp.precio * pp.cantidad))}}</span></div>
                                                <div style="margin-right: 4px;" *ngIf="muestraStock">Stock <span style="font-weight: bolder;">{{pp.stock}}</span></div>
                                            </div>
                                        </div>

                                        <div [ngClass]="{'precios': !muestraStock, 'precios_stock' : muestraStock }" *ngIf="!muestraDescuento">
                                            <div style="margin-right: 4px;">Precio Unitario</div>
                                            <div style="margin-right: 4px;">Precio Final</div>
                                            <div style="margin-right: 4px;" *ngIf="muestraStock">Stock</div>

                                            <div style="font-weight: bolder;">${{fcNum(pp.precio)}}</div>
                                            <div style="font-weight: bolder;">${{fcNum((pp.precio * pp.cantidad))}}</div>
                                            <div style="font-weight: bolder;" *ngIf="muestraStock">{{pp.stock}}</div>
                                        </div>

                                        <div [ngClass]="{'precios_descuento': !muestraStock, 'precios_descuento_stock' : muestraStock }" *ngIf="muestraDescuento">
                                            <div style="margin-right: 4px;">Precio Unitario</div>
                                            <div style="margin-right: 4px;">Precio Unitario (Con Descuento)</div>
                                            <div style="margin-right: 4px;">Precio Final</div>
                                            <div style="margin-right: 4px;" *ngIf="muestraStock">Stock</div>

                                            <div style="font-weight: bolder;">${{fcNum(pp.precio)}}</div>
                                            <div style="font-weight: bolder;">${{fcNum(pp.precio - pp.importe_descuento)}}</div>
                                            <div style="font-weight: bolder;">${{fcNum(((pp.precio - pp.importe_descuento)* pp.cantidad))}}</div>
                                            <div style="font-weight: bolder;" *ngIf="muestraStock">{{pp.stock}}</div>
                                        </div>
                                    </div>
                                    
                                </mat-card>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>