<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Actualizar Precios de Venta
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
            
        </div>
        <div *ngIf="venta">

          <table class="styled-table">
            <thead>
                <tr>
                    <th>Producto</th>
                    <th>Color</th>
                    <th>Medida</th>
                    <th>Cantidad</th>
                    <th>Precio Unitario</th>
                    <th>Descuento Unitario</th>
                    <th>Descuento SubTotal</th>
                    <th>Precio</th>
                    <th class="th-alerta" *ngIf="venta._diferenciaPrecio">Precio U. Nuevo</th>
                    <th class="th-alerta" *ngIf="venta._diferenciaPrecio">Precio Nuevo S/D</th>
                    <th class="th-alerta" *ngIf="venta._diferenciaPrecio">Precio Nuevo Final</th>
                    <th *ngIf="">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of venta.productos">
                    <td style="text-align: right;">{{item.producto_descripcion}} <span class="tip-desactivado" *ngIf="!item.producto_activo">DESACTIVADO</span></td>
                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                    <td style="text-align: right;">{{item.cantidad}}</td>
                    <td style="text-align: right;">$ {{fcNum((item.precio))}}</td>
                    <td style="text-align: right;">$ {{fcNum((item.importe_descuento))}}</td>
                    <td style="text-align: right;">$ {{fcNum((item.importe_descuento * item.cantidad))}}</td>
                    <td style="text-align: right;">$ {{fcNum(((item.precio - item.importe_descuento) *  item.cantidad))}}</td>
                    <td class="td-alerta" *ngIf="venta._diferenciaPrecio">
                      <input type="number" class="input-valores"  min="0" matInput autocomplete="nope" [(ngModel)]="item._precio_actual" (ngModelChange)='changeImporte(item)'>
                    </td>
                    <td class="td-alerta" *ngIf="venta._diferenciaPrecio">$ {{fcNum(((item._precio_actual ? item._precio_actual : 0) * item.cantidad))}}</td>
                    <td class="td-alerta" *ngIf="venta._diferenciaPrecio">$ {{fcNum((((item._precio_actual ? item._precio_actual : 0) - item.importe_descuento ) * item.cantidad))}}</td>
                </tr>
                <tr style="background-color: #bdbdbd;">
                    <td colspan="8" style="font-weight: bold;">TOTAL</td>
                    <td colspan="2" style="font-weight: bold;">$ {{fcNum(venta._total_productos)}}</td>
                    <td class="td-foot-alerta" style="font-weight: bold;" *ngIf="venta._diferenciaPrecio">$ {{fcNum(venta._total_productos_actual)}}</td>
                </tr>
            </tbody>
        </table>

        </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCancelar()">Cancelar</button>
        <button mat-button (click)="clickConfirmar()" [disabled]="cargando">Confirmar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>