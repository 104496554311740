<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
        Modificar Precios Masivo
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>
  
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">

        <div>
            <mat-form-field style="width: 50%; min-width: 200px; max-width: 500px;" appearance="outline">
                <mat-label>Porcentaje de Cambio (Permite valores negativos)</mat-label>
                <input matInput type="number" [(ngModel)]="aumento">
              </mat-form-field>
        </div>
  
        <div>
            <section style="display: flex; flex-direction: column; flex-wrap: wrap; align-items: flex-start;">
                <label>Aplicar Sobre el Listado</label>
                <mat-radio-group [(ngModel)]="seleccion" (change)="radioChange($event)" style="display: flex; flex-direction: column;">
                  <mat-radio-button style="margin: 6px;" value="total">Total de Productos ({{coleccionTotal.length}} productos)</mat-radio-button>
                  <mat-radio-button style="margin: 6px;" value="filtrado">
                    Productos filtrados por Rubro({{rubroDescripcion != "" ? rubroDescripcion : "TODOS"}}) 
                    y SubRubro ({{subRubroDescripcion != "" ? subRubroDescripcion : "TODOS"}})
                    ({{coleccionFiltrada.length}} productos)  
                </mat-radio-button>
                </mat-radio-group>
              </section>
        </div>

        <div style="margin-top: 8px; margin-bottom: 16px;">
            <div style="margin-bottom: 8px;">Ejemplo de algunos precios luego del cambio:</div>

            <table class="tabla-interna">
                <tr>
                    <th>Producto</th>
                    <th>Precio Actual</th>
                    <th>Diferencia Calculada</th>
                    <th>Nuevo Precio</th>
                </tr>
                <tr *ngFor="let e of ejemplos">
                    <td>
                        <div>{{e.productoDescripcion}}</div>
                        <div style="font-size: 9pt;">{{e.colorDescripcion}} | {{e.medidaDescripcion}}</div>
                    </td>
                    <td style="text-align: right;">{{e.precio}}</td>
                    <td style="text-align: right;">{{((aumento/100)*e.precio).toFixed(2)}}</td>
                    <td style="text-align: right;">{{(e.precio + ((aumento/100)*e.precio)).toFixed(2)}}</td>
                </tr>
            </table>

        </div>

        <div style="border: 1px solid #d00000;
        padding: 4px;
        border-radius: 6px;
        background-color: #ec4646;
        color: white;
        text-align: center;
        font-size: 11pt;">
            Aclaración: La modificación del precio se aplica sobre todas las presentaciones de cada producto
        </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cancelar</button>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>