<mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">

    </div>

    <div style="display: flex; flex-direction: row; align-items: baseline; justify-content: space-between;">
    
        <div style="flex-grow: 0.4; padding:6px;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event.target.value)" autocomplete="nope">
            </mat-form-field>
        </div>
   
        <!--
        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="Rubro" matInput readonly [(ngModel)]="rubroSelected.nombre">
                <button *ngIf="rubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearRubro()">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarRubro()" >
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="SubRubro" matInput readonly [(ngModel)]="subRubroSelected.nombre">
                <button *ngIf="subRubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearSubRubro()" >
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarSubRubro()"  [disabled]="rubroSelected.id == 0">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>
        -->

        <div>
            <button mat-raised-button color="primary" (click)="exportarExcel()">Exportar a Excel</button>
        </div>

    </div>

    <!--
    <div style="display: flex; font-size: 9pt; margin-bottom: 12px; justify-content: space-between;">
        <div>
            Filtrados por Rubro y SubRubro&nbsp;<span>{{dataSource.data.length}}</span>&nbsp;| Mostrando {{dataSource.filteredData.length}}
        </div>
        <div>
            Total de Presentaciones&nbsp;<span>{{dataCompleta.length}}</span>
        </div>
        
    </div>
-->

    <div>
        <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let rubro of list">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div style="font-size: 13pt;">
                            {{rubro.rubroNombre}}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div style="padding: 8px 8px 8px 16px;">
                    <mat-expansion-panel  *ngFor="let subRubro of rubro.subRubro">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div style="font-size: 11pt;">
                                    {{subRubro.subRubroNombre}}
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="margin: 8px 16px 8px 32px; overflow-x: auto;">
                            <table class="styled-table">
                                <thead>
                                    <tr>
                                        <th class="sticky-col">Producto</th>
                                        <th>Medida</th>
                                        <th>Precio Base</th>
                                        <ng-container *ngFor="let tp of listTipoPrecio">
                                            <th> {{tp.descripcion}}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let producto of subRubro.productos">
                                        <tr>
                                            <td class="sticky-col">{{producto.producto_nombre}}</td>
                                            <td>{{producto.medida_nombre}}</td>
                                            <td style="text-align: right;">{{producto._precio}}</td>
                                            <ng-container *ngFor="let precio of producto.listPrecios">
                                                <td style="text-align: right;"> {{precio._precio}}</td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </mat-expansion-panel>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

</div>
