import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { formatNumeroNacional } from 'src/app/utiles';

@Component({
  selector: 'app-venta-actualizar-precio',
  templateUrl: './venta-actualizar-precio.component.html',
  styleUrls: ['./venta-actualizar-precio.component.scss']
})
export class VentaActualizarPrecioComponent implements OnInit {

  cargando = false;

  fcNum = formatNumeroNacional;

  venta: any = null;
  ventaOriginal: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VentaActualizarPrecioComponent>,
    private apiService: ApiService,
    
  ) {



  }

  ngOnInit(): void {

    this.venta = JSON.parse(JSON.stringify(this.data.venta));
    this.ventaOriginal = JSON.parse(JSON.stringify(this.data.venta));
    

  }

  clickConfirmar(){
    let error = false;
    this.venta.productos.forEach(p => {
      if (!(p._precio_actual > 0)){
        error = true;
      }
    })

    if (!error){
      this.cargando = true;
      this.apiService.postActualizarPrecioVenta(this.venta).subscribe(x => {
        if (x){
          this.dialogRef.close(
            {
              modifica: true,
              cierre: true
            });
        }
        this.cargando = false;
      },e => {
        this.cargando = false;
        console.log("response error", e);
      })
    }
  }

  clickCancelar(){
    this.dialogRef.close(
      {
        cierre: true
      });
  }

  changeImporte(prod){
    let total = 0.0;
    this.venta.productos.forEach(p => {
      let _precio_actual = p._precio_actual ? p._precio_actual : 0;
      total = total + (_precio_actual - p.importe_descuento) * p.cantidad;
      console.log("total", total);
    })
    this.venta._total_productos_actual = total;
  }

}
