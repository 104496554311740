import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { formatNumeroNacional } from 'src/app/utiles';
import { ClientesNuevoDialogComponent } from '../../clientes/clientes-nuevo-dialog/clientes-nuevo-dialog.component';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';

@Component({
  selector: 'app-compras-productos-presentaciones-dialog',
  templateUrl: './compras-productos-presentaciones-dialog.component.html',
  styleUrls: ['./compras-productos-presentaciones-dialog.component.scss']
})
export class ComprasProductosPresentacionesDialogComponent implements OnInit {

  idProducto = 0;
  descripcion = "";
  activo = null;

  displayedColumns: string[] = ['id', 'color', 'medida', 'opciones'];

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  coleccion: any[] = [];

  cargando = false;
  listAgregados: any[] = [];

  muestraImporte = false;
  muestraPrecio = false;
  muestraSucursal = false;
  muestraStock = true;

  sucursal = {
    idsucursal: 0,
    nombre: ""
  }

  fcNum = formatNumeroNacional;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SelectorGenericoComponent>,
    private dialog: MatDialog,
    private apiService: ApiService

  ) {

    console.log("data", this.data);

    this.idProducto = this.data.idProducto;
    this.activo = this.data.activo;
    this.descripcion = this.data.descripcion;
    this.listAgregados = this.data.listAgregados;

    if (this.data.muestraImporte){
      this.muestraImporte = this.data.muestraImporte;
    }

    if (this.data.muestraPrecio){
      this.muestraPrecio = this.data.muestraPrecio;
      if (this.muestraPrecio){
        this.displayedColumns.splice( this.displayedColumns.length - 1, 0, "precio");
      }
    }

    if (this.data.muestraSucursal){
      this.muestraSucursal = this.data.muestraSucursal;
    }

    if (this.data.muestraStock){
      this.muestraStock = this.data.muestraStock;
      if (this.muestraStock){
        this.displayedColumns.splice( this.displayedColumns.length - 1, 0, "stock");
      }
    }

    if (this.data.sucursal){
      this.sucursal = this.data.sucursal;
    }
    
  }

  ngOnInit(): void {
    this.cargando = false;

    this.apiService.getProductoPresentacionesListByIdProducto(this.idProducto).subscribe((x:any[]) => {
      this.coleccion = x.map(x => {
        x.cantidad = 0;
        x.importe = 0.0;
        x.stock = 0;
        return x; 
      });
      
      let listOk = this.listAgregados.filter(z => z.idproducto == this.idProducto);
      
      listOk.forEach(z => {
        let e = this.coleccion.find(y => y.idproductopresentacion == z.idproductopresentacion);
        if (e != null){
          e.cantidad = z.cantidad;
          e.importe = z.importe;
        }
      });

      console.log("coleccion", this.coleccion);

      if (this.sucursal.idsucursal != 0 && this.muestraStock){
        this.apiService.getProductosPresentacionesStockSucursalSucProd(this.sucursal.idsucursal, this.idProducto).subscribe((pp: any[]) => {
          console.log("ppppppppp", pp);
          this.coleccion.forEach(c => {
            let exist = pp.find(cc => cc.idproductopresentacion == c.idproductopresentacion);
            if (exist){
              c.stock = exist.cantidad;
            }
          });
          this.dataSource = new MatTableDataSource(this.coleccion);
        });
      }else{
        this.dataSource = new MatTableDataSource(this.coleccion);
      }

    });
    
  }

  clickCerrar() {
    this.dialogRef.close(
      {
        cierre: true,
        listAgregados: this.listAgregados
      });
  }


  onChangeSelectItem(event: any, element: any) {
    //element.selected = event.checked;
  }

  onChangeSelectGlobal(event: any) {
    /*
    this.tecnicos.forEach(x => {
      x.selected = event.checked;
    });
    */
  }

  filtroRapido(filterValue: any){
    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
  }

  changeCantidad(element: any, v: number){

    let cantidad = Number(v);

    if (Number.isInteger(cantidad)){
      element.cantidad = cantidad;
      if (element.cantidad > 0){
        let a = this.listAgregados.find(x => x.idproductopresentacion == element.idproductopresentacion);
        if (a != null){
          a.cantidad = element.cantidad;
        }else{
          let p = {
            idproductopresentacion: element.idproductopresentacion,
            idproducto: element.idproducto,
            colorDescripcion: element.color_descripcion,
            medidaDescripcion: element.medida_descripcion,
            productoDescripcion: this.descripcion,
            productoActivo: this.activo,
            descripcion: element.color_descripcion + " | " + element.medida_descripcion,
            cantidad: element.cantidad,
            precio: element.precio,
            stock: element.stock,
            importe: 0.0,
            porcentaje_descuento: 0.0,
            importe_descuento: 0.0
          }
          this.listAgregados.push(p);
        }
      }else{
        element.cantidad = 0;
        let index = this.listAgregados.findIndex(x => x.idproductopresentacion == element.idproductopresentacion);
        if (index != -1){
          this.listAgregados.splice(index, 1);
        }
        element.importe = 0.0;
      }
    }

    console.log("element", element);

  }

  changeImporte(element: any, v: number){
    let importe = Number(v);
    if (!isNaN(importe)){
      let p = this.listAgregados.find(x => x.idproductopresentacion == element.idproductopresentacion);
      if (p){
        p.importe = importe;
      }
    }
  }

  clickIncrementar(elemento: any, e: any){
    let cantidad = Number(elemento.cantidad);
    cantidad++;
    elemento.cantidad = cantidad;

    this.changeCantidad(elemento, cantidad);
  }

  clickDecrementar(elemento: any, e: any){
    let cantidad = Number(elemento.cantidad);
    cantidad--;
    elemento.cantidad = cantidad < 0 ? 0 : cantidad;
    this.changeCantidad(elemento, cantidad);
  }

  
  

}

const ELEMENT_DATA: any[] = [];
