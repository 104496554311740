import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { formatNumeroNacional } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { ProductosPreciosMasivoDialogComponent } from '../productos-precios-masivo-dialog/productos-precios-masivo-dialog.component';
import { ProductosPreciosPresentacionesMasivoDialogComponent } from '../productos-precios-presentaciones-masivo-dialog/productos-precios-presentaciones-masivo-dialog.component';

@Component({
  selector: 'app-productos-abm',
  templateUrl: './productos-abm.component.html',
  styleUrls: ['./productos-abm.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ProductosAbmComponent implements OnInit {

  displayedColumns: string[] = ['idproducto', 'productoDescripcion', 'rubroDescripcion', 'subrubroDescripcion', 'activo', 'opciones'];
  //displayedColumns: string[] = ['idproducto', 'productoDescripcion', 'rubroDescripcion', 'subrubroDescripcion', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  listTipoPrecio: any[] = [];

  listSubRubro: any[] = [];
  listRubro: any[] = [];
  listStock: any[] = [];
  listSucursales: any[] = [];

  mapStock: Map<number, any> = new Map();

  stockInit: any[] = [];

  tipoPrecio?: TiposDePrecioModel;

  rubroSelected = {
    id: 0,
    nombre: ""
  }

  subRubroSelected = {
    id: 0,
    nombre: ""
  }

  sucursalSelected = {
    id: 0,
    nombre: ""
  }

  dataCompleta: any[] = [];

  cargando = false;

  @ViewChild(MatSort)
  sort!: MatSort;

  cargandoElemento = false;

  listEstado = [{nombre: 'Ambos', valor: null}, {nombre: 'Activo', valor: true}, {nombre: 'Inactivo', valor: false}];

  estadoSel;

  fcNum = formatNumeroNacional;


  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog
  ) {

    let obsTiposDePrecio = this.apiService.getTiposDePrecioList();
    let obsSubRubros = this.apiService.getSubRubrosList();
    let obsRubros = this.apiService.getRubrosList();
    let obsSucursales = this.apiService.getSucursalesList();

    this.cargando = true;

    this.estadoSel = this.listEstado[0];

    combineLatest([obsTiposDePrecio, obsSubRubros, obsRubros, obsSucursales]).subscribe(x => {
      this.listTipoPrecio = x[0];
      if(x[0].length > 0){
        this.tipoPrecio = x[0][0];
      }
      this.listSubRubro = x[1];
      this.listRubro = x[2];
      this.listSucursales = x[3];

      this.listSucursales.forEach(xx => {
        this.stockInit.push({
          idsucursal: xx.idsucursal,
          descripcion: xx.descripcion,
          cantidad: 0
        });
      });

      this.cargando = false;

      this.cargarTabla();

    });

  }

  ngOnInit(): void {
    
  }

  cargarTabla(){
    this.cargando = true;
    
    this.apiService.getProductosListCompleto().subscribe( (o: any[]) => {

      console.log("producto", o);

      let vals = o.map(x => {
        let r = {
          idproducto: x.idproducto,
          productoDescripcion: x.producto_descripcion,
          idrubroproducto: x.idrubroproducto,
          rubroDescripcion: x.rubro_descripcion,
          idsubrubroproducto: x.idsubrubroproducto,
          subrubroDescripcion: x.subrubro_descripcion,
          activo: x.activo ? (x.activo > 0) : false,
          _busqueda: x.producto_descripcion.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US'),
          presentaciones: []
        }

        return r;
      });

      console.log("producto vals", vals);

      this.dataCompleta = vals;

      this.cargando = false;

      this.cargarFiltrado();

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    });
  }

  clickBuscar(){

  }

  clickProductoRow(element){
    this.expandedElement = this.expandedElement === element ? null : element
    if (this.expandedElement != null){
      this.resolverDatos(element);
    }else{
      element.presentaciones = [];
    }
  }

  clickNuevo(){
    this.router.navigateByUrl('/productos/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/productos/editar/${row.idproducto}`,);
  }

  clickEditar(element: any, event: any){
    //console.log(element);
    event.stopPropagation();
    this.router.navigateByUrl(`/productos/editar/${element.idproducto}`,);
  }

  clickEliminar(element: any, event: any){
    console.log("element", element);

    event.stopPropagation();

    this.dialogBorrar("Confirmar", `¿Esta seguro que desea eliminar el producto ${element.productoDescripcion}?`, element);
  }

  onChangeEstado(estadoSel, event){
    console.log("estadoSel", estadoSel);
    console.log("estadoSel", event);

    this.cargarFiltrado();
  }

  resolverDatos(element){

    this.cargandoElemento = true;

    let obsRelProductosPresentacionesById = this.apiService.getRelProductosPresentacionesById(element.idproducto);
    let obsProductosPresentacionesStockSucursalByProducto = this.apiService.getProductosPresentacionesStockSucursalByProducto(element.idproducto);

    combineLatest([obsRelProductosPresentacionesById, obsProductosPresentacionesStockSucursalByProducto]).subscribe((hh: any[]) => {
    //this.apiService.getRelProductosPresentacionesById(element.idproducto).subscribe( (o: any[]) => {

      hh[1].forEach(xx => {
        if (this.mapStock.has(xx.idproductopresentacion)){
          let arr = this.mapStock.get(xx.idproductopresentacion);
          arr.push(xx);
        }else{
          this.mapStock.set(xx.idproductopresentacion, [xx]);
        }
      });

      let mapList: Map<number, any> = new Map();

      hh[0].forEach(x => {
        if (mapList.has(x.idproducto)){
          let contenido = mapList.get(x.idproducto);
          let presentacion: any = {
            idproductopresentacion: x.idproductopresentacion,
            colorDescripcion: x.color_descripcion,
            medidaDescripcion: x.medida_descripcion,
            idmedidaProducto: x.idmedidaproducto,
            idcolorproducto: x.idcolorproducto,
            precio: x.precio,
            cantidad: x.cantidad,
            stock: JSON.parse(JSON.stringify(this.stockInit))
          };
          contenido.presentaciones.push(presentacion);
          mapList.set(x.idproducto, contenido);
        }else{
          let contenido: any = {
            idproducto: x.idproducto,
            productoDescripcion: x.producto_descripcion,
            idrubroproducto: x.idrubroproducto,
            rubroDescripcion: x.rubro_descripcion,
            idsubrubroproducto: x.idsubrubroproducto,
            subrubroDescripcion: x.subrubro_descripcion,
            _busqueda: x.producto_descripcion.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US'),
            presentaciones: []
          };
          let presentacion: any = {
            idproductopresentacion: x.idproductopresentacion,
            colorDescripcion: x.color_descripcion,
            medidaDescripcion: x.medida_descripcion,
            idmedidaProducto: x.idmedidaproducto,
            idcolorproducto: x.idcolorproducto,
            precio: x.precio,
            cantidad: x.cantidad,
            stock: JSON.parse(JSON.stringify(this.stockInit))
          };

          if (x.idproductopresentacion != null){
            contenido.presentaciones.push(presentacion);
          }
          
          mapList.set(x.idproducto, contenido);
        }
      });

      let vals =[ ...mapList.values() ];

      vals.forEach(vv => {
        vv.presentaciones.forEach((vp: any) => {
          if (this.mapStock.has(vp.idproductopresentacion)){
            let st: any[] = this.mapStock.get(vp.idproductopresentacion);
            vp.stock.forEach((s: any) => {
              let existe = st.find(j => j.idsucursal == s.idsucursal);
              if (existe != null){
                s.cantidad = existe.cantidad;
              }
            })
          }
        })
      });

      console.log("vals", vals);

      let ee = this.dataCompleta.find(x => x.idproducto == element.idproducto);
      if (ee != null){
        ee.presentaciones  = vals[0].presentaciones;
      }
     
      //this.dataCompleta = vals;

      this.cargando = false;

      this.cargandoElemento = false;

      this.cargarFiltrado();

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    });
  }

  dialogBorrar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        this.borrar(elemento)
      } 
    });

  }

  borrar(elemento: any){
    

    //this.apiService.deleteProducto(elemento).subscribe(r => {
    this.apiService.deleteSeguro(elemento.idproducto).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    });

  }

  sortData(sort: any){
    console.log("event sort", sort);

    this.dataSource.data.sort((a: any, b: any) => {

      console.log("active", sort.active);
      console.log("a", a);
      console.log("b", b);

      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'idproducto': return compare(a.idproducto, b.idproducto, isAsc);
        case 'productoDescripcion': return compare(a.productoDescripcion, b.productoDescripcion, isAsc);
        case 'rubroDescripcion': return compare(a.rubroDescripcion, b.rubroDescripcion, isAsc);
        case 'subrubroDescripcion': return compare(a.subrubroDescripcion, b.subrubroDescripcion, isAsc);
        default: return 0;
      }
      
   });

  }

  filtroRapido(filterValue: any) {

    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
    
  }

  seleccionarRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listRubro.forEach(x => {
      let item = {
        id: x.idrubroproducto,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Rubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Rubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.rubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.rubroSelected.id != rr.seleccion.id){
            this.subRubroSelected.id = 0;
            this.subRubroSelected.nombre = "";
          }
          this.rubroSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.rubroSelected = {nombre: "", id: 0};
          this.subRubroSelected = { nombre: "", id: 0};
        }
        this.cargarFiltrado();
      }
    });

  }

  seleccionarSubRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    if (this.rubroSelected.id == 0){
      return;
    }

    let list = this.listSubRubro.filter(x => x.idrubroproducto == this.rubroSelected.id);

    list.forEach(x => {
      let item = {
        id: x.idsubrubroproducto,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de SubRubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo SubRubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.subRubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.subRubroSelected = {
            nombre: rr.seleccion.nombre,
            id: rr.seleccion.id 
          }
        }else{
          this.subRubroSelected = {nombre: "", id: 0};
        }
        this.cargarFiltrado();
      }
    });

  }

  seleccionarSucursal(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.sucursalSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.sucursalSelected.id != rr.seleccion.id){
            this.sucursalSelected.id = 0;
            this.sucursalSelected.nombre = "";
          }
          this.sucursalSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.sucursalSelected = {nombre: "", id: 0};
        }
        //this.cargarFiltradoRubroSubRubro();
      }
    });
  }

  cargarFiltrado(){

    let dataFiltrada: any[] = []

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id != 0){
      dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id && x.idsubrubroproducto == this.subRubroSelected.id);
    }

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id);
    }

    if (this.rubroSelected.id == 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.dataCompleta;
    }

    if (this.estadoSel != this.listEstado[0]){
      if (this.estadoSel == this.listEstado[1]){
        dataFiltrada = dataFiltrada.filter(x => x.activo == true);
      }
      if (this.estadoSel == this.listEstado[2]){
        dataFiltrada = dataFiltrada.filter(x => x.activo == false);
      }
    }

    this.dataSource.data = dataFiltrada;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }
  
  clickClearRubro(){
    this.rubroSelected = {id: 0, nombre: ""};
    this.subRubroSelected = {id: 0, nombre: ""};


    this.cargarFiltrado();

    /*

    this.dataSource.data = this.dataCompleta;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );
    */

  }

  clickClearSubRubro(){
    this.subRubroSelected = {id: 0, nombre: ""};

    if (this.rubroSelected.id == 0){
      this.dataSource.data = this.dataCompleta;
    }else{
      let dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id);
      this.dataSource.data = dataFiltrada;
    }

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }

  clickClearSucursal(){
    /*
    this.rubroSelected = {id: 0, nombre: ""};
    this.subRubroSelected = {id: 0, nombre: ""};

    this.dataSource.data = this.dataCompleta;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );
      */
  }

  clickModificarMasivo(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    dialogConfig.data = {
      coleccionFiltrada: this.dataSource.data,
      coleccionTotal: this.dataCompleta,
      idRubroProducto: this.rubroSelected.id,
      idSubRubroProducto: this.subRubroSelected.id,
      rubroDescripcion: this.rubroSelected.nombre,
      subRubroDescripcion: this.subRubroSelected.nombre
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(ProductosPreciosMasivoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr", rr);
      if (rr != null){
        if (rr.cierre && rr.update){
          this.cargarTabla()
        }
      }
    });
  }

  clickModificarProductoMasivo(e: any){
    console.log("elemento", e);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    dialogConfig.data = {
      producto: e,
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(ProductosPreciosPresentacionesMasivoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr", rr);
      if (rr != null){
        if (rr.cierre && rr.update){
          this.cargarTabla()
        }
      }
    });

  }

}
/*
function compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};
*/

function compare(a: string, b: string, isAsc: boolean){
  return a.localeCompare(b)
}

const ELEMENT_DATA: any[] = [];


