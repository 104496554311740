<mat-dialog-content>

    <div style="display: flex; flex-direction: column; max-width: 350px;">
   
        <mat-form-field class="example-full-width">
            <mat-label>Cantidad</mat-label>
            <input matInput  placeholder="Cantidad" type="number" [(ngModel)]="item.cantidad">
        </mat-form-field>
    
    </div>
    
    </mat-dialog-content>
    
    <mat-dialog-actions>
    
    <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
        <button mat-button (click)="clickGuardar()">Guardar</button>
    </div>
        
</mat-dialog-actions>