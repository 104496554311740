<mat-card class="mat-elevation-z0">
    <mat-card-header style="display: flex; flex-direction: column; align-items: center;">
        <div mat-card-avatar class="imagen-perfil" style="height: 70px; width: 70px; margin-bottom: 18px;"></div>
        <mat-card-title style="font-size: 16px; text-align: center;">
            {{ userName }}
        </mat-card-title>
        <mat-card-subtitle style="font-size: 14px; text-align: center;">
            {{ userEmail }}
        </mat-card-subtitle>
    </mat-card-header>
    <!--
    <mat-card-content>
        <mat-divider></mat-divider>
    </mat-card-content>
    -->
    <mat-card-actions>        
        <button mat-button color="accent" (click)="logOut()"><mat-icon>power_settings_new</mat-icon> Cerrar Sesión</button>
    </mat-card-actions>    
</mat-card>