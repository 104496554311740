import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-medidas-producto-abm',
  templateUrl: './medidas-producto-abm.component.html',
  styleUrls: ['./medidas-producto-abm.component.scss']
})
export class MedidasProductoAbmComponent implements OnInit {


  displayedColumns: string[] = ['idmedidaproducto', 'descripcion', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    public router: Router
  ) {
    
  }

  ngOnInit(): void {
    this.cargarTabla();
  }

  cargarTabla(){

    this.apiService.getMedidasList().subscribe( (o: any[]) => {
      this.dataSource.data = o;
    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/productos/medidas/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/productos/medidas/editar/${row.idmedidaproducto}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/productos/medidas/editar/${element.idmedidaproducto}`,);
  }

  clickEliminar(element: any){

    console.log(element);
    
    this.apiService.deleteMedidas(element).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    }, e => {
      this.snackBarService.errorMessage("Error al eliminar la Medida. " + e.message);
    });
    
    //this.router.navigateByUrl(`/productos/editar/${element.idproducto}`,);
  }

}

const ELEMENT_DATA: any[] = [];

