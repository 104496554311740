import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-profilemenu',
  templateUrl: './profilemenu.component.html',
  styleUrls: ['./profilemenu.component.scss']
})
export class ProfilemenuComponent implements OnInit {

  userName = '';
  userEmail = '';
  userApellido = 'Desconocido';

  userInfo: any;

  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {

    console.log("user data", this.auth.userInfo());

    this.userInfo = this.auth.userInfo();

    this.userName = this.userInfo.email;
    this.userEmail = this.userInfo.email;

  }

  logOut() {
    //this.auth.logout();
    this.auth.SignOut().then(
      x => {
        console.log("login x", x);
        this.router.navigateByUrl('login', {});
      },
      e => {
        console.log("login e", e);
      }

    );

  }
}
