import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mensaje-dialog',
  templateUrl: './mensaje-dialog.component.html',
  styleUrls: ['./mensaje-dialog.component.scss']
})
export class MensajeDialogComponent implements OnInit {

  id = '';

  mensaje = '';
  titulo = '';

  captionAccionA = 'Aceptar';
  captionAccionB = 'Cancelar';
  captionAccionC = 'Cerrar';
  captionAccionD = 'AccionD';

  mostrarAccionA = false;
  mostrarAccionB = false;
  mostrarAccionC = false;
  mostrarAccionD = false;

  cierraAccionA = true;
  cierraAccionB = true;
  cierraAccionC = true;
  cierraAccionD = true;

  onAccionA = new EventEmitter();
  onAccionB = new EventEmitter();
  onAccionC = new EventEmitter();
  onAccionD = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MensajeDialogComponent>
  ) { }

  ngOnInit() {

    this.id = this.data.id;
    this.mensaje = this.data.mensaje;
    this.titulo = this.data.titulo;

    this.captionAccionA = this.data.captionAccionA != null ? this.data.captionAccionA : this.captionAccionA;
    this.captionAccionB = this.data.captionAccionB != null ? this.data.captionAccionB : this.captionAccionB;
    this.captionAccionC = this.data.captionAccionC != null ? this.data.captionAccionC : this.captionAccionC;
    this.captionAccionD = this.data.captionAccionD != null ? this.data.captionAccionD : this.captionAccionD;

    this.mostrarAccionA = this.data.mostrarAccionA != null ? this.data.mostrarAccionA : false;
    this.mostrarAccionB = this.data.mostrarAccionB != null ? this.data.mostrarAccionB : false;
    this.mostrarAccionC = this.data.mostrarAccionC != null ? this.data.mostrarAccionC : true;
    this.mostrarAccionD = this.data.mostrarAccionD != null ? this.data.mostrarAccionD : false;

    this.cierraAccionA = this.data.cierraAccionA != null ? this.data.cierraAccionA : true;
    this.cierraAccionB = this.data.cierraAccionB != null ? this.data.cierraAccionB : true;
    this.cierraAccionC = this.data.cierraAccionC != null ? this.data.cierraAccionC : true;
    this.cierraAccionD = this.data.cierraAccionD != null ? this.data.cierraAccionD : true;

  }

  botonAccionA() {
    if (this.cierraAccionA) {
      this.dialogRef.close({opcion: 'accion_a', id: this.id});
    }else {
      this.onAccionA.emit();
    }
  }

  botonAccionB() {
    if (this.cierraAccionB) {
      this.dialogRef.close({opcion: 'accion_b', id: this.id});
    }else {
      this.onAccionB.emit();
    }
  }

  botonAccionC() {
    if (this.cierraAccionC) {
      this.dialogRef.close({opcion: 'accion_c', id: this.id});
    }else {
      this.onAccionC.emit();
    }
  }

  botonAccionD() {
    if (this.cierraAccionD) {
      this.dialogRef.close({opcion: 'accion_d', id: this.id});
    }else {
      this.onAccionD.emit();
    }
  }


}
