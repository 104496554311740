import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';
import { ApiService } from 'src/app/services/api.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { normalizarTextoBusqueda } from 'src/app/shared/utiles/snipets';
import { formatNumeroNacional } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';

@Component({
  selector: 'app-productos-agrupado-medidas',
  templateUrl: './productos-agrupado-medidas.component.html',
  styleUrls: ['./productos-agrupado-medidas.component.scss']
})
export class ProductosAgrupadoMedidasComponent implements OnInit {

  expandedElement: any | null;

  listTipoPrecio: any[] = [];

  listSubRubro: any[] = [];
  listRubro: any[] = [];

  stockInit: any[] = [];

  tipoPrecio?: TiposDePrecioModel;

  rubroSelected = {
    id: 0,
    nombre: ""
  }

  subRubroSelected = {
    id: 0,
    nombre: ""
  }

  sucursalSelected = {
    id: 0,
    nombre: ""
  }

  listOrig: any[] = [];
  list: any[] = [];

  dataCompleta: any[] = [];

  cargando = false;

  @ViewChild(MatSort)
  sort!: MatSort;

  panelOpenState: any;

  fcNum = formatNumeroNacional;


  constructor(
    private apiService: ApiService,
    private _excelService: ExcelService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog
  ) {

    let obsTiposDePrecio = this.apiService.getTiposDePrecioList();
    let obsSubRubros = this.apiService.getSubRubrosList();
    let obsRubros = this.apiService.getRubrosList();
    let obsProductosAgrupados = this.apiService.getProductosAgrupadoMedidas();

    this.cargando = true;

    combineLatest([obsTiposDePrecio, obsSubRubros, obsRubros, obsProductosAgrupados]).subscribe(x => {
      this.listTipoPrecio = x[0];

      console.log("tipoPrecio", this.listTipoPrecio);
      if(x[0].length > 0){
        this.tipoPrecio = x[0][0];
      }
      this.listSubRubro = x[1];
      this.listRubro = x[2];
      
      // procesamos el agrupado para armar esquema de arbol
      let listado: any = [];
      let mapRubro: Map<number, any> = new Map();

      x[3].forEach(p => {
        let arr = [];
        if (mapRubro.has(p.rpId)){
          arr = mapRubro.get(p.rpId);
        }
        arr.push(p);
        mapRubro.set(p.rpId, arr);
      });

      Array.from(mapRubro.keys()).forEach(key => {
        let nivelRubro: any[] = mapRubro.get(key);
        let mapSubRubro: Map<number, any> = new Map();
        nivelRubro.forEach(r => {
          let arr: any[] = [];
          if (mapSubRubro.has(r.srpId)){
            arr = mapSubRubro.get(r.srpId);
          }
          arr.push(r);
          mapSubRubro.set(r.srpId, arr);
        });

        let elemento = {
          rubroId: nivelRubro[0].rpId,
          rubroNombre: nivelRubro[0].rubro_nombre,
          subRubro: [] as any[]
        }

        Array.from(mapSubRubro.keys()).forEach(kk => {
          let productos: any[] = mapSubRubro.get(kk);

          productos.forEach(p => {
            p.listPrecios = [];
            p._busqueda = normalizarTextoBusqueda(`${p.producto_nombre} ${p.medida_nombre}`);
            this.listTipoPrecio.forEach(tp => {
              p.listPrecios.push({
                descripcion: tp.descripcion,
                precio: (p.precio * (1 + (tp.recargo / 100))).toFixed(2),
                _precio: this.fcNum((p.precio * (1 + (tp.recargo / 100))))
              })
            });
            p._precio = this.fcNum(p.precio)
          });

          let e = {
            subRubroId: productos[0].srpId,
            subRubroNombre: productos[0].subrubro_nombre,
            productos: productos
          }
          elemento.subRubro.push(e);
        });

        listado.push(elemento);

      });

      console.log("listado", listado);

      this.listOrig = listado;
      this.list = listado;

      //

      this.cargando = false;

      this.cargarTabla();

    });

  }

  ngOnInit(): void {
    
  }

  cargarTabla(){
    
    this.cargarFiltradoRubroSubRubro();
  }

  clickNuevo(){
    this.router.navigateByUrl('/productos/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/productos/editar/${row.idproducto}`,);
  }

 
  filtroRapido(value: any) {

    let keyword = normalizarTextoBusqueda(value);

    if (keyword.length > 0){

      let lr: any[] = [];

      let listRubro = this.listOrig;

      listRubro.forEach((r: any) => {
        let ls: any[] = [];
        r.subRubro.forEach((s: any) => {
          let lp: any[] = [];
          s.productos.forEach((p: any) => {
            if (p._busqueda.indexOf(keyword) != -1){
              lp.push(p);
            }
          });
          if (lp.length > 0){
            ls.push({
              subRubroId: s.subRubroId,
              subRubroNombre: s.subRubroNombre,
              productos: lp
            });
          }
        });
        if (ls.length > 0){
          lr.push({
            rubroId: r.rubroId,
            rubroNombre: r.rubroNombre,
            subRubro: ls
          });
        }
      });

      this.list = lr;

    }else{
      this.list = this.listOrig;
    }
    
    
    
  }

  seleccionarRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listRubro.forEach(x => {
      let item = {
        id: x.idrubroproducto,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Rubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Rubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.rubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.rubroSelected.id != rr.seleccion.id){
            this.subRubroSelected.id = 0;
            this.subRubroSelected.nombre = "";
          }
          this.rubroSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.rubroSelected = {nombre: "", id: 0};
          this.subRubroSelected = { nombre: "", id: 0};
        }
        this.cargarFiltradoRubroSubRubro();
      }
    });

  }

  seleccionarSubRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    if (this.rubroSelected.id == 0){
      return;
    }

    let list = this.listSubRubro.filter(x => x.idrubroproducto == this.rubroSelected.id);

    list.forEach(x => {
      let item = {
        id: x.idsubrubroproducto,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de SubRubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo SubRubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.subRubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.subRubroSelected = {
            nombre: rr.seleccion.nombre,
            id: rr.seleccion.id 
          }
        }else{
          this.subRubroSelected = {nombre: "", id: 0};
        }
        this.cargarFiltradoRubroSubRubro();
      }
    });

  }

  cargarFiltradoRubroSubRubro(){

    let dataFiltrada: any[] = []

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id != 0){
      dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id && x.idsubrubroproducto == this.subRubroSelected.id);
    }

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id);
    }

    if (this.rubroSelected.id == 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.dataCompleta;
    }

    if (this.sucursalSelected.id != 0){
      dataFiltrada = dataFiltrada.filter(x => x.idsucursal == this.sucursalSelected.id);
    }

    
  }
  
  clickClearRubro(){
    this.rubroSelected = {id: 0, nombre: ""};
    this.subRubroSelected = {id: 0, nombre: ""};

    //this.dataSource.data = this.dataCompleta;

    this.cargarFiltradoRubroSubRubro();

    
  }

  clickClearSubRubro(){
    this.subRubroSelected = {id: 0, nombre: ""};

    /*
    if (this.rubroSelected.id == 0){
      this.dataSource.data = this.dataCompleta;
    }else{
      let dataFiltrada = this.dataCompleta.filter(x => x.idrubroproducto == this.rubroSelected.id);
      this.dataSource.data = dataFiltrada;
    }*/
    this.cargarFiltradoRubroSubRubro();

    
  }

  exportarExcel(){

    let exporta: any[] = [];

    if (this.list.length == 0){
      return;
    }

    this.list.forEach(r => {
      r.subRubro.forEach((s: any) => {
        s.productos.forEach((p: any) => {
          let row: any = {
            "Rubro": r.rubroNombre,
            "SubRubro": s.subRubroNombre,
            "Producto": p.producto_nombre,
            "Medida": p.medida_nombre,
            "Precio Base": p.precio
          }
          p.listPrecios.forEach((tp: any) => {
            row[`${tp.descripcion}`] = Number(tp.precio)
          });
          exporta.push(row);
        });
      });
    });

    if (exporta.length > 0) {
      this._excelService.exportAsExcelFile(
        exporta,
        'lista-precios',
        false,
        [],
        ''
      );
    } else {
      
      /*
      Swal.fire({
        title: 'No hay datos para exportar',
        text: 'Los datos que se muestran son los que se exportan a Excel, actualmente no hay datos',
        icon: 'info',
      });
      */
    }
  }

}


