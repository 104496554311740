<mat-sidenav-container class="sidenav-container" [style.marginTop.px]="50" autosize>
    <mat-sidenav class="sidenav icono-opcion" [(opened)]="isOpen" (closed)="onClose()"
                [mode]="mobileQuery.matches ? 'over' : 'side'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="50"
                [ngClass]="{ 'sidenav-compact':compactSidebar }" style="display: flex; flex-direction: column;">

        <div style="display: flex; flex-direction: column; height: 100%;">

            <mat-list class="nav-list-aplicacion">
                <mat-list-item class="nav-list-item-aplicacion nav-list-item-color" style="background-color: #424242; height: auto; border-bottom: 1px solid #909090;" [@flyInOut]>
                    <div  style="display: flex; flex-direction: row-reverse; width: 100%; margin: 12px 0px 12px 0px;" >

                        <img src="assets/images/logo_256.png" style="height: 29px; width: 29px;"/>

                        <div *ngIf="!compactSidebar" style="font-weight: 500; line-height: 12pt; text-align: right; letter-spacing: 0.8pt; font-size: 16px; display: flex; align-items: center; margin-right: 10px;">
                            <div>
                                Verde<br>
                                Manzana
                            </div>
                        </div>

                    </div>

                </mat-list-item>
            </mat-list>

            <mat-accordion class="mat-elevation-z0" multi="true" displayMode="flat" style="flex-grow: 1; background-color: #708090e6; color: white;">
                <ng-container *ngFor="let opcion of opcionesMenu; let i = index">
                    <ng-template  [ngxPermissionsOnly]="opcion.roles">
                        <mat-expansion-panel class="mat-elevation-z0" hideToggle="true"
                                            [expanded]="step == [i] || allExpanded" (opened)="step[i] = 1" (closed)="step[i] = 0" (click)="clickOpcion(opcion)" style="background-color: #708090e6; color: white;">
                            <!-- Lista de opciones (menu principal) -->
                            <mat-expansion-panel-header *ngIf="opcionesMenu" expandedHeight="50px" collapsedHeight="50px" [ngClass]="[opcion.clickeable ? (lastStep === opcion.titulo ? 'item-activo' : '') : '']"
                                                        matTooltip="{{ compactSidebar? opcion.titulo : '' }}" matTooltipPosition="right"
                                                        [routerLink]="opcion.clickeable? opcion.href : null" style="color: white;">
                                <mat-panel-title>
                                    <mat-icon style="color: inherit;">{{ opcion.icono }}</mat-icon>
                                </mat-panel-title>
                                <mat-panel-description *ngIf="!compactSidebar" style="color: white;">
                                    {{ opcion.titulo }} {{ opcion.texto }}
                                </mat-panel-description>
                                <mat-icon *ngIf="opcion.desplegable" class="padding-right">{{ step[i] ? 'expand_less' : 'expand_more' }}</mat-icon>
                            </mat-expansion-panel-header>

                            <!-- Lista de opciones (submenu) -->

                            <mat-nav-list *ngIf="opcion.submenu" class="no-padding">

                                <ng-container *ngFor="let item of opcion.submenu">

                                    <ng-template  [ngxPermissionsOnly]="item.roles">

                                        <mat-list-item [routerLink]="item.href" class="titulo-submenu"
                                                        matTooltip="{{ compactSidebar? item.titulo : '' }}" matTooltipPosition="right" routerLinkActive="item-activo" (click)="lastStep = opcion.titulo">
                                            <mat-icon mat-list-icon class="icono-submenu" [ngClass]="{ 'margin-left': !compactSidebar }">{{ item.icono }}</mat-icon>
                                            <span *ngIf="!compactSidebar" mat-line [@flyInOut]>
                                                {{ item.titulo }}
                                            </span>
                                            <span *ngIf="item.out">
                                                <mat-icon style="font-size: 14pt;" matTooltip="Abre en una nueva pestaña" (click)="clickNewTab($event, item.href)">launch</mat-icon>
                                            </span>
                                        </mat-list-item>

                                    </ng-template>

                                </ng-container>

                            </mat-nav-list>


                        </mat-expansion-panel>
                    </ng-template>
                </ng-container>
            </mat-accordion>

            <div *ngIf="!compactSidebar" class="version-app">
                v. 2023.12.16.1
            </div>
            
            <mat-list class="nav-list-aplicacion">

                <mat-list-item class="nav-list-item-aplicacion nav-list-item-color" style="background-color: slategrey;" [@flyInOut]>
                    <!--<mat-icon *ngIf="!compactSidebar" [@flyInOut]>build</mat-icon>
                    <span class="titulo-app" *ngIf="!compactSidebar" [@flyInOut] >
                        Coordinación
                    </span>-->
                    <div *ngIf="!compactSidebar" style="min-width: 190px;"></div>
                    <button mat-button color="basic" class="icono-compact" (click)="clickToogleSidebar()"
                            matTooltip="Colapsar" matTooltipPosition="right">
                        <mat-icon>{{ compactSidebar? "chevron_right" : "chevron_left" }}</mat-icon>
                    </button>
                </mat-list-item>

            </mat-list>

        </div>

    </mat-sidenav>

    <!-- Contenido Principal -->
    <!--<mat-sidenav-content class="content" style="background-color: slategrey;">-->
    <mat-sidenav-content class="content" style="background-color: #fafafa;">

        <!-- <app-subheader></app-subheader> -->

        <router-outlet></router-outlet>

    </mat-sidenav-content>

    <mat-sidenav position="end" [(opened)]="isNotasOpen" (closed)="onCloseNotas()">
        <app-notas> </app-notas>
    </mat-sidenav>

</mat-sidenav-container>
