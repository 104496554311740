<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Egreso</button>
    </div>

    <!--
    <div style="display: flex; flex-direction: row;">
        <div style="flex-grow: 0.6; padding:6px;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
            </mat-form-field>
        </div>
    </div>
    -->

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idegreso">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idegreso}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="descripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Descripción </th>
        <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Sucursal </th>
        <td mat-cell *matCellDef="let element"> {{element.sucursalDescripcion}} </td>
        </ng-container>

       
        <ng-container matColumnDef="expandedDetalle">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                <div style="display: flex;flex-direction: column; margin-bottom: 24px; margin-top: 16px;">

                    <!--
                    <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-bottom: 4px; margin-top: 4px;">
                        <button mat-raised-button color="primary" (click)="clickExportarPdf(element)">Imprimir</button>
                    </div>
                    -->

                    <div>
                        <h3>Productos</h3>
                        <div *ngIf="element.presentaciones.length > 0" style="width: 100%;">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                </tr>
                                <tr *ngFor="let item of element.presentaciones">
                                    <td>{{item.productoDescripcion}}</td>
                                    <td>{{item.colorDescripcion}}</td>
                                    <td style="text-align: left;">{{item.medidaDescripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.presentaciones.length == 0" style="margin: 12px;">
                            No hay presentaciones del producto
                        </div>
                    </div>

                </div>
            </div>
        </td>
          </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>