import { Component, Inject, OnInit } from '@angular/core';
import { Database, onValue, query, ref, push, DatabaseReference, set } from '@angular/fire/database';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(
    
  ) { 
    console.log("renderiza layout-component");
  }

  ngOnInit(): void {
    
  }

  

}
