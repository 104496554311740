import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { toFechaCorta, toFechaLarga } from 'src/app/utiles';

@Component({
  selector: 'app-compras-gastos-abm',
  templateUrl: './compras-gastos-abm.component.html',
  styleUrls: ['./compras-gastos-abm.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ComprasGastosAbmComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  vals: any[] = [];

  cargando = false;

  displayedColumns: string[] = ['idcompra', 'descripcion', 'sucursal', 'proveedor', 'importe', 'fecha', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService

  ) {
    moment.locale('es');

    this.sucursalActual = this.sharedService.getSucursalActual();

    let obsComprasGastoCompleto = this.apiService.getComprasGastoCompleto(this.sucursalActual.sucursalId);
    let obsComprasFormaPagoCompleto = this.apiService.getComprasFormaPagoCompleto(this.sucursalActual.sucursalId);

    this.cargando = true;

    combineLatest([obsComprasGastoCompleto, obsComprasFormaPagoCompleto]).subscribe((hh: any[]) =>{
      let x:any[] = hh[0];
      let y:any[] = hh[1]
      //para x
      
      let mapList: Map<number, any> = new Map();

      x.forEach(z => {
        if (mapList.has(z.idcompra)){

        }else{
          let cont: any = {
            idcompra: z.idcompra,
            idproveedor: z.idproveedor,
            idtipocompra: z.idtipocompra,
            idsucursal: z.idsucursal,
            importe: z.importe,
            fecha: z.fecha,
            descripcion: z.descripcion,
            proveedorDescripcion: z.proveedor_descripcion,
            sucursalDescripcion: z.sucursal_descripcion,
            _fecha: toFechaCorta(z.fecha),
            _fechax: toFechaLarga(z.fecha),
            formasPago: []
          };
          mapList.set(z.idcompra, cont);
        }
      });

      y.forEach(yy => {
        if (mapList.has(yy.idcompra)){
          let cont = mapList.get(yy.idcompra);
          let nn = {
            idcompraformapago: yy.idcompraformapago,
            idcompra: yy.idcompra,
            idcompratipoprecio: yy.idcompratipoprecio,
            importe: yy.importe,
            observaciones: yy.observaciones,
            tipoPrecioDescripcion: yy.tipo_precio_descripcion
          }
          cont.formasPago.push(nn);
        }
      });

      console.log("y", y);

      this.vals =[ ...mapList.values() ];

      this.dataSource.data = this.vals;
      
      this.cargando = false;

    });

  }

  ngOnInit(): void {
    this.cargarTabla(0);
  }

  cargarTabla(estado?: number){
    /*
    let comprasProductos = this.apiService.(estado);
    let comprasFormasPagos = this.apiService.getPreventasFormaPago();

    combineLatest([preventasProductos, preventasFormaPago]).subscribe((hh: any[]) =>{

      let o: any[] = hh[0];
      let p: any[] = hh[1];

      p.forEach(xx => {
        xx._recargo = ((xx.importe * xx.recargo) /100)
        xx._conRecargo = xx.importe + xx._recargo
      })

      let mapList: Map<number, any> = new Map();

      o.forEach(x => {
        if (mapList.has(x.idpreventa)){
          let contenido = mapList.get(x.idpreventa);
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            sucursal_descripcion: x.sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto
          };
          contenido.productos.push(producto);
          
          let sum = 0
          contenido.productos.forEach((p: any) => {
            sum = sum + (p.cantidad * p.precio);
          })
          contenido._total = sum;
          
          mapList.set(x.idpreventa, contenido);
        }else{
          let contenido: any = {
            idpreventa: x.idpreventa,
            observaciones: x.observaciones,
            vendedor_nombre: x.vendedor_nombre,
            idvendedor: x.idvendedor,
            fecha: x.fecha,
            senia: x.senia,
            flete: x.flete,
            estado: x.estado,
            estado_descripcion: this.estados[x.estado],
            _fecha: moment(x.fecha).format("dddd, MMMM Do YYYY, HH:mm:ss"),
            productos: [],
            formaPago: []
          };
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            sucursal_descripcion: x.sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto
          };

          contenido.formaPago = p.filter(ff => ff.idpreventa == x.idpreventa);

          contenido.productos.push(producto);

          let sum = 0
          contenido.productos.forEach((p: any) => {
            sum = sum + (p.cantidad * p.precio);
          })
          contenido._total = sum;
          
          mapList.set(x.idpreventa, contenido);
        }
      });

      this.vals =[ ...mapList.values() ];

      console.log("data", this.vals);

      this.dataSource.data = this.vals;

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    })

    */
  }

  clickNuevo(){
    this.router.navigateByUrl('/gastos/nuevo', {});
  }

  clickEditar(e: any, event: any){
    event.stopPropagation();

    let valores = this.vals.find(x => x.idcompra == e.idcompra);
    if (valores != null){
      console.log("storage");
      localStorage.setItem(`gastos-${e.idcompra}`, JSON.stringify(valores));
      this.router.navigateByUrl(`/gastos/editar/${e.idcompra}`, {});
    }
    console.log("e", e);
  }

  clickCancelar(e: any, event: any){
    event.stopPropagation();
    //this.dialogCancelar("Alerta", `¿Confirma que desea cancelar la preventa #id ${e.idpreventa}?`, e.idpreventa);
  }

  dialogCancelar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar
        /*
        this.apiService.getPreventaCancelar(elemento).subscribe(x => {
          if (x){
            let index = this.vals.findIndex(z => z.idpreventa == elemento);
            if (index != -1){
              this.vals.splice(index, 1);
              this.dataSource.data = this.vals;
            }
          }
        });
        */
      } 
    });

  }

  filtroRapido(filterValue: any) {

    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
    
  }

  sortData(sort: any){
    console.log("event sort", sort);

    this.dataSource.data.sort((a: any, b: any) => {

      console.log("active", sort.active);
      console.log("a", a);
      console.log("b", b);

      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) {
        default: return 0;
      }
      
      
   });

  }



}

const ELEMENT_DATA: any[] = [];

