<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>

<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">



    <div style="display: flex; flex-direction: column;">
        <div style="display:flex; flex-direction: row; align-items: baseline; justify-content: space-between;">

            <div style="display: flex; flex-direction: row;">
    
                <mat-form-field appearance="outline">
                    <mat-label>Ingrese un rango de fechas</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Desde">
                        <input matEndDate formControlName="end" placeholder="Hasta">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
            
                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha Desde Incorrecta</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha Hasta Incorrecta</mat-error>
                </mat-form-field>

                <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; margin-left: 8px;">
                    <mat-form-field appearance="outline" style="flex-grow: 1;">
                        <input type="text" placeholder="Sucursal" matInput readonly [(ngModel)]="sucursalSelected.nombre">
                        <button *ngIf="sucursalSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearSucursal()" >
                            <mat-icon>close</mat-icon>
                        </button>
                        <button matSuffix mat-icon-button (click)="seleccionarSucursal()" >
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
    
            </div>
          
            <button mat-raised-button color="primary" (click)="clickConsultar()">Consultar</button>
    
        </div>
        
    </div>

    <div style="display: flex; flex-direction: row;">
        <div style="flex-grow: 0.5;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event)" autocomplete="null">
            </mat-form-field>
        </div>
    </div>

    <div style="display:flex; flex-direction: row; align-items: baseline; margin: 16px 0px 8px 0px;" *ngIf="false">
        <button mat-raised-button color="primary" (click)="exportarExcel()">Exportar a Excel</button>
        <button mat-raised-button color="primary" (click)="exportarPDF()" style="margin-left: 8px;">Exportar a PDF</button>
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idcliente">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idcliente}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="ventas">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Compras Totales </th>
        <td mat-cell *matCellDef="let element"> {{fcNum(element.ventas_totales)}} </td>
        </ng-container>

        <ng-container matColumnDef="pagos">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Pagos Totales </th>
        <td mat-cell *matCellDef="let element"> {{fcNum(element.pagos_totales)}} </td>
        </ng-container>

        <ng-container matColumnDef="debe">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Deuda Total </th>
        <td mat-cell *matCellDef="let element"> {{fcNum(element.debe)}} </td>
        </ng-container>

        <ng-container matColumnDef="porcentaje_deuda">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> % de Deuda </th>
        <td mat-cell *matCellDef="let element"> {{element.porcentaje_deuda}} % </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Cantidad de Ventas Con Deuda </th>
        <td mat-cell *matCellDef="let element"> {{element.cantidad_deudas}} </td>
        </ng-container>

        
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetalle">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div style="height: 2px;">
                        <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargandoElemento && element == expandedElement"></mat-progress-bar>
                    </div>

                    <div style="display: flex;flex-direction: column; margin: 16px 0px 24px 16px;">
                        <div *ngIf="element.detalle != null">
                            <table class="styled-table">
                                <thead>
                                    <tr>
                                        <th class="sticky-col">ID Venta</th>
                                        <th>Fecha</th>
                                        <th>Sucursal</th>
                                        <th>Total Productos</th>
                                        <th>Flete</th>
                                        <th>Descuento General</th>
                                        <th>Total Compra</th>
                                        <th>Pago Realizado</th>
                                        <th>Debe</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let venta of element.detalle">
                                        <tr>
                                            <td class="sticky-col">{{venta.preventa_idpreventa}}</td>
                                            <td>{{venta._fecha}}</td>
                                            <td>{{venta.preventa_sucursal}}</td>
                                            <td style="text-align: right;">{{fcNum(venta.total_productos)}}</td>
                                            <td style="text-align: right;">{{fcNum(venta.flete)}}</td>
                                            <td style="text-align: right;">{{fcNum(venta.descuento_general)}}</td>
                                            <td style="text-align: right;">{{fcNum(venta.precio_final)}}</td>
                                            <td style="text-align: right;">{{fcNum(venta.pago_realizado)}}</td>
                                            <td style="text-align: right;">{{fcNum(venta.debe)}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="clickRow(element); ">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>
    </table>
    

</div>