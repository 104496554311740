<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>



<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">
    

    <h2>Entrega de Productos</h2>
    
    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 16px;">
        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="width: 60vw; max-width: 350px; margin-right: 8px;" appearance="outline" >
                    <mat-label>Localidad</mat-label>
                    <input matInput type="text" [(ngModel)]="localidad.nombre" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarLocalidad()">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field style="width: 60vw; max-width: 350px; margin-right: 8px;" appearance="outline" >
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>

                <mat-form-field style="width: 60vw; max-width: 350px; margin-right: 8px;" appearance="outline">
                    <mat-label>Flete</mat-label>
                    <mat-select [(ngModel)]="selectorFlete" (selectionChange)="selectFlete()">
                        <mat-option *ngFor="let opcionFlete of opcionesFlete" [value]="opcionFlete.id">{{opcionFlete.nombre}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
    </div>

    <div style="display: flex; flex-direction: row; margin-bottom: 12px; justify-content: space-between;">

        <div style="display: flex;">
            <button mat-raised-button (click)="clickImprimirMercaderia()">
                Impresión de Mercaderia
            </button>
            <button mat-raised-button (click)="clickImprimir()">
                Imprimir Selección de Recibos
            </button>
        </div>
        
        <div>
            <button mat-raised-button [matMenuTriggerFor]="menu">Columnas</button>
            <mat-menu #menu="matMenu">
                <div mat-menu-item *ngFor="let item of columnaList; let i = index;">
                    <mat-checkbox #menuItems
                        [ngModel]="item.activado"
                        (change)="clickShowColumna($event, item)">{{ item.titulo }}
                    </mat-checkbox>
                </div>
            </mat-menu>
        </div>
        
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" style="width: 100%;">

        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <!--
                    <mat-checkbox
                (change)="onChangeSelectGlobal($event)"
                [checked]="checkedGlobal">
                </mat-checkbox>
                -->
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox
                (click)="$event.stopPropagation();"
                (change)="onChangeSelectItem($event, element)"
                [checked]="element._seleccionado">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="idpreventa">
        <th mat-header-cell *matHeaderCellDef> Id Venta </th>
        <td mat-cell *matCellDef="let element"> {{element.idpreventa}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente_descripcion">
            <th mat-header-cell *matHeaderCellDef> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente_descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="cliente_telefono">
            <th mat-header-cell *matHeaderCellDef> Telefono Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente_telefono}}</td>
        </ng-container>

        <ng-container matColumnDef="vendedor_descripcion">
            <th mat-header-cell *matHeaderCellDef> Vendedor </th>
            <td mat-cell *matCellDef="let element"> {{element.vendedor_descripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
            <th mat-header-cell *matHeaderCellDef> Sucursal </th>
            <td mat-cell *matCellDef="let element"> {{element.preventa_sucursal}} </td>
        </ng-container>

        <ng-container matColumnDef="domicilio">
            <th mat-header-cell *matHeaderCellDef> Domicilio Entrega </th>
            <td mat-cell *matCellDef="let element"> {{element.domicilio}}</td>
        </ng-container>

        <ng-container matColumnDef="localidad">
            <th mat-header-cell *matHeaderCellDef> Localidad Entrega </th>
            <td mat-cell *matCellDef="let element"> {{element.localidad_descripcion}} ({{element.localidad_codigo_postal}})</td>
        </ng-container>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element._fecha}}</td>
        </ng-container>

        <ng-container matColumnDef="flete">
            <th mat-header-cell *matHeaderCellDef> Flete </th>
            <td mat-cell *matCellDef="let element"> {{element._flete}}</td>
        </ng-container>

        <ng-container matColumnDef="estado_pago">
            <th mat-header-cell *matHeaderCellDef> Estado Pago </th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="element._debe > 0" class="pago-incompleto" matTooltip="Total: $ {{fcNum(element._total)}} &#13; Pagado: $ {{fcNum(element._pagado)}} &#13; Debe: $ {{fcNum(element._debe)}}">
                    Debe: ${{fcNum(element._debe)}}
                </div>
                <div *ngIf="element._debe == 0" class="pago-completo" matTooltip="Total: ${{fcNum(element._total)}} &#13; Pagado: ${{fcNum(element._pagado)}}">
                    Pago Completo
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="debe">
            <th mat-header-cell *matHeaderCellDef> Debe </th>
            <td mat-cell *matCellDef="let element">
                
            </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
        <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
        </ng-container>

        <ng-container matColumnDef="estado_descripcion">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="estado-{{element.estado}}">
                {{element.estado_descripcion}} 
            </div>
        </td>
        </ng-container>
        
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" *ngIf="element.estado >= 0 && element.estado != 3 && element._entregable" (click)="clickEntregar(element, $event)">Entregar</button>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetalle">
            
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                <div style="display: flex; flex-direction: column;">

                    <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-bottom: 4px; margin-top: 4px;">
                        <button mat-raised-button color="primary" (click)="clickExportarPdf(element)">Imprimir</button>
                    </div>

                    <div style="border: 1px solid grey; width: fit-content; padding: 12px; border-radius: 4px;">
                        <div><b>Información del Cliente</b></div>
                        <table>
                            <tr>
                                <td style="text-align: left; font-weight: bold;">Cliente</td>
                                <td style="text-align: left;">{{element.cliente_descripcion}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: left; font-weight: bold;">Domicilio Entrega</td>
                                <td style="text-align: left;">{{element.domicilio}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: left; font-weight: bold;">Telefono</td>
                                <td style="text-align: left;">{{element.cliente_telefono}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: left; font-weight: bold;">Correo Electronico</td>
                                <td style="text-align: left;">{{element.cliente_correo_electronico}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: left; font-weight: bold;">Flete</td>
                                <td style="text-align: left;">{{element.flete > 0 ? "Si ($ " + fcNum(element.flete) + ")" : "No"}}</td>
                            </tr>
                            <tr>
                                <td style="text-align: left; font-weight: bold;">Observaciones acerca del cliente</td>
                                <td style="text-align: left;">{{element.cliente_observaciones}}</td>
                            </tr>
                        </table>
                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;">
                        <div>Productos para Entregar (Disponible en Sucursal para Entregar)</div>
                        <div *ngIf="element.lineas.entregas.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                    <th>Estado de Entrega</th>
                                </tr>
                                <tr *ngFor="let item of element.lineas.entregas">
                                    <td style="text-align: right;">{{item.producto_descripcion}}</td>
                                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                    <td style="text-align: right;">{{item._estado}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.lineas.entregas.length == 0" style="margin: 12px;">
                            No hay productos
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;">
                
                        <div>
                            Productos Entregados
                        </div>

                        <div *ngIf="element.lineas.entregados.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                    <th>Estado de Entrega</th>
                                </tr>
                                <tr *ngFor="let item of element.lineas.entregados">
                                    <td style="text-align: right;">{{item.producto_descripcion}}</td>
                                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                    <td style="text-align: right;">{{item._estado}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.lineas.entregados.length == 0" style="margin: 12px;">
                            No hay productos
                        </div>

                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;">
                
                        <div>
                            Productos Solicitados a Logística (Sin Stock en Sucursal)
                        </div>

                        <div *ngIf="element.lineas.logistica.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                    <th>Estado de Entrega</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let item of element.lineas.logistica">
                                    <td style="text-align: right;">{{item.producto_descripcion}}</td>
                                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                    <td style="text-align: right;">{{item._estado}}</td>
                                    <td style="text-align: right;"><button type="button" (click)="clickForzarEntrega(item)">Pasar a 'Para Entregar'</button></td>

                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.lineas.logistica.length == 0" style="margin: 12px;">
                            No hay productos
                        </div>

                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;">
                
                        <div>
                            Solicitudes Generadas Asociadas a la Venta
                        </div>

                        <div *ngIf="element.solicitudes.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Cantidad</th>
                                    <th>Origen</th>
                                    <th>Destino</th>
                                    <th>Tipo</th>
                                    <th>Fecha</th>
                                    <th>Estado</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let item of element.solicitudes">
                                    <td style="text-align: right;">{{item.producto}} | {{item.color}} | {{item.medida}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                    <td style="text-align: right;">{{item.origen_nombre}}</td>
                                    <td style="text-align: right;">{{item.destino_nombre}}</td>
                                    <td style="text-align: right;">{{item._tipo}}</td>
                                    <td style="text-align: right;">{{item._fecha}}</td>
                                    <td style="text-align: right;">{{item._estado}}</td>
                                    <td style="text-align: right;"><button type="button" (click)="clickAtenderSolicitud(item, $event)" *ngIf="item.estado == 0">Marcar como Atendido</button></td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.solicitudes.length == 0" style="margin: 12px;">
                            No Hay Solicitudes Generadas
                        </div>

                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;">
                
                        <div>
                            Movimientos De Otras Sucursales Generados Por la Venta
                        </div>

                        <div *ngIf="element.movimientos.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>ID</th>
                                    <th>Estado</th>
                                    <th>Origen</th>
                                    <th>Destino</th>
                                    <th>OBS</th>
                                    <th>Fecha</th>
                                </tr>
                                <tr *ngFor="let item of element.movimientos">
                                    <td style="text-align: right;">{{item.idlogisticamovimiento}}</td>
                                    <td style="text-align: right;">{{item._estado}}</td>
                                    <td style="text-align: right;">{{item.origen_descripcion}}</td>
                                    <td style="text-align: right;">{{item.destino_descripcion}}</td>
                                    <td style="text-align: right;">{{item.obs}}</td>
                                    <td style="text-align: right;">{{item._fecha}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.movimientos.length == 0" style="margin: 12px;">
                            No Hay Movimientos Generados
                        </div>

                    </div>

                </div>
            
              </div>
            </td>
          </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row 
            *matRowDef="let element; columns: displayedColumns;"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="expandir(element)">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>
