<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Agregar Formas de Pago
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
            <div style="display: flex;">
                <span>Total: ${{fcNum(importe)}}</span>&nbsp;&nbsp;&nbsp;
                <span>Restante: ${{fcNum(restante)}}</span>
            </div>
            <button mat-raised-button color="primary" (click)="clickAgregar()">Agregar</button>
        </div>
        <div>
            <mat-card  *ngFor="let fp of listFormaPago; let i = index" style="margin: 4px;">
                <div style="display: flex; flex-direction: column; font-size: 10pt;">
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;align-items: baseline;">
                        <mat-checkbox [(ngModel)]="fp.senia" [disabled]="(bloquearSenia && fp.senia) || fp._bloquea" *ngIf="!bloquearSenia">Es Seña</mat-checkbox>
                        <button mat-flat-button (click)="clickBorrarFormaPago(fp, i, $event)" *ngIf="fp._bloquea ? false : (!bloquearSenia || !fp.senia)">Quitar</button>
                    </div>
                    <div *ngIf="fp._fecha" style="margin: 4px 0px 8px 0px;">
                        Pago Realizado el {{fp._fecha}}
                    </div>
                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                        <mat-form-field appearance="outline" style="flex-grow: 0.6;">
                            <mat-label>Forma de Pago</mat-label>
                            <mat-select [(ngModel)]="fp.idtipo"  (selectionChange)="onChange(fp, $event)" [disabled]="(bloquearSenia && fp.senia) || fp._bloquea">
                                <mat-option *ngFor="let ctp of tipoPrecioList" [value]="ctp.idtipo">{{ctp.descripcion + ' | ' + ctp.recargo + '%'}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="flex-grow: 0.4;">
                            <mat-label>Importe</mat-label>
                            <input (change)='changeImporte(fp, $event.target.value)' [disabled]="(bloquearSenia && fp.senia) || fp._bloquea"
                                matInput placeholder="Importe" value="" min="0" type="number" [(ngModel)]="fp.importe" name="importe">
                        </mat-form-field>
                    </div>
                    <div>
                        
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                        <div>Recargo: ${{fcNum(((fp.importe * fp.recargo) / 100))}}</div>
                        <div>Total con Recargo: ${{fcNum(((fp.importe * fp.recargo) / 100 + fp.importe))}}</div>
                    </div>
                    
                </div>
            </mat-card>
        </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCancelar()">Cancelar</button>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>