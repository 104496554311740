<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2 *ngIf="modo=='nuevo'">Ingreso / Compra</h2>
        <h2 *ngIf="modo=='edicion'">Editar Ingreso / Compra #{{compra.idcompra}}</h2>
        <h2 *ngIf="modo=='confirmar'">Confirmar Ingreso / Compra #{{compra.idcompra}}</h2>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="compra.descripcion" name="descripcion">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Sucursal</mat-label>
                <mat-select [(ngModel)]="compra.idsucursal">
                    <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Proveedor</mat-label>
                    <input matInput type="text" [(ngModel)]="compra.proveedorNombre" readonly>
                </mat-form-field>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickSeleccionarProveedor()">Seleccionar Proveedor</button>
            </div>

            <div style="display: flex; flex-direction: column;">
                <mat-checkbox (change)='changeIngresarImporte($event)' [(ngModel)]="ingresarImporte">Especificar importe total</mat-checkbox>
                <div>

                    <div  *ngIf="!ingresarImporte" style="margin: 8px 0px;">
                        <span style="font-weight: bold; display: block; font-size: 13pt;">Importe Total ${{compra.importe}}</span>
                        <span style="font-size: 9pt;">(Este importe es calculado con los precios de los productos)</span>
                    </div>

                    <mat-form-field appearance="outline" style="flex-grow: 1; margin: 8px 0px;" *ngIf="ingresarImporte">
                        <mat-label>Importe</mat-label>
                        <input (change)='changeImporte($event.target.value)' matInput placeholder="Importe" value="" min="0" type="number" [(ngModel)]="compra.importe" name="importe">
                    </mat-form-field>

                </div>
            </div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px; margin-bottom: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2 *ngIf="modo=='edicion' || modo=='nuevo'">Productos a Solicitar</h2>
                <h2 *ngIf="modo=='confirmar'">Productos Recibidos</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregar()">Agregar y Editar Productos</button>
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                <mat-accordion multi *ngIf="agregados.length > 0">
                    <mat-expansion-panel [expanded]="true" *ngFor="let p of agregados" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title style="align-items: center;">
                                {{p.descripcion}}
                            </mat-panel-title>
                            <mat-panel-description style="justify-content: flex-end;">
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div style="padding: 8px;">
                            <mat-card  *ngFor="let pp of p.presentaciones" style="margin: 4px;">
                                <div style="display: flex; flex-direction: column; font-size: 10pt;">
                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
                                        <div>Color: <b>{{pp.colorDescripcion}}</b> | Medida: <b>{{pp.medidaDescripcion}}</b></div>
                                    </div>
                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                        <div style="width: 120px;">Cantidad</div>
                                        <div>{{pp.cantidad}}</div>
                                    </div>
                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                        <div style="width: 120px;">Importe</div>
                                        <div>$ {{pp.importe}}</div>
                                    </div>
                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                        <div style="width: 120px;">SubTotal</div>
                                        <div>$ {{pp.importe * pp.cantidad}}</div>
                                    </div>
                                </div>
                                
                            </mat-card>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div *ngIf="agregados.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos Agregados</div>
                </div>
            </div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Forma de Pago</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar y Editar Formas de Pago</button>
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">

                <mat-card  *ngFor="let fp of formasDePago; let i = index" style="margin: 4px;">
                    <div style="display: flex; flex-direction: column; font-size: 10pt;">
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Forma de Pago: <b>{{fp.tipoPrecioDescripcion}}</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Importe: <b>$ {{fp.importe}}</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Observaciones: <b>{{fp.observaciones}}</b>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <div *ngIf="formasDePago.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Formas de Pago Agregadas</div>
                </div>
            </div>

        </div>
        
    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
    </div>

</div>