// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'verdemanzanamueblesweb',
    appId: '1:527359138294:web:6b1dc45c16fb22fcb0cfd7',
    databaseURL: 'https://verdemanzanamueblesweb-default-rtdb.firebaseio.com',
    storageBucket: 'verdemanzanamueblesweb.appspot.com',
    apiKey: 'AIzaSyATX1g3FTETIFF5xqqb68sD_0hZs7CZ_zM',
    authDomain: 'verdemanzanamueblesweb.firebaseapp.com',
    messagingSenderId: '527359138294',
    measurementId: 'G-Q8X9612R1M',
  },
  production: false,
  urlApi: 'http://apidev.verdemanzanamuebles.com.ar',

  firebaseConfig: {
    apiKey: "AIzaSyATX1g3FTETIFF5xqqb68sD_0hZs7CZ_zM",
    authDomain: "verdemanzanamueblesweb.firebaseapp.com",
    projectId: "verdemanzanamueblesweb",
    storageBucket: "verdemanzanamueblesweb.appspot.com",
    messagingSenderId: "527359138294",
    appId: "1:527359138294:web:6b1dc45c16fb22fcb0cfd7",
    measurementId: "G-Q8X9612R1M"
  },

  pathNotas: '/notas-v2-DEV'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
