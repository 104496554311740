import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { isDuration } from 'moment';
import { PreventaModel } from 'src/app/models/preventa-model';
import { PreventasFormaPagoModel } from 'src/app/models/preventas-forma-pago-model';
import { PreventasProductosModel } from 'src/app/models/preventas-productos-model';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-preventa-confirmar',
  templateUrl: './preventa-confirmar.component.html',
  styleUrls: ['./preventa-confirmar.component.scss']
})
export class PreventaConfirmarComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  lote = {
    totalArticulos: 0,
    listFormaPago: [] as any[],
    flete: 0,
    senia: 0,
    saldo: 0,
    totalFinal: 0
  }

  preventa: any

  listTipoPrecio: TiposDePrecioModel[] = [];

  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<PreventaConfirmarComponent>,
    private snackBarService: SnackBarService,
    public router: Router,
    private sharedService: SharedService
  ) {

    this.sucursalActual = this.sharedService.getSucursalActual();

    console.log("data", this.data);

    this.preventa = this.data.preventa;

    let items = this.data.preventa.listItems as [];
    let precioTotal = 0
    items.forEach((x: any) => {
      precioTotal = precioTotal + x.totalPrecioFinal;
    });
    this.lote.totalArticulos = precioTotal;
    this.calcularSaldo();
   
  }

  ngOnInit(): void {

    this.apiService.getTiposDePrecioList().subscribe(x => {
      this.listTipoPrecio = x;
    })
  }

  clickCerrar(){
    this.dialogRef.close();
  }

  clickConfirmar(){
    let valido = true;

    let sumImporte = 0
    this.lote.listFormaPago.forEach(x => {
      if (!(x.importe > 0)){
        this.snackBarService.errorMessage("Los importes deben ser números mayores a 0.");
        valido = false;
      }
      sumImporte = sumImporte + x.importe;
    })

    if (sumImporte != this.lote.saldo){
      this.snackBarService.errorMessage("La suma de los importes debe ser igual al saldo.");
      valido = false;
    }

    if (this.lote.senia > (this.lote.totalArticulos + this.lote.flete)){
      this.snackBarService.errorMessage("La seña no puede ser mayor a la suma del valor de los productos mas el flete.");
      valido = false;
    }

    if (valido){

      let idCliente = 0
      if (this.preventa.cliente != null){
        idCliente = this.preventa.cliente.idcliente;
      }

      let preventaConfirmada: PreventaModel = {
        flete: this.lote.flete,
        idpreventa: 0,
        idvendedor: this.preventa.idvendedor,
        observaciones: this.preventa.observaciones,
        senia: this.lote.senia,
        idcliente: idCliente,
        estado: 0,
        domicilio: "",
        idlocalidad: 0,
        idsucursal: this.sucursalActual.sucursalId,
        importe_descuento: 0.0,
        porcentaje_descuento: 0.0,
        importe_recargo: 0.0,
        porcentaje_recargo: 0.0,
        esPresupuesto: false
      }
      
      this.apiService.postPreventa(preventaConfirmada).subscribe(x => {
  
        let idpreventa = x.idpreventa;

        let formaPagoItems: PreventasFormaPagoModel [] = [];
        for (let index = 0; index < this.lote.listFormaPago.length; index++) {
          const t = this.lote.listFormaPago[index];
          let formaPago: PreventasFormaPagoModel = {
            idpreventa: idpreventa,
            idpreventaformapago: 0,
            idtipo: t.tipoId,
            importe: t.importe,
            recargo: t.tipoRecargo,
            tipo_descripcion: t.tipoDescripcion,
            senia: false
          };
          formaPagoItems.push(formaPago);
        }
  
        let preventaItems: PreventasProductosModel[] = [];
        this.preventa.listItems.forEach((z: any) => {
  
          z.items.forEach((zz: any) => {
            console.log("z", z);
  
            let n: PreventasProductosModel = {
              idpreventaproducto: 0,
              idpreventa: idpreventa,
              idproducto: zz.idproducto,
              observaciones: "",
              cantidad: zz._agregados_cantidad,
              precio_unitario: zz.precio,
              idproductopresentacion: zz.idproductopresentacion,
              idsucursal: z.idsucursal,
              importe_descuento: zz.importe_descuento,
              porcentaje_descuento: zz.porcentaje_descuento
            }
            preventaItems.push(n);
          });
  
        });
  
        console.log("items", this.preventa.listItems)
  
        this.apiService.postPreventasProductosBulk(preventaItems).subscribe(o => {
          console.log("postPreventasProductosBulk", o);
          //this.cargando = false;
          
          this.apiService.postPreventasFormaPagoBulk(formaPagoItems).subscribe(oo => {
            if (oo){
              this.dialogRef.close(this.lote);
              this.router.navigateByUrl(`/ventas/venta/lista`,);
            }
          })

          
        }, e => {
          //this.cargando = false;
        });
        
  
      }, e => {
        //this.cargando = false;
      })
      

    }
    
  }

  clickEliminar(event: any, item: any, index: number){
    event.stopPropagation();
    this.lote.listFormaPago.splice(index,1);
  }

  clickAgregarFormaPago(){
    let formaPago = {
      importe: 0,
      tipoId: 0,
      tipoDescripcion: "",
      tipoRecargo: 0,
      recargo: 0,
      total: 0
    }

    if (this.listTipoPrecio.length > 0){
      formaPago.tipoId = this.listTipoPrecio[0].idtipo;
      formaPago.tipoDescripcion = this.listTipoPrecio[0].descripcion + " / " + this.listTipoPrecio[0].recargo + "%";
      formaPago.tipoRecargo = this.listTipoPrecio[0].recargo;
    }

    this.lote.listFormaPago.push(formaPago);
  }

  selectTipoPrecio(index: number, formaPago: any, event: any){
    let tipoPrecio = this.listTipoPrecio.find(x => x.idtipo == event.value);
    if (tipoPrecio != null){
      formaPago.tipoId = tipoPrecio.idtipo;
      formaPago.tipoDescripcion = tipoPrecio.descripcion + " / " + tipoPrecio.recargo + "%";
      formaPago.tipoRecargo = tipoPrecio.recargo;
    }
    this.calcularPrecio(formaPago)
  }

  calcularPrecio(formaPago: any){
    let r = Number((1 + (formaPago.tipoRecargo / 100)).toFixed(2))
    formaPago.recargo = Number(((formaPago.importe * r) - formaPago.importe).toFixed(2));
    formaPago.total = Number((formaPago.importe * r).toFixed(2));
  }

  calcularSaldo(){
    this.lote.saldo = this.lote.totalArticulos + (this.lote.flete || 0) - (this.lote.senia || 0);
  }

}
