import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { ClientesModel } from 'src/app/models/clientes-model';

@Component({
  selector: 'app-clientes-nuevo',
  templateUrl: './clientes-nuevo.component.html',
  styleUrls: ['./clientes-nuevo.component.scss']
})
export class ClientesNuevoComponent implements OnInit {

  nuevo: ClientesModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    telefono: "",
    correo_electronico: "",
    codigo_localidad: 0,
    observaciones: "",
  }

  listLocalidades: any[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {
    this.nuevo.idcliente = Number(this.activatedRoute.snapshot.paramMap.get('idcliente'));

    if (this.nuevo.idcliente > 0){
      this.apiService.getClientes(this.nuevo.idcliente).subscribe(x => {
        this.nuevo = x;
      })
    }

    this.apiService.getLocalidadesList().subscribe(x => {
      this.listLocalidades = x as any[];
    });

  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idcliente == 0){
        this.apiService.postClientes(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el cliente correctamente");
          this.router.navigateByUrl('/clientes/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        this.apiService.putClientes(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el cliente correctamente");
          this.router.navigateByUrl('/clientes/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo nombre debe tener mas de 2 caracteres");
    }

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }


}
