import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';

@Component({
  selector: 'app-productos-precios-masivo-dialog',
  templateUrl: './productos-precios-masivo-dialog.component.html',
  styleUrls: ['./productos-precios-masivo-dialog.component.scss']
})
export class ProductosPreciosMasivoDialogComponent implements OnInit {

  cargando = false;

  coleccionTotal: any[] = [];
  coleccionFiltrada: any[] = [];

  idRubroProducto = 0;
  idSubRubroProducto = 0;

  rubroDescripcion = "";
  subRubroDescripcion = "";

  seleccion: 'filtrado' | 'total' = 'total';

  aumento = 0;

  ejemplos: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProductosPreciosMasivoDialogComponent>,
    private dialogInfo: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {

    this.coleccionFiltrada = this.data.coleccionFiltrada;
    this.coleccionTotal = this.data.coleccionTotal;
    this.idRubroProducto = this.data.idRubroProducto;
    this.idSubRubroProducto = this.data.idSubRubroProducto;
    this.rubroDescripcion = this.data.rubroDescripcion;
    this.subRubroDescripcion = this.data.subRubroDescripcion;


    console.log("data", this.data);
    console.log("seleccion", this.seleccion);

    this.generarEjemplos(this.coleccionTotal);

  }


  clickCerrar() {
    this.dialogRef.close(
      {
        cierre: true
      });
  }

  radioChange(event: any){
    console.log("evento", event);

    if (event.value == "total"){
      this.generarEjemplos(this.coleccionTotal);
    }
    if (event.value == "filtrado"){
      this.generarEjemplos(this.coleccionFiltrada);
    }

    console.log("seleccion", this.seleccion);
    
  }

  generarEjemplos(coleccion: any[]){

    let col = coleccion.filter( x=> x.presentaciones.length > 0)

    this.ejemplos = [];

    let max = col.length - 1;
    let min = 1
    let random = Math.floor(Math.random()*(max-min+1)+min);

    console.log("min", min);
    console.log("max", max);
    console.log("random", random);

    if (col.length > 0){

      let unEjemplo: any = {
        precio: col[0].presentaciones[0].precio,
        productoDescripcion: col[0].productoDescripcion,
        colorDescripcion: col[0].presentaciones[0].colorDescripcion,
        medidaDescripcion: col[0].presentaciones[0].medidaDescripcion
      }

      this.ejemplos.push(unEjemplo);

      if (col.length > 1){

        let otroEjemplo: any = {
          precio: col[random].presentaciones[0].precio,
          productoDescripcion: col[random].productoDescripcion,
          colorDescripcion: col[random].presentaciones[0].colorDescripcion,
          medidaDescripcion: col[random].presentaciones[0].medidaDescripcion
        }
  
        this.ejemplos.push(otroEjemplo);
        
      }
    }
    

  }

  clickConfirmar(){

    let msj = ""
    let variacion = this.aumento >= 0 ? `+${this.aumento}%` : `${this.aumento}%`;

    let rubro = this.rubroDescripcion == "" ? "TODOS" : this.rubroDescripcion;
    let subRubro = this.subRubroDescripcion == "" ? "TODOS" : this.subRubroDescripcion;

    if (this.seleccion == "total"){
      msj = `Va a aplicar un cambio de precios de ${variacion} sobre TODOS los productos y sus respectivas presentaciones, seguro que desea continuar?`
    }
    if (this.seleccion == "filtrado"){
      msj = `Va a aplicar un cambio de precios de ${variacion} sobre los productos con rubro '${rubro}' subrubro '${subRubro}' y sus respectivas presentaciones, seguro que desea continuar?`
    }

    this.dialogConfirmar("Alerta", msj);
    

  }

  dialogConfirmar(titulo: string, mensaje: string) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //confirmar

        let porcentaje = (this.aumento / 100);

        let obs;
        if (this.seleccion == "total"){
          obs = this.apiService.postProductosUpdatePrecioTodo(porcentaje);  
        }

        if (this.seleccion == "filtrado"){
          obs = this.apiService.postProductosUpdatePrecioByRubroSubRubro(this.idRubroProducto, this.idSubRubroProducto, porcentaje);
        }
        
        obs?.subscribe(x => {
          this.dialogRef.close(
            {
              cierre: true,
              update: true
            });
        });
        

      } 
    });

  }

}
