import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Location } from '@angular/common';
import { MedidasProductoModel } from 'src/app/models/medidas-producto-model';


@Component({
  selector: 'app-medidas-producto-nuevo',
  templateUrl: './medidas-producto-nuevo.component.html',
  styleUrls: ['./medidas-producto-nuevo.component.scss']
})
export class MedidasProductoNuevoComponent implements OnInit {

  nuevo: MedidasProductoModel = {
    idmedidaproducto: 0,
    descripcion: "",
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {

    
    this.nuevo.idmedidaproducto = Number(this.activatedRoute.snapshot.paramMap.get('idmedidaproducto'));

    if (this.nuevo.idmedidaproducto > 0){
      this.apiService.getMedidas(this.nuevo.idmedidaproducto).subscribe(x => {
        this.nuevo = x
      });
    }

    
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){

    
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idmedidaproducto == 0){
        this.apiService.postMedidas(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero la Medida correctamente");
          this.router.navigateByUrl('/productos/medidas/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        
        this.apiService.putMedidas(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo la Medida correctamente");
          this.router.navigateByUrl('/productos/medidas/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo descripción debe tener mas de 2 caracteres");
      
    }
    

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

}
