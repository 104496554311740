<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: column;">
        <div style="display:flex; flex-direction: row; align-items: baseline; justify-content: space-between;">

            <div style="display: flex; flex-direction: row;">
    
                <mat-form-field appearance="outline">
                    <mat-label>Ingrese un rango de fechas</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Desde">
                        <input matEndDate formControlName="end" placeholder="Hasta">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
    
                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha Desde Incorrecta</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha Hasta Incorrecta</mat-error>
                </mat-form-field>

                <ng-template [ngxPermissionsOnly]="['10']">
                    <mat-form-field appearance="outline" style="margin-right: 8px;" >
                        <mat-label>Sucursal</mat-label>
                        <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="sucursalNombre" name="sucursal" readonly>
                        <button *ngIf="sucursalNombre != ''" matSuffix mat-icon-button (click)="clickClear()">
                            <mat-icon>close</mat-icon>
                        </button>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarSucursal()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </ng-template>
            
                <!--
                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Vendedor</mat-label>
                        <input matInput type="text" [(ngModel)]="vendedor.nombre" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarVendedor()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            
                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Cliente</mat-label>
                        <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                -->
    
            </div>
          
            <button mat-raised-button color="primary" (click)="clickConsultar()">Consultar</button>
    
        </div>

        
        
    </div>

    <div style="display:flex; flex-direction: row; align-items: baseline; margin: 16px 0px 8px 0px;">
        <ng-template [ngxPermissionsOnly]="['10']">
            <button mat-raised-button color="primary" (click)="exportarExcel0()">Exportar a Excel</button>
        </ng-template>
        <button mat-raised-button color="primary" (click)="exportarPDF0()" style="margin-left: 8px;">Exportar a PDF</button>
    </div>

    <table mat-table [dataSource]="dataSourceR" multiTemplateDataRows class="mat-elevation-z1" style="width: 100%; margin-top: 16px;">

        <!-- Name Column -->
        <ng-container matColumnDef="forma_pago">
        <th mat-header-cell *matHeaderCellDef> Forma de Pago </th>
        <td mat-cell *matCellDef="let element"> {{element.formaPago}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
        <th mat-header-cell *matHeaderCellDef> Cantidad </th>
        <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef> Importe (Incluye Recargo)</th>
            <td mat-cell *matCellDef="let element" class="valor-moneda"> {{fcNum(element._importeConRecargo, 2)}}</td>
            <td mat-footer-cell *matFooterCellDef class="valor-moneda"> {{fcNum(total, 2)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsR"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsR;"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumnsR; sticky: false"></tr>


    </table>

    <div style="display:flex; flex-direction: row; align-items: baseline; margin: 16px 0px 8px 0px;">
        <ng-template [ngxPermissionsOnly]="['10']">
            <button mat-raised-button color="primary" (click)="exportarExcel()">Exportar a Excel</button>
        </ng-template>
        <button mat-raised-button color="primary" (click)="exportarPDF()" style="margin-left: 8px;">Exportar a PDF</button>
        <button mat-raised-button color="primary" (click)="exportarAgrupadoPDF()" style="margin-left: 8px;">Exportar a PDF (Reporte Agrupado)</button>
    </div>

  
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z1" style="width: 100%; margin-top: 16px;">

        <!-- Position Column -->
        <ng-container matColumnDef="idpreventa">
        <th mat-header-cell *matHeaderCellDef> id venta</th>
        <td mat-cell *matCellDef="let element"> {{element.idpreventa}} </td>
        <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
            <th mat-header-cell *matHeaderCellDef> Sucursal</th>
            <td mat-cell *matCellDef="let element"> {{element.sucursalNombre}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="forma_pago">
        <th mat-header-cell *matHeaderCellDef> Forma de Pago </th>
        <td mat-cell *matCellDef="let element"> {{element.formaPago}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha </th>
        <td mat-cell *matCellDef="let element"> {{element._fecha}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="importe">
            <th mat-header-cell *matHeaderCellDef> Importe (Incluye Recargo) </th>
            <td mat-cell *matCellDef="let element" class="valor-moneda"> {{fcNum(element._importeConRecargo, 2)}} </td>
            <td mat-footer-cell *matFooterCellDef class="valor-moneda"> {{fcNum(total, 2)}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetalle">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element">
                    <!--(click)="expandedElement = expandedElement === element ? null : element">-->
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: false"></tr>

    </table>

      
  </div>