import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SideBarService {

  isOpen: BehaviorSubject<boolean>;
  isNotasOpen: BehaviorSubject<boolean>;

  constructor() {
    this.isOpen = new BehaviorSubject<boolean>(true);
    this.isNotasOpen = new BehaviorSubject<boolean>(false);
  }

  toggle() {
    this.isOpen.next(!this.isOpen.getValue());
  }
  close() {
    this.isOpen.next(false);
  }
  open(){
    this.isOpen.next(true);
  }

  toggleNotas(){
    this.isNotasOpen.next(!this.isNotasOpen.getValue());
  }
  
  closeNotas() {
    this.isNotasOpen.next(false);
  }

  openNotas(){
    this.isNotasOpen.next(true);
  }

  
  
}
