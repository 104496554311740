import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';

@Component({
  selector: 'app-sucursales-abm',
  templateUrl: './sucursales-abm.component.html',
  styleUrls: ['./sucursales-abm.component.scss']
})
export class SucursalesAbmComponent implements OnInit {


  displayedColumns: string[] = ['idsucursal', 'descripcion', 'domicilio', 'nombre_localidad', 'codigo_postal', 'telefono', 'observaciones', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog
  ) {
    
  }

  ngOnInit(): void {
    this.cargarTabla();
  }

  cargarTabla(){
    this.apiService.getSucursalesCompletoList().subscribe( (o: any[]) => {
      this.dataSource.data = o;
    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/sucursales/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/sucursales/editar/${row.idsucursal}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/sucursales/editar/${element.idsucursal}`,);
  }

  clickEliminar(element: any){
    this.dialogBorrar("Confirmar", `¿Esta seguro que desea eliminar la sucursal ${element.descripcion}?`, element);
  }

  dialogBorrar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        this.borrar(elemento)
      } 
    });

  }

  borrar(element: any){
    console.log(element);
    this.apiService.deleteSucursales(element).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    });

  }


}

const ELEMENT_DATA: any[] = [];

