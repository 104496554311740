<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Vendedor</button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idvendedor">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idvendedor}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="domicilio">
        <th mat-header-cell *matHeaderCellDef> Domicilio </th>
        <td mat-cell *matCellDef="let element"> {{element.domicilio}} </td>
        </ng-container>

        <ng-container matColumnDef="nombre_localidad">
        <th mat-header-cell *matHeaderCellDef> Localidad </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre_localidad}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo_postal">
        <th mat-header-cell *matHeaderCellDef> Codigo Postal </th>
        <td mat-cell *matCellDef="let element"> {{element.codigo_postal}} </td>
        </ng-container>

        <ng-container matColumnDef="telefono">
        <th mat-header-cell *matHeaderCellDef> Telefono </th>
        <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
        </ng-container>

        <ng-container matColumnDef="correo_electronico">
        <th mat-header-cell *matHeaderCellDef> Correo Electronico </th>
        <td mat-cell *matCellDef="let element"> {{element.correo_electronico}} </td>
        </ng-container>

        <ng-container matColumnDef="sucursal_descripcion">
        <th mat-header-cell *matHeaderCellDef> Sucursal </th>
        <td mat-cell *matCellDef="let element"> {{element.sucursalDescripcion}} </td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
        <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
        </ng-container>

        <ng-container matColumnDef="porcentaje_comision">
        <th mat-header-cell *matHeaderCellDef> % Comision </th>
        <td mat-cell *matCellDef="let element"> {{element.porcentaje_comision}} </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" (click)="clickEditar(element)">Editar</button> 
                <button mat-raised-button class="boton-celda" (click)="clickEliminar(element)">Eliminar</button> 
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    

</div>