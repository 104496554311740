<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 16px;">
        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap;">

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Vendedor</mat-label>
                    <input matInput type="text" [(ngModel)]="vendedor.nombre" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarVendedor()">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center; margin: 0px 8px;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="margin-left: 8px;">
                <mat-form-field appearance="outline">
                    <mat-label>Ingrese un rango de fechas</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Desde">
                        <input matEndDate formControlName="end" placeholder="Hasta">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
    
                    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha Desde Incorrecta</mat-error>
                    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha Hasta Incorrecta</mat-error>
                </mat-form-field>
            </div>

            <div style="margin-left: 8px;">
                <button mat-raised-button color="primary" (click)="clickBuscar()">Buscar</button>
            </div>
    
        </div>
        <div>
            <mat-radio-group [(ngModel)]="seleccion" (change)="radioChange($event)" style="display: flex; flex-direction: row;">
                <mat-radio-button style="margin: 6px;" value="confirmados">Pendientes</mat-radio-button>
                <mat-radio-button style="margin: 6px;" value="deuda">Pagos Incompletos</mat-radio-button>
                <mat-radio-button style="margin: 6px;" value="pagados">Pagos Completos</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div style="display: flex; flex-direction: row-reverse; margin-bottom: 12px;">

        <button mat-raised-button [matMenuTriggerFor]="menu">Columnas</button>

        <mat-menu #menu="matMenu">
            <div mat-menu-item *ngFor="let item of columnaList; let i = index;">
                <mat-checkbox #menuItems
                    [ngModel]="item.activado"
                    (change)="clickShowColumna($event, item)">{{ item.titulo }}</mat-checkbox>
              </div>
            
          </mat-menu>
    </div>


    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" style="width: 100%;">

        <ng-container matColumnDef="idpreventa">
            <th mat-header-cell *matHeaderCellDef> id </th>
            <td mat-cell *matCellDef="let element"> {{element.idpreventa}} </td>
            <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <ng-container matColumnDef="cliente_nombre">
            <th mat-header-cell *matHeaderCellDef> Cliente </th>
            <td mat-cell *matCellDef="let element"> {{element.cliente_nombre}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>


        <ng-container matColumnDef="vendedor_nombre">
            <th mat-header-cell *matHeaderCellDef> Vendedor </th>
            <td mat-cell *matCellDef="let element"> {{element.vendedor_nombre}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="sucursal">
            <th mat-header-cell *matHeaderCellDef> Sucursal </th>
            <td mat-cell *matCellDef="let element"> {{element.preventa_sucursal}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="domicilio">
            <th mat-header-cell *matHeaderCellDef> Domicilio Entrega </th>
            <td mat-cell *matCellDef="let element"> {{element.domicilio}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="localidad">
            <th mat-header-cell *matHeaderCellDef> Localidad Entrega </th>
            <td mat-cell *matCellDef="let element"> {{element.localidad_descripcion}} ({{element.localidad_codigo_postal}})</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element._fecha}}</td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
    
        <ng-container matColumnDef="observaciones">
            <th mat-header-cell *matHeaderCellDef> Observaciones </th>
            <td mat-cell *matCellDef="let element"> {{element.observaciones}} </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="estado_descripcion">
            <th mat-header-cell *matHeaderCellDef> Estado </th>
            <td mat-cell *matCellDef="let element"> 
                <div class="estado-{{element.estado}}">
                    {{element.estado_descripcion}} 
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef> Total Productos</th>
            <td mat-cell *matCellDef="let element"> 
                {{fcNum(element._total)}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{fcNum(sumTotalProductos)}}</td>
        </ng-container>

        <ng-container matColumnDef="pagado">
            <th mat-header-cell *matHeaderCellDef> Pagado </th>
            <td mat-cell *matCellDef="let element">
                {{fcNum(element._pagado)}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{fcNum(sumTotalPagados)}}</td>
        </ng-container>

        <ng-container matColumnDef="debe">
            <th mat-header-cell *matHeaderCellDef> Debe </th>
            <td mat-cell *matCellDef="let element">
                {{fcNum(element._debe)}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{fcNum(sumTotalDebe)}}</td>
        </ng-container>

        <ng-container matColumnDef="recargo">
            <th mat-header-cell *matHeaderCellDef> Recargo </th>
            <td mat-cell *matCellDef="let element">
                {{fcNum(element._recargo)}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{fcNum(sumTotalRecargo)}}</td>
        </ng-container>

        <ng-container matColumnDef="a_favor">
            <th mat-header-cell *matHeaderCellDef> A Favor </th>
            <td mat-cell *matCellDef="let element">
                {{fcNum(element._aFavor)}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <div style="display: flex;">
                    <button mat-raised-button class="boton-celda" *ngIf="element.estado == 1" (click)="clickPagar(element, $event)">Pagar</button> 
                    <button mat-raised-button class="boton-celda" *ngIf="element.estado == 2" (click)="clickEditarPago(element, $event)">Editar Pagos</button>
                    <ng-container>
                        <mat-spinner [diameter]="15" *ngIf="element._diferenciaPrecio == null"></mat-spinner>
                        <mat-icon *ngIf="element._diferenciaPrecio == true" style="color: red;" matTooltip="Revisar Cambio de Precio">warning</mat-icon>
                    </ng-container>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetalle">
            
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-bottom: 4px; margin-top: 4px;">
                    <button mat-raised-button color="primary" (click)="clickExportarPdf(element)">Imprimir</button>
                </div>

                <app-venta-detalle [venta]="element" (cambio)="cambioVentaDetalle($event)"></app-venta-detalle>
            
              </div>
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
            class="element-row"
            [class.expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: false"></tr>

        
    </table>
    

</div>
