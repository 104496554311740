<mat-dialog-content>
    <h2 mat-dialog-title>{{titulo}}</h2>
    <div class="msj">
      {{mensaje}}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="flex-direction: row-reverse;">
    <button *ngIf="mostrarAccionD" style="margin-left: 8px;" mat-button (click)="botonAccionD()">{{captionAccionD}}</button>
    <button *ngIf="mostrarAccionC" style="margin-left: 8px;" mat-button (click)="botonAccionC()">{{captionAccionC}}</button>
    <button *ngIf="mostrarAccionB" style="margin-left: 8px;" mat-button (click)="botonAccionB()">{{captionAccionB}}</button>
    <button *ngIf="mostrarAccionA" style="margin-left: 8px;" mat-button (click)="botonAccionA()">{{captionAccionA}}</button>
  </mat-dialog-actions>