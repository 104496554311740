<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Confirmar</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2 *ngIf="modo=='nuevo'">Egreso</h2>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Descripción</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="compra.descripcion" name="descripcion">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Sucursal</mat-label>
                <mat-select [(ngModel)]="compra.idsucursal">
                    <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px; margin-bottom: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2 *ngIf="modo=='edicion' || modo=='nuevo'">Productos a Egresar (Baja Stock)</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregar()">Agregar y Editar Productos</button>
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                <mat-accordion multi *ngIf="agregados.length > 0">
                    <mat-expansion-panel [expanded]="true" *ngFor="let p of agregados" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title style="align-items: center;">
                                {{p.descripcion}}
                            </mat-panel-title>
                            <mat-panel-description style="justify-content: flex-end;">
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div style="padding: 8px;">
                            <mat-card  *ngFor="let pp of p.presentaciones" style="margin: 4px;">
                                <div style="display: flex; flex-direction: column; font-size: 10pt;">
                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
                                        <div>Color: <b>{{pp.colorDescripcion}}</b> | Medida: <b>{{pp.medidaDescripcion}}</b></div>
                                    </div>
                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                        <div style="width: 120px;">Cantidad</div>
                                        <div>{{pp.cantidad}}</div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div *ngIf="agregados.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos Agregados</div>
                </div>
            </div>

        </div>

        <mat-divider></mat-divider>

    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Confirmar</button>
    </div>

</div>