import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';

@Component({
  selector: 'app-productos-precios-presentaciones-masivo-dialog',
  templateUrl: './productos-precios-presentaciones-masivo-dialog.component.html',
  styleUrls: ['./productos-precios-presentaciones-masivo-dialog.component.scss']
})
export class ProductosPreciosPresentacionesMasivoDialogComponent implements OnInit {

  cargando = false;

  producto: any;

  variacionPorcentaje = 0;
  variacionFija = 0;

  seleccion: 'fijo' | 'porcentaje' = 'porcentaje';

  ejemplos: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ProductosPreciosPresentacionesMasivoDialogComponent>,
    private dialogInfo: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {

    this.producto = this.data.producto;


    console.log("data", this.data);

    this.generarEjemplos(this.producto);

  }


  clickCerrar() {
    this.dialogRef.close(
      {
        cierre: true
      });
  }

  
  radioChange(event: any){
    console.log("evento", event);
    console.log("seleccion", this.seleccion);
    
  }
  

  generarEjemplos(producto: any){

    this.ejemplos = [];
    let col = producto.presentaciones;

    let max = col.length - 1;
    let min = 1
    let random = Math.floor(Math.random()*(max-min+1)+min);

    console.log("min", min);
    console.log("max", max);
    console.log("random", random);

    if (col.length > 0){

      let unEjemplo: any = {
        precio: col[0].precio,
        productoDescripcion: producto.productoDescripcion,
        colorDescripcion: col[0].colorDescripcion,
        medidaDescripcion: col[0].medidaDescripcion
      }

      this.ejemplos.push(unEjemplo);

      if (col.length > 1){

        let otroEjemplo: any = {
          precio: col[random].precio,
          productoDescripcion: producto.productoDescripcion,
          colorDescripcion: col[random].colorDescripcion,
          medidaDescripcion: col[random].medidaDescripcion
        }
  
        this.ejemplos.push(otroEjemplo);
        
      }
    }
    

  }

  clickConfirmar(){
    
    let msj = ""

    if (this.seleccion == "fijo"){

      msj = `Va a aplicar un cambio de precios, asignando el precio ${this.variacionFija} a TODAS las presentaciones de este producto, seguro que desea continuar?`
    }
    if (this.seleccion == "porcentaje"){
      let variacion = this.variacionPorcentaje >= 0 ? `+${this.variacionPorcentaje}%` : `${this.variacionPorcentaje}%`;
      msj = `Va a aplicar un cambio de precios de ${variacion} sobre TODAS las presentaciones de este producto, seguro que desea continuar?`
    }

    this.dialogConfirmar("Alerta", msj);
    

  }

  dialogConfirmar(titulo: string, mensaje: string) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //confirmar
        //this.borrar(elemento)

        let obs;
        if (this.seleccion == "fijo"){
          obs = this.apiService.postProductosUpdatePrecioFijo(this.variacionFija, this.producto.idproducto)
        }

        if (this.seleccion == "porcentaje"){
          let porcentaje = (this.variacionPorcentaje /100)
          obs = this.apiService.postProductosUpdatePrecioPorcentaje(porcentaje, this.producto.idproducto)
        }

        obs?.subscribe(x => {
          this.dialogRef.close(
            {
              cierre: true,
              update: true
            });
        })
        
      } 
    });

  }

}
