<div>

    <!--<div style="border-radius: 2px; border: 1px solid rgb(161, 161, 161);" class="p-24" >-->
    <div style="display: flex; flex-direction: column; align-items: center;">

        <div style="border-radius: 4px; border: 1px solid rgb(210, 210, 210); max-width: 40vw; min-width: 270px; box-shadow: 1px -1px 5px 0px #B2B2B2; align-self: center;" class="p-24 m-24">

            <div>
                <h2>Log In</h2>
            </div>

            <form  fxLayout="row" class="w-100-p">
                <div>
                    <mat-form-field class="w-100-p">
                        <mat-label>email</mat-label>
                        <input matInput placeholder="email" value="" [(ngModel)]="email" name="email">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="w-100-p">
                        <mat-label>password</mat-label>
                        <input matInput placeholder="password" value="" type="password" [(ngModel)]="password" name="password">
                    </mat-form-field>
                </div>
            </form>

            <div>
                <button mat-raised-button color="primary" (click)="clickIngresar()">Ingresar</button>
            </div>

        </div>

    </div>

</div>