<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw; display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h2>{{nuevo.idtipo == 0 ? 'Nuevo Tipo de Precio' : ('Editar Tipo de Precio #' + nuevo.idtipo) }}</h2>
    </div>

    <div style="display: flex; flex-direction: column; max-width: 350px; width: 85vw;">
        <mat-form-field appearance="outline"> 
            <mat-label>Descripción</mat-label>
            <input matInput placeholder="Descripcion" value="" [(ngModel)]="nuevo.descripcion" name="descripcion">
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Recargo</mat-label>
            <input matInput placeholder="Recargo" value="" type="number" [(ngModel)]="nuevo.recargo" name="recargo">
        </mat-form-field>

    </div>

    <div>
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>