import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ClientesCompletoModel } from 'src/app/models/clientes-completo-model';
import { ApiService } from 'src/app/services/api.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import * as moment from 'moment';
import { formatNumeroNacional } from 'src/app/utiles';


@Component({
  selector: 'app-clientes-historial',
  templateUrl: './clientes-historial.component.html',
  styleUrls: ['./clientes-historial.component.scss']
})
export class ClientesHistorialComponent implements OnInit {

  displayedColumnsVentas: string[] = ['idventa', 'vendedor', 'domicilio', 'nombre_localidad', 'fecha', 'sucursal', 'observaciones', 'opciones'];
  displayedColumnsPagos: string[] = ['idventapago', 'idventa', 'tipo', 'importe', 'recargo', 'es_senia', 'fecha', 'opciones'];
  
  dataSourceVentas = new MatTableDataSource<any>(ELEMENT_DATA);
  dataSourcePagos = new MatTableDataSource<any>(ELEMENT_DATA);

  dataVentasOrig: any[] = [];
  dataPagosOrig: any[] = [];

  cargando = false;

  listCliente: ClientesCompletoModel[] = [];

  cliente:ClientesCompletoModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    codigo_localidad: 0,
    nombre_localidad: "",
    codigo_postal: "",
    telefono: "",
    correo_electronico: "",
    observaciones: "",
  };

  fcNum = formatNumeroNacional;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private _excelService: ExcelService,
  ) {

    moment.locale('es');
    
    let clientesCompletoObs = this.apiService.getClientesCompletoList();

    this.cargando = true;
    combineLatest([clientesCompletoObs]).subscribe((hh: any[]) =>{
      this.listCliente = hh[0];
      this.cargando = false;
    });

  }

  ngOnInit(): void {
    //this.cargarTabla();
  }

  cargarTabla(){

    if (this.cliente.idcliente != 0){

      this.cargando = true;

      let obsPagos = this.apiService.getClienteHistorialPagos(this.cliente.idcliente);
      let obsVentas = this.apiService.getClienteHistorialVentas(this.cliente.idcliente);

      combineLatest([obsPagos, obsVentas]).subscribe(x => {

        let x0: any[] = x[0].map((xx: any) => {
          let m = moment(xx.fecha);
          xx._fecha = m.format("DD-MM-YYYY HH:mm");
          return xx;        
        });

        this.dataPagosOrig = x0;
        
        let x1: any[] = x[1].map((xx: any) => {
          let m = moment(xx.fecha);
          xx._fecha = m.format("DD-MM-YYYY HH:mm");
          return xx;        
        });

        this.dataVentasOrig = x1;

        this.dataSourcePagos.data = this.dataPagosOrig;
        this.dataSourceVentas.data = this.dataVentasOrig;

        this.cargando = false;

      });

    }else{

      this.dataPagosOrig = [];
      this.dataVentasOrig = [];

      this.dataSourcePagos.data = this.dataPagosOrig;
      this.dataSourceVentas.data = this.dataVentasOrig;

    }

  }

  clickSeleccionarCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listCliente.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: true,
      referenciaNuevo: "cliente"
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          let clienteSel = this.listCliente.find(x => x.idcliente == rr.seleccion.id);

          if (clienteSel == null){

          }else{
            this.cliente = clienteSel as ClientesCompletoModel
          }

        }else{
          this.cliente = {
            idcliente: 0,
            nombre: "",
            domicilio: "",
            codigo_localidad: 0,
            nombre_localidad: "",
            codigo_postal: "",
            telefono: "",
            correo_electronico: "",
            observaciones: "",
          };
        }

        this.cargarTabla();

        //this.aplicarFiltro();
        
      }
    });
  }

  

  exportarExcel(){

    let exporta: any[] = [];

    let data = []; //this.dataSource.data;

    if (data.length == 0){
      return;
    }

    data.forEach(r => {
      let row: any = {
        "Id": r.idcliente,
        "Nombre": r.nombre,
        "Domicilio": r.domiclio,
        "Localidad": r.nombre_localidad,
        "Codigo Postal": r.codigo_postal,
        "Telefono": r.telefono,
        "Correo Electronico": r.correo_electronico,
        "Observaciones": r.observaciones
      };
      exporta.push(row);
    });

    if (exporta.length > 0) {
      this._excelService.exportAsExcelFile(
        exporta,
        'lista-clientes',
        false,
        [],
        ''
      );
    } else {
      
      /*
      Swal.fire({
        title: 'No hay datos para exportar',
        text: 'Los datos que se muestran son los que se exportan a Excel, actualmente no hay datos',
        icon: 'info',
      });
      */
    }
  }


}

const ELEMENT_DATA: any[] = [];

