import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ColoresProductoModel } from 'src/app/models/colores-producto-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-colores-producto-nuevo',
  templateUrl: './colores-producto-nuevo.component.html',
  styleUrls: ['./colores-producto-nuevo.component.scss']
})
export class ColoresProductoNuevoComponent implements OnInit {

  nuevo: ColoresProductoModel = {
    idcolorproducto: 0,
    nombre: "",
    codigo_color: ""
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {

    
    this.nuevo.idcolorproducto = Number(this.activatedRoute.snapshot.paramMap.get('idcolorproducto'));

    if (this.nuevo.idcolorproducto > 0){
      this.apiService.getColores(this.nuevo.idcolorproducto).subscribe(x => {
        this.nuevo = x
      });
    }

    
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){

    
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idcolorproducto == 0){
        this.apiService.postColores(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el Color correctamente");
          this.router.navigateByUrl('/productos/colores/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        
        this.apiService.putColores(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el Color correctamente");
          this.router.navigateByUrl('/productos/colores/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo descripción debe tener mas de 2 caracteres");
      
    }
    

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

}
