<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>

<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw; display: flex; flex-direction: column;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h1>{{nuevo.idproducto == 0 ? 'Nuevo Producto' : ('Editar Producto #' + nuevo.idproducto) }}</h1>
    </div>

    <div style="display: flex; flex-direction: column; flex-wrap: wrap; align-content: flex-start; align-items: flex-start;">
        <div style="flex-grow: 1;">
            <h2>Información del Producto</h2>
        </div>
        
        <mat-form-field class="input-alta" appearance="outline">
            <mat-label>Descripción</mat-label>
            <input matInput placeholder="Nombre del Producto" value="" [(ngModel)]="nuevo.descripcion" name="descripcion">
        </mat-form-field>

        <mat-form-field class="input-alta" appearance="outline">
            <mat-label>Rubro</mat-label>
            <mat-select [ngModel]="nuevo.idrubroproducto" (selectionChange)="selectRubro($event.value)">
                <mat-option *ngFor="let rubro of listRubros" [value]="rubro.idrubroproducto">{{rubro.descripcion}}</mat-option>
            </mat-select>
        </mat-form-field>
        

        <mat-form-field class="input-alta" appearance="outline">
            <mat-label>SubRubro</mat-label>
            <mat-select [(ngModel)]="nuevo.idsubrubroproducto" >
                <mat-option *ngFor="let subRubro of listSubRubros" [value]="subRubro.idsubrubroproducto">{{subRubro.descripcion}}</mat-option>
            </mat-select>
        </mat-form-field>
        
        
    </div>

    <div style="margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

    <div>
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: flex-start;">
            <div style="flex-grow: 1;">
                <h2>Presentaciones</h2>
            </div>
            <div style="display: flex; flex-direction: row; justify-content: space-around; align-items: baseline;">
                <button mat-raised-button color="primary" (click)="clickEliminarPresentaciones()" [disabled]="nuevo.idproducto == 0 && nuevo.presentaciones.length > 0">Eliminar Todas Las Presentaciones</button>
                <button mat-raised-button color="primary" (click)="clickMasivo()" [disabled]="nuevo.idproducto == 0 || editandoPresentacion" style="margin-right: 8px; margin-left: 8px;">Modificar Precios Masivo</button>
                <button mat-raised-button color="primary" (click)="clickAgregarPresentacion()" [disabled]="nuevo.idproducto == 0">Agregar</button>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate" *ngIf="cargandoPresentaciones"></mat-progress-bar>
        <div *ngFor="let item of nuevo.presentaciones; let i = index" style="padding: 6px;">
            <mat-card style="width: 85vw; max-width: 650px; min-width: 270px;">
                <div style="display: flex; flex-direction: column; flex-wrap: wrap; justify-content: flex-start; align-items: baseline;">
                    
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap;">

                        <div style="flex-grow: 1; padding: 4px;">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Medida</mat-label>
                                <mat-select [(ngModel)]="item.idmedidaproducto" [disabled]="!item._editar">
                                    <mat-option *ngFor="let medida of listMedidas" [value]="medida.idmedidaproducto">{{medida.descripcion}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    
                        <div style="flex-grow: 1; padding: 4px;">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Color</mat-label>
                                <mat-select [(ngModel)]="item.idcolorproducto" [disabled]="!item._editar">
                                    <mat-option *ngFor="let color of listColores" [value]="color.idcolorproducto">{{color.nombre}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    
                        <div style="flex-grow: 1; padding: 4px;">
                            <mat-form-field style="width: 100%;">
                                <mat-label>Precio</mat-label>
                                <input matInput placeholder="Precio" value="0" type="number" [(ngModel)]="item.precio" name="precio" [disabled]="!item._editar"> 
                            </mat-form-field>
                        </div>
                    
                    </div>

                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-around; width: 100%; align-items: baseline;">
                        <!--
                        <div>
                            Stock: {{item.cantidad}}
                        </div>
                        -->
                        <div *ngIf="!item._editar">
                            <button mat-button color="primary" (click)="clickEditarPresentacion(item, i)">Editar</button>
                        </div>
                        <div *ngIf="item._editar">
                            <button mat-button color="primary" (click)="clickConfirmarPresentacion(item, i)">Confirmar</button>
                        </div>
                        <div *ngIf="item._editar">
                            <button mat-button color="warn" (click)="clickQuitarPresentacion(item, i)">Quitar</button>
                        </div>
                    </div>
                    
                </div>
            </mat-card>
        </div>
        <div *ngIf="nuevo.presentaciones.length == 0">
            <div style="width: 70vw; max-width: 650px; min-width: 270px; height: 40px;">
                Sin Presentaciones
            </div>
        </div>
    </div>


</div>