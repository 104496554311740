import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SucursalesModel } from 'src/app/models/sucursales-model';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';


@Component({
  selector: 'app-sucursales-nuevo',
  templateUrl: './sucursales-nuevo.component.html',
  styleUrls: ['./sucursales-nuevo.component.scss']
})
export class SucursalesNuevoComponent implements OnInit {

  nuevo: SucursalesModel = {
    idsucursal: 0,
    descripcion: "",
    domicilio: "",
    telefono: "",
    codigo_localidad: 0,
    observaciones: ""
  }

  listLocalidades: any[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location
  ) {
    this.nuevo.idsucursal = Number(this.activatedRoute.snapshot.paramMap.get('idsucursal'));

    if (this.nuevo.idsucursal > 0){
      this.apiService.getSucursales(this.nuevo.idsucursal).subscribe(x => {
        this.nuevo = x;
        
      })
    }

    this.apiService.getLocalidadesList().subscribe(x => {
      this.listLocalidades = x as any[];
    });

  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idsucursal == 0){
        this.apiService.postSucursales(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero la sucursal correctamente");
          this.router.navigateByUrl('/sucursales/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        this.apiService.putSucursales(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo la sucursal correctamente");
          this.router.navigateByUrl('/sucursales/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo nombre debe tener mas de 2 caracteres");
    }

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

}
