import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { combineLatest } from 'rxjs';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { ExcelService } from 'src/app/services/excel.service';


@Component({
  selector: 'app-clientes-abm',
  templateUrl: './clientes-abm.component.html',
  styleUrls: ['./clientes-abm.component.scss']
})
export class ClientesAbmComponent implements OnInit {

  displayedColumns: string[] = ['idcliente', 'nombre', 'domicilio', 'nombre_localidad', 'codigo_postal', 'telefono', 'correo_electronico', 'observaciones', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  dataOrig: any[] = [];

  listLocalidades: any[] = [];

  cargando = false;

  localidad = {
    idlocalidad: 0,
    nombre: ""
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private _excelService: ExcelService,
  ) {

  }

  ngOnInit(): void {

    let localidadesObs = this.apiService.getLocalidadesList();

    this.cargando = true;
    combineLatest([localidadesObs]).subscribe((hh: any[]) =>{
      this.listLocalidades = hh[0];
      this.cargando = false;
      this.cargarTabla();
    });

  }

  cargarTabla(){

    this.cargando = true;

    this.apiService.getClientesCompletoList().subscribe( (o: any[]) => {

      console.log("o", o);

      this.dataOrig = o;
      this.dataSource.data = this.dataOrig;

      this.cargando = false;

    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/clientes/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/clientes/editar/${row.idcliente}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/clientes/editar/${element.idcliente}`,);
  }

  clickEliminar(element: any){
    this.dialogBorrar("Confirmar", `¿Esta seguro que desea eliminar el cliente ${element.nombre}?`, element);

  }

  dialogBorrar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        this.borrar(elemento)
      } 
    });

  }

  borrar(element: any){
    console.log(element);
    this.apiService.deleteClientes(element).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    });

  }

  clickSeleccionarLocalidad(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listLocalidades.forEach(x => {
      let item = {
        id: x.idlocalidad,
        nombre: `${x.nombre} (${x.codigo_postal})`
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Localidad",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Localidad"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.localidad.idlocalidad,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.localidad = {
            nombre: rr.seleccion.nombre,
            idlocalidad: rr.seleccion.id 
          }
        }else{
          this.localidad = {nombre: "", idlocalidad: 0};
        }

        this.filtroLocal();
        
      }
    });
  }

  filtroLocal(){

    let filtrado =this.dataOrig;

    if(this.localidad.idlocalidad != 0){
      filtrado = filtrado.filter(v => v.codigo_localidad == this.localidad.idlocalidad)
    }

    this.dataSource.data = filtrado;
  }

  clickClearLocalidad(){
    this.localidad = {idlocalidad: 0, nombre: ""};
    this.filtroLocal();
  }

  exportarExcel(){

    let exporta: any[] = [];

    let data = this.dataSource.data;

    if (data.length == 0){
      return;
    }

    data.forEach(r => {
      let row: any = {
        "Id": r.idcliente,
        "Nombre": r.nombre,
        "Domicilio": r.domiclio,
        "Localidad": r.nombre_localidad,
        "Codigo Postal": r.codigo_postal,
        "Telefono": r.telefono,
        "Correo Electronico": r.correo_electronico,
        "Observaciones": r.observaciones
      };
      exporta.push(row);
    });

    if (exporta.length > 0) {
      this._excelService.exportAsExcelFile(
        exporta,
        'lista-clientes',
        false,
        [],
        ''
      );
    } else {
      
      /*
      Swal.fire({
        title: 'No hay datos para exportar',
        text: 'Los datos que se muestran son los que se exportan a Excel, actualmente no hay datos',
        icon: 'info',
      });
      */
    }
  }

  filtroRapido(filterValue: any) {

    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
    
  }


}

const ELEMENT_DATA: any[] = [];

