import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest, zip } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { formatNumeroNacional } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { ComprasProductosPresentacionesDialogComponent } from '../compras-productos-presentaciones-dialog/compras-productos-presentaciones-dialog.component';

@Component({
  selector: 'app-compras-productos-dialog',
  templateUrl: './compras-productos-dialog.component.html',
  styleUrls: ['./compras-productos-dialog.component.scss']
})
export class ComprasProductosDialogComponent implements OnInit {

  displayedColumns: string[] = ['id', 'producto', 'opciones'];

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  cargando = false;

  coleccion: any[] = [];

  listAgregados: any[] = [];
  agregados: any[] = [];

  listRubro: any[] = [];
  listSubRubro: any[] = [];

  rubroSelected = {
    id: 0,
    nombre: ""
  }

  subRubroSelected = {
    id: 0,
    nombre: ""
  }

  sucursal = {
    idsucursal: 0,
    nombre: ""
  }

  muestraImporte = false;
  muestraPrecio = false;
  muestraSucursal = false;
  muestraStock = false;
  muestraDescuento = false;
  estadoProducto = null;

  fcNum = formatNumeroNacional;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ComprasProductosDialogComponent>,
    private dialog: MatDialog,
    private dialogInfo: MatDialog,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.cargando = true;

    if (this.data.agregados.length > 0){
      this.precargar(this.data.agregados);
    }
    if (this.data.muestraImporte){
      this.muestraImporte = this.data.muestraImporte;
    }
    if (this.data.muestraPrecio){
      this.muestraPrecio = this.data.muestraPrecio;
    }
    if (this.data.muestraSucursal){
      this.muestraSucursal = this.data.muestraSucursal;
    }
    if (this.data.muestraStock){
      this.muestraStock = this.data.muestraStock;
    }
    if (this.data.muestraDescuento){
      this.muestraDescuento = this.data.muestraDescuento;
    }
    if (this.data.sucursal){
      this.sucursal = this.data.sucursal;
    }
    if (this.data.estadoProducto){
      this.estadoProducto = this.data.estadoProducto;
    }

    let obsRubros = this.apiService.getRubrosList();
    let obsSubRubros = this.apiService.getSubRubrosList();

    combineLatest([obsRubros, obsSubRubros]).subscribe(x => {
      this.listRubro = x[0];
      this.listSubRubro = x[1]
    });


    this.apiService.getProductosList().subscribe(x => {

      console.log("productos", x);

      this.coleccion = x;

      if (this.estadoProducto != null){
        this.coleccion = x.filter(x => x.activo == this.estadoProducto);
      }

      
      this.coleccion.map(z => {
        z._busqueda = z.descripcion.toLocaleLowerCase();
        z._contiene = false;
        return z;
      });
      this.dataSource = new MatTableDataSource(this.coleccion);

      if (this.data.agregados.length > 0){
        this.precargar(this.data.agregados);
      }

      this.cargando = false;

    });
    
  }

  filtroRapido(filterValue: any){
    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
  }

  clickCerrar(){
    this.dialogRef.close(
      {
        cierre: true,
        agregados: this.agregados
      });
  }

  clickAgregar(element: any){

    this.dialogAgregar(element.idproducto, element.descripcion, element.activo);
    
  }

  dialogAgregar(idproducto: any, descripcion: any, activo: boolean) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '50vw';
    dialogConfig.minWidth = '360px';
    dialogConfig.data = {
      idProducto: idproducto,
      activo: activo,
      descripcion: descripcion,
      listAgregados: this.listAgregados,
      muestraImporte: this.muestraImporte,
      muestraPrecio: this.muestraPrecio,
      muestraSucursal: this.muestraSucursal,
      muestraStock: this.muestraStock,
      sucursal: this.sucursal
    };

    console.log("data dialog", dialogConfig.data);

    const dialogRef = this.dialog.open(ComprasProductosPresentacionesDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((r: any) => {
      if (r != null && r.cierre){
        this.listAgregados = r.listAgregados;

        console.log("listAgregados", this.listAgregados);
        this.refrescarAgregados();


      }
      
    });

  }

  refrescarAgregados(){
    this.agregados = [];
    this.listAgregados.forEach(x => {
      let a = this.agregados.find(z => z.idproducto == x.idproducto);
      if (a != null){
        a.presentaciones.push(x);
      }else{
        let nuevo = {
          idproducto: x.idproducto,
          descripcion: x.productoDescripcion,
          activo: x.productoActivo,
          presentaciones: [x]
        }
        this.agregados.push(nuevo);
      }
    });

    this.refrescarContiene();

  }

  refrescarContiene(){
    let contiene = this.coleccion.filter(z => z._contiene = true);
    contiene.forEach(y => y._contiene = false);

    this.agregados.forEach(x => {
      let n = this.coleccion.find(z => z.idproducto == x.idproducto);
      if(n != null){
        n._contiene = true;
      }
    });
  }

  clickEditar(element: any, e: any){
    e.stopPropagation();
    this.dialogAgregar(element.idproducto, element.descripcion, element.activo);
  }

  changeImporte(element: any, value: any){
    
  }

  changeCantidad(element: any, value: any){
    let cantidad = Number(value);
    if (Number.isInteger(cantidad)){
      if (cantidad == 0){
        this.dialogBorrar("Información", "Al poner 0 de cantidad estaria indicando que no agregue la presentación. Desea eliminarla?", element)
      }
    }
  }

  changePorcentajeDescuento(element: any, value: any){
    let porcentaje = Number(value);
    if (porcentaje > 0){
      element.importe_descuento = Number((element.precio * (porcentaje / 100)).toFixed(2));
    }else{
      element.importe_descuento = 0.0;
      element.porcentaje_descuento = 0.0;
    }
  }

  changeImporteDescuento(element: any, value: any){
    let importe = Number(value);
    if (importe > 0){
      element.porcentaje_descuento = Number(((element.importe_descuento / element.precio) * 100).toFixed(2));
    }else{
      element.importe_descuento = 0.0;
      element.porcentaje_descuento = 0.0;
    }
  }

  dialogBorrar(titulo: string, mensaje: string, elemento: any) {

    console.log("elemento", elemento);

    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        this.borrar(elemento.idproducto, elemento.idproductopresentacion);
      }else{
        elemento.cantidad = 1
      }
    });

  }

  borrar(idproducto: number, idproductopresentacion: number){
    let index = this.listAgregados.findIndex(x => x.idproductopresentacion == idproductopresentacion);
    if (index != -1){
      this.listAgregados.splice(index, 1);
    }
    let i = this.agregados.findIndex(x => x.idproducto == idproducto);
    if (i != -1){
      let a = this.agregados[i];
      let ii = a.presentaciones.findIndex((x: any) => x.idproductopresentacion == idproductopresentacion);
      if (ii != -1){
        a.presentaciones.splice(ii, 1);
        if (a.presentaciones.length == 0){
          this.agregados.splice(i, 1);
          let ee = this.coleccion.find(x => x.idproducto == idproducto);
          if (ee != null){
            ee._contiene = false;
          }
        }
      }
    }
  }

  clickBorrarPresentacion(pp: any, e: any){
    this.dialogBorrar("Información", "Confirma que desea quita la presentación?", pp);
  }

  clickIncrementar(elemento: any, e: any){
    let cantidad = Number(elemento.cantidad);
    cantidad++;
    elemento.cantidad = cantidad;

    this.changeCantidad(elemento, cantidad);
  }

  clickDecrementar(elemento: any, e: any){
    let cantidad = Number(elemento.cantidad);
    cantidad--;
    elemento.cantidad = cantidad < 0 ? 0 : cantidad;
    this.changeCantidad(elemento, cantidad);
  }

  precargar(lista: any[]){
    let temp: any[] = [];
    lista.forEach((x: any) => {
      x.presentaciones.forEach((z: any) => {
        temp.push(z);
      });
    });
    this.agregados = lista;
    this.listAgregados = temp;
    this.refrescarContiene();
  }

  clickSeleccionarRubro(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    coleccion = this.listRubro.map(x => {
      let r = {
        id: x.idrubroproducto,
        nombre: x.descripcion
      }
      return r;
    });

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Rubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Rubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.rubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.rubroSelected.id != rr.seleccion.id){
            this.subRubroSelected.id = 0;
            this.subRubroSelected.nombre = "";
          }
          this.rubroSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.rubroSelected = {nombre: "", id: 0};
          this.subRubroSelected = { nombre: "", id: 0};
        }
        this.cargarFiltradoRubroSubRubro();
      }
    });
  }

  clickSeleccionarSubRubro(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    if (this.rubroSelected.id == 0){
      return;
    }

    let list = this.listSubRubro.filter(x => x.idrubroproducto == this.rubroSelected.id);

    coleccion = list.map(x => {
      let r = {
        id: x.idsubrubroproducto,
        nombre: x.descripcion
      }
      return r;
    });

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de SubRubro",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo SubRubro"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.subRubroSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.subRubroSelected = {
            nombre: rr.seleccion.nombre,
            id: rr.seleccion.id 
          }
        }else{
          this.subRubroSelected = {nombre: "", id: 0};
        }
        this.cargarFiltradoRubroSubRubro();
      }
    });

  }

  cargarFiltradoRubroSubRubro(){

    let dataFiltrada: any[] = []

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id != 0){
      dataFiltrada = this.coleccion.filter(x => x.idrubroproducto == this.rubroSelected.id && x.idsubrubroproducto == this.subRubroSelected.id);
    }

    if (this.rubroSelected.id != 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.coleccion.filter(x => x.idrubroproducto == this.rubroSelected.id);
    }

    if (this.rubroSelected.id == 0 && this.subRubroSelected.id == 0){
      dataFiltrada = this.coleccion;
    }

    this.dataSource.data = dataFiltrada;
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }
  
  clickClearRubro(){
    this.rubroSelected = {id: 0, nombre: ""};
    this.subRubroSelected = {id: 0, nombre: ""};

    this.dataSource.data = this.coleccion;
    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }

  clickClearSubRubro(){
    this.subRubroSelected = {id: 0, nombre: ""};

    if (this.rubroSelected.id == 0){
      this.dataSource.data = this.coleccion;
    }else{
      let dataFiltrada = this.coleccion.filter(x => x.idrubroproducto == this.rubroSelected.id);
      this.dataSource.data = dataFiltrada;
    }

    //this.dataSource.sort = this.sort;
    this.dataSource.paginator = null;

    this.dataSource.filterPredicate = (dd, filter) => (
      dd._busqueda.includes(filter)
    );

  }


}


const ELEMENT_DATA: any[] = [];

