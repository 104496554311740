<mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">

            <div style="display: flex; flex-direction: column; max-width: 350px;">

                <mat-form-field>
                    <mat-label>Rubro</mat-label>
                    <mat-select (selectionChange)="selectRubro($event.value)" formControlName="rubroCtrl">
                        <mat-option *ngFor="let rubro of listRubros" [value]="rubro.idrubroproducto">{{rubro.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>SubRubro</mat-label>
                    <mat-select (selectionChange)="selectSubRubro($event.value)" formControlName="subrubroCtrl">
                        <mat-option *ngFor="let subRubro of listSubRubros" [value]="subRubro.idsubrubroproducto">{{subRubro.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div>
                <button mat-button matStepperNext >Siguiente</button>
            </div>
        </form>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup" style="display: flex; flex-direction: column;">
            <mat-form-field style="width: 60vw; max-width: 350px;">
                <mat-label>Producto</mat-label>
                <input type="text"
                       placeholder="Producto"
                       matInput
                       formControlName="productoCtrl"
                       [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayProperty" >
                  <mat-option *ngFor="let producto of filteredProductos | async" [value]="producto" >
                    {{producto.descripcion}}
                  </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="width: 60vw; max-width: 350px;">
                <mat-label>Sucursal</mat-label>
                <mat-select (selectionChange)="selectSucursal($event.value)" formControlName="sucursalCtrl">
                    <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div>
                <button mat-button matStepperNext >Siguiente</button>
            </div>
        </form>
    </mat-step>
    <mat-step completed="false">
        <div>
            Producto Seleccionado: <b>{{nuevo.nombreProducto}}</b>
        </div>
        <div>
            Stock para Sucursal: <b>{{nuevo.nombreSucursal}}</b>
        </div>


        <div style="margin: 4px 0px 4px 0px;">
            Indique las presentaciones para agregar a la preventa, si hay presentaciones que no va agregar simplemente deje la cantidad en 0
        </div>

        <mat-card style="max-width: 450px; margin-top: 6px;" *ngFor="let item of listPresentaciones" (click)="clickAgregarPre(item)">
            <div style="display: flex; flex-direction: column; flex-grow: 1; font-size: small;">
                <div style="display: flex; flex-direction: column; flex-grow: 1; border-radius: 4px; border: 1px solid #e2e2e2; padding: 4px; border-style: inset;">
                    <div>Color: {{item.color_descripcion}}</div>
                    <div>Medida: {{item.medida_descripcion}}</div>
                    <div>Stock: {{item._stock_sucursal}}</div>
                    <div>Precio Unitario: $ {{item.precio}}</div>
                </div>
                <div>
                    <div>Cantidad: {{item._agregados_cantidad}}</div>
                </div>
            </div>
        </mat-card>

        <div>
            <button mat-button (click)="clickNext(2)" >Siguiente</button>
        </div>
        
    </mat-step>
    <mat-step>
        <p>Confirma que desea realizar la preventa para:</p>
        <mat-card style="max-width: 450px;">
            <div style="display: flex; flex-direction: column; font-size: small; flex-grow: 1;">
                <div>Producto: {{nuevo.nombreProducto}}</div>
                <div></div>
                <div style="border:1px solid white; border-style: inset;"></div>
                <div>Sucursal: {{nuevo.nombreSucursal}}</div>
                <div></div>
                <div style="border:1px solid white; border-style: inset;"></div>
                <div *ngFor="let item of nuevo.items">
                    <div>Color: {{item.color_descripcion}}</div>
                    <div>Medida: {{item.medida_descripcion}}</div>
                    <div>Cantidad: {{item._agregados_cantidad}}</div>
                    <div>Precio Unitario: $ {{item.precio}}</div>
                    <div>Precio Total: $ {{item._agregados_cantidad * item.precio}}</div>
                    <div style="border:1px solid white; border-style: inset;"></div>
                </div>
                <!--<div>Precio Total Final: $ {{nuevo.totalPrecioFinal}}</div>-->
            </div>
        </mat-card>
        <div>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
        <!--<button mat-button (click)="stepper.reset()">Reset</button>-->
        </div>
    </mat-step>
</mat-horizontal-stepper>