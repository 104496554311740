<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Confirmar Entrega
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">

        <div class="mat-elevation-z2" style="margin: 0px 0px 4px 0px; ">
            <table mat-table [dataSource]="dataSource" style="width: 100%;">
      
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                        (change)="onChangeSelectGlobal($event)"
                        [checked]="checkedGlobal">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox
                        (change)="onChangeSelectItem($event, element)"
                        [checked]="element.selected">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <!-- Position Column -->
                <ng-container matColumnDef="linea">
                    <th mat-header-cell *matHeaderCellDef>Linea</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dato-tabla">
                        {{element.idlinea}}
                        </div>
                    </td>
                </ng-container>
        
                <!-- Name Column -->
                <ng-container matColumnDef="producto">
                    <th mat-header-cell *matHeaderCellDef>Producto</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element.producto_descripcion}} | {{element.color_descripcion}} | {{element.medida_descripcion}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element.cantidad}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef>Estado</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element._estado}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="acciones">
                <th mat-header-cell *matHeaderCellDef>Cantidad a Entregar</th>
                <td mat-cell *matCellDef="let element"> 
                    <div class="dato-tabla" style="display: flex;">
                        <div style="display: flex; flex-direction: row; margin-left: 4px; margin-right: 12px;">
                            <input type="number" class="input-cantidad"  min="0" [ngClass]="{'input-cantidad-ok': element._cantidad > 0, 'input-cantidad-cero': element._cantidad <= 0}" matInput autocomplete="nope" (change)='changeCantidad(element, $event.target.value)' [value]="element._cantidad">
                            <button (click)="clickIncrementar(element, $event)">+</button>
                            <button (click)="clickDecrementar(element, $event)">-</button>
                        </div>
                    </div>
                </td>
                </ng-container>
      
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    class="element-row"
                    [class.selected-row]="selectedElement != null ? (selectedElement.id == row.id) : false"
                    (click)="selectRow(row)"
                    >
                </tr>
    
                <!--
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                        class="element-row"
                        [class.expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>-->
              
            </table>
      
            <div class="info-vacio" *ngIf="dataSource.data.length === 0">
              <div class="sin-informacion">
                No hay información
              </div>
            </div>
            
          </div>
        
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>