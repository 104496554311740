import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductosModel } from 'src/app/models/productos-model';
import { ApiService } from 'src/app/services/api.service';

import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Location } from '@angular/common';
import { ColoresProductoModel } from 'src/app/models/colores-producto-model';
import { RubrosProductoModel } from 'src/app/models/rubros-producto-model';
import { SubRubrosProductoModel } from 'src/app/models/subrubros-producto-model';
import { MedidasProductoModel } from 'src/app/models/medidas-producto-model';
import { ProductosPresentacionesModel } from 'src/app/models/productos-presentaciones-model';
import { TouchSequence } from 'selenium-webdriver';
import { ProductosPreciosPresentacionesMasivoDialogComponent } from '../productos-precios-presentaciones-masivo-dialog/productos-precios-presentaciones-masivo-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Utiles, renameKey } from 'src/app/utiles';
import { combineLatest } from 'rxjs';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';



@Component({
  selector: 'app-productos-nuevo',
  templateUrl: './productos-nuevo.component.html',
  styleUrls: ['./productos-nuevo.component.scss']
})
export class ProductosNuevoComponent implements OnInit {

  nuevo: any = {
    idproducto: 0,
    descripcion: "",
    idrubroproducto: 0,
    idsubrubroproducto: 0,
    presentaciones: []
  }

  listColores: ColoresProductoModel[] = [];
  listMedidas: MedidasProductoModel[] = [];

  listRubros: RubrosProductoModel[] = [];
  listSubRubros: SubRubrosProductoModel[] = [];
  listSubRubrosAll: SubRubrosProductoModel[] = [];

  editandoPresentacion = false;

  cargandoPresentaciones = false;
  cargando = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private _location: Location,
    private dialog: MatDialog,
    private dialogInfo: MatDialog
  ) {

    this.nuevo.idproducto = Number(this.activatedRoute.snapshot.paramMap.get('idproducto'));

    let obsRubros = this.apiService.getRubrosList();
    let obsSubRubros = this.apiService.getSubRubrosList();
    let obsColores = this.apiService.getColoresList();
    let obsMedidas = this.apiService.getMedidasList()

    this.cargando = true;

    combineLatest([obsRubros, obsSubRubros, obsColores, obsMedidas]).subscribe(x => {
      this.listRubros = x[0];
      this.listSubRubrosAll = x[1];
      this.listColores = x[2];
      this.listMedidas = x[3];

      this.cargando = false;

      if (this.nuevo.idproducto > 0){
        this.cargarDatos();
      }

    });

  }

  ngOnInit(): void {

  }

  cargarDatos(){

    this.nuevo.descripcion = "";
    this.nuevo.idrubroproducto = 0;
    this.nuevo.idsubrubroproducto = 0;

    this.cargando = true;

    this.apiService.getProductos(this.nuevo.idproducto).subscribe(x => {
      this.nuevo.descripcion = x.descripcion;
      this.nuevo.idrubroproducto = x.idrubroproducto;
      this.listSubRubros = this.listSubRubrosAll.filter(x => x.idrubroproducto == this.nuevo.idrubroproducto);
      this.nuevo.idsubrubroproducto = x.idsubrubroproducto;
      this.cargando = false;
    });

    this.cargarPresentaciones()
    
  }

  cargarPresentaciones(){
    this.cargandoPresentaciones = true;
    this.nuevo.presentaciones = [];
    this.apiService.getProductoPresentacionesListByIdProducto(this.nuevo.idproducto).subscribe(x => {
      this.nuevo.presentaciones = x;
      this.cargandoPresentaciones = false;
    });
  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);

    if (this.nuevo.descripcion.length > 2){

      let nuevoProducto = {
        idproducto: this.nuevo.idproducto,
        descripcion: this.nuevo.descripcion,
        idrubroproducto: this.nuevo.idrubroproducto,
        idsubrubroproducto: this.nuevo.idsubrubroproducto,
        activo: true,
      }

      this.cargando = true;

      if (this.nuevo.idproducto == 0){

        console.log("producto nuevo", this.nuevo);

        this.apiService.postProductos(nuevoProducto).subscribe(x => {
          let nuevoIdProducto = x.idproducto;
          this.snackBarService.successMessage("Se Genero el Producto correctamente");
          this.router.navigateByUrl(`/productos/editar/${nuevoIdProducto}`, {replaceUrl: true});
          this.cargando = false;
        }, e => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
          this.cargando = false;
        });
      }else{

        console.log("producto actualiza", this.nuevo);
        
        this.apiService.putProductos(nuevoProducto).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el Producto");
          this.cargando = false;
        }, e => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
          this.cargando = false;
        });
      }
    }else{
      this.snackBarService.commentMessage("Campo descripción debe tener mas de 2 caracteres");
    }

  }

  clickBack(){
    this._location.back();
  }

  selectRubro(value: any){

    let idrubrosel = value;

    if (idrubrosel != this.nuevo.idrubroproducto){

      this.nuevo.idrubroproducto = idrubrosel;

      this.listSubRubros = [];
      this.listSubRubros = this.listSubRubrosAll.filter(x => x.idrubroproducto == idrubrosel);
      this.nuevo.idsubrubroproducto = 0;

    }

  }

  hasDuplicates(array: any[]): boolean {
    return (new Set(array)).size !== array.length;
  }


  

  //

  clickAgregarPresentacion(){

    let ultimoPrecio = this.nuevo.presentaciones.at(-1)?.precio || 0;

    let item = {
      idproductopresentacion: 0,
      idproducto: 0,
      idcolorproducto: 0,
      idmedidaproducto: 0,
      cantidad: 0,
      precio: ultimoPrecio,
      _editar: true
    }
    this.nuevo.presentaciones.push(item);

    this.editandoPresentacion = true;

    console.log("nuevo", this.nuevo);

  }

  clickConfirmarPresentacion(item: any, i: number){
    
    let nuevoItem: ProductosPresentacionesModel = {
      idproductopresentacion: item.idproductopresentacion,
      idproducto: this.nuevo.idproducto,
      idmedidaproducto: item.idmedidaproducto,
      idcolorproducto: item.idcolorproducto,
      cantidad: item.cantidad,
      precio: item.precio
    }

    if (nuevoItem.idcolorproducto == 0 || nuevoItem.idcolorproducto == null){
      this.snackBarService.errorMessage("Debe especificar un color.");
      return;
    }

    if (nuevoItem.idmedidaproducto == 0 || nuevoItem.idmedidaproducto == null){
      this.snackBarService.errorMessage("Debe especificar una medida.");
      return;
    }

    if (nuevoItem.precio <= 0 || nuevoItem.precio == null){
      this.snackBarService.errorMessage("Debe especificar un precio.");
      return;
    }

    if (nuevoItem.idproductopresentacion == 0){

      console.log("nuevo", nuevoItem);

      this.cargandoPresentaciones = true;
      
      this.apiService.postProductosPresentaciones(nuevoItem).subscribe(x => {
        item._editar = false;
        this.editandoPresentacion = false;
        this.snackBarService.successMessage("Se Genero la presentacion correctamente");
        this.cargandoPresentaciones = false;
      }, e => {
        this.snackBarService.errorMessage("Error al guardar la nueva presentacion en el Producto. " + e.message);
        this.cargandoPresentaciones = false;
      });
      
    }else{

      let parcialNuevoItem = {
        idproductopresentacion: nuevoItem.idproductopresentacion,
        idproducto: nuevoItem.idproducto,
        idmedidaproducto: nuevoItem.idmedidaproducto,
        idcolorproducto: nuevoItem.idcolorproducto,
        precio: nuevoItem.precio
      }

      console.log("edita", parcialNuevoItem);

      this.cargandoPresentaciones = true;
      
      this.apiService.patchProductosPresentaciones(parcialNuevoItem).subscribe(x => {
        item._editar = false;
        this.editandoPresentacion = false;
        this.cargandoPresentaciones = false;
        this.snackBarService.successMessage("Se Actualizo la presentacion del Producto correctamente");
      }, e => {
        this.snackBarService.errorMessage("Error al guardar el precio. " + e.message);
        this.cargandoPresentaciones = false;
      });
      
    }

  }

  clickQuitarPresentacion(item: any, i: number){
    console.log("item", item);
    console.log("index", i);
    
    if (item.idproductopresentacion != 0){
      this.apiService.deleteProductosPresentaciones(item).subscribe(x => {
        this.snackBarService.successMessage("Se elimino la Presentación correctamente");
        this.nuevo.presentaciones.splice(i, 1);
      }, e => {
        this.snackBarService.errorMessage("Error al eliminar la Presentación." + e.message);
      })

    }else{
      this.nuevo.presentaciones.splice(i, 1);
      this.snackBarService.successMessage("Se elimino la Presentación correctamente");
    }
  }

  clickEditarPresentacion(item: any, i: number){
    this.editandoPresentacion = true
    item._editar = true;
  }

  clickMasivo(){
    
    console.log("elemento", this.nuevo);

    let p: any = JSON.parse(JSON.stringify(this.nuevo));

    p = renameKey(p, "productoDescripcion", "descripcion");

    p.presentaciones = p.presentaciones.map((j: any) => {
      return {
        cantidad: j.cantidad,
        colorDescripcion: j.color_descripcion,
        idcolorproducto: j.idcolordescripcion,
        idmedidaproducto: j.idmedidaproducto,
        idproductopresentacion: j.idproductopresentacion,
        medidaDescripcion: j.medida_descripcion,
        precio: j.precio
      }
    });

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    dialogConfig.data = {
      producto: p,
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(ProductosPreciosPresentacionesMasivoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((rr: any) => {
      console.log("rr", rr);
      if (rr != null){
        if (rr.cierre && rr.update){
          this.cargarPresentaciones();
        }
      }
    });


  }

  clickEliminarPresentaciones(){

    this.dialogInformaBorrarPresentaciones("Información", `Se borraran todas las presentaciones del producto "${this.nuevo.descripcion}". Esta acción no se puede deshacer. ¿Confirmar la eliminación?`, this.nuevo.idproducto);

  }

  dialogInformaBorrarPresentaciones(titulo: string, mensaje: string, idproducto: number) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      if (r.opcion == 'accion_b'){ //borrar
        this.apiService.deleteAllProductoPresentacionesFromProducto(idproducto).subscribe(x => {
          console.log("x", x);
        }, e => {
          
        });
      } 
    });

  }

}
