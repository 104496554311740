<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Seleccione Sucursal y Cantidad a Mover
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">

      <div>
       El pedido actual es de {{cantidadActual}} sobre la sucursal {{sucursalDescripcionActual}}
      </div>

      <div>
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Cantidad a Mover</mat-label>
          <input matInput  placeholder="Cantidad a Mover" type="number" min="0" [(ngModel)]="cantidadMover" autocomplete="nope">
        </mat-form-field>
        <button mat-button (click)="cantidadMover = cantidadActual">Todos</button>
      </div>
  
      <div class="mat-elevation-z1" style="margin: 0px 0px 4px 0px; ">
        <table mat-table [dataSource]="dataSource" style="width: 100%;">
  
          <!-- Position Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef><b> Id </b></th>
            <td mat-cell *matCellDef="let element">
              <div class="dato-tabla">
                {{element.id}}
              </div>
            </td>
          </ng-container>
  
          <!-- Name Column -->
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef><b> Sucursal </b></th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.nombre}}
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="cantidad">
            <th mat-header-cell *matHeaderCellDef><b> Cantidad </b></th>
            <td mat-cell *matCellDef="let element"> 
              <div class="dato-tabla" style="display: flex;">
                <div>
                  {{element.cantidad}}
                </div>
              </div>
            </td>
          </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                class="element-row"
                [class.selected-row]="selectedElement != null ? (selectedElement.id == row.id) : false"
                (click)="selectRow(row)"
                >
            </tr>

            <!--
          <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>-->
          
        </table>
  
        <div class="info-vacio" *ngIf="dataSource.data.length === 0">
          <div style="font-size: 16px;">
            No hay información
          </div>
        </div>
        
      </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>