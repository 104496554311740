<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">
    <h2>Logística - Movimientos</h2>

    <div style="display: flex; flex-direction: column;">
        <div style="display: flex; align-items: baseline; flex-direction: row; justify-content: space-between;">

            <mat-form-field appearance="outline" style="flex-grow: 1; padding-right: 8px;" *ngIf="false">
                <mat-label>Ingrese un rango de fechas</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Fecha Desde">
                    <input matEndDate formControlName="end" placeholder="Fecha Hasta">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha Desde Incorrecta</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha Hasta Incorrecta</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="outline" style="flex-grow: 1; padding-right: 8px;">
                <mat-label>Sucursal Origen</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="filtro.sucursalOrigenNombre" name="sucursal_origen" readonly>
                <button *ngIf="filtro.sucursalOrigenNombre != ''" matSuffix mat-icon-button (click)="clickClear('origen')">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="clickSeleccionarSucursal('origen')">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
    
            <mat-form-field appearance="outline"style="flex-grow:1; padding-right: 8px;" >
                <mat-label>Sucursal Destino</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="filtro.sucursalDestinoNombre" name="sucursal_destino" readonly (dblclick)="clickSeleccionarSucursal('destino')">
                <button *ngIf="filtro.sucursalDestinoNombre != ''" matSuffix mat-icon-button (click)="clickClear('destino')">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="clickSeleccionarSucursal('destino')">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
    
            <div style="flex-grow: 1; display: flex; flex-direction: row-reverse;">
                <button mat-raised-button color="primary" (click)="clickConsultar()">Consultar</button>
            </div>
        </div>
        
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idlogisticamovimiento}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element._fecha}} </td>
            </ng-container>

        <ng-container matColumnDef="origen">
            <th mat-header-cell *matHeaderCellDef> Origen </th>
            <td mat-cell *matCellDef="let element"> {{element.origen_descripcion}}</td>
        </ng-container>

        <ng-container matColumnDef="destino">
            <th mat-header-cell *matHeaderCellDef> Destino </th>
            <td mat-cell *matCellDef="let element"> {{element.destino_descripcion}}</td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
        <td mat-cell *matCellDef="let element"> {{element.obs}} </td>
        </ng-container>

        <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="estado-{{element.estado}}">
                {{element._estado}} 
            </div>
        </td>
        </ng-container>
        
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 0 && element.tipo_movimiento != 3 && element.destino == sucursalActual.sucursalId" (click)="clickSolicitar(element, $event)">Solicitar</button>
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 0 && element.tipo_movimiento != 3 && element.destino == sucursalActual.sucursalId" (click)="clickEditarSolicitud(element, $event)">Editar Solicitud</button>
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 0 && element.tipo_movimiento != 3 && element.destino == sucursalActual.sucursalId" (click)="clickCancelar(element, $event, element.estado)">Cancelar Solicitud</button>

                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 1 && element.tipo_movimiento != 3 && element.origen == sucursalActual.sucursalId" (click)="clickEnviar(element, $event)">Enviar</button>
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 1 && element.tipo_movimiento != 3 && element.origen == sucursalActual.sucursalId" (click)="clickEditarEnvio(element, $event)">Editar Envío</button>
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 1 && element.tipo_movimiento != 3 && element.origen == sucursalActual.sucursalId" (click)="clickCancelar(element, $event, element.estado)">Cancelar Envío</button>

                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 2 && element.tipo_movimiento != 3 && element.destino == sucursalActual.sucursalId" (click)="clickRecibir(element, $event)">Recibir</button>
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 2 && element.tipo_movimiento != 3 && element.destino == sucursalActual.sucursalId" (click)="clickEditarRecepcion(element, $event)">Editar Recepción</button>

                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 1 && element.tipo_movimiento == 3 && element.origen == sucursalActual.sucursalId" (click)="clickEnviarVendidos(element, $event)">Enviar Vendidos</button>
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 2 && element.tipo_movimiento == 3 && element.destino == sucursalActual.sucursalId" (click)="clickRecibirVendidos(element, $event)">Recibir Vendidos</button>

            </td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetalle">
            
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                <div style="display: flex; flex-direction: column;">

                    <div style="display: flex; flex-direction: row; justify-content: flex-end; margin-bottom: 4px; margin-top: 4px;">
                        <button mat-raised-button color="primary" (click)="clickExportarPdf(element)">Imprimir</button>
                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;" *ngIf="element.tipo_movimiento == 0;">
                        <div>Productos Solicitados</div>
                        <div *ngIf="element.solicitados.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                </tr>
                                <tr *ngFor="let item of element.solicitados">
                                    <td style="text-align: right;">{{item.producto_descripcion}} <span class="tip-desactivado" *ngIf="!item.producto_activo">DESACTIVADO</span></td>
                                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.solicitados.length == 0" style="margin: 12px;">
                            No hay productos
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;" *ngIf="element.estado == 2 || element.estado == 3 || element.estado == 1">
                        <div>Productos Enviados</div>
                        <div *ngIf="element.enviados.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                </tr>
                                <tr *ngFor="let item of element.enviados">
                                    <td style="text-align: right;">{{item.producto_descripcion}} <span class="tip-desactivado" *ngIf="!item.producto_activo">DESACTIVADO</span></td>
                                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.enviados.length == 0" style="margin: 12px;">
                            No hay productos
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: column; padding-top: 10px;" *ngIf="element.estado == 2 || element.estado == 3">
                        <div>Productos Recibidos</div>
                        <div *ngIf="element.recibidos.length > 0">
                            <table class="tabla-interna">
                                <tr>
                                    <th>Producto</th>
                                    <th>Color</th>
                                    <th>Medida</th>
                                    <th>Cantidad</th>
                                </tr>
                                <tr *ngFor="let item of element.recibidos">
                                    <td style="text-align: right;">{{item.producto_descripcion}} <span class="tip-desactivado" *ngIf="!item.producto_activo">DESACTIVADO</span></td>
                                    <td style="text-align: right;">{{item.color_descripcion}}</td>
                                    <td style="text-align: right;">{{item.medida_descripcion}}</td>
                                    <td style="text-align: right;">{{item.cantidad}}</td>
                                </tr>
                            </table>
                        </div>
                        <div *ngIf="element.recibidos.length == 0" style="margin: 12px;">
                            No hay productos
                        </div>
                    </div>

                </div>
            
              </div>
            </td>
          </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>
