<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2 *ngIf="modo=='nuevo'">Nueva Venta</h2>
        <h2 *ngIf="modo=='presupuesto-nuevo'">Nuevo Presupuesto</h2>
        <h2 *ngIf="modo=='edicion'">Editar Venta #{{preventa.idpreventa}}</h2>
        <h2 *ngIf="modo=='confirmar'">Confirmar Venta #{{preventa.idpreventa}}</h2>
        <h2 *ngIf="modo=='confirmar-edicion'">Editar Venta Confirmada#{{preventa.idpreventa}}</h2>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Observaciones</mat-label>
                <input matInput placeholder="Observaciones" value="" [disabled]="modo == 'confirmar-edicion'"  type="text" [(ngModel)]="preventa.observaciones" name="descripcion">
            </mat-form-field>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Vendedor</mat-label>
                    <input matInput type="text" [(ngModel)]="vendedor.nombre" [disabled]="modo == 'confirmar-edicion'" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarVendedor()" [disabled]="modo == 'confirmar-edicion'">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" [(ngModel)]="cliente.nombre" [disabled]="modo == 'confirmar-edicion'" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()" [disabled]="modo == 'confirmar-edicion'">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="display: flex; flex-direction: column; border: 1px solid #bbbbbb; padding: 16px; border-radius: 4px; margin-bottom: 16px;">

                <div style="font-size: 12pt; margin: 4px 0px 8px 0px;">Datos de la Entrega</div>

                <mat-form-field appearance="outline" >
                    <mat-label>Domicilio</mat-label>
                    <input matInput placeholder="Domicilio" value=""  type="text" [(ngModel)]="preventa.domicilio" name="domicilio" [disabled]="modo == 'confirmar-edicion'">
                </mat-form-field>

                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Localidad</mat-label>
                        <input matInput type="text" [(ngModel)]="localidad.nombre" [disabled]="modo == 'confirmar-edicion'" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarLocalidad()" [disabled]="modo == 'confirmar-edicion'">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline" >
                    <mat-label>Flete</mat-label>
                    <input matInput placeholder="Flete" value="0" min="0" type="number" [(ngModel)]="preventa.flete" (ngModelChange)="onChangeFlete($event)" name="flete" [disabled]="modo == 'confirmar-edicion'">
                </mat-form-field>

            </div>

            <div>
                <h3 style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'normal'">Importe Total ${{fcNum(preventa.importe)}} (Incluye Flete)</h3>
                <h3 style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'descuento'">Importe Total ${{fcNum(preventa.importe)}} (Incluye Flete y Descuento General)</h3>
                <h3 style="font-weight: bold;" *ngIf="accionPrecioGeneral == 'recargo'">Importe Total ${{fcNum(preventa.importe)}} (Incluye Flete y Recargo General)</h3>
                <table>
                    <tr>
                        <td><div style="font-weight: bold;">Importe Productos</div></td>
                        <td><div style="font-weight: bold; text-align: right;">$ {{fcNum(totalProductos)}}</div></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><div style="font-weight: bold;">Importe Flete</div></td>
                        <td><div style="font-weight: bold; text-align: right;">$ {{fcNum(preventa.flete)}}</div></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><div style="font-weight: bold;">Importe Total (Sin Descuento, Sin Recargo)</div></td>
                        <td><div style="font-weight: bold; text-align: right;">$ {{fcNum((preventa.flete +  totalProductos))}}</div></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><div style="font-weight: bold;">Total en Formas de Pago</div></td>
                        <td><div style="font-weight: bold; text-align: right;">$ {{fcNum((totalFormaPago))}}</div></td>
                        <td><div style="font-weight: bold; text-align: right;">(con Recargo ${{fcNum((totalFormaPagoConRecargo))}})</div></td>
                    </tr>
                </table>
            </div>

            

        </div>

        <mat-divider style="margin-top: 16px;"></mat-divider>

        <div style="margin-top: 16px; margin-bottom: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <div style="flex-grow: 1;">
                    <ng-container *ngIf="modo=='edicion' || modo=='nuevo'">
                        <div style="display: flex; flex-direction: row;">
                            <h2 style="flex-grow: 1;">Productos a Vender</h2>
                            <div style="display: flex; flex-direction: row; align-items: baseline;">
                                <div style="font-weight: bold;">Importe Productos $ {{fcNum(totalProductos)}}</div>
                                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarSucursal()">Agregar Sucursal</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="modo=='confirmar'">
                        <h2 >Productos Vendidos</h2>
                    </ng-container>

                    <div style="display: flex; flex-direction: column;">
                        <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChanged($event)">
                            <ng-container *ngFor="let suc of sucursalesCont">
                                <mat-tab>
                                    <ng-template mat-tab-label>
                                        <span matBadge="{{suc.agregados.length}}" matBadgeOverlap="false">{{suc.nombre}}</span>
                                    </ng-template>

                                    <div style="margin-top: 8px; margin-bottom: 8px;">
                                        <div style="display: flex; flex-direction: row-reverse; align-items: baseline;">
                                            <button style="margin-left: 16px;" mat-raised-button [disabled]="suc.agregados.length > 0 || sucursalesCont.length <= 1" (click)="clickQuitarSucursal(suc)">Quitar Sucursal</button>
                                            <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregar(suc)">Agregar y Editar Productos</button>
                                        </div>
                                        <div style="margin: 16px 8px 8px 8px;" *ngIf="suc.agregados.length > 0">
                                            
                                            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort class="mat-elevation-z4" style="width: 100%;">

                                                <!-- Position Column -->
                                                <ng-container matColumnDef="id">
                                                <th mat-header-cell mat-sort-header *matHeaderCellDef> id </th>
                                                <td mat-cell *matCellDef="let element"> {{element.idproducto}} </td>
                                                <td mat-footer-cell *matFooterCellDef> <b>Total Sucursal</b> </td>
                                                </ng-container>
                                            
                                                <!-- Name Column -->
                                                <ng-container matColumnDef="producto">
                                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Producto </th>
                                                <td mat-cell *matCellDef="let element"> {{element.descripcion}} <span class="tip-desactivado" *ngIf="!element.activo">DESACTIVADO</span></td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                                </ng-container>
                                        
                                                <ng-container matColumnDef="total">
                                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Total </th>
                                                <td mat-cell *matCellDef="let element"> $ {{fcNum(element.subTotalProducto)}}</td>
                                                <td mat-footer-cell *matFooterCellDef> <b>$ {{fcNum(suc.subTotalSucursal)}}</b> </td>
                                                </ng-container>
                                        
                                               
                                                <ng-container matColumnDef="expandedDetalle">
                                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                                    <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                                        <div style="display: flex;flex-direction: column; margin-bottom: 24px; margin-top: 16px;">
                                                            <div>
                                                                <h3>Detalle de Presentaciones</h3>
                                                                <div *ngIf="element.presentaciones.length > 0" style="width: 100%;">
                                                                    <table class="styled-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Color</th>
                                                                                <th>Medida</th>
                                                                                <th>Stock en Sucursal</th>
                                                                                <th>Cantidad</th>
                                                                                <th>Precio Unitario</th>
                                                                                <th>Descuento Unitario</th>
                                                                                <th>Precio Unitario (Con Descuento)</th>
                                                                                <th>Descuento SubTotal</th>
                                                                                <th>SubTotal</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of element.presentaciones">
                                                                                <td>{{item.colorDescripcion}}</td>
                                                                                <td style="text-align: left;">{{item.medidaDescripcion}}</td>
                                                                                <td style="text-align: right;">{{item.stock}}</td>
                                                                                <td style="text-align: right;">{{item.cantidad}}</td>
                                                                                <td style="text-align: right;">$ {{fcNum(item.precio)}}</td>
                                                                                <td style="text-align: right;">$ {{fcNum(item.importe_descuento)}} ({{item.porcentaje_descuento}}%)</td>
                                                                                <td style="text-align: right;">$ {{fcNum(item.precio - item.importe_descuento)}}</td>
                                                                                <td style="text-align: right;">$ {{fcNum((item.importe_descuento * item.cantidad))}}</td>
                                                                                <td style="text-align: right;">$ {{fcNum(((item.precio - item.importe_descuento) * item.cantidad))}}</td>
                                                                            </tr>
                                                                            <tr style="background-color: #bdbdbd;">
                                                                                <td colspan="8" style="font-weight: bold;">TOTAL</td>
                                                                                <td style="font-weight: bold;">$ {{fcNum(element.subTotalProducto)}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div *ngIf="element.presentaciones.length == 0" style="margin: 12px;">
                                                                    No hay presentaciones del producto
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td mat-footer-cell *matFooterCellDef></td>
                                                </ng-container>
                                                
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row 
                                                    *matRowDef="let element; columns: displayedColumns;"
                                                    class="element-row"
                                                    [class.expanded-row]="expandedElement === element"
                                                    (click)="expandedElement = expandedElement === element ? null : element">
                                                </tr>
                                        
                                                <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>
                                                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: false"></tr>
                                                
                                            </table>
                                            
                                        </div>
                                        <div *ngIf="suc.agregados.length == 0">
                                            <div style="height: 200px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center; align-items: center;">
                                                <span>Sin productos agregados en sucursal <span style="font-weight: bold;">{{suc.nombre}}</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </mat-tab>
                            </ng-container>
                        </mat-tab-group>
                    </div>

                </div>
                
            </div>

        </div>

        <mat-divider></mat-divider>

        <mat-radio-group [(ngModel)]="accionPrecioGeneral" (ngModelChange)="onChangeAccionPrecio($event)" style="margin-top: 16px;">
            <mat-radio-button value="normal" style="margin-right: 8px;">Precio Normal de Venta</mat-radio-button>
            <mat-radio-button value="recargo" style="margin-right: 8px;">Aplicar Recargo</mat-radio-button>
            <mat-radio-button value="descuento" style="margin-right: 8px;">Aplicar Descuento</mat-radio-button>
        </mat-radio-group>

        <div style="margin-top: 16px;" *ngIf="accionPrecioGeneral == 'normal' ">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Mantener Precio Normal de Venta</h2>
            </div>
        </div>

        <div style="margin-top: 16px;" *ngIf="accionPrecioGeneral == 'descuento' ">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Descuento General</h2>
            </div>

            <div style="font-size: 10pt; margin: 8px 0px 16px 16px;">
                Aplica sobre el total de la venta (Importe de Productos + Flete)<br>
                *El importe de productos a su vez puede contener descuentos de producto.
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                <mat-form-field appearance="outline" >
                    <mat-label>$ Descuento</mat-label>
                    <span matPrefix>$&nbsp;</span>
                    <input matInput placeholder="$ Descuento" value="0" min="0" type="number" [(ngModel)]="preventa.descuentoGeneralImporte" (ngModelChange)="onChangeDescuentoGeneralImporte($event)" name="descuento_general_importe">
                </mat-form-field>


                <mat-form-field appearance="outline" >
                    <mat-label>% Descuento</mat-label>
                    <span matSuffix>%</span>
                    <input matInput placeholder="% Descuento" value="0" min="0" type="number" [(ngModel)]="preventa.descuentoGeneralPorcentaje" (ngModelChange)="onChangeDescuentoGeneralPorcentaje($event)" name="descuento_general_porcentaje">
                </mat-form-field>
            </div>

        </div>

        <div style="margin-top: 16px;" *ngIf="accionPrecioGeneral == 'recargo' ">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Recargo General</h2>
            </div>

            <div style="font-size: 10pt; margin: 8px 0px 16px 16px;">
                Aplica sobre el total de la venta (Importe de Productos + Flete)<br>
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                <mat-form-field appearance="outline" >
                    <mat-label>$ Recargo</mat-label>
                    <span matPrefix>$&nbsp;</span>
                    <input matInput placeholder="$ Recargo" value="0" min="0" type="number" [(ngModel)]="preventa.recargoGeneralImporte" (ngModelChange)="onChangeRecargoGeneralImporte($event)" name="recargo_general_importe">
                </mat-form-field>


                <mat-form-field appearance="outline" >
                    <mat-label>% Recargo</mat-label>
                    <span matSuffix>%</span>
                    <input matInput placeholder="% Recargo" value="0" min="0" type="number" [(ngModel)]="preventa.recargoGeneralPorcentaje" (ngModelChange)="onChangeRecargoGeneralPorcentaje($event)" name="recargo_general_porcentaje">
                </mat-form-field>
            </div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Forma de Pago</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar y Editar Formas de Pago</button>
            </div>

            <div>

                <div *ngIf="formasDePago.length > 0">

                    <table mat-table [dataSource]="dataSourceFP" multiTemplateDataRows matSort class="mat-elevation-z4" style="width: 100%;">

                        <ng-container matColumnDef="formaPago">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Forma de Pago </th>
                            <td mat-cell *matCellDef="let element"> {{element.tipoDescripcion}} </td>
                            <td mat-footer-cell *matFooterCellDef> <b>Total</b> </td>
                        </ng-container>

                        <ng-container matColumnDef="senia">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Es Seña </th>
                            <td mat-cell *matCellDef="let element"> {{element.senia ? 'SI' : 'NO'}}</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>

                        <ng-container matColumnDef="recargo">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Recargo </th>
                            <td mat-cell *matCellDef="let element"> $ {{fcNum(((element.importe * element.recargo) / 100))}}</td>
                            <td mat-footer-cell *matFooterCellDef></td>
                        </ng-container>
                    
                        <ng-container matColumnDef="importe">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Importe </th>
                            <td mat-cell *matCellDef="let element"> $ {{fcNum(element.importe)}} </td>
                            <td mat-footer-cell *matFooterCellDef> $ {{fcNum(totalFormaPago)}}</td>
                        </ng-container>
                
                        <ng-container matColumnDef="totalConRecargo">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Total con Recargo </th>
                            <td mat-cell *matCellDef="let element"> $ {{fcNum((((element.importe * element.recargo) / 100) + element.importe))}}</td>
                            <td mat-footer-cell *matFooterCellDef> $ {{fcNum(totalFormaPagoConRecargo)}}</td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="displayedColumnsFP"></tr>
                        <tr mat-row 
                            *matRowDef="let element; columns: displayedColumnsFP;"
                            class="element-row"
                            [class.expanded-row]="expandedElement === element">
                        </tr>
                
                        <tr mat-footer-row *matFooterRowDef="displayedColumnsFP; sticky: false"></tr>

                    </table>

                </div>

                <div *ngIf="formasDePago.length == 0">
                    <div style="height: 200px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center; align-items: center;">Sin formas de pago agregadas</div>
                </div>

            </div>

        </div>
        
    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
    </div>

</div>