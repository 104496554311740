import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { ComprasFormasPagoDialogComponent } from '../compras-formas-pago-dialog/compras-formas-pago-dialog.component';
import { ComprasProductosDialogComponent } from '../compras-productos-dialog/compras-productos-dialog.component';
import { Location } from '@angular/common';
import { AnyTxtRecord } from 'dns';


@Component({
  selector: 'app-compras-recepcion',
  templateUrl: './compras-recepcion.component.html',
  styleUrls: ['./compras-recepcion.component.scss']
})
export class ComprasRecepcionComponent implements OnInit {

  
  compra: any = {
    idcompra: 0,
    idproveedor: 0,
    idtipocompra: 1,
    proveedorNombre: "",
    idsucursal: 0,
    sucursalNombre: "",
    importe: 0.0,
    descripcion: "",
    fecha: ""
  }

  listSucursales: any[] = [];
  listProveedores: any[] = [];

  agregados: any[] = [];
  formasDePago: any[] = [];

  cargando = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private dialog: MatDialog

  ) {
    
    this.compra.idcompra = Number(this.activatedRoute.snapshot.paramMap.get('idcompra'));

    this.cargando = true;

    if (this.compra.idcompra > 0){
      
      this.apiService.getComprasCompleto(this.compra.idcompra).subscribe((x:any) => {

        if (x != null && x.estado == 0){
          this.compra = {
            idcompra: x.idcompra,
            idproveedor: x.idproveedor,
            idtipocompra: x.idtipocompra,
            proveedorNombre: x.proveedor_descripcion,
            idsucursal: x.idsucursal,
            sucursalNombre: x.sucursal_descripcion,
            importe: x.importe,
            descripcion: x.descripcion,
            fecha: x.fecha
          }

          this.apiService.getComprasProductoSemiCompleto(this.compra.idcompra).subscribe((xx: any[]) => {
            
            let lista: any[] = [];
            xx.forEach(y => {
              let a = {
                cantidad: y.cantidad,
                colorDescripcion: y.color_descripcion,
                descripcion: y.color_descripcion + " | " + y.medida_descripcion,
                idproducto: y.idproducto,
                idproductopresentacion: y.idproductopresentacion,
                importe: y.importe_presentacion,
                medidaDescripcion: y.medida_descripcion,
                productoDescripcion: y.producto_descripcion
              
              }
              lista.push(a);
            });

            this.agregados = [];
            lista.forEach(x => {
              let a = this.agregados.find(z => z.idproducto == x.idproducto);
              if (a != null){
                a.presentaciones.push(x);
              }else{
                let nuevo = {
                  idproducto: x.idproducto,
                  descripcion: x.productoDescripcion,
                  presentaciones: [x]
                }
                this.agregados.push(nuevo);
              }
            });

            this.cargando = false;

          });
        }
        
      });
      
    }

    this.apiService.getSucursalesList().subscribe((x: any) => {
      this.listSucursales = x;
    });

    this.apiService.getProveedoresList().subscribe((x: any[]) => {
      this.listProveedores = x;
    });
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    
    console.log("nuevo", this.compra);

    this.cargando = true;

    let arrAgregados: any[] = []

    let importesCompletos = true;
    this.agregados.forEach(x => {
      let presentaciones: any[] = x.presentaciones;
      presentaciones.forEach(y => {
        if (y.importe <= 0){
          importesCompletos = false;
        }

        arrAgregados.push({
          idproductopresentacion: y.idproductopresentacion,
          cantidad: y.cantidad,
          importe: y.importe
        });

      });
    });

    if (this.agregados.length == 0){
      this.snackBarService.errorMessage("Debe agregar al menos un producto.");
      return;
    }

    /*
    if (this.formasDePago.length == 0){
      this.snackBarService.errorMessage("Debe agregar al menos una forma de pago.");
      return;
    }

    if (!importesCompletos){
      this.snackBarService.errorMessage("Existen importes de productos en 0. Debe completarlos.");
      return;
    }
    */

    /*
    let importeTotalFormaPago = 0;
    this.formasDePago.forEach(x => {
      importeTotalFormaPago += x.importe;
    })

    if (this.compra.importe != importeTotalFormaPago){
      this.snackBarService.errorMessage("Los importes de las formas de pago no son iguales al importe total.");
      return;
    }

    let arrFormasPago: any[] = [];
    this.formasDePago.forEach(x => {
      arrFormasPago.push({
        idcompratipoprecio: x.idcompratipoprecio,
        importe: x.importe,
        observaciones: x.observaciones
      });
    });

    let itemCompra = {
      idproveedor: this.compra.idproveedor,
      idtipocompra: this.compra.idtipocompra,
      idsucursal: this.compra.idsucursal,
      importe: this.compra.importe,
      descripcion: this.compra.descripcion
    }
    */

    let loteStock: any[] = [];
    arrAgregados.forEach(x => {
      let stock = {
        cantidad: x.cantidad,
        idproductopresentacion: x.idproductopresentacion,
        idsucursal: this.compra.idsucursal
      }
      loteStock.push(stock);
    });

    /*
    this.apiService.updateComprasRecepcion(this.compra.idcompra).subscribe(x => {
      if (x){
        this.apiService.postComprasProductosRecepcionBulk(this.compra.idcompra, arrAgregados).subscribe(y => {
          if (y){
            this.cargando = false;
            this.router.navigateByUrl(`/compras/abm`, {});
          }
        });
      }
    });
    */

    let data ={
      idcompra: this.compra.idcompra,
      items: arrAgregados
    }

    this.apiService.postComprasRecepcionCompleto(data).subscribe(x => {
      this.cargando = false;
      if (x){
        this.router.navigateByUrl(`/compras/abm`, {});
      }else{
        this.snackBarService.errorMessage("Ocurrio un error interno al realizar la recepcion de la compra");
      }
    }, e => {
      this.snackBarService.errorMessage("Ocurrio un error al realizar la recepcion de la compra");
    });

    
  }

  clickBack(){
    this._location.back();
  }

  clickSeleccionarProveedor(){
    this.seleccionarProveedor();
  }

  clickAgregar(){
    this.dialogAgregar();
  }

  dialogAgregar() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80vw';
    dialogConfig.minWidth = '560px';
    dialogConfig.data = {
      agregados: this.agregados,
      muestraImporte: false
    };

    const dialogRef = this.dialog.open(ComprasProductosDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((r: any) => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.agregados = [];
          this.agregados = r.agregados;

          let total = 0.0;
          this.agregados.forEach((x: any) => {
            x.presentaciones.forEach((z: any) => {
              total += z.importe * z.cantidad;
            });
          });

          this.compra.importe = total;

        }
      }
    });

  }

  clickAgregarFormaPago(){
    this.dialogFormaPago()
  }

  changeImporte(element: any, value: any){
    
  }

  changeCantidad(element: any, value: any){
    let cantidad = Number(value);
    if (Number.isInteger(cantidad)){
      if (cantidad == 0){
        //this.dialogBorrar("Información", "Al poner 0 de cantidad estaria indicando que no agregue la presentación. Desea eliminarla?", element)
      }
    }
  }

  dialogFormaPago() {

    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      formasDePago: this.formasDePago
    };

    const dialogRef = this.dialog.open(ComprasFormasPagoDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.formasDePago = r.formasDePago;
        }
      }
    });

  }

  seleccionarProveedor(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listProveedores.forEach(x => {
      let item = {
        id: x.idproveedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Proveedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Proveedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.compra.idproveedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.compra.idproveedor = rr.seleccion.id;
          this.compra.proveedorNombre = rr.seleccion.nombre;
        }
      }
    });

  }


}
