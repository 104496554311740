import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, combineLatest, concat } from 'rxjs';
import { ClientesCompletoModel } from 'src/app/models/clientes-completo-model';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SharedService } from 'src/app/services/shared.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { formatNumeroNacional, toFechaApiRequest, toFechaCorta, toFechaLarga } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PagosComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any>;

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  displayedColumns: string[] = ['idpreventa', 'opciones'];

  displayedColumnsInit: string[] = ['idpreventa', 'opciones'];

  displayedColumnsPendiente: string[] = [];
  displayedColumnsPagado: string[] = [];
  displayedColumnsDeuda: string[] = [];

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  // 0 pendiente, 1 confirmado, 2 pagados
  estados = ['Pendiente', 'Pago Pendiente', 'Pagado'];

  estadosVisuales = ['Pendiente', 'Pago Pendiente', 'Pagado'];

  vals: any[] = [];

  seleccion: 'pagados' | 'confirmados' | 'deuda' = 'confirmados';

  listVendedores: VendedoresModel[] = [];

  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  listCliente: ClientesCompletoModel[] = [];

  cliente:ClientesCompletoModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    codigo_localidad: 0,
    nombre_localidad: "",
    codigo_postal: "",
    telefono: "",
    correo_electronico: "",
    observaciones: "",
  };

  uid = "";

  columnaList: any = [];

  columnaListPendiente = [
    {id: "cliente_nombre", titulo: "Cliente", activado: true},
    {id: "vendedor_nombre", titulo: "Vendedor",  activado: true},
    {id: "sucursal", titulo: "Sucursal",  activado: true},
    {id: "localidad", titulo: "Localidad Entrega",  activado: true},
    {id: "domicilio", titulo: "Domicilio Entrega",  activado: true},
    {id: "fecha", titulo: "Fecha",  activado: true},
    {id: "observaciones", titulo: "Observaciones",  activado: true},
    {id: "estado_descripcion", titulo: "Estado",  activado: true},
    {id: "total", titulo: "Total Productos",  activado: true}
  ];

  columnaListPagado = [
    {id: "cliente_nombre", titulo: "Cliente", activado: true},
    {id: "vendedor_nombre", titulo: "Vendedor",  activado: true},
    {id: "sucursal", titulo: "Sucursal",  activado: true},
    {id: "localidad", titulo: "Localidad Entrega",  activado: true},
    {id: "domicilio", titulo: "Domicilio Entrega",  activado: true},
    {id: "fecha", titulo: "Fecha",  activado: true},
    {id: "observaciones", titulo: "Observaciones",  activado: true},
    {id: "estado_descripcion", titulo: "Estado",  activado: true},
    {id: "total", titulo: "Total Productos",  activado: true},
    {id: "pagado", titulo: "Pagado",  activado: true},
    {id: "recargo", titulo: "Recargo",  activado: true},
    {id: "debe", titulo: "Debe",  activado: true},
    //{id: "a_favor", titulo: "A Favor",  activado: true}
  ];

  columnaListDeuda = [
    {id: "cliente_nombre", titulo: "Cliente", activado: true},
    {id: "vendedor_nombre", titulo: "Vendedor",  activado: true},
    {id: "sucursal", titulo: "Sucursal",  activado: true},
    {id: "localidad", titulo: "Localidad Entrega",  activado: true},
    {id: "domicilio", titulo: "Domicilio Entrega",  activado: true},
    {id: "fecha", titulo: "Fecha",  activado: true},
    {id: "observaciones", titulo: "Observaciones",  activado: true},
    {id: "estado_descripcion", titulo: "Estado",  activado: true},
    {id: "total", titulo: "Total Productos",  activado: true},
    {id: "pagado", titulo: "Pagado",  activado: true},
    {id: "recargo", titulo: "Recargo",  activado: true},
    {id: "debe", titulo: "Debe",  activado: true},
  ]


  cargando = false;

  desde = null;
  hasta = null;
  mesActualDesde = '';
  mesActualHasta = '';

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  fcNum = formatNumeroNacional;

  sumTotalProductos = 0.0;
  sumTotalPagados = 0.0;
  sumTotalRecargo = 0.0;
  sumTotalDebe = 0.0;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private pdfService: PdfService,
    private auth: AuthService
  ) {
    moment.locale('es');

    this.uid = this.auth.userInfo() ? this.auth.userInfo().uid : "-";

    this.sucursalActual = this.sharedService.getSucursalActual();

    if (this.uid != "-"){
      let listStrPendiente = sessionStorage.getItem(`${this.uid}--pagos-pendientes_`);
      if (listStrPendiente != null){
        this.columnaListPendiente = JSON.parse(listStrPendiente);
      }
      let listStrPagado = sessionStorage.getItem(`${this.uid}--pagos-pagados_`);
      if (listStrPagado != null){
        this.columnaListPagado = JSON.parse(listStrPagado);
      }
      let listStrDeuda = sessionStorage.getItem(`${this.uid}--pagos-deudas_`);
      if (listStrDeuda != null){
        this.columnaListDeuda = JSON.parse(listStrDeuda);
      }
    }

    this.inicializarColumnas(true);

    this.mesActualDesde = toFechaApiRequest(moment().startOf('month').toDate());
    this.mesActualHasta = toFechaApiRequest(moment().endOf('month').toDate());

    this.apiService.getVendedoresList().subscribe(x => {
      this.listVendedores = x;
    });

    this.apiService.getClientesCompletoList().subscribe((x: any[]) => {
      this.listCliente = x;
    });

  }

  ngOnInit(): void {

    let mDesde = moment().startOf('month');
    let mHasta = moment().endOf('month');

    mHasta.set({hour:23,minute:59,second:59,millisecond:0});

    this.range.controls.start.setValue(mDesde.toDate());
    this.range.controls.end.setValue(mHasta.toDate());


    this.cargarTabla(1);
  }

  cargarTabla(estado?: number){

    this.vals = [];
    this.dataSource.data = this.vals;

    this.sumTotalProductos = 0.0;
    this.sumTotalPagados = 0.0;
    this.sumTotalRecargo = 0.0;
    this.sumTotalDebe = 0.0;

    if (estado != null){
      if (estado == 1){
        this.cargarTablaPendiente();
      }
      
      if (estado == 2){
        this.cargarTablaPagado();
      }

      if (estado == 3){
        this.cargarTablaPagadoDeuda();
      }
      
    }
    
  }

  cargarTablaPendiente(){

    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    let rDesde = toFechaApiRequest(mDesde.toDate());
    let rHasta = toFechaApiRequest(mHasta.toDate());

    let preventasProductos = this.apiService.getPreventasProductosListCompleto(1, this.sucursalActual.sucursalId, rDesde, rHasta);
    let preventasFormaPago = this.apiService.getPreventasFormaPago();

    this.cargando = true;

    combineLatest([preventasProductos, preventasFormaPago]).subscribe((hh: any[]) =>{

      console.log("hh", hh);

      let o: any[] = hh[0];
      let p: any[] = hh[1];

      p.forEach(xx => {
        xx._recargo = ((xx.importe * xx.recargo) /100)
        xx._conRecargo = xx.importe + xx._recargo
      })

      let mapList: Map<number, any> = new Map();

      o.forEach(x => {

        let contenido: any = null;

        if (mapList.has(x.idpreventa)){
          contenido = mapList.get(x.idpreventa);
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento
          };
          contenido.productos.push(producto);
          
          let sum = 0
          contenido.productos.forEach((p: any) => {
            sum = sum + (p.cantidad * p.precio);
          });
          contenido._total = sum;
          
          mapList.set(x.idpreventa, contenido);
        }else{
          contenido = {
            idpreventa: x.idpreventa,
            observaciones: x.observaciones,
            vendedor_nombre: x.vendedor_nombre,
            idvendedor: x.idvendedor,
            idcliente: x.idcliente,
            idsucursal: x.preventa_idsucursal,
            preventa_idsucursal: x.preventa_idsucursal,
            preventa_sucursal: x.preventa_sucursal,
            domicilio: x.domicilio,
            localidad_descripcion: x.localidad_descripcion,
            localidad_codigo_postal: x.localidad_codigo_postal,
            cliente_nombre: x.cliente_nombre,
            fecha: x.fecha,
            senia: x.senia,
            flete: x.flete,
            estado: x.estado,
            estado_descripcion: this.estados[x.estado],
            importe_descuento: x.preventa_importe_descuento,
            porcentaje_descuento: x.preventa_porcentaje_descuento,
            importe_recargo: x.preventa_importe_recargo,
            porcentaje_recargo: x.preventa_porcentaje_recargo,
            _fecha: toFechaCorta(x.fecha),
            _fechax: toFechaLarga(x.fecha),
            productos: [],
            formaPago: []
          };
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento
          };

          contenido.formaPago = p.filter(ff => ff.idpreventa == x.idpreventa);

          contenido.productos.push(producto);
          
        }

        ///


        let sum = 0
        contenido.productos.forEach((p: any) => {
          sum = sum + (p.cantidad * (p.precio - p.importe_descuento));
        })

        contenido._total_productos = sum;
        contenido._total = sum + contenido.flete - contenido.importe_descuento + contenido.importe_recargo;

        let pagado = 0.0;
        let recargo = 0.0;

        contenido.formaPago.forEach((p: any) => {
          pagado = pagado + p.importe;
          recargo = recargo + p._recargo;
        });

        let debe = contenido._total - pagado;
        contenido._pagado = pagado;
        contenido._debe = debe;
        contenido._recargo = recargo;
        
        mapList.set(x.idpreventa, contenido);

      });

      this.vals =[ ...mapList.values() ];


      //calculamos totales globales
      this.vals.forEach(vv => {
        this.sumTotalProductos = this.sumTotalProductos + vv._total;
        this.sumTotalPagados = this.sumTotalPagados + vv._pagado;
        this.sumTotalDebe = this.sumTotalDebe + vv._debe;
        this.sumTotalRecargo = this.sumTotalRecargo + vv._recargo;
      })

      //

      console.log("data", this.vals);

      this.dataSource.data = this.vals;

      //control de precio asincrona
      let calls = [];
      this.vals.forEach(vvv => {
        vvv._diferenciaPrecio = null;
        calls.push(this.resolverControlPrecios(vvv));
      });
      concat(...calls).subscribe((r: any) => {
        let val = this.vals.find(val => val.idpreventa == r.idpreventa);
        if (val){
          val.productos = r.productos;
          val._diferenciaPrecio = r._diferenciaPrecio;
          val._total_productos_actual = r._total_productos_actual;
        }
      })
      //

      //this.table.renderRows();

      this.cargando = false;

      this.aplicarFiltro();

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);

      this.cargando = false;
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    })
  }

  cargarTablaPagado(){


    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    let rDesde = toFechaApiRequest(mDesde.toDate());
    let rHasta = toFechaApiRequest(mHasta.toDate());


    let preventasProductos = this.apiService.getPreventasProductosListCompleto(2, this.sucursalActual.sucursalId, rDesde, rHasta);
    let preventasFormaPago = this.apiService.getPreventasPago();

    this.cargando = true;

    combineLatest([preventasProductos, preventasFormaPago]).subscribe((hh: any[]) =>{

      let o: any[] = hh[0];
      let p: any[] = hh[1];

      p.forEach(xx => {
        xx._recargo = ((xx.importe * xx.recargo) /100);
        xx._conRecargo = xx.importe + xx._recargo;
        xx._fecha = toFechaCorta(moment(xx.fecha).toDate())
      })

      let mapList: Map<number, any> = new Map();

      o.forEach(x => {

        let contenido: any = null;

        if (mapList.has(x.idpreventa)){
          contenido = mapList.get(x.idpreventa);
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento
          };
          contenido.productos.push(producto);
          
          let sum = 0
          contenido.productos.forEach((p: any) => {
            sum = sum + (p.cantidad * p.precio);
          })
          contenido._total = sum;
          
          mapList.set(x.idpreventa, contenido);
        }else{
          contenido = {
            idpreventa: x.idpreventa,
            observaciones: x.observaciones,
            vendedor_nombre: x.vendedor_nombre,
            idvendedor: x.idvendedor,
            idcliente: x.idcliente,
            idsucursal: x.preventa_idsucursal,
            preventa_idsucursal: x.preventa_idsucursal,
            preventa_sucursal: x.preventa_sucursal,
            domicilio: x.domicilio,
            localidad_descripcion: x.localidad_descripcion,
            localidad_codigo_postal: x.localidad_codigo_postal,
            cliente_nombre: x.cliente_nombre,
            fecha: x.fecha,
            senia: x.senia,
            flete: x.flete,
            estado: x.estado,
            estado_descripcion: this.estados[x.estado],
            importe_descuento: x.preventa_importe_descuento,
            porcentaje_descuento: x.preventa_porcentaje_descuento,
            importe_recargo: x.preventa_importe_recargo,
            porcentaje_recargo: x.preventa_porcentaje_recargo,
            _fecha: toFechaCorta(x.fecha),
            _fechax: toFechaLarga(x.fecha),
            productos: [],
            formaPago: []
          };
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento,
          };

          contenido.formaPago = p.filter(ff => ff.idpreventa == x.idpreventa);

          contenido.productos.push(producto);

          
        }

        ///


        let sum = 0
        contenido.productos.forEach((p: any) => {
          sum = sum + (p.cantidad * (p.precio - p.importe_descuento));
        })

        contenido._total_productos = sum;
        contenido._total = sum + contenido.flete - contenido.importe_descuento + contenido.importe_recargo;

        let pagado = 0.0;
        let recargo = 0.0;

        contenido.formaPago.forEach((p: any) => {
          pagado = pagado + p.importe;
          recargo = recargo + p._recargo;
        });

        let debe = contenido._total - pagado;
        let aFavor = 0;

        if (debe < 0){
          debe = 0.0;
          aFavor = debe * -1;
        }

        contenido._pagado = pagado;
        contenido._debe = debe;
        contenido._recargo = recargo;
        contenido._aFavor = aFavor;
        
        mapList.set(x.idpreventa, contenido);

      });

      this.vals =[ ...mapList.values() ];

      this.vals = this.vals.filter(vv => vv._debe == 0.0);

      //calculamos totales globales
      this.vals.forEach(vv => {
        this.sumTotalProductos = this.sumTotalProductos + vv._total;
        this.sumTotalPagados = this.sumTotalPagados + vv._pagado;
        this.sumTotalDebe = this.sumTotalDebe + vv._debe;
        this.sumTotalRecargo = this.sumTotalRecargo + vv._recargo;
      })

      //

      console.log("data", this.vals);

      this.dataSource.data = this.vals;
      this.cargando = false;

      this.aplicarFiltro();

    }, e => {

      this.cargando = false;
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    })
  }

  cargarTablaPagadoDeuda(){


    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    let rDesde = toFechaApiRequest(mDesde.toDate());
    let rHasta = toFechaApiRequest(mHasta.toDate());


    let preventasProductos = this.apiService.getPreventasProductosListCompleto(2, this.sucursalActual.sucursalId, rDesde, rHasta);
    let preventasFormaPago = this.apiService.getPreventasPago();

    this.cargando = true;

    combineLatest([preventasProductos, preventasFormaPago]).subscribe((hh: any[]) =>{

      console.log("hh", hh);

      let o: any[] = hh[0];
      let p: any[] = hh[1];

      p.forEach(xx => {
        xx._recargo = Number(((xx.importe * xx.recargo) /100).toFixed(2));
        xx._conRecargo = Number((xx.importe + xx._recargo).toFixed(2));
        xx._fecha = toFechaCorta(moment(xx.fecha).toDate())
      })

      let mapList: Map<number, any> = new Map();

      o.forEach(x => {

        let contenido: any = null;

        if (mapList.has(x.idpreventa)){
          contenido = mapList.get(x.idpreventa);
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento
          };
          contenido.productos.push(producto);
          
          let sum = 0
          contenido.productos.forEach((p: any) => {
            sum = sum + (p.cantidad * p.precio);
          })
          contenido._total = sum;
          
          mapList.set(x.idpreventa, contenido);
        }else{
          contenido = {
            idpreventa: x.idpreventa,
            observaciones: x.observaciones,
            vendedor_nombre: x.vendedor_nombre,
            idvendedor: x.idvendedor,
            idcliente: x.idcliente,
            idsucursal: x.preventa_idsucursal,
            preventa_idsucursal: x.preventa_idsucursal,
            preventa_sucursal: x.preventa_sucursal,
            domicilio: x.domicilio,
            localidad_descripcion: x.localidad_descripcion,
            localidad_codigo_postal: x.localidad_codigo_postal,
            cliente_nombre: x.cliente_nombre,
            fecha: x.fecha,
            senia: x.senia,
            flete: x.flete,
            estado: x.estado,
            estado_descripcion: "Adeuda",//this.estados[x.estado],
            importe_descuento: x.preventa_importe_descuento,
            porcentaje_descuento: x.preventa_porcentaje_descuento,
            importe_recargo: x.preventa_importe_recargo,
            porcentaje_recargo: x.preventa_porcentaje_recargo,
            _fecha: toFechaCorta(x.fecha),
            _fechax: toFechaLarga(x.fecha),
            productos: [],
            formaPago: []
          };
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento,
          };

          contenido.formaPago = p.filter(ff => ff.idpreventa == x.idpreventa);

          contenido.productos.push(producto);

          
        }

        ///


        let sum = 0
        contenido.productos.forEach((p: any) => {
          sum = sum + (p.cantidad * (p.precio - p.importe_descuento));
        })

        contenido._total_productos = sum;
        contenido._total = sum + contenido.flete - contenido.importe_descuento + contenido.importe_recargo;

        let pagado = 0.0;
        let recargo = 0.0;

        contenido.formaPago.forEach((p: any) => {
          pagado = pagado + p.importe;
          recargo = recargo + p._recargo;
        });

        let debe = contenido._total - pagado;
        let aFavor = 0;

        if (debe < 0){
          debe = 0.0;
          aFavor = debe * -1;
        }

        contenido._pagado = Number(pagado.toFixed(2));
        contenido._debe = Number(debe.toFixed(2));
        contenido._recargo = Number(recargo.toFixed(2));
        contenido._aFavor = Number(aFavor.toFixed(2));
        
        mapList.set(x.idpreventa, contenido);

      });

      this.vals =[ ...mapList.values() ];

      this.vals = this.vals.filter(vv => vv._debe > 0);

      //calculamos totales globales
      this.vals.forEach(vv => {
        this.sumTotalProductos = this.sumTotalProductos + vv._total;
        this.sumTotalPagados = this.sumTotalPagados + vv._pagado;
        this.sumTotalDebe = this.sumTotalDebe + vv._debe;
        this.sumTotalRecargo = this.sumTotalRecargo + vv._recargo;
      })

      //

      console.log("data", this.vals);

      this.dataSource.data = this.vals;

      //control de precio asincrona
      let calls = [];
      this.vals.forEach(vvv => {
        vvv._diferenciaPrecio = null;
        calls.push(this.resolverControlPrecios(vvv));
      });
      concat(...calls).subscribe((r: any) => {
        let val = this.vals.find(val => val.idpreventa == r.idpreventa);
        if (val){
          val.productos = r.productos;
          val._diferenciaPrecio = r._diferenciaPrecio;
          val._total_productos_actual = r._total_productos_actual;
        }
      })
      //

      this.cargando = false;

      this.aplicarFiltro();

    }, e => {

      this.cargando = false;
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    })
  }

  clickBuscar(){
    this.vendedor = {nombre: "", idvendedor: 0};
    this.cliente = {
      idcliente: 0,
      nombre: "",
      domicilio: "",
      codigo_localidad: 0,
      nombre_localidad: "",
      codigo_postal: "",
      telefono: "",
      correo_electronico: "",
      observaciones: "",
    };

    if (this.seleccion == 'confirmados'){
      this.cargarTabla(1);
    }

    if(this.seleccion == 'pagados'){
      this.cargarTabla(2);
    }

    if (this.seleccion == 'deuda'){
      this.cargarTabla(3);
    }

  }

  resolverControlPrecios(venta): Observable<any>{

    return new Observable((observer) => {

      console.log("venta a revisar", venta);
      let obs = [];
      venta.productos.forEach(p => {
        obs.push(this.apiService.getProductosPresentaciones(p.idproductopresentacion));
      });

      let diferenciaPrecio = false;

      combineLatest(obs).subscribe(h => {
        let sumaTotalActual = 0;
        venta.productos.forEach(p => {
          let prodActual: any = h.find((x: any) => x.idproductopresentacion == p.idproductopresentacion);
          if (prodActual){
            p._precio_actual = prodActual.precio;
            if (prodActual.precio != p.precio){
              diferenciaPrecio = true;
            }
          }else{
            p._precio_actual = p.precio;
          }

          sumaTotalActual = sumaTotalActual + (p._precio_actual - p.importe_descuento) * p.cantidad;

        });

        venta._diferenciaPrecio = diferenciaPrecio;
        venta._total_productos_actual = sumaTotalActual;

      });

      observer.next(venta);
      observer.complete();
    })

  }


  clickPagar(e: any, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`/ventas/pagos/confirmar/${e.idpreventa}`, {});
  }

  clickEditarPago(e: any, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`/ventas/pagos/confirmar/${e.idpreventa}`, {});
  }

  clickEditar(e: any, event: any){
    event.stopPropagation();

  }

  clickCancelar(e: any, event: any){
    event.stopPropagation();
    this.dialogCancelar("Alerta", `¿Confirma que desea cancelar la venta #id ${e.idpreventa}?`, e.idpreventa);
  }

  dialogCancelar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar
        this.apiService.getPreventaCancelar(elemento).subscribe(x => {
          if (x){
            let index = this.vals.findIndex(z => z.idpreventa == elemento);
            if (index != -1){
              this.vals.splice(index, 1);
              this.dataSource.data = this.vals;
            }
          }
        });
      } 
    });

  }

  radioChange(event: any){
    console.log("seleccion", this.seleccion);

    //this.vendedor = {nombre: "", idvendedor: 0};

    if (this.seleccion == 'confirmados'){
      this.columnaList = this.columnaListPendiente;
      this.resetColumnas();
      this.cargarTabla(1);
    }

    if(this.seleccion == 'pagados'){
      this.columnaList = this.columnaListPagado;
      this.resetColumnas();
      this.cargarTabla(2);
    }

    if(this.seleccion == 'deuda'){
      this.columnaList = this.columnaListPagado;
      this.resetColumnas();
      this.cargarTabla(3);
    }

  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }
        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }

        this.aplicarFiltro();
        
      }
    });
  }

  clickSeleccionarCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listCliente.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: true,
      referenciaNuevo: "cliente"
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          let clienteSel = this.listCliente.find(x => x.idcliente == rr.seleccion.id);

          if (clienteSel == null){

          }else{
            this.cliente = clienteSel as ClientesCompletoModel
          }

        }else{
          this.cliente = {
            idcliente: 0,
            nombre: "",
            domicilio: "",
            codigo_localidad: 0,
            nombre_localidad: "",
            codigo_postal: "",
            telefono: "",
            correo_electronico: "",
            observaciones: "",
          };
        }

        this.aplicarFiltro();
        
      }
    });
  }

  clickExportarPdf(e: any){

    console.log("element", e);

    let id = e.idpreventa;
    let clienteNombre = `${e.cliente_nombre}`;
    let vendedorNombre = `${e.vendedor_nombre}`;
    let fecha = `${e._fecha}`;
    let sucursal = `${e.preventa_sucursal}`;
    let estado = `${e.estado_descripcion}`;
    let localidad = `${e.localidad_descripcion}`;
    let codigoPostal = `${e.localidad_codigo_postal}`;
    let domicilio = `${e.domicilio}`;
    let flete = `${e.flete}`;

    let productos: any = [['Producto', 'Color', 'Medida', 'Cantidad', 'Sucursal', 'Precio Unitario', 'Descuento Unitario', 'Precio']];

    let precioProductos = e._total_productos.toFixed(2);
    let total = (e._total).toFixed(2);

    let formasPago: any = [['Tipo', 'Importe', 'Recargo', 'Total', 'Es Seña']];


    let p: any = e.productos.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`,
        `${r.producto_sucursal_descripcion}`,
        `$${r.precio}`,
        `$${r.importe_descuento}`,
        `$${(r.precio - r.importe_descuento) * r.cantidad}`
      ]
      return x;
    })

    p.forEach((x: any) => {
      productos.push(x);
    })

    let f: any = e.formaPago.map((r: any) => {
      let x: any[] = [
        `${r.tipo_descripcion}`,
        `$${r.importe}`,
        `$${r._recargo} (${r.recargo}%)`,
        `$${r._conRecargo}`,
        `${r.senia? 'Si' : 'No'}`
      ]
      return x;
    })

    f.forEach((x: any) => {
      formasPago.push(x);
    })

    let content: any[] = [
      { text: `Venta - #${id} - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${clienteNombre}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente Domicilio Entrega: ${domicilio}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente Localidad Entrega: ${localidad} (${codigoPostal})`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedorNombre}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Fecha: ${fecha}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Estado: ${estado}`, fontSize: 11, margin:[0, 0, 0, 10] },

      { text: `Flete: $${flete}`, fontSize: 11, margin:[0, 0, 0, 10] },
      
      { text: `Productos: `, fontSize: 11, margin:[0, 0, 0, 10] },
      {
        table: {
          headerRows: 1,
          body: productos
        }
      },
      { text: `Precio Productos: $${precioProductos}`, fontSize: 11, margin:[0, 10, 0, 10] },
      { text: `Descuento General: $${e.importe_descuento}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Total: $${total}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Formas de Pago:`, fontSize: 11, margin:[0, 0, 0, 10] },
      {
        table: {
          headerRows: 1,
          body: formasPago
        }
      },
    ]

    this.pdfService.generarPDF(content);

  }

  clickShowColumna(event: any, item: any){
    //  event.stopPropagation();
    //console.log("event", event); 

    item.activado = event.checked;

    if (this.seleccion == 'confirmados' ){
      let itemEncontrado = this.columnaListPendiente.find(x => x.id == item.id);
      if (itemEncontrado != null){
        console.log("chequea confirmado");
        itemEncontrado.activado = event.checked;
      }
    }

    if (this.seleccion == 'pagados'){
      let itemEncontrado = this.columnaListPagado.find(x => x.id == item.id);
      if (itemEncontrado != null){
        console.log("chequea pagado");
        itemEncontrado.activado = event.checked;
      }
    }

    if (this.seleccion == 'deuda'){
      let itemEncontrado = this.columnaListDeuda.find(x => x.id == item.id);
      if (itemEncontrado != null){
        console.log("chequea deuda");
        itemEncontrado.activado = event.checked;
      }
    }

    if (event.checked){

      this.displayedColumns = ['idpreventa', 'opciones'];
      this.inicializarColumnas();

    }else{
      let columnaIndex = this.displayedColumns.findIndex(x => x == item.id);
      if (columnaIndex != -1){
        this.displayedColumns.splice(columnaIndex, 1);
      }
    }

    sessionStorage.setItem(`${this.uid}--pagos-pendientes_`, JSON.stringify(this.columnaListPendiente));
    sessionStorage.setItem(`${this.uid}--pagos-pagados_`, JSON.stringify(this.columnaListPagado));
    sessionStorage.setItem(`${this.uid}--pagos-deudas_`, JSON.stringify(this.columnaListDeuda));

  }

  inicializarColumnas(afectarColumnas?: boolean){

    let columnas: any = [];

    if (this.seleccion == 'confirmados'){
      columnas = this.columnaListPendiente;
      if (afectarColumnas){
        this.columnaList = this.columnaListPendiente;
      }
    }

    if (this.seleccion == 'pagados'){
      columnas = this.columnaListPagado;
      if (afectarColumnas){
        this.columnaList = this.columnaListPagado;
      }
    }

    columnas.forEach((c: any) => {
      if (c.activado){
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, c.id);
      }
    });
  }

  resetColumnas(){

    this.displayedColumns = JSON.parse(JSON.stringify(this.displayedColumnsInit));

    let columnas: any = [];

    if (this.seleccion == 'confirmados'){
      columnas = JSON.parse(JSON.stringify(this.columnaListPendiente));
    }

    if (this.seleccion == 'pagados'){
      columnas = JSON.parse(JSON.stringify(this.columnaListPagado));
    }

    if (this.seleccion == 'deuda'){
      columnas = JSON.parse(JSON.stringify(this.columnaListDeuda));
    }

    columnas.forEach((c: any) => {
      if (c.activado){
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, c.id);
      }
    });
  }

  aplicarFiltro(){

    let filtrado: any[] = [];

    let fc = (idv: number, idc: number) => {
      if (this.vendedor.idvendedor != 0 && this.cliente.idcliente != 0){
        return this.vendedor.idvendedor == idv && this.cliente.idcliente == idc;
      }else{
        if (this.vendedor.idvendedor == 0 && this.cliente.idcliente == 0){
          return true;
        }else{
          if (this.vendedor.idvendedor != 0){
            return this.vendedor.idvendedor == idv;
          }
          if (this.cliente.idcliente != 0){
            return this.cliente.idcliente == idc;
          }
          return true
        }
      }
    }
    
    filtrado = this.vals.filter(v => fc(v.idvendedor, v.idcliente))
    
    this.dataSource.data = filtrado;
  }

  cambioVentaDetalle(e){
    console.log("e", e);
    this.clickBuscar();
  }

}

const ELEMENT_DATA: any[] = [];

