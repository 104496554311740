import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ClientesModel } from 'src/app/models/clientes-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-clientes-nuevo-dialog',
  templateUrl: './clientes-nuevo-dialog.component.html',
  styleUrls: ['./clientes-nuevo-dialog.component.scss']
})
export class ClientesNuevoDialogComponent implements OnInit {

  nuevo: ClientesModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    telefono: "",
    correo_electronico: "",
    codigo_localidad: 0,
    observaciones: "",
  }

  cargando = false;

  listLocalidades: any[] = [];


  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<ClientesNuevoDialogComponent>,
    private snackBarService: SnackBarService,

  ) { 

    this.apiService.getLocalidadesList().subscribe(x => {
      this.listLocalidades = x as any[];
    });


  }

  ngOnInit(): void {
  }

  clickCancelar(){
    this.dialogRef.close(
      {
        cierre: true
      });
  }

  clickCrear(){
    console.log("nuevo", this.nuevo);
    this.cargando = true;
    if (this.validar(this.nuevo)){
      if (this.nuevo.idcliente == 0){
        
        
        this.apiService.postClientes(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el cliente correctamente");
          console.log("x", x);
          
          this.dialogRef.close(
            {
              cierre: true,
              nuevoCompleto: x,
              nuevo: {
                id: x.idcliente,
                nombre: x.nombre
              }
            });

        }, (e: any) => {
          this.cargando = false;
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
        
      }else{
        this.apiService.putClientes(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el cliente correctamente");
          

        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
          this.cargando = false;
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo nombre debe tener mas de 2 caracteres");
      this.cargando = false;
    }
  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

}
