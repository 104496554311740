import { Component, Inject, OnInit } from '@angular/core';
import { Database, onValue, query, ref, push, DatabaseReference, set, remove } from '@angular/fire/database';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-notas',
  templateUrl: './notas.component.html',
  styleUrls: ['./notas.component.scss']
})
export class NotasComponent implements OnInit {

  listSucursales = [];

  listNotas = [];

  listNotasFiltrada = [];

  notaActual = null;

  pathNotas = environment.pathNotas; // '/notas' en prod o '/notasDEV' en dev
  //pathNotas = '/notas'; // '/notas' en prod o '/notasDEV' en dev

  notaHtmlContent = "";

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'calc(100vh - 250px)',
    minHeight: '0',
    //maxHeight: 'calc(100vh - 200px)',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Agregue texto aquí...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        //'undo',
        //'redo',
        //'bold',
        //'italic',
        //'underline',
        //'strikeThrough',
        'subscript',
        'superscript',
        //'justifyLeft',
        //'justifyCenter',
        //'justifyRight',
        //'justifyFull',
        //'indent',
        //'outdent',
        //'insertUnorderedList',
        //'insertOrderedList',
        'heading',
        //'fontName'
      ],
      [
        'textColor',
        'backgroundColor',
        'customClasses',
        //'link',
        //'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  sucursalActual = {
    idsucursal: 0,
    descripcion: "Todas"
  }

  constructor(
    @Inject(Database) public database: Database,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    console.log("path", this.pathNotas);
    this.getListNotas();
    this.getSucursales();
  }

  clickNuevaNota(){
    this.crearNota("");
  }

  clickNota(nota){
    this.notaActual = nota;
    this.notaHtmlContent = nota.value.contenido;
  }

  async clickBorrarNota(nota){
    let r = await this.borrarNota(nota.key);
  }

  notaTituloOnChange(event, nota){
    let data = nota.value;
    this.updateNota(nota.key, event, data.contenido, data.sucursalId, data.sucursalNombre);
  }

  notaContenidoOnChange(event, nota){
    let data = nota.value;
    let html = this.notaHtmlContent;

    this.updateNota(nota.key, data.titulo, html, data.sucursalId, data.sucursalNombre);
  }

  getListNotas(){
    let q = query(ref(this.database, `${this.pathNotas}/`));

    onValue(q, (snapshot) => {

      let notas = [];

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        notas.push({
          key: childKey,
          value: childData
        });

      });

      this.listNotas = notas;

      this.filtrarNotas();

    });
  }

  filtrarNotas(){

    let idSucursal = this.sucursalActual.idsucursal;

    if (idSucursal != 0){
      this.listNotasFiltrada = this.listNotas.filter(x => x.value.sucursalId == idSucursal);
      //verificamos que la nota actual pertenezca a las filtradas
      if (this.notaActual != null){
        let actual = this.listNotasFiltrada.find(x => x.key == this.notaActual.key);
        if (actual){
          console.log("mantiene la nota actual por que es de la lista");
          this.notaActual = actual;
        }else{
          console.log("vacia la nota actual, es de otro grupo");
          this.notaActual = null;
        }
      }else{
        if (this.listNotasFiltrada.length > 0){
          this.notaActual = this.listNotasFiltrada[0];
          this.notaHtmlContent = this.notaActual.value.contenido;
        }
      }

    }else{
      this.listNotasFiltrada = this.listNotas;
    }

  }

  crearNota(tituloNota){

    let sucursalId = this.sucursalActual.idsucursal;
    let sucursalNombre = this.sucursalActual.idsucursal != 0 ? this.sucursalActual.descripcion : "Global";

    const notasListRef = ref(this.database, this.pathNotas);
    const newRef = push(notasListRef);
    set(newRef, {
      contenido: "",
      titulo: tituloNota,
      sucursalId: sucursalId,
      sucursalNombre: sucursalNombre,
      activo: true
    }).then(() => {
      //this.notaActual = 
    });
  }

  updateNota(id, titulo, contenido, sucursalId, sucursalNombre, estado = true){
    const notaRef = ref(this.database, `${this.pathNotas}/${id}`);
    set(notaRef, {
      contenido: contenido,
      titulo: titulo,
      sucursalId: sucursalId,
      sucursalNombre: sucursalNombre,
      activo: estado
    });
  }

  borrarNota(id){
    const notaRef = ref(this.database, `${this.pathNotas}/${id}`);
    return remove(notaRef);
  }

  //////////manejo de sucursales

  getSucursales(){
    this.apiService.getSucursalesList().subscribe(ss => {
      this.listSucursales.push({
        idsucursal: 0,
        descripcion: "Todas"
      })
      this.listSucursales.push(...ss);
    }, e => {
      if (!environment.production){
        this.listSucursales = 
          [
            {
              "idsucursal": 0,
              "descripcion": "Todas",
            },
            {
                "idsucursal": 1,
                "descripcion": "Verde Manzana Neuquén",
                "domicilio": "Lainez 267",
                "codigo_localidad": 1,
                "telefono": "291 4128292",
                "observaciones": ""
            },
            {
                "idsucursal": 2,
                "descripcion": "Verde Manzana Coronel Suárez",
                "domicilio": "Sarmiento 275",
                "codigo_localidad": 3,
                "telefono": "2926 421447",
                "observaciones": ""
            },
            {
                "idsucursal": 4,
                "descripcion": "Depósito Coronel Suárez",
                "domicilio": "Chacabuco 184",
                "codigo_localidad": 3,
                "telefono": "2926 455870",
                "observaciones": ""
            },
            {
                "idsucursal": 5,
                "descripcion": "Depósito Alfonsina Storni",
                "domicilio": "Alfonsina Storni 1216",
                "codigo_localidad": 1,
                "telefono": "2926 463054",
                "observaciones": ""
            },
            {
                "idsucursal": 7,
                "descripcion": "Depósito Buenos Aires",
                "domicilio": "Intendente Federico Russo 3350",
                "codigo_localidad": 7,
                "telefono": "2926 466738",
                "observaciones": ""
            },
            {
                "idsucursal": 8,
                "descripcion": "Deposito Alcorta Neuquen",
                "domicilio": "Alcorta 533",
                "codigo_localidad": 1,
                "telefono": "2914128292",
                "observaciones": ""
            },
            {
                "idsucursal": 9,
                "descripcion": "Verde Manzana Catriel ",
                "domicilio": "San Martin 436",
                "codigo_localidad": 2,
                "telefono": "2995078643",
                "observaciones": ""
            },
            {
                "idsucursal": 10,
                "descripcion": "Verde Manzana Zonal",
                "domicilio": "Neuquén ",
                "codigo_localidad": 1,
                "telefono": "2995349205",
                "observaciones": "VENTAS ZONALES"
            },
            {
                "idsucursal": 11,
                "descripcion": "Verde Manzana Gral. Roca",
                "domicilio": "Neuquen 1540",
                "codigo_localidad": 70,
                "telefono": "2984316920",
                "observaciones": ""
            },
            {
                "idsucursal": 12,
                "descripcion": "Verde Manzana Centenario",
                "domicilio": "Perú 58",
                "codigo_localidad": 77,
                "telefono": "2995977365",
                "observaciones": ""
            }
        ];
        
      }
    });
  }

  onChangeSucursal(event){
    let sucu = this.listSucursales.find(x => x.idsucursal == event);
    if (sucu){
      this.sucursalActual.descripcion = sucu.descripcion;
    }
    this.filtrarNotas();
  }

}
