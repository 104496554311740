import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
//import { Workbook } from 'exceljs/dist/exceljs.min.js';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() {}

  /*
   * Export As Excel File
   * -- Variables --
   * json: JSON DATA to Export
   * excelFileName: Excel File Name to the final user
   * replaceValues: True or false to replace 'EMPRESA' and 'ACTIVO' to the correct values
   * rowsToDelete: Delete rows in the export excel file
   */
  exportAsExcelFile(
    json: any[],
    excelFileName: string,
    replaceValues: boolean = false,
    rowsToDelete: Array<string> = [],
    type: string = ''
  ): void {
    // copyData for not modify data-binding
    const copyData = JSON.parse(JSON.stringify(json));

    // Checking values to replace or delete
    if (replaceValues) {
      this._findAndReplace(copyData, type);
    }
    if (rowsToDelete) {
      this._deleteRows(copyData, rowsToDelete);
    }

    // Magic import (excel)
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(copyData);
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelArray: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this._saveAsExcelFile(excelArray, excelFileName);
  }

  // Save As Excel File
  private _saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  // Find and replace values
  private _findAndReplace(object: any, type = ''): any {
    switch (type) {
      case 'sugerencias':
        object.map((key: any) => {
          key.fecha = key.fecha;
          key.usuario = key.usuario.login;
          key.activo = key.activo ? 'Activo' : 'Inactivo';
          key.aplicacion = key.aplicacion.nombre;
        });
        break;
      case 'informe':
        for (const key of object) {
          let aplicaciones: string | null = null;
          let plantillas = null;
          if (key.aplicaciones && key.aplicaciones.length > 0) {
            for (const [idxApp, app] of key.aplicaciones.entries()) {
              if (!aplicaciones) {
                aplicaciones = `${app.nombre}`;
              } else {
                aplicaciones += `\n ${app.nombre}`;
              }

              if (idxApp === key.aplicaciones.length - 1) {
                key.aplicaciones = aplicaciones;
              }
            }
          } else {
            key.aplicaciones = '-';
          }

          if (key.plantillas && key.plantillas.length > 0) {
            for (const [idxTemp, temp] of key.plantillas.entries()) {
              if (!plantillas) {
                plantillas = `${temp.nombre}`;
              } else {
                plantillas += `\n ${temp.nombre}`;
              }

              if (idxTemp === key.plantillas.length - 1) {
                key.plantillas = plantillas;
              }
            }
          } else {
            key.plantillas = '-';
          }
        }
        break;
      case 'empresa-usuarios-export':
        object.map((key: any) => {
          key.activo = key.activo ? 'Activo' : 'Inactivo';
          key.empresa = key.empresa.nombre;
        });
        break;
      case 'plantilla-empresa-usuario':
        object.map((key: any) => {
          key.activo = key.activo ? 'Activo' : 'Inactivo';
          key.empresa = key.empresa;
        });
        break;
      case 'servicios-pae':
        object.map((key: any) => {
          key.autorizado = key.autorizado ? 'Si' : 'No';
          return key;
        });
        break;
      default:
        object.map((key: any) => {
          key.empresa = key.empresa.nombre !== undefined && key.empresa.nombre;
          key.activo = key.activo ? 'Activo' : 'Inactivo';
        });
    }
  }

  // Find and delete rows. If doesn't exist, will be ignored
  private _deleteRows(object: any, rowsToDelete: any): void {
    object.map((key: any) => {
      for (const row of rowsToDelete) {
        delete key[row];
      }
    });
  }
  
}
