import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ICacheHandler } from './cache';
import { Observable, Subject, Subscriber, TeardownLogic, defer } from 'rxjs';
import { finalize, concatMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { delay } from 'rxjs/operators';
import { uniq } from 'lodash';

// TODO: Implementar la personalización de merge de update (*1)
// TODO: Incluir el payload como parte de queryId (*2)
// TODO: Agregar todo el tipado que falta
// TODO: Agregar inyección de clientFilters personalizados (*3)
// TODO: Agregar inyección conversores de queryParams para url / queryString (*4)
// TODO: Agregar a info de cache, si se espera o no actualización (*5)
// TODO: Hacer algo que loguee los sistemas de cache cargados desde algún lugar
//       que pueda verse en la consola, para tener info de panorama global,
//       los tiempos de refresco, drivers de cache, etc.
@Injectable({
  providedIn: 'root'
})
export class WebApi {

  public apiUrl: string;
  public modulePrefix: string;

  constructor(
    protected req: ApiRequestService,
  ) { }

  public get<T>(accion, params = {}, options: any = {}) {
    return this.req.get<T>(this.getUrl(accion), params, options);
  }
  public post<T>(accion, params = {}, payload = null, options: any = {}) {
    return this.req.post<T>(this.getUrl(accion), params, payload, options);
  }
  public put<T>(accion, params = {}, payload = null, options: any = {}) {
    return this.req.put<T>(this.getUrl(accion), params, payload, options);
  }
  public delete<T>(accion, params = {}, payload = null, options: any = {}) {
    return this.req.delete<T>(this.getUrl(accion), params, payload, options);
  }

  public module(modulePrefix: string) {
    const thisClass = (this.constructor as new (req: ApiRequestService) => this);
    let result = new thisClass(this.req);
    result.apiUrl = this.apiUrl;
    result.modulePrefix = modulePrefix;
    return result;
  }

  private includesApiUrl(accion) {
    return accion.substr(0, 2) !== '//'
      && !accion.match(/^https?:\/\//);
  }

  private includesModulePrefix(accion) {
    return accion.substr(0, 1) !== '/'
      && !accion.match(/^https?:\/\//);
  }

  private getUrl(accion) {
    return [
      this.includesApiUrl(accion) ? this.apiUrl : null,
      this.includesModulePrefix(accion) ? this.modulePrefix : null,
      accion,
    ]
      .map(str => str?.replace(/\/*$|^\/*/g, ''))
      .filter(str => str)
      .join('/');
  }
}

interface CacheQueue {
  cacheGroup: string,
  cacheDriver: ICacheHandler,
  queue: Subject<any>,
}

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {

  private h: Helpers = new Helpers();

  private _cacheDrivers: {[key:string]: ICacheHandler} = {};

  cacheRegistryQueues: CacheQueue[] = [];

  constructor(
    private http: HttpClient,
  ) { }

  public get<T>(accion, params = {}, options: any = {}) {
    const transformedParams = this.transformQueryParams(params);
    return this.apiQuery<T>('get', accion, transformedParams, {}, options);
  }
  public post<T>(accion, params = {}, payload = null, options: any = {}) {
    return this.apiQuery<T>('post', accion, params, payload, options);
  }
  public put<T>(accion, params = {}, payload = null, options: any = {}) {
    return this.apiQuery<T>('put', accion, params, payload, options);
  }
  public delete<T>(accion, params = {}, payload = null, options: any = {}) {
    return this.apiQuery<T>('delete', accion, params, payload, options);
  }

  public apiQuery<T>(method, accion, params = {}, payload = null, options: any = {}) {

    const apiRequest = new ApiRequest<T>(
      this.http,
      this,
      method,
      accion,
      params,
      payload,
      options);

    const query = apiRequest.makeQuery();
    if (apiRequest.cache) {
      this.registerQueryCache(query, apiRequest);
    }
    return query;
  }

  registerQueryCache<T>(query: ReqObservable, apiRequest: ApiRequest<T>) {
    // Registra globalmente
    this.registerQueryCacheGroup(query, apiRequest.cache, '');
    // Registra el grupo específico si corresponde
    if (apiRequest.config.cacheGroup) {
      this.registerQueryCacheGroup(query, apiRequest.cache, apiRequest.config.cacheGroup);
    }
  }

  registerQueryCacheGroup<T>(query: ReqObservable, cache: ApiRequestCacheHandler, cacheGroup: string) {
    const cacheGroupHandler = new ApiRequestCacheGroupHandler(cacheGroup, cache.cacheDriver);
    const groupRegistryQueue = this.getCacheRegistryQueue(cacheGroup, cache.cacheDriver);
    groupRegistryQueue.queue.next(defer(() => {
      cacheGroupHandler.appendGroupInfo(cache.queryId);
      return [cache.queryId];
    }));
  }

  private getCacheRegistryQueue<T>(cacheGroup: string, cacheDriver: ICacheHandler) {
    return this.cacheRegistryQueues.find(queue => {
      return queue.cacheGroup === cacheGroup
        && queue.cacheDriver === cacheDriver;
    }) || this.initCacheRegistryQueue(cacheGroup, cacheDriver);
  }

  private initCacheRegistryQueue<T>(cacheGroup: string, cacheDriver: ICacheHandler) {
    const queue = new Subject<any>();
    let queueObservable = queue.pipe(
      concatMap((x: Observable<any>) => x)
    );
    queueObservable.subscribe();
    const registryQueue = { cacheGroup, cacheDriver, queue };
    this.cacheRegistryQueues.push(registryQueue);
    return registryQueue;
  }

  public clearGlobalCache() {
    this.clearGroupCache('');
  }

  public clearGroupCache(group: string) {
    Object.values(this.cacheDrivers).forEach(cacheDriver => {
      const cacheGroupHandler = new ApiRequestCacheGroupHandler(group, cacheDriver);
      const groupInfo = cacheGroupHandler.getGroupInfo();
      const cacheHandlers = groupInfo.map(queryId => {
        return new ApiRequestCacheHandler(queryId, cacheDriver);
      });
      cacheHandlers.forEach(cacheHandler => {
        cacheHandler.clearData();
      });
    });
  }

  // (*4) TODO: Podría también ser buena idea permitir inyectar conversores individualmente
  // y manejarlos en un array de funciones
  private transformQueryParam(value)
  {
    // Si viene una fecha, quita los milisegundos
    if (value && (typeof value === 'object') && 'toISOString' in value) {
      value = value.toISOString().substr(0, 19);
    }

    return value;
  }

  private transformQueryParams(params)
  {
    // CHECK: Posiblemente lo de las fechas influya en los POST, PUT, etc. también
    let result = {};
    for (const key in params) {
      result[key] = this.transformQueryParam(params[key]);
    }
    return result;
  }

  public setCacheDrivers(cacheDrivers: {[key:string]: ICacheHandler})
  {
    this._cacheDrivers = cacheDrivers;
  }

  public get cacheDrivers() {
    return this._cacheDrivers;
  }
}

// OBS: Por razones de practicidad, no se agrega el tipado aun, no es necesario
export interface ReqObservable { // <T>
  pipe: any; // TODO: Buscar y asignar el tipado correcto
  // subscribe: (this: Observable<T>, subscriber: Subscriber<T>) => TeardownLogic;
  flushCache?: () => void;
  cacheInfo?: () => void;
}

class ApiRequest<T> {

  private h: Helpers = new Helpers();

  private http: HttpClient;
  private apiRequestService: ApiRequestService;

  method: string; // TODO: Restringir valores
  accion: string;
  payload: any;
  config: any; // TODO: Definir interface de configuración
  clientParams: any;
  queryParams: any;
  queryId: string;
  cache?: ApiRequestCacheHandler;
  queryBufferHandler: QueryBufferHandler;

  constructor(http: HttpClient, apiRequestService: ApiRequestService,
    method: string, accion: string,
    params: any = {}, payload: any = {}, options: any = {})
  {
    this.http = http;
    this.apiRequestService = apiRequestService;
    this.method = method;
    this.accion = accion;
    this.payload = payload;

    this.config = this.buildConfig(options); // TODO: Definir interface de opciones, es distinta a la de configuración

    const { clientParams, queryParams }: { clientParams: any, queryParams: any }
      = this.classifyQueryParams(params, this.config.clientFilters);
    this.clientParams = clientParams;
    this.queryParams = queryParams;

    // (*2) TODO: Incluir el payload, como en los casos PUT que consultan con arrays de ids en el cuerpo
    this.queryId = this.buildQueryId(method, accion, queryParams);

    if (this.config.enableCache) {
      this.cache = this.buildCacheHandler(this.queryId, this.config.cacheDriver, this.config.cacheGroup);
    }

    this.queryBufferHandler = new QueryBufferHandler(this.queryId);
  }

  private buildConfig(options) {
    const defaultConfig = {
      clientFilters: {},
      enableCache: true,
      cacheGroup: null,
      showPreviousCached: true,
      updateCache: true,
      getFullResponse: false,
      showCachedAsync: false,
    }
    return this.adjustConfig({
      ...defaultConfig,
      ...options,
    });
  }

  private adjustConfig(config) {
    let result = {...config};
    if (!result.cacheDriver) {
      result.enableCache = false;
      // result.updateCache = false;
    }
    if (!result.cacheGroup) {
      result.cacheGroup = null;
      // result.updateCache = false;
    }
    result.httpOptions = this.buildHttpOptions(result);
    return result;
  }

  private buildHttpOptions(config) {
    let result: { observe?: string, headers?: HttpHeaders } = {};
    if (config.getFullResponse) {
      result.observe = 'response';
    }
    if (config.contentType) {
      result.headers = new HttpHeaders({ 'Content-Type': config.contentType });
    }
    return result;
  }

  private classifyQueryParams(params, clientFilters)
  {
    const clientParamNames = Object.keys(clientFilters);
    return Object.keys(params).reduce((acc, paramName) => {
      const paramsGroup = clientParamNames.includes(paramName)
        ? 'clientParams' : 'queryParams';
      acc[paramsGroup][paramName] = params[paramName];
      return acc;
    }, {clientParams: {}, queryParams: {}});
  }

  // OBS: Es posible que ayude un md5 para las claves demasiado largas
  // En ese caso, posiblemente dejar una parte de la url sea conveniente
  // para propósitos de desarrollo
  private buildQueryId(method, accion, queryParams) {
    const url = this.buildUrl(accion, queryParams);
    return `${method}:${url}`;
  }

  private buildUrl(accion, params) {
    const classifiedParams = this.classifyUrlParams(accion, params);
    const baseUrl = this.resolveUrlLayout(accion, classifiedParams.url);
    return this.appendQueryStringParams(baseUrl, classifiedParams.queryString);
  }

  private classifyUrlParams(accion, params) {
    const urlParamNames = this.urlParamNames(accion);

    return Object.keys(params).reduce((acc, paramName) => {
      const paramType = urlParamNames.includes(paramName) ? 'url' : 'queryString';
      acc[paramType][paramName] = params[paramName];
      return acc;
    }, {url: {}, queryString: {}});
  }

  private urlParamNames(accion)
  {
    let result = [];
    let regexIterator = accion.matchAll(/\{(\w+)\}/g);

    let elem = regexIterator.next();
    while (!elem.done) {
      result.push(elem.value[1]);
      elem = regexIterator.next();
    }

    return result;
  }

  private resolveUrlLayout(urlLayout: string, params: any): string {
    return Object.keys(params).reduce(
      (acc, attr) => acc.replace(`{${attr}}`, params[attr]),
      urlLayout);
  }

  private appendQueryStringParams(baseUrl: string, params: any): string {
    return Object.keys(params).length
      ? `${baseUrl}?${this.buildQueryString(params)}`
      : baseUrl;
  }

  private processQueryParams(input) {
    return Object.keys(input)
      .filter(key => ![undefined, null].includes(input[key]))
      .reduce((result, key) => {
        const valueIsArray = Array.isArray(input[key]);
        const isRealArray = !!key.match(/\[\]$/) && valueIsArray;

        const attr = key.replace(/(\w+).*/, "$1");
        const value = isRealArray
          ? input[key].map(val => val.toString())
          : (valueIsArray ? input[key].join(',') : input[key].toString());

        const params = isRealArray
          ? value.map(val => ({attr, value: val}))
          : [ {attr, value} ];

        return [...result, ...params];
      }, []);
  }

  private buildQueryString(params: any): string {
    const httpParams = this.processQueryParams(params).reduce((httpParams, param) => {
      return httpParams.append(param.attr, param.value);
    }, new HttpParams());
    return httpParams.toString();
  }

  private buildCacheHandler(queryId: string, cacheDriver: string | ICacheHandler, cacheGroup: string = ''): ApiRequestCacheHandler {
    if (!cacheDriver) {
      return null;
    }
    const cacheHandler = typeof cacheDriver === 'string'
      ? this.apiRequestService.cacheDrivers[cacheDriver]
      : cacheDriver;

    return cacheHandler
      ? new ApiRequestCacheHandler(queryId, cacheHandler)
      : null;
  }

  public makeObservable(): Observable<T> {

    return new Observable<T>(observer => {

      if (!this.cache || !this.cache.hasData()) {
        if (!this.queryBufferHandler.hasPreviousQuery()) {
          const apiCallSuscription = this.callToApi().subscribe(apiResult => {
            this.cache?.setData(apiResult);
            this.queryBufferHandler.sendToQueryBuffer<T>(apiResult);
            observer.next(this.finalizeResult(apiResult, false, false));
            observer.complete();
          });
          this.queryBufferHandler.registerQueryBuffer(apiCallSuscription);
        } else {
          this.queryBufferHandler.subscribeToQueryBuffer((bufferData) => {
            observer.next(this.finalizeResult(bufferData, false, false));
            observer.complete();
          });
        }
      }
      else {
        const cachedData = this.cache.getData();
        const needsCacheUpdate = this.needsCacheUpdate();
        if (this.config.showPreviousCached) {
          const showCached = () => {
            observer.next(this.finalizeResult(cachedData, true, needsCacheUpdate));
            if (!needsCacheUpdate) {
              observer.complete();
            }
          }
          // El timeout evita problemas con valores de formularios reactivos en componentes anidados
          // pero trae problemas por ejemplo para los "permissions"
          if (this.config.showCachedAsync) {
            setTimeout(() => { showCached(); });
          } else {
            showCached();
          }
        }

        if (needsCacheUpdate) {
          // Aclaración: No pueden existir en simultáneo un buffer de consulta y otro de update
          if (!this.queryBufferHandler.hasPreviousQuery()) {
            const apiCallSuscription = this.callToApi().subscribe(apiUpdateResult => {
              // TODO: IMPORTANTE: Cuidado con esta verificación!!!!!
              if (this.resultIsNotEmpty(apiUpdateResult)) {
                const mergedData = this.mergeData(cachedData, apiUpdateResult);
                this.cache.setData(mergedData);

                this.queryBufferHandler.sendToQueryBuffer<T>(mergedData);
                observer.next(this.finalizeResult(mergedData, false, false));
              } else {
                // TODO: Cuando esté implementado el mergeUpdate, devolver false
                // en 'expectsForUpdate' desde finalizeResult
              }
              observer.complete();
            });
            this.queryBufferHandler.registerQueryBuffer(apiCallSuscription);
          } else {
            this.queryBufferHandler.subscribeToQueryBuffer((bufferData) => {
              observer.next(this.finalizeResult(bufferData, false, false));
              observer.complete();
            });
          }
        }
      }
    }).pipe(
      finalize(() => { this.queryBufferHandler.decreaseApiCall(); })
    );
  }

  public makeQuery(): Observable<T> {
    return this.toReqObservable(this.makeObservable());
  }

  private callToApi() {
    const url = this.buildUrl(this.accion, this.queryParams);
    return this.makeRequest(this.method, url, this.payload, this.config.httpOptions);
  }

  private finalizeResult(data, fromCache, expectsForUpdate) {
    const filtered = this.applyClientFilters(data, this.clientParams, this.config.clientFilters);
    const mapped = this.mapResult(filtered, this.config.transform, this.config.each);
    return this.cache ? this.appendCacheInfo(mapped, fromCache, expectsForUpdate, this.cache) : mapped;
  }

  private needsCacheUpdate() {
    if (this.config.updateCache) {
      if (this.config.refreshTime) {
        const cacheDate = this.cache.getDate();
        const elapsedTime = Math.abs(new Date().getTime() - cacheDate.getTime()) / 1000;
        return elapsedTime > this.config.refreshTime;
      }
      return true;
    }
    return false;
  }

  private resultIsNotEmpty(data) {
    return !!data && (!Array.isArray(data) || !!data.length);
  }

  // OBS: Dependería de config.mergeUpdateMode cuando esté finalizado
  private mergeData(oldData, newData)
  {
    return newData;
    let result = oldData.reduce((acc, elem) => {
      const i = newData.findIndex(nElem => nElem.id === elem.id);
      const newElem = newData[i];
      if (newElem) {
        if (!('fechaBaja' in newElem) || newElem.fechaBaja === null) {
          acc.push(newElem);
        } else {
          // Simplemente ignora el push para que quede descartado
        }
        newData.splice(i, 1);
      } else {
        acc.push(elem);
      }
      return acc;
    }, []);
    // Agrega los elementos nuevos
    newData.forEach(newElem => {
      if (!('fechaBaja' in newElem) || newElem.fechaBaja === null) {
        result.push(newElem);
      }
    });
    return result;
  }

  private methodSendsPayload(method) {
    return !['get', 'delete'].includes(method);
  }

  private makeRequest<T>(method, url, payload = null, httpOptions: any = {}) // TODO: Definir interfaz de httpOptions
  {
    const methodSendsPayload = this.methodSendsPayload(method);
    const payloadAsHttpOption = (payload !== null && !methodSendsPayload);

    if (payloadAsHttpOption) {
      httpOptions.body = payload;
    }
    const result = methodSendsPayload
      ? this.http[method]<T>(url, payload, httpOptions)
      : this.http[method]<T>(url, httpOptions);

    return result;
  }

  /*
  |------------------------------------------------------------------
  | Funciones de filtros cliente
  |------------------------------------------------------------------
  */

  // OBS: Esto es desprolijo y posiblemente me traiga problemas cuando expanda su funcionamiento
  private toCompare(value) {
    if (value?.calendar) {
      return value.toISOString()
        .slice(0,-5); // Quita los milisegundos y la "Z" => '.000Z'
    }
    return value;
  }

  // (*3) TODO: También sería buena idea permitir inyectar filtros personalizados
  private clientFilters = {
    default: (val, ref) => this.toCompare(val) === this.toCompare(ref),
    substring: (val, ref) => this.h.strSimplify(val).includes(this.h.strSimplify(ref)),
    boolean: (val, ref) => !!this.toCompare(val) === !!this.toCompare(ref),
    min: (val, ref) => this.toCompare(val) >= this.toCompare(ref),
    max: (val, ref) => this.toCompare(val) <= this.toCompare(ref),
  };

  private filterListWithParams(input, queryParams, clientFilter) {
    const queryParamsKeys = Object.keys(queryParams);
    // console.log('FILTROS', {input, queryParams, queryParamsKeys, clientFilter});
    return queryParamsKeys.length
      ? input.filter(elem => {
        // console.log('-------------');
        const filtrados = queryParamsKeys.map(key => {
          let val = queryParams[key],
              filterName = clientFilter[key] || null; // TODO: Configurar como opcional 'default' o null
          // console.log('ELEM', {elem_val: elem[key], key, val, filterName});
          if (['', null, undefined].includes(val) || filterName === null) {
            return true;
          }
          else if (typeof filterName === 'string') {
            if (filterName.includes(':')) {
              [key, filterName] = filterName.split(':');
            }
            const criteriaIsArray = filterName.match(/(.*)\[\]$/);
            if (criteriaIsArray) {
              filterName = criteriaIsArray[1];
              return val.filter(elemVal => {
                return this.clientFilters[filterName](elem[key], elemVal);
              }).length;
            } else {
              return this.clientFilters[filterName](elem[key], val);
            }
          }
          else if (typeof filterName === 'function') {
            return filterName(elem[key], val, elem);
          }
        })
        // Si al menos uno falla, falla todo
        return !filtrados.filter(elem => !elem).length;
      })
      : input;
  }

  private applyClientFilters(apiResult, clientParams, clientFilters)
  {
    return this.filterListWithParams(apiResult, clientParams, clientFilters);
  }

  // Es posible aplicar las 2 funciones. Precede la general.
  private mapResult(data, transform, each)
  {
    const transformed = transform
      ? transform(data)
      : data;
    return Array.isArray(transformed) && each
      ? transformed.map(elem => each(elem))
      : transformed;
  }

  // OBS: Esta implementación todavía es pobre porque no funciona con resultados literales!!
  private appendToResultado(resultado, info)
  {
    for (let key in info) {
      resultado[key] = info[key];
    }
    return resultado;
  }

  // (*5) TODO: Agregar a info de cache, si se espera o no actualización, para
  // poder mostrar esa información en la vista
  private appendCacheInfo(resultado, fromCache, expectsForUpdate, cache)
  {
    const cacheDate = cache.getDate();
    return this.appendToResultado(resultado, { fromCache, cacheDate, expectsForUpdate });

    // ultMod = this.maxDate(filtered); // OBS: Eso creería que debe venir desde el modo de merge del update
  }

  // private maxDate(resultado) { return 'max date'; }


  /*
  |------------------------------------------------------------------
  | Inyección de comportamiento extra al observable
  |------------------------------------------------------------------
  */

  public addReqObservableFeatures<T>(auxReq: ReqObservable): ReqObservable
  {
    auxReq.flushCache = () => {
      this.cache?.clearData();
    };
    auxReq.cacheInfo = () => ({
      accion: this.accion,
      cacheDriver: this.cache?.cacheDriver,
      clientParams: this.clientParams,
      config: this.config,
      method: this.method,
      payload: this.payload,
      queryId: this.queryId,
      queryParams: this.queryParams,
    });
    return auxReq;
  }

  private toReqObservable(observable: Observable<T>): Observable<T>
  {
    const auxReq = this.addReqObservableFeatures(observable as ReqObservable);
    auxReq.pipe = (...argss: any[]) => { // TODO: Asignar tipado preciso
      let obs = Observable.prototype.pipe.call(auxReq, ...argss);
      return this.toReqObservable(obs);
    };
    return auxReq as Observable<T>;
  }

  // (*1): Implementar la personalización de merge de update
  // mergeUpdateMode: Método de Update cache (diferencial | completo)
  // diferencial: requiere =>
  // - pre-modif. de consulta a API
  // - func. ult.Fecha.Fecha
  // - post-modif. de consulta cliente
  // - campo de id x registro
}

class QueryBufferHandler {

  private static queriesBuffer: any = {};

  private queryId: string;

  constructor(queryId: string) {
    this.queryId = queryId;
  }

  public hasPreviousQuery() {
    return !!QueryBufferHandler.queriesBuffer[this.queryId];
  }

  public registerQueryBuffer<T>(apiCallSuscription: any) {
    QueryBufferHandler.queriesBuffer[this.queryId] = {
      subject: new Subject<T[]>(),
      apiCallSuscription,
      count: 1,
    };
  }

  public destroyQueryBuffer() {
    QueryBufferHandler.queriesBuffer[this.queryId] = null;
  }

  public sendToQueryBuffer<T>(data: any) {
    QueryBufferHandler.queriesBuffer[this.queryId].subject.next(data);
    this.destroyQueryBuffer();
  }

  public subscribeToQueryBuffer(fn: any) {
    QueryBufferHandler.queriesBuffer[this.queryId].count++;
    let subscription = QueryBufferHandler.queriesBuffer[this.queryId].subject.subscribe((data) => {
      subscription.unsubscribe();
      return fn(data);
    });
  }

  public decreaseApiCall() {
    if (this.hasPreviousQuery()) {
      QueryBufferHandler.queriesBuffer[this.queryId].count--;
      if (!QueryBufferHandler.queriesBuffer[this.queryId].count) {
        QueryBufferHandler.queriesBuffer[this.queryId].apiCallSuscription.unsubscribe();
        this.destroyQueryBuffer();
      }
    }
  }
}

class ApiRequestCacheGroupHandler {

  public cacheGroup: string;
  public cacheDriver: ICacheHandler;

  constructor(cacheGroup: string, cacheDriver: ICacheHandler) {
    this.cacheGroup = cacheGroup;
    this.cacheDriver = cacheDriver;
  }

  public groupInfoKey(group: string) {
    return `__apiRequestCache_group_${group}`;
  }

  public getGroupInfo(): string[] {
    return this.cacheDriver.get(this.groupInfoKey(this.cacheGroup)) || [];
  }

  private setGroupInfo(data: any) {
    return this.cacheDriver.set(this.groupInfoKey(this.cacheGroup), data);
  }

  public appendGroupInfo(info: any) {
    const currentInfo: string[] = this.getGroupInfo();
    return this.setGroupInfo(uniq([...currentInfo, info]));
  }
}

class ApiRequestCacheHandler {

  public queryId: string;
  public cacheDriver: ICacheHandler;

  constructor(queryId: string, cacheDriver: ICacheHandler) {
    this.queryId = queryId;
    this.cacheDriver = cacheDriver;
  }

  public get dataKey() {
    return `__apiRequestCache_${this.queryId}_data`;
  }

  public get dateKey() {
    return `__apiRequestCache_${this.queryId}_date`;
  }

  public getData()
  {
    return this.cacheDriver.get(this.dataKey);
  }

  public setData(data: any)
  {
    this.cacheDriver.set(this.dateKey, new Date().toISOString());
    return this.cacheDriver.set(this.dataKey, data);
  }

  public hasData()
  {
    return this.cacheDriver.hasValue(this.dataKey);
  }

  public clearData()
  {
    this.cacheDriver.remove(this.dateKey);
    this.cacheDriver.remove(this.dataKey);
  }

  public getDate()
  {
    return new Date(this.cacheDriver.get(this.dateKey));
  }
}

/**
 * OBS: Estos Helpers posiblemente vayan a una dependencia particular
 * pero mientras no esté definido, por el momento se incluyen acá
 */
class Helpers {

  strSimplify(input) {
    return input
      ?.trim()
      .toLowerCase()
      .replace(/[áàäâ]/g, 'a')
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u');
  }
}
