import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { ComprasFormasPagoDialogComponent } from '../compras-formas-pago-dialog/compras-formas-pago-dialog.component';
import { ComprasProductosDialogComponent } from '../compras-productos-dialog/compras-productos-dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-compras-gastos-nuevo',
  templateUrl: './compras-gastos-nuevo.component.html',
  styleUrls: ['./compras-gastos-nuevo.component.scss']
})
export class ComprasGastosNuevoComponent implements OnInit {

  nuevo: any = {
    idcompra: 0,
    idproveedor: 0,
    idtipocompra: 2,
    proveedorNombre: "",
    idsucursal: 0,
    sucursalNombre: "",
    importe: 0.0,
    descripcion: "",
    fecha: ""
  }

  listSucursales: any[] = [];
  listProveedores: any[] = [];

  agregados: any[] = [];
  formasDePago: any[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private dialog: MatDialog

  ) {
    
    this.nuevo.idcompra = Number(this.activatedRoute.snapshot.paramMap.get('idcompra'));

    if (this.nuevo.idcompra > 0){
      
      let datos = localStorage.getItem(`gastos-${this.nuevo.idcompra}`);
      if (datos != "" && datos != null){
        let d = JSON.parse(datos);

        console.log("d", d);

        this.nuevo.idcompra = d.idcompra,
        this.nuevo.idproveedor = d.idproveedor,
        this.nuevo.idtipocompra = 1,
        this.nuevo.proveedorNombre = d.proveedorDescripcion,
        this.nuevo.idsucursal = d.idsucursal,
        this.nuevo.sucursalNombre = d.sucursalDescripcion,
        this.nuevo.importe = d.importe,
        this.nuevo.descripcion = d.descripcion,
        this.nuevo.fecha = ""

        this.formasDePago = d.formasPago;


      }
      
    }

    this.apiService.getSucursalesList().subscribe((x: any) => {
      this.listSucursales = x;
    });

    this.apiService.getProveedoresList().subscribe((x: any[]) => {
      this.listProveedores = x;
    });
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    
    console.log("nuevo", this.nuevo);

    let importe = Number(this.nuevo.importe);

    if (this.nuevo.descripcion.length <= 4){
      this.snackBarService.errorMessage("Debe dar una breve descripción.");
      return;
    }    

    if (this.nuevo.idsucursal == 0){
      this.snackBarService.errorMessage("Debe proporcionar una sucursal.");
      return;
    }

    if (this.nuevo.idproveedor == 0){
      this.snackBarService.errorMessage("Debe proporcionar un proveedor.");
      return;
    }

    if (this.formasDePago.length == 0){
      this.snackBarService.errorMessage("Debe agregar al menos una forma de pago.");
      return;
    }

    if (importe <= 0){
      this.snackBarService.errorMessage("Los importes deben ser mayores a 0.");
      return;
    }

    let importeTotalFormaPago = 0;
    this.formasDePago.forEach(x => {
      importeTotalFormaPago += x.importe;
    });

    if (importe != importeTotalFormaPago){
      this.snackBarService.errorMessage("Los importes de las formas de pago no son iguales al importe total.");
      return;
    }

    let arrFormasPago: any[] = [];
    this.formasDePago.forEach(x => {
      arrFormasPago.push({
        idcompratipoprecio: x.idcompratipoprecio,
        importe: x.importe,
        observaciones: x.observaciones
      });
    });

    let itemCompra: any = {
      idproveedor: this.nuevo.idproveedor,
      idtipocompra: this.nuevo.idtipocompra,
      idsucursal: this.nuevo.idsucursal,
      importe: importe,
      descripcion: this.nuevo.descripcion,
      estado: 0
    }

    if (this.nuevo.idcompra == 0){
      this.apiService.postCompras(itemCompra).subscribe(x => {
        if (x != null){
          let idcompra = x.idcompra;
          this.apiService.postComprasFormaPagoBulk(idcompra, arrFormasPago).subscribe(z => {
            console.log("z", z);
            this.router.navigateByUrl('/gastos/abm', {});
          });
        }
      });
    }else{
      /*
      itemCompra.idcompra = this.nuevo.idcompra;
      this.apiService.putCompras(itemCompra, this.nuevo.idcompra).subscribe(x => {
        console.log("r put", x);
        if (x != null){
          let idcompra = x.idcompra;
          this.apiService.postComprasFormaPagoBulk(idcompra, arrFormasPago).subscribe(z => {
            console.log("z", z);
          });
        }
      });
      */
    }
    
  }

  clickBack(){
    this._location.back();
  }

  clickSeleccionarProveedor(){
    this.seleccionarProveedor();
  }

  clickAgregarFormaPago(){
    this.dialogFormaPago()
  }

  dialogFormaPago() {

    const dialogInfoConfig = new MatDialogConfig();

    console.log("dialog->formaDePago", this.formasDePago);

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      formasDePago: this.formasDePago,
      importe: Number(this.nuevo.importe)
    };

    const dialogRef = this.dialog.open(ComprasFormasPagoDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.formasDePago = r.formasDePago;
        }
      }
    });

  }

  seleccionarProveedor(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listProveedores.forEach(x => {
      let item = {
        id: x.idproveedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Proveedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Proveedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.nuevo.idproveedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.nuevo.idproveedor = rr.seleccion.id;
          this.nuevo.proveedorNombre = rr.seleccion.nombre;
        }
      }
    });

  }


}
