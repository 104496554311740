import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { concat, Observable } from 'rxjs';
import { SelectorGenericoComponent } from 'src/app/features/generico/selector-generico/selector-generico.component';
import { PreventasProductosModel } from 'src/app/models/preventas-productos-model';
import { ApiService } from 'src/app/services/api.service';
import { PreventaSelSucursalDialogComponent } from '../preventa-sel-sucursal-dialog/preventa-sel-sucursal-dialog.component';

@Component({
  selector: 'app-preventa-confirmar-dialog',
  templateUrl: './preventa-confirmar-dialog.component.html',
  styleUrls: ['./preventa-confirmar-dialog.component.scss']
})
export class PreventaConfirmarDialogComponent implements OnInit {

  displayedColumns: string[] = ['producto_descripcion', 'color_descripcion', 'medida_descripcion', 'cantidad', 'sucursal_descripcion', '_stock', '_info', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  cargando = false;

  producto: any;

  listSucursal: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PreventaConfirmarDialogComponent>,
    private dialog: MatDialog,
    private apiService: ApiService,
  ) {

    this.producto = null;

    this.apiService.getSucursalesList().subscribe(x => {
      this.listSucursal = x;
    });

  }

  ngOnInit(): void {

    this.producto = null;
    this.dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

    this.producto = JSON.parse(JSON.stringify(this.data.producto));

    this.dataSource.data = this.producto.productos;

    console.log("producto", this.producto);
    
    this.calcularStock();
    
  }

  calcularStock(){
    let obs: Observable<any[]>[] = []
    this.producto.productos.forEach((x: any) => {
      x._stock = "...";
      x._info = "..."
      obs.push(this.apiService.getPresentacionProductoStock(x.idproductopresentacion));
    });

    concat(...obs).subscribe((x: any) => {
      let p: any[] = this.producto.productos.filter((z: any) => z.idproductopresentacion == x.id);
      if (p.length > 0){
        p.forEach(y => {
          y.stock = x.valores;
          let sucursalBase = y.stock.find((suc: any) => suc.idsucursal == y.idsucursal);
          if (sucursalBase == null){
            y._stock = 0
          }else{
            y._stock = sucursalBase.cantidad;
          }
          let hayStock = false
          y.stock.forEach((zz: any) => {
            if (zz.cantidad > 0 && zz.idsucursal != y.idsucursal){
              hayStock = true
            }
          });
          if (hayStock){
            y._info = "Hay stock en otras sucursales";
          }else{
            y._info = "Sin stock en otras sucursales";
          }
        });
      }
    });

  }

  clickCerrar(){
    this.dialogRef.close({cierre: true});
  }

  clickConfirmar(){

    let lote: PreventasProductosModel[] = [];
    
    this.producto.productos.forEach((x: any) => {
      let n: PreventasProductosModel = {
        idpreventaproducto: x.idpreventaproducto,
        idpreventa: this.producto.idpreventa,
        idproducto: x.idproducto,
        observaciones: "",
        cantidad: x.cantidad,
        precio_unitario: x.precio,
        idproductopresentacion: x.idproductopresentacion,
        idsucursal: x.idsucursal,
        importe_descuento: x.importe_descuento,
        porcentaje_descuento: x.porcentaje_descuento
      }
      lote.push(n);
    });

    let loteStock: any[] = [];
    this.producto.productos.forEach((x: any) => {
      let n: any = {
        cantidad: x.cantidad,
        idproductopresentacion: x.idproductopresentacion,
        idsucursal: x.idsucursal
      }
      loteStock.push(n);
    });

    this.cargando = true
    this.apiService.postPreventasProductosBulk(lote).subscribe(x => {
      this.apiService.patchPreventa(this.producto.idpreventa, {estado: 1}).subscribe(xx => {
        this.apiService.postPresentacionesSucursalesRestaStock(loteStock).subscribe(xxx => {
          this.dialogRef.close({confirma: true});
        });
      });
    });

  } 

  clickCambiar(e: any){

    console.log("e", e);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursal.forEach((x: any) => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion,
        cantidad: 0
      }
      coleccion.push(item);
    });

    e.stock.forEach((x: any) => {
      let i = coleccion.find(z => z.id == x.idsucursal);
      if (i != null){
        i.cantidad = x.cantidad;
      }
    });

    dialogConfig.data = {
      coleccion: coleccion,
      idSeleccionPrevia: e.idsucursal,
      cantidadActual: e.cantidad,
      sucursalDescripcionActual: e.sucursal_descripcion
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(PreventaSelSucursalDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr", rr);
      if (rr.cierre){
        if (rr.movimiento.length > 0){
          e.cantidad = rr.movimiento[0].cantidad;

          let p = this.producto.productos.find((z: any) => z.idproductopresentacion == e.idproductopresentacion && z.idsucursal == rr.movimiento[1].idsucursal);

          if (p != null){
            //existe se suma
            p.cantidad = p.cantidad + rr.movimiento[1].cantidad;
          }else{
            //no existe se crea
            let nuevo = {...e};
            nuevo.idsucursal = rr.movimiento[1].idsucursal;
            let s = this.listSucursal.find(xxx => xxx.idsucursal == nuevo.idsucursal);
            nuevo.sucursal_descripcion = s.descripcion;
            nuevo.cantidad = rr.movimiento[1].cantidad;
            nuevo.idpreventaproducto = 0;
            this.producto.productos.push(nuevo);

            this.dataSource.data = this.producto.productos;
          }
          
          this.calcularStock();

        }
      }
    });

  }

  clickEliminar(e: any){

  }

}

const ELEMENT_DATA: any[] = [];
