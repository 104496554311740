import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalidadesModel } from 'src/app/models/localidades-model';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';


@Component({
  selector: 'app-localidades-nuevo',
  templateUrl: './localidades-nuevo.component.html',
  styleUrls: ['./localidades-nuevo.component.scss']
})
export class LocalidadesNuevoComponent implements OnInit {

  nuevo: LocalidadesModel = {
    idlocalidad: 0,
    nombre: "",
    codigo_postal: ""
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location
  ) {
    this.nuevo.idlocalidad = Number(this.activatedRoute.snapshot.paramMap.get('idlocalidad'));

    console.log("nuevo", this.nuevo);

    if (this.nuevo.idlocalidad > 0){
      this.apiService.getLocalidades(this.nuevo.idlocalidad).subscribe(x => {
        this.nuevo = x;
      })
    }
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);
    if (this.nuevo.nombre.length > 2){
      if (this.nuevo.idlocalidad == 0){
        this.apiService.postLocalidades(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero la localidad correctamente");
          this.router.navigateByUrl('general/localidades/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        
        this.apiService.putLocalidades(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo la localidad correctamente");
          this.router.navigateByUrl('/general/localidades/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo nombre debe tener mas de 2 caracteres");
    }

  }
 

  clickBack(){
    this._location.back();
  }

}
