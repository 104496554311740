import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest } from 'rxjs';
import { ClientesCompletoModel } from 'src/app/models/clientes-completo-model';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { ExcelService } from 'src/app/services/excel.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { formatNumeroNacional, toFechaCorta, toFechaCortaSoloFecha, Utiles } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';

@Component({
  selector: 'app-caja-diaria-reporte',
  templateUrl: './caja-diaria-reporte.component.html',
  styleUrls: ['./caja-diaria-reporte.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class CajaDiariaReporteComponent implements OnInit {

  expandedElement: any | null;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  displayedColumnsR: string[] = ['forma_pago', 'cantidad', 'importe'];


  displayedColumns: string[] = ['idpreventa', 'sucursal', 'forma_pago', 'fecha', 'importe'];
  displayedColumnsTotal: string[] = ['total'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  dataSourceR = new MatTableDataSource<any>(ELEMENT_DATA);

  listVendedores: VendedoresModel[] = [];
  
  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  listCliente: ClientesCompletoModel[] = [];
  listSucursales: any[] = [];

  cliente:ClientesCompletoModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    codigo_localidad: 0,
    nombre_localidad: "",
    codigo_postal: "",
    telefono: "",
    correo_electronico: "",
    observaciones: "",
  };

  total = 0.0;
  totalComision = 0.0;

  lastParam: any = null;

  detalle = false;
  detalleFormaPago = false;
  detalleComision = false;

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  listFormaPago = [];

  listTipoPrecio = [];

  sucursalId = 0;
  sucursalNombre = "";

  cargando = false;

  fcNum = formatNumeroNacional;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private pdfService: PdfService,
    private sharedService: SharedService,
    private snackBarService: SnackBarService,
    private _excelService: ExcelService,
    private _permissionsService: NgxPermissionsService
  ) {
    moment.locale('es');

    this.sucursalActual = this.sharedService.getSucursalActual();

  }

  ngOnInit(): void {

    this.apiService.getVendedoresList().subscribe((x: any[]) => {
      this.listVendedores = x;
    });

    this.apiService.getClientesCompletoList().subscribe((x: any[]) => {
      this.listCliente = x;
    });

    this.apiService.getSucursalesList().subscribe(x => {
      this.listSucursales = x;
    })

  }

  clickConsultar(){

 
    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    mHasta.set({hour:23,minute:59,second:59,millisecond:0});

    let sDesde = mDesde.format("YYYY-MM-DD HH:mm:ss");
    let sHasta = mHasta.format("YYYY-MM-DD HH:mm:ss");

    console.log("desde", sDesde);
    console.log("hasta", sHasta);


    let sId = this.sucursalActual.sucursalId;
    let sNom = this.sucursalActual.sucursalNombre;

    let p = this._permissionsService.getPermissions();

    if ('10' in p){
      sId = this.sucursalId;
      sNom = this.sucursalNombre;
    }

    let param = {
      vendedorId: this.vendedor.idvendedor,
      clienteId: this.cliente.idcliente,
      sucursalId: sId,
      sucursalNombre: sNom,
      fecha: {
        desde: sDesde != 'Fecha inválida' ? sDesde : null,
        hasta: sHasta != 'Fecha inválida' ? sHasta : null
      }
    }

    if (param.fecha.desde == null || param.fecha.hasta == null) {
      if (!(param.fecha.desde == null && param.fecha.hasta == null)){
        this.snackBarService.errorMessage("Debe proporcionar un rango de fechas completo");
        return;
      }
    }

    this.lastParam = JSON.parse(JSON.stringify(param));

    const obsPostReporteCajaDiaria = this.apiService.postReporteCajaDiaria(param);

    this.cargando = true;
    
    combineLatest([obsPostReporteCajaDiaria]).subscribe((h: any[]) => {
    //this.apiService.postPreventasReporteGeneral(param).subscribe((col: any[]) => {
      let d: any[] = h[0];

      console.log("data", d);

      let data: any[] = [];
      let totalCalc = 0.0;

      d.forEach(x => {

        let recargo = ((x.importe * x.recargo) /100);
        let conRecargo = Number((x.importe + recargo).toFixed(2));

        let item = {
          idpreventa: x.idpreventa,
          formaPago: x.tipo_descripcion,
          fecha: x.fecha,
          sucursalNombre: x.sucursal_nombre,
          _fecha: toFechaCorta(x.fecha),
          _importeConRecargo: conRecargo,
          importe: x.importe

        }
        data.push(item);

        totalCalc = totalCalc + item._importeConRecargo;

      });

      let m = new Map();

      data.forEach(x => {
        if (m.has(x.formaPago)){
          let i = m.get(x.formaPago);
          i.importe = i.importe + x.importe;
          i._importeConRecargo = i._importeConRecargo + x._importeConRecargo;
          i.cantidad = i.cantidad + 1;
          m.set(x.formaPago, i);
        }else{
          let i = {
            formaPago: x.formaPago,
            importe: x.importe,
            _importeConRecargo: x._importeConRecargo,
            cantidad: 1
          }
          m.set(x.formaPago, i);
        }
      });

      this.dataSourceR.data = Array.from(m.values());

      this.total = totalCalc;
            
      this.dataSource.data = data;

      this.lastParam = param;

      if (data.length == 0){
        this.snackBarService.errorMessage("No hay datos para los parametros ingresados");
      }

      this.cargando = false;

    });

    

  }

  clickSeleccionarCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listCliente.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: true,
      referenciaNuevo: "cliente"
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          let clienteSel = this.listCliente.find(x => x.idcliente == rr.seleccion.id);

          if (clienteSel == null){
            this.apiService.getClientesCompletoList().subscribe(x => {
              this.listCliente = [];
              this.listCliente = x;
              let cs = this.listCliente.find(x => x.idcliente == rr.seleccion.id);
              this.cliente = cs as ClientesCompletoModel;
            });
          }else{
            this.cliente = clienteSel as ClientesCompletoModel
          }

        }else{
          this.cliente = {
            idcliente: 0,
            nombre: "",
            domicilio: "",
            codigo_localidad: 0,
            nombre_localidad: "",
            codigo_postal: "",
            telefono: "",
            correo_electronico: "",
            observaciones: "",
          };
        }
        
      }
    });
  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }
        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }
      }
    });
  }

  clickExportar(){

    if (this.detalle){
      if (this.detalleComision){
        this.exportarDetalleConComsion();
      }else{
        this.exportarDetalle();
      }
      return;
    }

    let param = this.lastParam;

    const sizeTextoDatoTabla = 10;
    const sizeTextoTituloTabla = 11;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = this.sucursalActual.sucursalNombre;

    let preventas: any = [
      [
        {text: 'id', fontSize: sizeTextoTituloTabla}, 
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, 
        {text: 'Cliente', fontSize: sizeTextoTituloTabla}, 
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla},
        {text: 'Importe', fontSize: sizeTextoTituloTabla}
      ]
    ];

    let data = this.dataSource.data;

    data.forEach(x => {
      let _fecha = toFechaCorta(x.fecha);

      let c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let c1 = {text: _fecha, fontSize: sizeTextoDatoTabla};
      let c2 = {text: x.cliente, fontSize: sizeTextoDatoTabla};
      let c3 = {text: x.vendedor, fontSize: sizeTextoDatoTabla};
      let c4 = {text: this.fcNum(x.importe), alignment: 'right', fontSize: sizeTextoDatoTabla};

      let r = [c0, c1, c2, c3, c4];
      
      preventas.push(r);
    });

    let tableWidths = [35, '*', '*', '*', '*'];

    preventas.push(['', '', '', {text: 'TOTAL', bold: true}, {text: this.total.toFixed(2), alignment: 'right', bold: true}]);
    
    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, 10] },

      preventas.length > 1 ? 
      {
        table: {
          headerRows: 1,
          widths: tableWidths,
          body: preventas
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Ventas`, fontSize: 11, margin:[10, 0, 0, 10] },
     

    ]

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 20, 0, 0 ],
          table: {
            headerRows: 1,
            
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }

    this.pdfService.generarPDF(content);

  }

  exportarDetalle(){
    let param = this.lastParam;

    const sizeTextoDatoTabla = 10;
    const sizeTextoTituloTabla = 11;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${toFechaCortaSoloFecha(param.fecha.desde)} Hasta: ${toFechaCortaSoloFecha(param.fecha.hasta)}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = this.sucursalActual.sucursalNombre;

    let data = this.dataSource.data;

    let tablaComun: any[] = []

    data.forEach((x: any) => {

      tablaComun.push([
        {text: 'id', fontSize: sizeTextoTituloTabla}, //1
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, //2
        {text: 'Cliente', fontSize: sizeTextoTituloTabla, colSpan: 2}, //3
        '', //4
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla, colSpan: 2}, //5
        '', //6
        {text: 'Importe', fontSize: sizeTextoTituloTabla, colSpan: 2}, //7
        '' //8
      ]);

      let t0c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let t0c1 = {text: toFechaCorta(x.fecha), fontSize: sizeTextoDatoTabla};
      let t0c2 = {text: x.cliente, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c3 = {text: x.vendedor, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c4 = {text: this.fcNum(x.importe), fontSize: sizeTextoDatoTabla, alignment: 'right', colSpan: 2};
     
      tablaComun.push([t0c0, t0c1, t0c2, '', t0c3, '', t0c4, '']);

      tablaComun.push([
        '', 
        {text: 'Articulo', fontSize: sizeTextoTituloTabla, colSpan: 4},
        '',
        '',
        '',
        {text: 'Cantidad', fontSize: sizeTextoTituloTabla}, 
        {text: 'Precio Total', fontSize: sizeTextoTituloTabla},
        ''
      ]);
      x.articulos.forEach((y: any) => {

        let t1c2 = {text: y.articulo, fontSize: sizeTextoDatoTabla, colSpan: 4};
        let t1c3 = {text: y.cantidad, fontSize: sizeTextoDatoTabla};
        let t1c4 = {text: this.fcNum(y.precioTotal), fontSize: sizeTextoDatoTabla};
        

        tablaComun.push(['', t1c2, '', '', '', t1c3, t1c4, '']);
      });

    });

    tablaComun.push([
      '', 
      '', 
      '', 
      '',
      '',
      '',
      {text: 'TOTAL', bold: true}, 
      {text: this.fcNum(this.total), alignment: 'right', bold: true}
    ]);

    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, 10] }
    ]

    content.push(
      {
        table: {
          headerRows: 1,
          widths: [25, 55, '*', '*', '*', '*', '*', '*'],
          body: tablaComun
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      });

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 20, 0, 0 ],
          table: {
            headerRows: 1,
            
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }
    

    this.pdfService.generarPDF(content);

  }

  exportarDetalleConComsion(){
    let param = this.lastParam;

    const sizeTextoDatoTabla = 10;
    const sizeTextoTituloTabla = 11;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${toFechaCortaSoloFecha(param.fecha.desde)} Hasta: ${toFechaCortaSoloFecha(param.fecha.hasta)}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = this.sucursalActual.sucursalNombre;

    let data = this.dataSource.data;

    let tablaComun: any[] = []

    data.forEach((x: any) => {

      tablaComun.push([
        {text: 'id', fontSize: sizeTextoTituloTabla}, //1
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, //2
        {text: 'Cliente', fontSize: sizeTextoTituloTabla, colSpan: 2}, //3
        '', //4
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla, colSpan: 2}, //5
        '', //6
        {text: 'Comisión', fontSize: sizeTextoTituloTabla}, //5
        {text: 'Importe', fontSize: sizeTextoTituloTabla, colSpan: 2}, //7
        '' //8
      ]);

      let t0c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let t0c1 = {text: toFechaCorta(x.fecha), fontSize: sizeTextoDatoTabla};
      let t0c2 = {text: x.cliente, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c3 = {text: x.vendedor, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c4 = {text: this.fcNum(x.comision), fontSize: sizeTextoDatoTabla};
      let t0c5 = {text: this.fcNum(x.importe), fontSize: sizeTextoDatoTabla, alignment: 'right', colSpan: 2};
     
      tablaComun.push([t0c0, t0c1, t0c2, '', t0c3, '', t0c4, t0c5, '']);

      tablaComun.push([
        '', 
        {text: 'Articulo', fontSize: sizeTextoTituloTabla, colSpan: 4},
        '',
        '',
        '',
        '',
        {text: 'Cantidad', fontSize: sizeTextoTituloTabla}, 
        {text: 'Precio Total', fontSize: sizeTextoTituloTabla},
        ''
      ]);
      x.articulos.forEach((y: any) => {

        let t1c2 = {text: y.articulo, fontSize: sizeTextoDatoTabla, colSpan: 4};
        let t1c3 = {text: y.cantidad, fontSize: sizeTextoDatoTabla};
        let t1c4 = {text: this.fcNum(y.precioTotal), fontSize: sizeTextoDatoTabla};
        

        tablaComun.push(['', t1c2, '', '', '', '', t1c3, t1c4, '']);
      });

    });

    tablaComun.push([
      '', 
      '', 
      '', 
      '',
      '',
      '',
      '',
      {text: 'TOTAL', bold: true}, 
      {text: this.fcNum(this.total), alignment: 'right', bold: true}
    ]);

    tablaComun.push([
      '', 
      '', 
      '', 
      '',
      '',
      '',
      '',
      {text: 'TOTAL COMISION', bold: true}, 
      {text: this.fcNum(this.totalComision), alignment: 'right', bold: true}
    ]);

    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, 10] }
    ]

    content.push(
      {
        table: {
          headerRows: 1,
          widths: [25, 55, '*', '*', '*', '*', '*', '*', '*'],
          body: tablaComun
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      });

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 20, 0, 0 ],
          table: {
            headerRows: 1,
            
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }
    

    this.pdfService.generarPDF(content);

  }

  generarTablaDetalleFormaPago(sizeTextoTituloTabla: number, sizeTextoDatoTabla: number){
    let tiposId: number[] = [];
    this.listTipoPrecio.forEach((x: any) => {
      tiposId.push(x.idtipo);
    });

    let tablaFormaPago: any[] = [
      [
        {text: 'Forma de Pago', fontSize: sizeTextoTituloTabla}, 
        {text: 'Cantidad de Operaciones', fontSize: sizeTextoTituloTabla}, 
        {text: 'Importe', fontSize: sizeTextoTituloTabla}
      ]];
    
    tiposId.forEach(tid => {
      let fps = this.listFormaPago.filter((fp: any) => fp.idtipo == tid);
      
      if (fps.length > 0){
        let k = 0;
        let sum = 0;
        let nombre = "";
        fps.forEach((fp: any) => {
          nombre = fp.tipo_descripcion;
          sum = sum + fp.importe;
          k++;
        });

        let row = [
          {text: nombre, fontSize: sizeTextoDatoTabla}, 
          {text: k, fontSize: sizeTextoDatoTabla}, 
          {text: sum, fontSize: sizeTextoDatoTabla}
        ];
        tablaFormaPago.push(row);
      }
    });

    return tablaFormaPago;
  }

  exportarExcel(){
    let exporta: any[] = [];

    let data = this.dataSource.data;

    if (data.length == 0){
      return;
    }

    data.forEach(r => {
      let row: any = {
        "Id Venta": r.idpreventa,
        "Forma de Pago": r.formaPago,
        "Fecha": r._fecha,
        "Importe": Number(r._importeConRecargo),
      }
      exporta.push(row);
    });

    exporta.push({"Id Venta": 'TOTAL', "Forma de Pago": '', "Fecha": '', 'Importe': this.fcNum(this.total)});

    if (exporta.length > 0) {
      this._excelService.exportAsExcelFile(
        exporta,
        'reporte-caja-diaria',
        false,
        [],
        ''
      );
    } else {
      
      /*
      Swal.fire({
        title: 'No hay datos para exportar',
        text: 'Los datos que se muestran son los que se exportan a Excel, actualmente no hay datos',
        icon: 'info',
      });
      */
    }
  }

  exportarPDF(){
    
    
    let periodo = `Desde: ${this.lastParam.desde != '' ? this.lastParam.fecha.desde : '*'} Hasta: ${this.lastParam.fecha.hasta != '' ? this.lastParam.fecha.hasta : '*'}`;

    let sucursal = this.lastParam.sucursalNombre;

    let datos: any = [
      [
        {text: `Id Venta`, fontSize: 10}, 
        {text: `Forma de Pago`, fontSize: 10}, 
        {text: `Fecha`, fontSize: 10}, 
        {text: `Importe`, fontSize: 10}, 
      ]
    ];

    let data = this.dataSource.data;

    data.forEach(x => {

      let idVenta = {text: `${x.idpreventa}`, alignment: 'left', fontSize: 8.5};
      let formaPago = {text: `${x.formaPago}`, alignment: 'left', fontSize: 8.5};
      let fecha = {text: `${x._fecha}`, alignment: 'left', fontSize: 8.5};
      let importe = {text: `${this.fcNum(x._importeConRecargo)}`, alignment: 'right', fontSize: 8.5};
      
      let r = [idVenta, formaPago, fecha, importe];
      datos.push(r);
    });

    datos.push([
      {text: `TOTAL`, alignment: 'left', fontSize: 8.5},
      {text: ``, alignment: 'left', fontSize: 8.5},
      {text: ``, alignment: 'left', fontSize: 8.5},
      {text: `${this.fcNum(this.total)}`, alignment: 'right', fontSize: 8.5}
    ]);

    console.log("datos", datos);

    let content: any[] = [
      { text: `Caja Diaria - Reporte Detallado`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },

      datos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: datos
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Datos`, fontSize: 11, margin:[10, 0, 0, 10] },
     

    ]

    this.pdfService.generarPDF(content);

  }

  exportarExcel0(){
    let exporta: any[] = [];

    let data = this.dataSourceR.data;

    if (data.length == 0){
      return;
    }

    data.forEach(r => {
      let row: any = {
        "Forma de Pago": r.formaPago,
        "Cantidad": r.cantidad,
        "Importe": Number(r._importeConRecargo),
      }
      exporta.push(row);
    });

    exporta.push({"Forma de Pago": 'TOTAL', "Cantidad": '', 'Importe': this.total});

    if (exporta.length > 0) {
      this._excelService.exportAsExcelFile(
        exporta,
        'reporte-caja-diaria-resumen',
        false,
        [],
        ''
      );
    } else {
      
      /*
      Swal.fire({
        title: 'No hay datos para exportar',
        text: 'Los datos que se muestran son los que se exportan a Excel, actualmente no hay datos',
        icon: 'info',
      });
      */
    }
  }

  exportarPDF0(){
    
    
    let periodo = `Desde: ${this.lastParam.desde != '' ? this.lastParam.fecha.desde : '*'} Hasta: ${this.lastParam.fecha.hasta != '' ? this.lastParam.fecha.hasta : '*'}`;

    let sucursal = this.lastParam.sucursalNombre;

    let datos: any = [
      [
        {text: `Forma de Pago`, fontSize: 10}, 
        {text: `Cantidad`, fontSize: 10}, 
        {text: `Importe`, fontSize: 10}, 
      ]
    ];

    let data = this.dataSourceR.data;

    data.forEach(x => {

      let formaPago = {text: `${x.formaPago}`, alignment: 'left', fontSize: 8.5};
      let cantidad = {text: `${x.cantidad}`, alignment: 'left', fontSize: 8.5};
      let importe = {text: `${this.fcNum(x._importeConRecargo)}`, alignment: 'right', fontSize: 8.5};
      
      let r = [formaPago, cantidad, importe];
      datos.push(r);
    });

    datos.push([
      {text: `TOTAL`, alignment: 'left', fontSize: 8.5},
      {text: ``, alignment: 'left', fontSize: 8.5},
      {text: `${this.fcNum(this.total, 2)}`, alignment: 'right', fontSize: 8.5}
    ]);

    console.log("datos", datos);

    let content: any[] = [
      { text: `Caja Diaria - Reporte Simplificado`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },

      datos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*'],
          body: datos
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Datos`, fontSize: 11, margin:[10, 0, 0, 10] },
     

    ]

    this.pdfService.generarPDF(content);

  }

  exportarAgrupadoPDF(){
    
    
    let periodo = `Desde: ${this.lastParam.desde != '' ? this.lastParam.fecha.desde : '*'} Hasta: ${this.lastParam.fecha.hasta != '' ? this.lastParam.fecha.hasta : '*'}`;

    let sucursal = this.lastParam.sucursalNombre;

    let data = this.dataSource.data;

    let formaPagoMap = new Map();

    let totalFinal = 0;

    data.forEach(x => {
      if (formaPagoMap.has(x.formaPago)){
        let o = formaPagoMap.get(x.formaPago);
        o.total = o.total + x._importeConRecargo;
        o.ventas.push(x);
        formaPagoMap.set(x.formaPago, o);
      }else{
        let o = {
          total: x._importeConRecargo,
          ventas: [x]
        }
        formaPagoMap.set(x.formaPago, o);
      };
      totalFinal = totalFinal + x._importeConRecargo;
    });

    let tablas = [];

    for (const [key, value] of formaPagoMap.entries()) {

      let tituloFormaPago = { text: `${key}`, fontSize: 11, margin:[0, 10, 0, 10] };
      let formatoTabla: any = { text: `Sin Datos`, fontSize: 11, margin:[10, 0, 0, 10] };

      if (value.ventas.length > 0){

        let datosTabla: any = [
          [
            {text: `Id Venta`, fontSize: 10}, 
            {text: `Fecha`, fontSize: 10}, 
            {text: `Importe`, fontSize: 10}, 
          ]
        ];
      
        value.ventas.forEach(v => {
          let idVenta = {text: `${v.idpreventa}`, alignment: 'left', fontSize: 8.5};
          let fecha = {text: `${v._fecha}`, alignment: 'left', fontSize: 8.5};
          let importe = {text: `${this.fcNum(v._importeConRecargo)}`, alignment: 'right', fontSize: 8.5};
          
          let r = [idVenta, fecha, importe];
          datosTabla.push(r);
        });

        datosTabla.push([
          {text: `TOTAL`, alignment: 'left', fontSize: 8.5},
          {text: ``, alignment: 'left', fontSize: 8.5},
          {text: `${this.fcNum(value.total)}`, alignment: 'right', fontSize: 8.5}
        ]);

        formatoTabla = {
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],
            body: datosTabla
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        }

      }

      tablas.push(tituloFormaPago);
      tablas.push(formatoTabla);

    }

    let content: any[] = [
      { text: `Caja Diaria - Reporte Agrupado por Forma Pago`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },

      ...tablas,
      { text: ``, fontSize: 11, margin:[0, 10, 0, 10] },
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {text: `Total`, fontSize: 11}, 
              {text: ``, fontSize: 11}, 
              {text: ``, fontSize: 11}, 
              {text: `${this.fcNum(totalFinal)}`, alignment: 'right', fontSize: 11}, 
            ]
          ]
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      }

    ]

    this.pdfService.generarPDF(content);

  }

  clickSeleccionarSucursal(){

    let idSeleccionPrevia  = 0;
    idSeleccionPrevia = this.sucursalId;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: idSeleccionPrevia,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.sucursalId != rr.seleccion.id){
            this.sucursalId = 0;
            this.sucursalNombre = "";
          }
          this.sucursalId = rr.seleccion.id;
          this.sucursalNombre = rr.seleccion.nombre;
          
        }else{
          this.sucursalId = 0;
          this.sucursalNombre = "";
        }
      }
    });
  }

  clickClear(){
    this.sucursalId = 0;
    this.sucursalNombre = "";
  }

}

const ELEMENT_DATA: any[] = [];

