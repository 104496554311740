<mat-dialog-content>

<div style="display: flex; flex-direction: column; max-width: 350px;">

    <mat-form-field>
        <mat-label>Tipo de Precio</mat-label>
        <mat-select [(ngModel)]="item.idtipo" (selectionChange)="selectTipoPrecio($event)">
            <mat-option *ngFor="let tipo of listTipoPrecio" [value]="tipo.idtipo">{{tipo.descripcion}} / {{tipo.recargo}}%</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Cantidad</mat-label>
        <input matInput  placeholder="Cantidad" type="number" [(ngModel)]="item.cantidad">
    </mat-form-field>

</div>

</mat-dialog-content>

<mat-dialog-actions>

    <div style="display: flex; flex-direction: row-reverse; width: 100%;">
      <button mat-button (click)="clickCerrar()">Cerrar</button>
      <button mat-button (click)="clickGuardar()">Guardar</button>
    </div>
    
</mat-dialog-actions>