<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="margin-bottom: 16px;">
        <button mat-raised-button color="primary" (click)="clickNuevo()">Nueva Localidad</button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idlocalidad">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idlocalidad}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="codigo_postal">
            <th mat-header-cell *matHeaderCellDef> Codigo Postal </th>
            <td mat-cell *matCellDef="let element"> {{element.codigo_postal}} </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" (click)="clickEditar(element)">Editar</button> 
                <button mat-raised-button class="boton-celda" (click)="clickEliminar(element)">Eliminar</button> 
            </td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    

</div>