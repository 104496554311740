import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SharedService } from 'src/app/services/shared.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { toFechaApiRequest, toFechaCorta, toFechaLarga } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { EntregasConfirmarDialogComponent } from '../../ventas/entregas-confirmar-dialog/entregas-confirmar-dialog.component';

@Component({
  selector: 'app-logistica-solicitud',
  templateUrl: './logistica-solicitud.component.html',
  styleUrls: ['./logistica-solicitud.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class LogisticaSolicitudComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  displayedColumns: string[] = ['id', 'producto', 'cantidad', 'origen', 'destino', 'estado', 'fecha', 'venta', 'observaciones', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  // 0 pendiente, 1 confirmado, 2 pagados

  tipo = ['Movimiento', 'Reposición']
  estados = ['Pendiente', 'Atendido'];

  vals: any[] = [];

  modo: string | null = null;

  descripcion = "Solicitudes de Movimientos y Reposiciones"

  filtro = {
    sucursalOrigenNombre: "",
    sucursalOrigenId: 0,
    sucursalDestinoNombre: "",
    sucursalDestinoId: 0,
  }

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  listSucursales: any[] = [];

  param = {
    desde: "",
    hasta: "",
    origen: "",
    destino: ""
  }

  cargando = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private pdfService: PdfService
  ) {
    moment.locale('es');

    let m = this.activatedRoute.snapshot.paramMap.get('modo')

    if (m != null){
      this.modo = m.toLocaleLowerCase();
    }

    if (this.modo == "movimientos"){
      this.descripcion = "Solicitud de Movimientos";
    }

    if (this.modo == "reposiciones"){
      this.descripcion = "Solicitud de Reposiciones";
    }

    this.sucursalActual = this.sharedService.getSucursalActual();

  }

  ngOnInit(): void {
    this.cargarTabla(1);
  }

  cargarTabla(estado?: number){

    this.vals = [];
    this.dataSource.data = this.vals;

    let solicitudes = this.apiService.getLogisticaSolicitudes(this.sucursalActual.sucursalId, null, null, null, null);
    let sucursales = this.apiService.getSucursalesList();

    this.cargando = true;

    combineLatest([solicitudes, sucursales]).subscribe((hh: any[]) =>{

      this.procesarSolicitudes(hh[0]);
      this.listSucursales = hh[1];

      this.cargando = false;

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener datos de logistica. " + e.message);
      this.cargando = false;
    })

    
  }

  procesarSolicitudes(data: any[]){

    let p: any[] = data;

    if (this.modo != null){

      if (this.modo == "movimientos"){
        p = p.filter(x => x.tipo == 0) // 0 movimiento - 1 reposicion  
      }

      if (this.modo == "reposiciones"){
        p = p.filter(x => x.tipo == 1) // 0 movimiento - 1 reposicion
      }
      
    }

    p.forEach(x => {
      x._tipo = this.tipo[x.tipo];
      x._estado = this.estados[x.estado];
      x._venta = x.idventa == null ? "" : x.idventa;
      x._fecha = x.fecha != null ? toFechaCorta(x.fecha) : "";
      x._fechax = x.fecha != null ? toFechaLarga(x.fecha) : "";
    });

    this.vals = p;

    console.log("data", this.vals);

    this.dataSource.data = this.vals;

  }

  clickEditar(e: any, event: any){
    event.stopPropagation();

  }

  clickAtendido(e: any, event: any){
    event.stopPropagation();

    console.log("e", e);

    let fc = () => {
      this.apiService.postLogisticaSolicitudesEstado(e.idlogisticasolicitud, 1).subscribe(x => {
        if (x){
          e.estado = 1;
          e._estado = this.estados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a marcar la solicitud como atendida, esto indica que ya tomo las medidas correspondientes relacionadas con la solicitud. Desea continuar?", fc);


  }
  
  dialogAccion(titulo: string, mensaje: string, fc: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        fc()
      } 
    });

  }

  clickClear(tipo: string){

    if (tipo == "origen"){
      this.filtro.sucursalOrigenId = 0;
      this.filtro.sucursalOrigenNombre = "";
    }

    if (tipo == "destino"){
      this.filtro.sucursalDestinoId = 0;
      this.filtro.sucursalDestinoNombre = "";
    }

  }

  clickConsultar(){

    this.dataSource.data = [];

    let origen = this.filtro.sucursalOrigenId == 0 ? null : this.filtro.sucursalOrigenId;
    let destino = this.filtro.sucursalDestinoId == 0 ? null : this.filtro.sucursalDestinoId;

    this.param.origen = this.filtro.sucursalOrigenNombre;
    this.param.destino = this.filtro.sucursalDestinoNombre;

    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    let desde = null;
    let hasta = null;

    if (mDesde.isValid()){
      desde = toFechaApiRequest(mDesde.toDate());
      this.param.desde = toFechaCorta(mDesde.toDate());
    }else{
      this.param.desde = "";
    }

    if (mHasta.isValid()){
      hasta = toFechaApiRequest(mHasta.toDate());
      this.param.hasta = toFechaCorta(mHasta.toDate());
    }else{
      this.param.hasta = "";
    }

    

    let solicitudes = this.apiService.getLogisticaSolicitudes(this.sucursalActual.sucursalId, origen, destino, desde, hasta );
    combineLatest([solicitudes]).subscribe((hh: any[]) =>{

      this.procesarSolicitudes(hh[0]);

    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener datos de logistica. " + e.message);
    })

  }

  clickSeleccionarSucursal(tipo: string){

    let idSeleccionPrevia  = 0;
    if (tipo == 'origen'){
      idSeleccionPrevia = this.filtro.sucursalOrigenId;
    }
    if (tipo == 'destino'){
      idSeleccionPrevia = this.filtro.sucursalDestinoId;
    }


    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: idSeleccionPrevia,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          if (tipo == "origen"){
            if (this.filtro.sucursalOrigenId != rr.seleccion.id){
              this.filtro.sucursalOrigenId = 0;
              this.filtro.sucursalOrigenNombre = "";
            }
            this.filtro.sucursalOrigenId = rr.seleccion.id;
            this.filtro.sucursalOrigenNombre = rr.seleccion.nombre;
          }

          if (tipo == "destino"){
            if (this.filtro.sucursalDestinoId != rr.seleccion.id){
              this.filtro.sucursalDestinoId = 0;
              this.filtro.sucursalDestinoNombre = "";
            }
            this.filtro.sucursalDestinoId = rr.seleccion.id;
            this.filtro.sucursalDestinoNombre = rr.seleccion.nombre;
          }
          
        }else{

          if (tipo == "origen"){
            this.filtro.sucursalOrigenId = 0;
            this.filtro.sucursalOrigenNombre = "";
          }

          if (tipo == "destino"){
            this.filtro.sucursalDestinoId = 0;
            this.filtro.sucursalDestinoNombre = "";
          }
          
        }
        //this.cargarFiltradoRubroSubRubro();
      }
    });
  }

  
  clickImprimir(){
    
    
    let periodo = `Desde: ${this.param.desde != '' ? this.param.desde : '*'} Hasta: ${this.param.hasta != '' ? this.param.hasta : '*'}`;

    let sucursal = this.sucursalActual.sucursalNombre;

    let datos: any = [
      [
        {text: `Id`, fontSize: 10}, 
        {text: `Prod.`, fontSize: 10}, 
        {text: `Cant.`, fontSize: 10}, 
        {text: `Origen`, fontSize: 10}, 
        {text: `Destino`, fontSize: 10}, 
        {text: `Estado`, fontSize: 10}, 
        {text: `Fecha`, fontSize: 10}, 
        {text: `Venta`, fontSize: 10}, 
        {text: `Obs.`, fontSize: 10}
      ]
    ];

    let data = this.dataSource.data;

    data.forEach(x => {

      let idLogisticaSolicitud = {text: `${x.idlogisticasolicitud}`, alignment: 'left', fontSize: 8.5};
      let productoDescripcion = {text: `${x.producto_descripcion} | ${x.color_descripcion} | ${x.medida_descripcion}`, alignment: 'left', fontSize: 8.5};
      let cantidad = {text: `${x.cantidad}`, alignment: 'left', fontSize: 8.5};
      let origen = {text: `${x.origen_descripcion}`, alignment: 'left', fontSize: 8.5};
      let destino = {text: `${x.destino_descripcion}`, alignment: 'left', fontSize: 8.5};
      let estado =  {text: `${x._estado}`, alignment: 'left', fontSize: 8.5};
      let fecha =  {text: `${x._fecha}`, alignment: 'left', fontSize: 8.5};
      let idVenta = {text: `${x.idventa == null ? "" : x.idventa}`, alignment: 'left', fontSize: 8.5};
      let obs = {text: `${x.obs}`, alignment: 'left', fontSize: 8.5};
      
      let r = [idLogisticaSolicitud, productoDescripcion, cantidad, origen, destino, estado, fecha, idVenta, obs];
      datos.push(r);
    });

    console.log("datos", datos);

    let content: any[] = [
      { text: `Listado de ${this.descripcion} - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },

      datos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          widths: [25, 45, 25, '*', '*', '*', '*', '*', 45],
          body: datos
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Datos`, fontSize: 11, margin:[10, 0, 0, 10] },
     

    ]

    /*
    content.push({
      table: {
        body: [['Total', this.total]]
      },
      layout: {
        hLineWidth: function (i: any, node: any) {
          return 0.0;
        },
        vLineWidth: function (i: any, node: any) {
          return 0.0;
        }
      }
    });
    */

    this.pdfService.generarPDF(content);

  }



}

const ELEMENT_DATA: any[] = [];

