import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { SharedModule } from './shared/shared.module';
import { PreventaComponent } from './features/ventas/preventa/preventa.component';
import { ResumenComponent } from './features/ventas/resumen/resumen.component';
import { ProductosComponent } from './features/productos/productos.component';
import { ProductosAbmComponent } from './features/productos/productos-abm/productos-abm.component';
import { ProductosNuevoComponent } from './features/productos/productos-nuevo/productos-nuevo.component';
import { ProveedoresComponent } from './features/proveedores/proveedores.component';
import { ProveedoresAbmComponent } from './features/proveedores/proveedores-abm/proveedores-abm.component';
import { ProveedoresNuevoComponent } from './features/proveedores/proveedores-nuevo/proveedores-nuevo.component';
import { SucursalesComponent } from './features/sucursales/sucursales.component';
import { SucursalesAbmComponent } from './features/sucursales/sucursales-abm/sucursales-abm.component';
import { SucursalesNuevoComponent } from './features/sucursales/sucursales-nuevo/sucursales-nuevo.component';
import { VendedoresComponent } from './features/ventas/vendedores/vendedores.component';
import { VendedoresAbmComponent } from './features/ventas/vendedores/vendedores-abm/vendedores-abm.component';
import { VendedoresNuevoComponent } from './features/ventas/vendedores/vendedores-nuevo/vendedores-nuevo.component';
import { ProductosPreciosComponent } from './features/productos/productos-precios/productos-precios.component';
import { TipoDePrecioComponent } from './features/productos/productos-precios/tipo-de-precio/tipo-de-precio.component';
import { TipoDePrecioAbmComponent } from './features/productos/productos-precios/tipo-de-precio/tipo-de-precio-abm/tipo-de-precio-abm.component';
import { TipoDePrecioNuevoComponent } from './features/productos/productos-precios/tipo-de-precio/tipo-de-precio-nuevo/tipo-de-precio-nuevo.component';
import { LayoutComponent } from './features/layout/layout.component';
import { LocalidadesComponent } from './features/general/localidades/localidades.component';
import { LocalidadesAbmComponent } from './features/general/localidades/localidades-abm/localidades-abm.component';
import { LocalidadesNuevoComponent } from './features/general/localidades/localidades-nuevo/localidades-nuevo.component';
import { RubrosComponent } from './features/rubros/rubros.component';
import { RubrosAbmComponent } from './features/rubros/rubros-abm/rubros-abm.component';
import { RubrosNuevoComponent } from './features/rubros/rubros-nuevo/rubros-nuevo.component';
import { ColoresProductoComponent } from './features/colores-producto/colores-producto.component';
import { ColoresProductoAbmComponent } from './features/colores-producto/colores-producto-abm/colores-producto-abm.component';
import { ColoresProductoNuevoComponent } from './features/colores-producto/colores-producto-nuevo/colores-producto-nuevo.component';
import { SubrubrosComponent } from './features/subrubros/subrubros.component';
import { SubrubrosAbmComponent } from './features/subrubros/subrubros-abm/subrubros-abm.component';
import { SubrubrosNuevoComponent } from './features/subrubros/subrubros-nuevo/subrubros-nuevo.component';
import { MedidasProductoComponent } from './features/medidas-producto/medidas-producto.component';
import { MedidasProductoAbmComponent } from './features/medidas-producto/medidas-producto-abm/medidas-producto-abm.component';
import { MedidasProductoNuevoComponent } from './features/medidas-producto/medidas-producto-nuevo/medidas-producto-nuevo.component';
import { PreventaAbmComponent } from './features/ventas/preventa/preventa-abm/preventa-abm.component';
import { PreventaNuevoComponent } from './features/ventas/preventa/preventa-nuevo/preventa-nuevo.component';
import { VentasComponent } from './features/ventas/ventas/ventas.component';
import { PreventaNuevoDialogComponent } from './features/ventas/preventa/preventa-nuevo-dialog/preventa-nuevo-dialog.component';
import { PreventaSelTipoPrecioComponent } from './features/ventas/preventa/preventa-sel-tipo-precio/preventa-sel-tipo-precio.component';
import { PreventaCantidadComponent } from './features/ventas/preventa/preventa-cantidad/preventa-cantidad.component';
import { PreventaConfirmarComponent } from './features/ventas/preventa/preventa-confirmar/preventa-confirmar.component';
import { ClientesComponent } from './features/clientes/clientes.component';
import { ClientesAbmComponent } from './features/clientes/clientes-abm/clientes-abm.component';
import { ClientesNuevoComponent } from './features/clientes/clientes-nuevo/clientes-nuevo.component';
import { SelectorGenericoComponent } from './features/generico/selector-generico/selector-generico.component';
import { ClientesNuevoDialogComponent } from './features/clientes/clientes-nuevo-dialog/clientes-nuevo-dialog.component';
import { UsuariosComponent } from './features/usuarios/usuarios.component';
import { UsuariosAbmComponent } from './features/usuarios/usuarios-abm/usuarios-abm.component';
import { UsuariosNuevoComponent } from './features/usuarios/usuarios-nuevo/usuarios-nuevo.component';
import { ProductosPreciosMasivoDialogComponent } from './features/productos/productos-precios-masivo-dialog/productos-precios-masivo-dialog.component';
import { ProductosPreciosPresentacionesMasivoDialogComponent } from './features/productos/productos-precios-presentaciones-masivo-dialog/productos-precios-presentaciones-masivo-dialog.component';
import { VentasVendedorConsultaComponent } from './features/ventas/ventas-vendedor-consulta/ventas-vendedor-consulta.component';
import { PreventaConfirmarDialogComponent } from './features/ventas/preventa/preventa-confirmar-dialog/preventa-confirmar-dialog.component';
import { PreventaSelSucursalDialogComponent } from './features/ventas/preventa/preventa-sel-sucursal-dialog/preventa-sel-sucursal-dialog.component';
import { ComprasComponent } from './features/compras/compras.component';
import { ComprasNuevoComponent } from './features/compras/compras-nuevo/compras-nuevo.component';
import { ComprasAbmComponent } from './features/compras/compras-abm/compras-abm.component';
import { ComprasProductosDialogComponent } from './features/compras/compras-productos-dialog/compras-productos-dialog.component';
import { ComprasProductosPresentacionesDialogComponent } from './features/compras/compras-productos-presentaciones-dialog/compras-productos-presentaciones-dialog.component';
import { ComprasFormasPagoDialogComponent } from './features/compras/compras-formas-pago-dialog/compras-formas-pago-dialog.component';
import { ComprasGastosAbmComponent } from './features/compras/compras-gastos-abm/compras-gastos-abm.component';
import { ComprasGastosNuevoComponent } from './features/compras/compras-gastos-nuevo/compras-gastos-nuevo.component';
import { ProductosConsultaComponent } from './features/productos/productos-consulta/productos-consulta.component';
import { ComprasRecepcionComponent } from './features/compras/compras-recepcion/compras-recepcion.component';
import { VentasNuevaComponent } from './features/ventas/ventas-nueva/ventas-nueva.component';
import { VentasFormasPagoDialogComponent } from './features/ventas/ventas-formas-pago-dialog/ventas-formas-pago-dialog.component';
import { PagosComponent } from './features/ventas/pagos/pagos.component';
import { PagosConfirmarComponent } from './features/ventas/pagos-confirmar/pagos-confirmar.component';
import { EntregasComponent } from './features/ventas/entregas/entregas.component';
import { EntregasConfirmarDialogComponent } from './features/ventas/entregas-confirmar-dialog/entregas-confirmar-dialog.component';
import { LogisticaComponent } from './features/logistica/logistica.component';
import { LogisticaSolicitudComponent } from './features/logistica/logistica-solicitud/logistica-solicitud.component';
import { LogisticaMovimientoComponent } from './features/logistica/logistica-movimiento/logistica-movimiento.component';
import { LogisticaMovimientoNuevoDialogComponent } from './features/logistica/logistica-movimiento-nuevo-dialog/logistica-movimiento-nuevo-dialog.component';
import { LogisticaMovimientoNuevoComponent } from './features/logistica/logistica-movimiento-nuevo/logistica-movimiento-nuevo.component';
import { VentasReporteComponent } from './features/ventas/ventas-reporte/ventas-reporte.component';
import { VentasEditarProductosComponent } from './features/ventas/ventas-editar-productos/ventas-editar-productos.component';
import { ProductosAgrupadoMedidasComponent } from './features/productos/productos-agrupado-medidas/productos-agrupado-medidas.component';
import { VentasNuevaPresupuestoComponent } from './features/ventas/ventas-nueva-presupuesto/ventas-nueva-presupuesto.component';
import { CajaDiariaReporteComponent } from './features/ventas/caja-diaria-reporte/caja-diaria-reporte.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ComprasEgresoComponent } from './features/compras/compras-egreso/compras-egreso.component';
import { ComprasEgresoAbmComponent } from './features/compras/compras-egreso-abm/compras-egreso-abm.component';
import { ApiService } from './services/api.service';
import { AuthService } from './shared/services/auth.service';
import { VentaDetalleComponent } from './ui/venta-detalle/venta-detalle.component';
import { ProductosStockComponent } from './features/productos/productos-stock/productos-stock.component';
import { ClientesDeudoresComponent } from './features/clientes/clientes-deudores/clientes-deudores.component';
import { ClientesHistorialComponent } from './features/clientes/clientes-historial/clientes-historial.component';
import { combineLatest } from 'rxjs';

import { ComisionesComponent } from './features/comisiones/comisiones.component';
import { ComisionesAbmComponent } from './features/comisiones/comisiones-abm/comisiones-abm.component';
import { DialogSeleccionTipoReporteComponent } from './features/ventas/ventas-reporte/dialog-seleccion-tipo-reporte/dialog-seleccion-tipo-reporte.component';
import { DialogImprimirEntregasComponent } from './features/ventas/entregas/dialog-imprimir-entregas/dialog-imprimir-entregas.component';
import { DialogImprimirMercaderiaComponent } from './features/ventas/entregas/dialog-imprimir-mercaderia/dialog-imprimir-mercaderia.component';
import { getApp, initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { VentaActualizarPrecioComponent } from './features/ventas/venta-actualizar-precio/venta-actualizar-precio.component';


@NgModule({
    declarations: [
        AppComponent,
        PreventaComponent,
        ResumenComponent,
        ProductosComponent,
        ProductosAbmComponent,
        ProductosNuevoComponent,
        ProveedoresComponent,
        ProveedoresAbmComponent,
        ProveedoresNuevoComponent,
        SucursalesComponent,
        SucursalesAbmComponent,
        SucursalesNuevoComponent,
        VendedoresComponent,
        VendedoresAbmComponent,
        VendedoresNuevoComponent,
        ProductosPreciosComponent,
        TipoDePrecioComponent,
        TipoDePrecioAbmComponent,
        TipoDePrecioNuevoComponent,
        LayoutComponent,
        LocalidadesComponent,
        LocalidadesAbmComponent,
        LocalidadesNuevoComponent,
        RubrosComponent,
        RubrosAbmComponent,
        RubrosNuevoComponent,
        ColoresProductoComponent,
        ColoresProductoAbmComponent,
        ColoresProductoNuevoComponent,
        SubrubrosComponent,
        SubrubrosAbmComponent,
        SubrubrosNuevoComponent,
        MedidasProductoComponent,
        MedidasProductoAbmComponent,
        MedidasProductoNuevoComponent,
        PreventaAbmComponent,
        PreventaNuevoComponent,
        VentasComponent,
        PreventaNuevoDialogComponent,
        PreventaSelTipoPrecioComponent,
        PreventaCantidadComponent,
        PreventaConfirmarComponent,
        ClientesComponent,
        ClientesAbmComponent,
        ClientesNuevoComponent,
        SelectorGenericoComponent,
        ClientesNuevoDialogComponent,
        UsuariosComponent,
        UsuariosAbmComponent,
        UsuariosNuevoComponent,
        ProductosPreciosMasivoDialogComponent,
        ProductosPreciosPresentacionesMasivoDialogComponent,
        VentasVendedorConsultaComponent,
        PreventaConfirmarDialogComponent,
        PreventaSelSucursalDialogComponent,
        ComprasComponent,
        ComprasNuevoComponent,
        ComprasAbmComponent,
        ComprasProductosDialogComponent,
        ComprasProductosPresentacionesDialogComponent,
        ComprasFormasPagoDialogComponent,
        ComprasGastosAbmComponent,
        ComprasGastosNuevoComponent,
        ProductosConsultaComponent,
        ComprasRecepcionComponent,
        VentasNuevaComponent,
        VentasFormasPagoDialogComponent,
        PagosComponent,
        PagosConfirmarComponent,
        EntregasComponent,
        EntregasConfirmarDialogComponent,
        LogisticaComponent,
        LogisticaSolicitudComponent,
        LogisticaMovimientoComponent,
        LogisticaMovimientoNuevoDialogComponent,
        LogisticaMovimientoNuevoComponent,
        VentasReporteComponent,
        VentasEditarProductosComponent,
        ProductosAgrupadoMedidasComponent,
        VentasNuevaPresupuestoComponent,
        CajaDiariaReporteComponent,
        ComprasEgresoComponent,
        ComprasEgresoAbmComponent,
        VentaDetalleComponent,
        ProductosStockComponent,
        ClientesDeudoresComponent,
        ClientesHistorialComponent,
        ComisionesComponent,
        ComisionesAbmComponent,
        DialogSeleccionTipoReporteComponent,
        DialogImprimirEntregasComponent,
        DialogImprimirMercaderiaComponent,
        VentaActualizarPrecioComponent,
        
    ],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule.forRoot(),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        //provideAuth(() => getAuth()),
        provideAuth(() => getAuth(getApp())),
        provideDatabase(() => getDatabase())
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (auth: AuthService, api: ApiService, ps: NgxPermissionsService) => function () {
                console.log("app module get permissions");
                let userInfo: any = auth.userInfo();
                if (userInfo != null) {
                    let uid = userInfo.uid;
                    /*
                    let a = api.getUsuarios(uid);
                    let b = api.getSucursalesList();
          
                    return combineLatest([a, b]).toPromise().then((data) => {
                      console.log("ddd data", data);
          
                      if (data[0] != null){
          
                        let nombreSucursal = "-";
                        if (data[1] != null){
                          nombreSucursal = data[1].find(s => s.idsucursal == data[0].idsucursal).descripcion;
                        }
          
                        let sucursal = {
                          sucursalId: data[0].idsucursal,
                          sucursalNombre: nombreSucursal
                        }
                        localStorage.setItem('sucursal', JSON.stringify(sucursal));
          
          
                        let rol = data[0].rol ? data[0].rol : 0;
                        const permissions = [`${rol}`];
                        return ps.loadPermissions(permissions)
          
                      }
                      return ps.loadPermissions([]);
          
                    });
                    */
                    return api.getUsuariosPromise(uid).then((data) => {
                        if (data != null) {
                            console.log("data module", data);
                            let rol = data.rol ? data.rol : 0;
                            const permissions = [`${rol}`];
                            return ps.loadPermissions(permissions);
                        }
                        return ps.loadPermissions([]);
                    });
                }
                return ps.loadPermissions([]);
            },
            deps: [AuthService, ApiService, NgxPermissionsService],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
