<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
        Confirmar Preventa
    </div>
  
    <div style="height: 2px;">
        <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>
  
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
        <div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

                <ng-container matColumnDef="producto_descripcion">
                <th mat-header-cell *matHeaderCellDef> Producto </th>
                <td mat-cell *matCellDef="let element"> {{element.producto_descripcion}} </td>
                </ng-container>

                <ng-container matColumnDef="color_descripcion">
                <th mat-header-cell *matHeaderCellDef> Color </th>
                <td mat-cell *matCellDef="let element"> {{element.color_descripcion}} </td>
                </ng-container>

                <ng-container matColumnDef="medida_descripcion">
                <th mat-header-cell *matHeaderCellDef> Medida </th>
                <td mat-cell *matCellDef="let element"> {{element.medida_descripcion}} </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef> Cantidad </th>
                <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
                </ng-container>

                <ng-container matColumnDef="sucursal_descripcion">
                <th mat-header-cell *matHeaderCellDef> Sucursal </th>
                <td mat-cell *matCellDef="let element"> {{element.sucursal_descripcion}} </td>
                </ng-container>

                <ng-container matColumnDef="_stock">
                <th mat-header-cell *matHeaderCellDef> Stock Sucursal</th>
                <td mat-cell *matCellDef="let element"> {{element._stock}} </td>
                </ng-container>
        
                <ng-container matColumnDef="_info">
                <th mat-header-cell *matHeaderCellDef> Información</th>
                <td mat-cell *matCellDef="let element"> {{element._info}} </td>
                </ng-container>
    
                <ng-container matColumnDef="opciones">
                    <th mat-header-cell *matHeaderCellDef> Opciones </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-raised-button class="boton-celda" (click)="clickCambiar(element)">Cambiar Sucursal</button> 
                    </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
        <div style="display: flex; flex-direction: row-reverse; width: 100%;">
            <button mat-button (click)="clickCerrar()">Cancelar</button>
            <button mat-button (click)="clickConfirmar()" [disabled]="cargando">Confirmar</button>
        </div>
    </mat-dialog-actions>
  
  </div>