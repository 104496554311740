<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Impresión de Mercaderia
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    <div style="margin: 8px 0px;">
        Los productos que se muestran corresponde a los productos de las ventas seleccionadas y que se encuentran en estado "En Logística" y "Listos para Entregar". Seleccione cuales seran agregados al listado.
    </div>
    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">

        <div class="mat-elevation-z2" style="margin: 0px 0px 4px 0px; ">
            <table mat-table [dataSource]="dataSource" style="width: 100%;">
      
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                        (change)="onChangeSelectGlobal($event)"
                        [checked]="checkedGlobal">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox
                        (change)="onChangeSelectItem($event, element)"
                        [checked]="element._seleccionado">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <!-- Position Column -->

                <ng-container matColumnDef="idventa">
                    <th mat-header-cell *matHeaderCellDef>Venta</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dato-tabla">
                            {{element.idventa}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="producto">
                    <th mat-header-cell *matHeaderCellDef>Producto</th>
                    <td mat-cell *matCellDef="let element">
                        <div class="dato-tabla">
                        {{element.producto_descripcion}}
                        </div>
                    </td>
                </ng-container>
        
                <!-- Name Column -->
                <ng-container matColumnDef="color">
                    <th mat-header-cell *matHeaderCellDef>Color</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element.color_descripcion}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="medida">
                    <th mat-header-cell *matHeaderCellDef>Medida</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element.medida_descripcion}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cantidad">
                    <th mat-header-cell *matHeaderCellDef>Cantidad</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element.cantidad}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="cliente">
                    <th mat-header-cell *matHeaderCellDef>Cliente</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element._clienteNombre}}
                        </div>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef>Estado Actual</th>
                    <td mat-cell *matCellDef="let element"> 
                        <div class="dato-tabla" style="display: flex;">
                        <div>
                            {{element._estado}}
                        </div>
                        </div>
                    </td>
                </ng-container>
                
      
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    class="element-row"
                    [class.selected-row]="selectedElement != null ? (selectedElement.id == row.id) : false">
                </tr>
    
                <!--
              <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                        class="element-row"
                        [class.expanded-row]="expandedElement === element"
                        (click)="expandedElement = expandedElement === element ? null : element">
                    </tr>-->
              
            </table>
      
            <div class="info-vacio" *ngIf="dataSource.data.length === 0">
              <div class="sin-informacion">
                No hay información
              </div>
            </div>

            <!--
            <div>
                Resta Abonar 
                <div *ngFor="let r of dataSource.data">
                    <div>{{r | json}}</div>
                </div>
            </div>
        -->
            
          </div>
        
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
        <button mat-button (click)="clickConfirmar()" [disabled]="cantidadSeleccionados == 0">Generar Impresión</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>