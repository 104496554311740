import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxPermissionsGuard, NgxPermissionsModule } from 'ngx-permissions';
import { ClientesAbmComponent } from './features/clientes/clientes-abm/clientes-abm.component';
import { ClientesDeudoresComponent } from './features/clientes/clientes-deudores/clientes-deudores.component';
import { ClientesHistorialComponent } from './features/clientes/clientes-historial/clientes-historial.component';
import { ClientesNuevoComponent } from './features/clientes/clientes-nuevo/clientes-nuevo.component';
import { ClientesComponent } from './features/clientes/clientes.component';
import { ColoresProductoAbmComponent } from './features/colores-producto/colores-producto-abm/colores-producto-abm.component';
import { ColoresProductoNuevoComponent } from './features/colores-producto/colores-producto-nuevo/colores-producto-nuevo.component';
import { ComisionesAbmComponent } from './features/comisiones/comisiones-abm/comisiones-abm.component';
import { ComisionesComponent } from './features/comisiones/comisiones.component';
import { ComprasAbmComponent } from './features/compras/compras-abm/compras-abm.component';
import { ComprasEgresoAbmComponent } from './features/compras/compras-egreso-abm/compras-egreso-abm.component';
import { ComprasEgresoComponent } from './features/compras/compras-egreso/compras-egreso.component';
import { ComprasGastosAbmComponent } from './features/compras/compras-gastos-abm/compras-gastos-abm.component';
import { ComprasGastosNuevoComponent } from './features/compras/compras-gastos-nuevo/compras-gastos-nuevo.component';
import { ComprasNuevoComponent } from './features/compras/compras-nuevo/compras-nuevo.component';
import { ComprasRecepcionComponent } from './features/compras/compras-recepcion/compras-recepcion.component';
import { ComprasComponent } from './features/compras/compras.component';
import { LocalidadesAbmComponent } from './features/general/localidades/localidades-abm/localidades-abm.component';
import { LocalidadesNuevoComponent } from './features/general/localidades/localidades-nuevo/localidades-nuevo.component';
import { LocalidadesComponent } from './features/general/localidades/localidades.component';
import { LayoutComponent } from './features/layout/layout.component';
import { LogisticaMovimientoNuevoComponent } from './features/logistica/logistica-movimiento-nuevo/logistica-movimiento-nuevo.component';
import { LogisticaMovimientoComponent } from './features/logistica/logistica-movimiento/logistica-movimiento.component';
import { LogisticaSolicitudComponent } from './features/logistica/logistica-solicitud/logistica-solicitud.component';
import { LogisticaComponent } from './features/logistica/logistica.component';
import { MedidasProductoAbmComponent } from './features/medidas-producto/medidas-producto-abm/medidas-producto-abm.component';
import { MedidasProductoNuevoComponent } from './features/medidas-producto/medidas-producto-nuevo/medidas-producto-nuevo.component';
import { ProductosAbmComponent } from './features/productos/productos-abm/productos-abm.component';
import { ProductosAgrupadoMedidasComponent } from './features/productos/productos-agrupado-medidas/productos-agrupado-medidas.component';
import { ProductosConsultaComponent } from './features/productos/productos-consulta/productos-consulta.component';
import { ProductosNuevoComponent } from './features/productos/productos-nuevo/productos-nuevo.component';
import { TipoDePrecioAbmComponent } from './features/productos/productos-precios/tipo-de-precio/tipo-de-precio-abm/tipo-de-precio-abm.component';
import { TipoDePrecioNuevoComponent } from './features/productos/productos-precios/tipo-de-precio/tipo-de-precio-nuevo/tipo-de-precio-nuevo.component';
import { ProductosStockComponent } from './features/productos/productos-stock/productos-stock.component';
import { ProductosComponent } from './features/productos/productos.component';
import { ProveedoresAbmComponent } from './features/proveedores/proveedores-abm/proveedores-abm.component';
import { ProveedoresNuevoComponent } from './features/proveedores/proveedores-nuevo/proveedores-nuevo.component';
import { ProveedoresComponent } from './features/proveedores/proveedores.component';
import { RubrosAbmComponent } from './features/rubros/rubros-abm/rubros-abm.component';
import { RubrosNuevoComponent } from './features/rubros/rubros-nuevo/rubros-nuevo.component';
import { RubrosComponent } from './features/rubros/rubros.component';
import { SubrubrosAbmComponent } from './features/subrubros/subrubros-abm/subrubros-abm.component';
import { SubrubrosNuevoComponent } from './features/subrubros/subrubros-nuevo/subrubros-nuevo.component';
import { SubrubrosComponent } from './features/subrubros/subrubros.component';
import { SucursalesAbmComponent } from './features/sucursales/sucursales-abm/sucursales-abm.component';
import { SucursalesNuevoComponent } from './features/sucursales/sucursales-nuevo/sucursales-nuevo.component';
import { SucursalesComponent } from './features/sucursales/sucursales.component';
import { UsuariosAbmComponent } from './features/usuarios/usuarios-abm/usuarios-abm.component';
import { UsuariosNuevoComponent } from './features/usuarios/usuarios-nuevo/usuarios-nuevo.component';
import { UsuariosComponent } from './features/usuarios/usuarios.component';
import { CajaDiariaReporteComponent } from './features/ventas/caja-diaria-reporte/caja-diaria-reporte.component';
import { EntregasComponent } from './features/ventas/entregas/entregas.component';
import { PagosConfirmarComponent } from './features/ventas/pagos-confirmar/pagos-confirmar.component';
import { PagosComponent } from './features/ventas/pagos/pagos.component';
import { PreventaAbmComponent } from './features/ventas/preventa/preventa-abm/preventa-abm.component';
import { PreventaNuevoComponent } from './features/ventas/preventa/preventa-nuevo/preventa-nuevo.component';
import { PreventaComponent } from './features/ventas/preventa/preventa.component';
import { ResumenComponent } from './features/ventas/resumen/resumen.component';
import { VendedoresAbmComponent } from './features/ventas/vendedores/vendedores-abm/vendedores-abm.component';
import { VendedoresNuevoComponent } from './features/ventas/vendedores/vendedores-nuevo/vendedores-nuevo.component';
import { VendedoresComponent } from './features/ventas/vendedores/vendedores.component';
import { VentasNuevaPresupuestoComponent } from './features/ventas/ventas-nueva-presupuesto/ventas-nueva-presupuesto.component';
import { VentasNuevaComponent } from './features/ventas/ventas-nueva/ventas-nueva.component';
import { VentasReporteComponent } from './features/ventas/ventas-reporte/ventas-reporte.component';
import { VentasVendedorConsultaComponent } from './features/ventas/ventas-vendedor-consulta/ventas-vendedor-consulta.component';
import { VentasComponent } from './features/ventas/ventas/ventas.component';
import { LoginComponent } from './shared/components/auth/login/login.component';
import { ErrorProhibidoComponent } from './shared/components/error-prohibido/error-prohibido.component';


const routes: Routes = [
  //{ path: '', redirectTo: '', pathMatch: 'full' },

  { 
    path: '', 
    component: LayoutComponent, 
    data: { breadcrumb: 'base' }, 
    canActivateChild: [NgxPermissionsGuard],
    children: [
  
      { 
        path: 'productos', 
        component: ProductosComponent, 
        data: { breadcrumb: 'productos' }, 
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: ProductosAbmComponent, data: { breadcrumb: 'productos abm', permissions: {only: ['10', '102', '104', '105', '106', '107'], redirectTo: '401'} }},
          { path: 'nuevo', component: ProductosNuevoComponent, data: { breadcrumb: 'productos nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'consulta', component: ProductosConsultaComponent, data: { breadcrumb: 'productos consulta', permissions: {only: ['10', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'stock', component: ProductosStockComponent, data: { breadcrumb: 'productos stock', permissions: {only: ['10', '102', '104', '105', '106', '107'], redirectTo: '401'} }},
          { path: 'agrupado', component: ProductosAgrupadoMedidasComponent, data: { breadcrumb: 'productos agrupados por medida', permissions: {only: ['10', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'editar/:idproducto', component: ProductosNuevoComponent, data: { breadcrumb: 'productos editar', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'tipos-de-precio/abm', component: TipoDePrecioAbmComponent, data: { breadcrumb: 'tipos-de-precio abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'tipos-de-precio/nuevo', component: TipoDePrecioNuevoComponent, data: { breadcrumb: 'tipos-de-precio nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'tipos-de-precio/editar/:idtipo', component: TipoDePrecioNuevoComponent, data: { breadcrumb: 'tipos-de-precio editar', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'colores/abm', component: ColoresProductoAbmComponent, data: { breadcrumb: 'colores-producto abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'colores/nuevo', component: ColoresProductoNuevoComponent, data: { breadcrumb: 'colores-producto nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'colores/editar/:idcolorproducto', component: ColoresProductoNuevoComponent, data: { breadcrumb: 'colores-producto editar', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'medidas/abm', component: MedidasProductoAbmComponent, data: { breadcrumb: 'medidas-producto abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'medidas/nuevo', component: MedidasProductoNuevoComponent, data: { breadcrumb: 'medidas-producto nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'medidas/editar/:idmedidaproducto', component: MedidasProductoNuevoComponent, data: { breadcrumb: 'medidas-producto editar', permissions: {only: ['10'], redirectTo: '401'} }},

        ]
      },

      { 
        path: 'general/localidades', 
        component: LocalidadesComponent, 
        data: { breadcrumb: 'localidades' }, 
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: LocalidadesAbmComponent, data: { breadcrumb: 'localidades abm', permissions: {only: ['10'], redirectTo: '401'}  }},
          { path: 'nuevo', component: LocalidadesNuevoComponent, data: { breadcrumb: 'localidades nuevo', permissions: {only: ['10'], redirectTo: '401'}  }},
          { path: 'editar/:idlocalidad', component: LocalidadesNuevoComponent, data: { breadcrumb: 'localidades editar', permissions: {only: ['10'], redirectTo: '401'}  }}
        ]
      },

      { 
        path: 'proveedores', 
        component: ProveedoresComponent, 
        data: { breadcrumb: 'proveedores' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: ProveedoresAbmComponent, data: { breadcrumb: 'proveedores abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: ProveedoresNuevoComponent, data: { breadcrumb: 'proveedores nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:idproveedor', component: ProveedoresNuevoComponent, data: { breadcrumb: 'proveedores editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },

      { 
        path: 'sucursales', 
        component: SucursalesComponent, 
        data: { breadcrumb: 'sucursales' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: SucursalesAbmComponent, data: { breadcrumb: 'sucursales abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: SucursalesNuevoComponent, data: { breadcrumb: 'sucursales nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:idsucursal', component: SucursalesNuevoComponent, data: { breadcrumb: 'sucursales editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },

      { 
        path: 'ventas', 
        component: VentasComponent, 
        data: { breadcrumb: 'ventas' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'vendedores', component: VendedoresAbmComponent, data: { breadcrumb: 'vendedores abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: VendedoresNuevoComponent, data: { breadcrumb: 'vendedores nuevo', permissions: {only: ['10', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'editar/:idsucursal', component: VendedoresNuevoComponent, data: { breadcrumb: 'vendedores editar', permissions: {only: ['10', '102','104', '107'], redirectTo: '401'} }},
          { path: 'venta', component: PreventaComponent, data: { breadcrumb: 'venta', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} },
            children: [
              { path: 'lista', component: PreventaAbmComponent, data: { breadcrumb: 'preventa abm', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
              { path: 'nueva', component: PreventaNuevoComponent, data: { breadcrumb: 'preventa nueva', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
            ]
          },
          { path: 'reporte-ventas', component: VentasReporteComponent, data: { breadcrumb: 'ventas vendedor reporte', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo-presupuesto', component: VentasNuevaComponent, data: { breadcrumb: 'ventas nuevo presupuesto', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'editar-presupuesto/:idpreventa/:tipo', component: VentasNuevaComponent, data: { breadcrumb: 'ventas editar presupuesto', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'nueva-venta', component: VentasNuevaComponent, data: { breadcrumb: 'nueva venta', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'confirmar/:idpreventa/:tipo', component: VentasNuevaComponent, data: { breadcrumb: 'confirmar venta', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'editar/:idpreventa/:tipo', component: VentasNuevaComponent, data: { breadcrumb: 'editar venta', permissions: {only: ['10', '102', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'pagos', component: PagosComponent, data: { breadcrumb: 'ventas pagos', permissions: {only: ['10', '100', '102', '106', '107'], redirectTo: '401' }}},
          { path: 'pagos/confirmar/:idpreventa', component: PagosConfirmarComponent, data: { breadcrumb: 'ventas pagos', permissions: {only: ['10', '100', '102', '106', '107'], redirectTo: '401'} }},
          { path: 'entregas', component: EntregasComponent, data: { breadcrumb: 'ventas entregas', permissions: {only: ['10', '100', '101', '102', '104', '106', '107'], redirectTo: '401'} }},
          { path: 'caja-diaria', component: CajaDiariaReporteComponent, data: { breadcrumb: 'pagos caja diaria', permissions: {only: ['10', '100', '102', '106', '107'], redirectTo: '401'} }},
          
        ]
      },

      { 
        path: 'logistica', 
        component: LogisticaComponent, 
        data: { breadcrumb: 'logistica' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'solicitud/:modo', component: LogisticaSolicitudComponent, data: { breadcrumb: 'logistica solicitud movimientos', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'movimientos', component: LogisticaMovimientoComponent, data: { breadcrumb: 'logistica movimientos', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'movimiento/:tipo/:id', component: LogisticaMovimientoNuevoComponent, data: { breadcrumb: 'logistica movimiento', permissions: {only: ['10', '103'], redirectTo: '401'} }},
        ]
      },

      { 
        path: 'vendedores', 
        component: VendedoresComponent, 
        data: { breadcrumb: 'vendedores' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: VendedoresAbmComponent, data: { breadcrumb: 'vendedores abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: VendedoresNuevoComponent, data: { breadcrumb: 'vendedores nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:idvendedor', component: VendedoresNuevoComponent, data: { breadcrumb: 'vendedores editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },

      { 
        path: 'rubros', 
        component: RubrosComponent, 
        data: { breadcrumb: 'rubros' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: RubrosAbmComponent, data: { breadcrumb: 'rubro abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: RubrosNuevoComponent, data: { breadcrumb: 'rubro nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:idrubroproducto', component: RubrosNuevoComponent, data: { breadcrumb: 'rubro editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },
      
      { 
        path: 'subrubros', 
        component: SubrubrosComponent, 
        data: { breadcrumb: 'sub-rubro' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: SubrubrosAbmComponent, data: { breadcrumb: 'sub-rubro abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: SubrubrosNuevoComponent, data: { breadcrumb: 'sub-rubro nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:idsubrubroproducto', component: SubrubrosNuevoComponent, data: { breadcrumb: 'sub-rubro editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },

      { 
        path: 'clientes', 
        component: ClientesComponent, 
        data: { breadcrumb: 'clientes' },
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: ClientesAbmComponent, data: { breadcrumb: 'clientes abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: ClientesNuevoComponent, data: { breadcrumb: 'clientes nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'historial', component: ClientesHistorialComponent, data: { breadcrumb: 'clientes historial', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'deudores', component: ClientesDeudoresComponent, data: { breadcrumb: 'clientes deudores', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:idcliente', component: ClientesNuevoComponent, data: { breadcrumb: 'clientes editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },

      { 
        path: 'usuarios', 
        component: UsuariosComponent, 
        data: { breadcrumb: 'usuarios' }, 
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: UsuariosAbmComponent, data: { breadcrumb: 'usuarios abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: UsuariosNuevoComponent, data: { breadcrumb: 'usuarios nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'editar/:uid', component: UsuariosNuevoComponent, data: { breadcrumb: 'usuarios editar', permissions: {only: ['10'], redirectTo: '401'} }}
        ]
      },

      { 
        path: 'compras', 
        component: ComprasComponent, 
        data: { breadcrumb: 'compras' }, 
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: ComprasAbmComponent, data: { breadcrumb: 'compras abm', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'lista-egresos', component: ComprasEgresoAbmComponent, data: { breadcrumb: 'compras egreso abm', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'nueva', component: ComprasNuevoComponent, data: { breadcrumb: 'compras nuevo', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'egreso', component: ComprasEgresoComponent, data: { breadcrumb: 'compras egreso', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'confirmar/:idcompra/:tipo', component: ComprasNuevoComponent, data: { breadcrumb: 'compras nuevo', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'recepcion/:idcompra', component: ComprasRecepcionComponent, data: { breadcrumb: 'compras recepcion', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'editar/:idcompra/:tipo', component: ComprasNuevoComponent, data: { breadcrumb: 'compras editar', permissions: {only: ['10', '103'], redirectTo: '401'} }},
          { path: 'solicitud/:modo', component: LogisticaSolicitudComponent, data: { breadcrumb: 'logistica solicitud reposiciones', permissions: {only: ['10', '103'], redirectTo: '401'} }},
        ]
      },

      { 
        path: 'gastos', 
        component: ComprasComponent, 
        data: { breadcrumb: 'gastos' }, 
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: ComprasGastosAbmComponent, data: { breadcrumb: 'gastos abm', permissions: {only: ['10'], redirectTo: '401'} }},
          { path: 'nuevo', component: ComprasGastosNuevoComponent, data: { breadcrumb: 'gastos nuevo', permissions: {only: ['10'], redirectTo: '401'} }},
        ]
      },

      { 
        path: 'comisiones', 
        component: ComisionesComponent, 
        data: { breadcrumb: 'comisiones' }, 
        canActivateChild: [NgxPermissionsGuard],
        children: [
          { path: 'abm', component: ComisionesAbmComponent, data: { breadcrumb: 'comisiones abm', permissions: {only: ['10'], redirectTo: '401'} }},
        ]
      },

    ]
  },

  { 
    path: 'login', 
    component: LoginComponent 
  },

  { 
    path: '401', 
    component: ErrorProhibidoComponent
  },

];

@NgModule({
  imports: [
    //RouterModule.forRoot(routes)
    NgxPermissionsModule.forChild(),
    RouterModule.forRoot(routes, { useHash: false, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
