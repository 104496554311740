import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipo-de-precio',
  templateUrl: './tipo-de-precio.component.html',
  styleUrls: ['./tipo-de-precio.component.scss']
})
export class TipoDePrecioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
