import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild, HostListener, Input } from '@angular/core';
//import { SideBarService } from '../../servicios/side-bar.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { style, state, animate, transition, trigger } from '@angular/animations';
import { MatSidenav } from '@angular/material/sidenav';
import { SideBarService } from '../../services/side-bar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)'})),
      transition('void => *', [
        style({ transform: 'translateX(-100%)'}),
        animate(300)
      ]),
      transition('* => void', [
        animate(300, style({ transform: 'translateX(100%)'}))
      ])
    ])
  ],
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {

  isNotasOpen = false;
  isOpen = true;
  compactSidebar = false;
  allExpanded = false;

  lastStep = 0;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  private menuFuncionIndicadores = {
    'titulo': 'Indicadores',
    'href': '',
    'texto': '',
    'desplegable': false,
    'expandido': false,
    'icono': 'insert_chart_outlined',
    'clickeable': true,
    'roles': ['10']
  };

  private menuFuncionVentas = {
    'titulo': 'Ventas',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': true,
    'icono': 'sell',
    'roles': ['10', '100', '101', '102', '104', '106', '107'],
    'submenu': [
      {
        'titulo': 'Nueva Venta',
        'href': '/ventas/nueva-venta',
        'icono': '',
        'roles': ['10', '102', '104', '106', '107']
      },
      {
        'titulo': 'Nuevo Presupuesto',
        'href': '/ventas/nuevo-presupuesto',
        'icono': '',
        'roles': ['10', '102', '104', '106', '107']
      },
      {
        'titulo': 'Lista Ventas',
        'href': '/ventas/venta/lista',
        'icono': '',
        'roles': ['10', '102', '104', '106', '107']
      },
      {
        'titulo': 'Pagos',
        'href': '/ventas/pagos',
        'icono': '',
        'roles': ['10', '100', '102', '106', '107']
      },
      {
        'titulo': 'Reporte de Ventas',
        'href': '/ventas/reporte-ventas',
        'icono': '',
        'roles': ['10']
        //reporte-vendedores
      },
      {
        'titulo': 'Caja Diaria',
        'href': '/ventas/caja-diaria',
        'icono': '',
        'roles': ['10', '100', '102', '106', '107']
      }
    ],
    'clickeable': false
  };

  private menuFuncionLogistica = {
    'titulo': 'Logística ',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': true,
    'icono': 'local_shipping',
    'roles': ['10', '100', '101', '102', '103', '104', '106', '107'],
    'submenu': [
      {
        'titulo': 'Nuevo Movimiento',
        'href': '/logistica/movimiento/nuevo/0',
        'icono': '',
        'roles': ['10', '103']
      },
      {
        'titulo': 'Movimientos',
        'href': '/logistica/movimientos',
        'icono': '',
        'roles': ['10', '103']
      },
      {
        'titulo': 'Entregas',
        'href': '/ventas/entregas',
        'icono': '',
        'roles': ['10', '100', '101', '102', '104', '106', '107']
      },
      /*
      {
        'titulo': 'Solicitud Movimientos',
        'href': '/logistica/solicitud/movimientos',
        'icono': '',
        'roles': ['10', '103']
      }
      */
    ],
    'clickeable': false
  };

  private menuFuncionCompras = {
    'titulo': 'Ingreso Mercaderia',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': true,
    'icono': 'inventory_2',
    'roles': ['10', '103'],
    'submenu': [
      {
        'titulo': 'Ingreso / Compra',
        'href': '/compras/nueva',
        'icono': '',
        'roles': ['10', '103']
      },
      {
        'titulo': 'Egreso',
        'href': '/compras/egreso',
        'icono': '',
        'roles': ['10', '103']
      },
      {
        'titulo': 'Compras',
        'href': '/compras/abm',
        'icono': '',
        'roles': ['10', '103']
      },
      {
        'titulo': 'Egresos Forzados',
        'href': '/compras/lista-egresos',
        'icono': '',
        'roles': ['10', '103']
      },
      {
        'titulo': 'Solicitud Reposiciones',
        'href': '/compras/solicitud/reposiciones',
        'icono': '',
        'roles': ['10', '103']
      }
    ],
    'clickeable': false
  };

  private menuFuncionGastos = {
    'titulo': 'Gastos',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': true,
    'icono': 'point_of_sale',
    'roles': ['10'],
    'submenu': [
      {
        'titulo': 'Nuevo Gasto',
        'href': '/gastos/nuevo',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Gastos',
        'href': '/gastos/abm',
        'icono': '',
        'roles': ['10']
      }
    ],
    'clickeable': false
  };

  private menuFuncionProductos = {
    'titulo': 'Productos',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': true,
    'icono': 'chair',
    'roles': ['10', '102', '104', '105', '106', '107'],
    'submenu': [
      {
        'titulo': 'Nuevo Producto',
        'href': '/productos/nuevo',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Productos',
        'href': '/productos/abm',
        'icono': '',
        'roles': ['10', '102', '104', '105', '106', '107']
      },
      {
        'titulo': 'Presentaciones',
        'href': '/productos/consulta',
        'icono': '',
        'out': true,
        'roles': ['10', '104', '106', '107']
      },
      {
        'titulo': 'Stock',
        'href': '/productos/stock',
        'icono': '',
        'roles': ['10', '102', '104', '105', '106', '107']
      },
      {
        'titulo': 'Lista de Precios',
        'href': '/productos/agrupado',
        'icono': '',
        'roles': ['10', '104', '106', '107']
      }
    ],
    'clickeable': false
  };

  private menuFuncionClientes = {
    'titulo': 'Clientes',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': true,
    'icono': 'face',
    'roles': ['10'],
    'submenu': [
      {
        'titulo': 'Clientes',
        'href': '/clientes/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Historial',
        'href': '/clientes/historial',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Deudores',
        'href': '/clientes/deudores',
        'icono': '',
        'roles': ['10']
      }
    ],
    'clickeable': false
  };
  
  private menuFuncionConfiguracion = {
    'titulo': 'Configuración',
    'href': '',
    'texto': '',
    'desplegable': true,
    'expandido': false,
    'icono': 'settings',
    'roles': ['10'],
    'submenu': [
      {
        'titulo': 'Clientes',
        'href': '/clientes/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Colores',
        'href': '/productos/colores/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Localidades',
        'href': 'general/localidades/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Medidas Producto',
        'href': '/productos/medidas/abm',
        'icono': '',
        'roles': ['10']
      }, 
      {
        'titulo': 'Productos',
        'href': '/productos/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Proveedores',
        'href': '/proveedores/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Rubros',
        'href': '/rubros/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'SubRubros',
        'href': '/subrubros/abm',
        'icono': '',
        'roles': ['10']
      },
       {
        'titulo': 'Sucursales',
        'href': '/sucursales/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Tipos de Precio',
        'href': '/productos/tipos-de-precio/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Usuarios',
        'href': '/usuarios/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Vendedores',
        'href': 'vendedores/abm',
        'icono': '',
        'roles': ['10']
      },
      {
        'titulo': 'Comisiones',
        'href': 'comisiones/abm',
        'icono': '',
        'roles': ['10']
      }
    ],
    'clickeable': false
  };


  constructor(
    private sideBarService: SideBarService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.opcionesMenu.push(this.menuFuncionIndicadores);
    this.opcionesMenu.push(this.menuFuncionVentas);
    this.opcionesMenu.push(this.menuFuncionLogistica);
    this.opcionesMenu.push(this.menuFuncionCompras);
    this.opcionesMenu.push(this.menuFuncionGastos);
    this.opcionesMenu.push(this.menuFuncionProductos);
    this.opcionesMenu.push(this.menuFuncionClientes);
    this.opcionesMenu.push(this.menuFuncionConfiguracion);
    
  }

  step: any[] = [];

  public opcionesMenu: any[] = [];

  onClose() {
    this.sideBarService.close();
  }

  onCloseNotas(){
    this.sideBarService.closeNotas();
  }

  clickToogleSidebar() {
    this.compactSidebar = !this.compactSidebar;

    console.log('sidebar', this.compactSidebar);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    
    this.sideBarService.isOpen.subscribe(isOpen => {
      this.isOpen = isOpen;
    });

    this.sideBarService.isNotasOpen.subscribe(isNotasOpen => {
      this.isNotasOpen = isNotasOpen;
    })
    
  }

  clickOpcion(opcion: any) {
    if (opcion.clickeable) {
      this.lastStep = opcion.titulo;
    }
  }

  clickNewTab(event: any, ref: any){
    event.stopPropagation();
    window.open(ref, "_blank");
  }

}
