<mat-progress-bar mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">

        <!--

        <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Producto</button>

        <div>
            <button mat-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="clickModificarMasivo()">Modificar Precios Masivo</button>
            </mat-menu>
        </div>

    -->

    </div>

    <div style="display: flex; flex-direction: row;">
    
        <div style="flex-grow: 0.4; padding:6px;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
            </mat-form-field>
        </div>

        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="Sucursal" matInput readonly [(ngModel)]="sucursalSelected.nombre">
                <button *ngIf="sucursalSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearSucursal()" >
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarSucursal()" >
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>
    
        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="Rubro" matInput readonly [(ngModel)]="rubroSelected.nombre">
                <button *ngIf="rubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearRubro()">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarRubro()" >
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="SubRubro" matInput readonly [(ngModel)]="subRubroSelected.nombre">
                <button *ngIf="subRubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearSubRubro()" >
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarSubRubro()"  [disabled]="rubroSelected.id == 0">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>

        
    </div>

    <div style="display: flex; font-size: 9pt; margin-bottom: 12px; justify-content: space-between;">
        <div>
            Filtrados por Rubro y SubRubro&nbsp;<span>{{dataSource.data.length}}</span>&nbsp;| Mostrando {{dataSource.filteredData.length}}
        </div>
        <div>
            Total de Presentaciones&nbsp;<span>{{dataCompleta.length}}</span>
        </div>
        
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idproductopresentacion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idproductopresentacion}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="productoDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Producto </th>
        <td mat-cell *matCellDef="let element"> {{element.producto}} </td>
        </ng-container>

        <ng-container matColumnDef="colorDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Color </th>
        <td mat-cell *matCellDef="let element"> {{element.color}} </td>
        </ng-container>

        <ng-container matColumnDef="medidaDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Medida </th>
        <td mat-cell *matCellDef="let element"> {{element.medida}} </td>
        </ng-container>

        <ng-container matColumnDef="rubroDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Rubro </th>
        <td mat-cell *matCellDef="let element"> {{element.rubro}} </td>
        </ng-container>

        
        
        <ng-container matColumnDef="subrubroDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> SubRubro </th>
        <td mat-cell *matCellDef="let element"> {{element.subrubro}} </td>
        </ng-container>

        <ng-container matColumnDef="sucursalDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Sucursal </th>
        <td mat-cell *matCellDef="let element"> {{element.sucursal}} </td>
        </ng-container>
        
        <ng-container matColumnDef="cantidad">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Cantidad </th>
        <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetalle">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              </div>
            </td>
          </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>
