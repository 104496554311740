<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

  <div>
    <mat-form-field appearance="outline">
      <mat-label>Ingrese un rango de fechas</mat-label>
      <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

      <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
      <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="clickConsultar()">Consultar</button>
  </div>

    <h3>Reporte Consolidado Ventas</h3>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idvendedor">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idvendedor}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="nombre">
        <th mat-header-cell *matHeaderCellDef> Nombre </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>

        <ng-container matColumnDef="cantidad_productos">
        <th mat-header-cell *matHeaderCellDef> Productos Totales </th>
        <td mat-cell *matCellDef="let element"> {{element.total_productos}} </td>
        </ng-container>

        <ng-container matColumnDef="cantidad_valor">
        <th mat-header-cell *matHeaderCellDef> Pesos Totales </th>
        <td mat-cell *matCellDef="let element"> {{element.total_dinero}} </td>
        </ng-container>

        <ng-container matColumnDef="cantidad_ventas">
        <th mat-header-cell *matHeaderCellDef> Ventas Totales </th>
        <td mat-cell *matCellDef="let element"> {{element.total_ventas}} </td>
        </ng-container>

        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <h3>Reporte Consolidado por Forma de Pago</h3>
    
    <table mat-table [dataSource]="dataSource2" class="mat-elevation-z8" style="width: 100%;">

      <!-- Position Column -->
      <ng-container matColumnDef="idtipo">
      <th mat-header-cell *matHeaderCellDef> id </th>
      <td mat-cell *matCellDef="let element"> {{element.idtipo}} </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="tipo_descripcion">
      <th mat-header-cell *matHeaderCellDef> Forma de Pago </th>
      <td mat-cell *matCellDef="let element"> {{element.tipo_descripcion}} </td>
      </ng-container>

      <ng-container matColumnDef="recargo">
      <th mat-header-cell *matHeaderCellDef> Recargo </th>
      <td mat-cell *matCellDef="let element"> {{element.recargo}} </td>
      </ng-container>

      <ng-container matColumnDef="monto">
      <th mat-header-cell *matHeaderCellDef> Monto </th>
      <td mat-cell *matCellDef="let element"> {{element.monto}} </td>
      </ng-container>

      <ng-container matColumnDef="cantidad_ventas">
      <th mat-header-cell *matHeaderCellDef> Ventas Totales </th>
      <td mat-cell *matCellDef="let element"> {{element.total_ventas}} </td>
      </ng-container>

      
      <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns2;"></tr>
    </table>

</div>