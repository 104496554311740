import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, startWith, map } from 'rxjs/operators';
import { ClientesCompletoModel } from 'src/app/models/clientes-completo-model';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { PreventaConfirmarComponent } from '../preventa/preventa-confirmar/preventa-confirmar.component';
import { Location } from '@angular/common';
import { ComprasProductosDialogComponent } from '../../compras/compras-productos-dialog/compras-productos-dialog.component';
import { VentasFormasPagoDialogComponent } from '../ventas-formas-pago-dialog/ventas-formas-pago-dialog.component';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { PreventaModel } from 'src/app/models/preventa-model';
import { PreventasFormaPagoModel } from 'src/app/models/preventas-forma-pago-model';
import { PreventasProductosModel } from 'src/app/models/preventas-productos-model';
import { combineLatest } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { formatNumeroNacional } from 'src/app/utiles';

@Component({
  selector: 'app-ventas-nueva',
  templateUrl: './ventas-nueva.component.html',
  styleUrls: ['./ventas-nueva.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VentasNuevaComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  modo = 'nuevo'; //modos:  0 'nuevo', 1 'edicion', 2 'confirmar'

  formasDePago: any[] = [];
  agregados: any[] = [];

  sucursalesCont: any[] = [];

  preventa: any = {
    idpreventa: 0,
    importe: 0.0,
    observaciones: "",
    domicilio: "",
    flete: 0.0,
    descuentoGeneralPorcentaje: 0.0,
    descuentoGeneralImporte: 0.0,
    recargoGeneralPorcentaje: 0.0,
    recargoGeneralImporte: 0.0
  }

  //////

  listItems: any[] = [];

  listVendedores: VendedoresModel[] = [];

  listSucursales: any[] = [];

  listLocalidades: any[] = [];

  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  localidad = {
    nombre: "",
    idlocalidad: 0
  };

  precioTotal = 0;

  totalProductos = 0.0;
  totalFormaPago = 0.0;
  totalRecargo = 0.0;
  totalFormaPagoConRecargo = 0.0;

  observaciones = "";

  cargando = false;

  listCliente: ClientesCompletoModel[] = [];
  filteredClientes: Observable<ClientesCompletoModel[]> = new Observable();

  cliente:ClientesCompletoModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    codigo_localidad: 0,
    nombre_localidad: "",
    codigo_postal: "",
    telefono: "",
    correo_electronico: "",
    observaciones: "",
  };

  formGroup: FormGroup;

  //tabla que contiene los productos
  displayedColumns: string[] = ['id', 'producto', 'total'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  expandedElement: any | null;
  //

  //tabla forma de pago
  dataSourceFP = new MatTableDataSource<any>(ELEMENT_DATA);
  displayedColumnsFP: string[] = ['formaPago', 'senia', 'importe', 'recargo', 'totalConRecargo'];
  //

  fcNum = formatNumeroNacional;

  rolAdmin = false;

  accionPrecioGeneral: 'normal' | 'descuento' | 'recargo' = 'normal';

  constructor(
    private _location: Location,
    private dialog: MatDialog,
    private dialogInfo: MatDialog,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    public router: Router,
    private _formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private _permissionsService: NgxPermissionsService
  ) {

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        console.log("......", params.dict);
      }
    )

    console.log("renderiza ventas-nueva-component");

    this.sucursalActual = this.sharedService.getSucursalActual();

    this.formGroup = this._formBuilder.group({
      clienteCtrl: ['', Validators.required]
    });

    const path = this.activatedRoute.snapshot.routeConfig.path;

    this.preventa.idpreventa = Number(this.activatedRoute.snapshot.paramMap.get('idpreventa'));
    let tipo = Number(this.activatedRoute.snapshot.paramMap.get('tipo'));

    if (tipo == 0 && path == 'nuevo-presupuesto'){
      tipo = 4;
    }

    let pe = this._permissionsService.getPermissions();
    if (('10' in pe)){
      this.rolAdmin = true;
    }

    if (Number.isInteger(tipo)){
      //chequeo permisos y si el estado previo es valido
      let p = this._permissionsService.getPermissions();
      switch(tipo){
        case 1:
          this.modo = 'edicion';
          break;
        case 2:
          this.modo = 'confirmar';
          if (!('10' in p)){
            this.router.navigateByUrl(`/ventas/venta/lista`,);
          }
          break;
        case 3:
          this.modo = 'confirmar-edicion';
          if (!('10' in p)){
            this.router.navigateByUrl(`/ventas/venta/lista`,);
          }
          break;
        case 4:
          this.modo = 'presupuesto-nuevo'
          break;
        case 5:
          this.modo = 'presupuesto-edicion'
          break;
        default:
          this.modo = 'nuevo';
          break;
      }
      
    }

  }

  ngOnInit(): void {

    let obsVendedoresList = this.apiService.getVendedoresList();
    let obsClientesCompletoList = this.apiService.getClientesCompletoList();
    let obsSucursalesList = this.apiService.getSucursalesList();
    let obsLocalidadesList = this.apiService.getLocalidadesList();
    
    combineLatest([obsVendedoresList, obsClientesCompletoList, obsSucursalesList, obsLocalidadesList]).subscribe((hh: any[]) =>{

      this.listVendedores = hh[0];

      this.listCliente = [];
      this.listCliente = hh[1];

      this.listSucursales = [];
      this.listSucursales = hh[2];

      this.listLocalidades = [];
      this.listLocalidades = hh[3];

      if (this.preventa.idpreventa > 0){
        this.apiService.getPreventa(this.preventa.idpreventa).subscribe((x: any) => {
          console.log("preventa", x);
          this.preventa.observaciones = x.observaciones;
          this.preventa.domicilio = x.domicilio;
          this.preventa.flete = x.flete;
          this.preventa.descuentoGeneralPorcentaje = x.porcentaje_descuento;
          this.preventa.descuentoGeneralImporte = x.importe_descuento;
          this.preventa.recargoGeneralPorcentaje = x.porcentaje_recargo;
          this.preventa.recargoGeneralImporte = x.importe_recargo;


          if (this.preventa.recargoGeneralImporte > 0){
            this.accionPrecioGeneral = 'recargo';
          }

          if (this.preventa.descuentoGeneralImporte > 0){
            this.accionPrecioGeneral = 'descuento';
          }

          this.preventa.estado = x.estado;

          let cli = this.listCliente.find(c => c.idcliente == x.idcliente);
          if (cli != null){
            this.cliente = cli as ClientesCompletoModel;
          }

          let ven = this.listVendedores.find(v => v.idvendedor == x.idvendedor);
          if (ven != null){
            this.vendedor = {
              idvendedor: ven.idvendedor,
              nombre: ven.nombre
            }
          }

          let loc = this.listLocalidades.find(v => v.idlocalidad == x.idlocalidad);
          if (loc != null){
            this.localidad = {
              idlocalidad: loc.idlocalidad,
              nombre: `${loc.nombre} (${loc.codigo_postal})`
            }
          }

          this.calcularImporte();

        });

        this.apiService.getPreventasProductoCompleto(this.preventa.idpreventa, this.sucursalActual.sucursalId).subscribe((x: any[]) => {

          console.log("recupero producto completo", x);

          let mapSuc: Map<number, any> = new Map();

          x.forEach(xx => {
            if(mapSuc.has(xx.idsucursal)){
              let e = mapSuc.get(xx.idsucursal);
              if (e.mapProd.has(xx.idproducto)){
                let ee = e.mapProd.get(xx.idproducto);
                console.log("ee", ee);
                ee.presentaciones.push(xx);
              }else{
                e.mapProd.set(xx.idproducto, {
                  idproducto: xx.idproducto,
                  productoDescripcion: xx.productoDescripcion,
                  productoActivo: xx.productoActivo,
                  presentaciones: [xx]
                });
              }
            }else{
              let mapProd: Map<number, any> = new Map();
              mapSuc.set(xx.idsucursal, {
                idsucursal: xx.idsucursal,
                sucursalDescripcion: xx.sucursalDescripcion,
                mapProd: mapProd
              });

              mapProd.set(xx.idproducto, {
                idproducto: xx.idproducto,
                productoDescripcion: xx.productoDescripcion,
                productoActivo: xx.productoActivo,
                presentaciones: [xx]
              });

            }
          });

          console.log("kk", mapSuc);

          let sucus =[ ...mapSuc.values() ];

          sucus.forEach(k => {
            let prods = [...k.mapProd.values()];

            k.productos = prods;

            let agreg: any[] = [];
            k.productos.forEach((kk: any) => {
              
              let prod: any[] = []

              kk.presentaciones.forEach((kkk: any) => {
                let p = {
                  cantidad: kkk.cantidad,
                  colorDescripcion: kkk.colorDescripcion,
                  descripcion: kkk.colorDescripcion + " | " + kkk.medidaDescripcion,
                  idproducto: kkk.idproducto,
                  idproductopresentacion: kkk.idproductopresentacion,
                  importe: kkk.importe,
                  medidaDescripcion: kkk.medidaDescripcion,
                  precio: kkk.precio,
                  productoDescripcion: kkk.productoDescripcion,
                  productoActivo: kkk.productoActivo,
                  stock: kkk.stock,
                  importe_descuento: kkk.importe_descuento,
                  porcentaje_descuento: kkk.porcentaje_descuento
                }
                prod.push(p);
              })

              agreg.push({
                descripcion: kk.productoDescripcion,
                activo: kk.productoActivo,
                idproducto: kk.idproducto,
                presentaciones: prod
              });
            })

            this.sucursalesCont.push({
              idsucursal: k.idsucursal,
              nombre: k.sucursalDescripcion,
              agregados: agreg
            })

            

          });

          this.calcularImporte();

        });

        this.apiService.getPreventasFormaPagoPropuestaCompleto(this.preventa.idpreventa).subscribe((x: any[]) => {

          x.forEach(xx => {
            this.formasDePago.push({
              idpreventa: xx.idpreventa,
              idpreventaformapago: xx.idpreventaformapago,
              idtipo: xx.idtipo,
              importe: xx.importe,
              recargo: xx.recargo,
              senia: xx.senia > 0 ? true : false,
              tipoDescripcion: xx.tipo_descripcion,
            });
          });

          this.calcularImporteFormaPago();
          this.recargarTablaFormaPago();

        });

      }else{
        this.agregarSucursalActual();
      }

    });

    this.filteredClientes = this.formGroup.get("clienteCtrl")!.valueChanges.pipe(
      tap(value => {
        this.cliente = value;
        console.log("cliente", this.cliente);
      }),
      startWith(''),
      map(value => this._filter(value)),
    );

  }

  clickGuardar(){
    if (this.modo == 'nuevo'){
      this.guardarNuevo();
    }

    if (this.modo == 'confirmar'){
      this.guardarConfirmar();
    }

    if (this.modo == 'edicion'){
      this.guardarEdicion();
    }

    if (this.modo == 'presupuesto-nuevo'){
      this.guardarNuevoPresupuesto();
    }
   
  }

  guardarNuevo(){
    this.guardar('nuevo');
  }

  guardarEdicion(){
    this.guardar('edicion');
  }

  guardarNuevoPresupuesto(){
    this.guardar('nuevo-presupuesto', 4);
  }

  guardar(tipo: string, estadoInicial?: number){

    console.log("guardar", tipo);

    if (!(this.vendedor.idvendedor > 0)){
      this.snackBarService.errorMessage("El vendedor no es valido.");
      return;
    }

    if (!(this.localidad.idlocalidad > 0)){
      this.snackBarService.errorMessage("Debe indicar una localidad.");
      return;
    }

    if (this.preventa.domicilio.length == 0){
      this.snackBarService.errorMessage("Debe indicar un domicilio");
      return;
    }

    if (isNaN(this.preventa.flete)){
      this.snackBarService.errorMessage("El valor indicado en el flete no es valido");
      return;
    }

    let cantidadItems = 0;
    let importeTotal = 0.0
    this.sucursalesCont.forEach((x: any) => {
      x.agregados.forEach((y: any) => {
        y.presentaciones.forEach((z: any) => {
          cantidadItems += z.cantidad;
          importeTotal += z.cantidad * z.precio;
        });
      })
    });

    importeTotal = importeTotal + this.preventa.flete;

    if (cantidadItems == 0){
      this.snackBarService.errorMessage("Debe ingresar al menos un producto .");
      return;
    }

    let pagoTotal = 0.0;
    this.formasDePago.forEach(x => {
      pagoTotal += x.importe;
    });

    let debe = false;

    if (pagoTotal < importeTotal){
      if (this.cliente.idcliente == 0){
        this.snackBarService.errorMessage("Para dejar saldo negativo debe ingresar un cliente.");
        return;
      }else{
        debe = true;
      }
    }

    let preventa: any = {
      idpreventa: 0,
      idvendedor: this.vendedor.idvendedor,
      observaciones: this.observaciones,
      listItems: this.listItems,
      cliente: this.cliente
    }

    ///calculamos si debe algo

    let fc = () => {
      
      console.log("guardarNuevo");

      let idCliente = 0
      if (this.preventa.cliente != null){
        idCliente = this.preventa.cliente.idcliente;
      }

      let preventaConfirmada: PreventaModel = {
        flete: this.preventa.flete,
        idpreventa: tipo == 'nuevo' ? 0 : this.preventa.idpreventa,
        idvendedor: this.vendedor.idvendedor,
        observaciones: this.preventa.observaciones,
        senia: 0,
        idcliente: this.cliente.idcliente,
        estado: estadoInicial ? estadoInicial : 0,
        domicilio: this.preventa.domicilio,
        idlocalidad: this.localidad.idlocalidad,
        idsucursal: this.sucursalActual.sucursalId,
        importe_descuento: this.preventa.descuentoGeneralImporte,
        porcentaje_descuento: this.preventa.descuentoGeneralPorcentaje,
        importe_recargo: this.preventa.recargoGeneralImporte,
        porcentaje_recargo: this.preventa.recargoGeneralPorcentaje,
        esPresupuesto: this.preventa.estado == 4
      }

      let formaPagoItems: PreventasFormaPagoModel [] = [];

      this.formasDePago.forEach((x: any) => {
        let formaPago: PreventasFormaPagoModel = {
          idpreventa: 0,
          idpreventaformapago: 0,
          idtipo: x.idtipo,
          importe: x.importe,
          recargo: x.recargo,
          tipo_descripcion: x.tipoDescripcion,
          senia: x.senia
        };
        formaPagoItems.push(formaPago);
      })

      console.log("formas de pago items", formaPagoItems);

      let preventaItems: PreventasProductosModel[] = [];
      this.sucursalesCont.forEach((x: any) => {
        x.agregados.forEach((y: any) => {
          y.presentaciones.forEach((z: any) => {
            let n: PreventasProductosModel = {
              idpreventaproducto: 0,
              idpreventa: 0,
              idproducto: z.idproducto,
              observaciones: "",
              cantidad: z.cantidad,
              precio_unitario: z.precio,
              idproductopresentacion: z.idproductopresentacion,
              idsucursal: x.idsucursal,
              importe_descuento: z.importe_descuento,
              porcentaje_descuento: z.porcentaje_descuento
            };
            preventaItems.push(n);
          })
        })
      });

      let data = {
        preventa: preventaConfirmada,
        preventaProductos: preventaItems,
        preventaFormaPago: formaPagoItems
      }

      this.cargando = true;

      if (tipo == 'nuevo'){

        this.apiService.postPreventaNueva(data).subscribe(x => {
          this.cargando = false;
          console.log("x", x);
          if (x){
            this.snackBarService.successMessage("Se genero la preventa");
            this.router.navigateByUrl(`/ventas/venta/lista`,);
          }else{
            this.snackBarService.errorMessage("Ocurrio un error al generar la preventa");
          }
                  
        }, e => {
          console.log("e", e);
          this.snackBarService.errorMessage("Ocurrio un error al generar la preventa.");
          this.cargando = false;
        });

      }

      if (tipo == 'nuevo-presupuesto'){

        this.apiService.postPreventaNueva(data).subscribe(x => {
          this.cargando = false;
          console.log("x", x);
          if (x){
            this.snackBarService.successMessage("Se generó el presupuesto");
            this.router.navigateByUrl(`/ventas/venta/lista`,);
          }else{
            this.snackBarService.errorMessage("Ocurrio un error al generar el presupuesto");
          }
                  
        }, e => {
          console.log("e", e);
          this.snackBarService.errorMessage("Ocurrio un error al generar el presupuesto.");
          this.cargando = false;
        });

      }

      if (tipo == 'edicion'){

        this.apiService.postPreventaEditar(data, 0).subscribe(x => {
          this.cargando = false;
          console.log("x", x);
          if (x){
            this.snackBarService.successMessage("Se editó la preventa");
            this.router.navigateByUrl(`/ventas/venta/lista`,);
          }else{
            this.snackBarService.errorMessage("Ocurrio un error al editar la preventa");
          }
                  
        }, e => {
          console.log("e", e);
          this.snackBarService.errorMessage("Ocurrio un error interno al editar la preventa.");
          this.cargando = false;
        });


      }
      
      
    };

    if (debe && !(tipo == 'nuevo-presupuesto')){
      this.dialogMsg("Información", "Los pagos no cubren el costo total de la compra, desea continuar?", fc)
    }else{
      if (tipo == "nuevo"){
        this.dialogMsg("Información", "Confirma que desea generar la preventa?", fc)
      }
      if (tipo == "edicion"){
        this.dialogMsg("Información", "Confirma que desea editar la preventa?", fc)
      }
      if (tipo == "nuevo-presupuesto"){
        this.dialogMsg("Información", "Confirma que desea generar el presupuesto?", fc)
      }
    }

    //this.abrirDialogConfirmar(preventa);

  }

  guardarConfirmar(){

    console.log("confirmar");

    if (!(this.vendedor.idvendedor > 0)){
      this.snackBarService.errorMessage("El vendedor no es valido.");
      return;
    }

    let cantidadItems = 0;
    let importeTotal = 0.0
    this.sucursalesCont.forEach((x: any) => {
      x.agregados.forEach((y: any) => {
        y.presentaciones.forEach((z: any) => {
          cantidadItems += z.cantidad;
          importeTotal += z.cantidad * z.precio;
        });
      })
    })

    if (cantidadItems == 0){
      this.snackBarService.errorMessage("Debe ingresar al menos un producto .");
      return;
    }

    let pagoTotal = 0.0;
    this.formasDePago.forEach(x => {
      pagoTotal += x.importe;
    });

    let debe = false;

    /*
    if (pagoTotal > importeTotal){
      this.snackBarService.errorMessage("Esta acreditando mas que el importe total.");
      return;
    }else{
     
    }
    */

    if (pagoTotal < importeTotal){
      if (this.cliente.idcliente == 0){
        this.snackBarService.errorMessage("Para dejar saldo negativo debe ingresar un cliente.");
        return;
      }else{
        debe = true;
      }
    }


    let preventa: any = {
      idpreventa: 0,
      idvendedor: this.vendedor.idvendedor,
      observaciones: this.observaciones,
      listItems: this.listItems,
      cliente: this.cliente
    }

    ///calculamos si debe algo

    let fc = () => {

      console.log("guardarConfirmar");

      this.cargando = true;

      let idpreventa = this.preventa.idpreventa;

      let idCliente = 0
      if (this.preventa.cliente != null){
        idCliente = this.preventa.cliente.idcliente;
      }

      let preventaConfirmada: any = {
        flete: this.preventa.flete,
        idpreventa: idpreventa,
        idvendedor: this.vendedor.idvendedor,
        observaciones: this.preventa.observaciones,
        senia: 0,
        idcliente: this.cliente.idcliente,
        estado: 0,
        fecha: new Date,
        domicilio: this.preventa.domicilio,
        idlocalidad: this.localidad.idlocalidad,
        idsucursal: this.sucursalActual.sucursalId,
        importe_descuento: this.preventa.descuentoGeneralImporte,
        porcentaje_descuento: this.preventa.descuentoGeneralPorcentaje,
        importe_recargo: this.preventa.recargoGeneralImporte,
        porcentaje_recargo: this.preventa.recargoGeneralPorcentaje
      }      
  
      let formaPagoItems: PreventasFormaPagoModel [] = [];

      this.formasDePago.forEach((x: any) => {
        let formaPago: PreventasFormaPagoModel = {
          idpreventa: idpreventa,
          idpreventaformapago: 0,
          idtipo: x.idtipo,
          importe: x.importe,
          recargo: x.recargo,
          tipo_descripcion: x.tipoDescripcion,
          senia: x.senia
        };
        formaPagoItems.push(formaPago);
      })

      console.log("formas de pago items", formaPagoItems);

      let preventaItems: PreventasProductosModel[] = [];
      this.sucursalesCont.forEach((x: any) => {
        x.agregados.forEach((y: any) => {
          y.presentaciones.forEach((z: any) => {
            let n: PreventasProductosModel = {
              idpreventaproducto: 0,
              idpreventa: idpreventa,
              idproducto: z.idproducto,
              observaciones: "",
              cantidad: z.cantidad,
              precio_unitario: z.precio,
              idproductopresentacion: z.idproductopresentacion,
              idsucursal: x.idsucursal,
              importe_descuento: z.importe_descuento,
              porcentaje_descuento: z.porcentaje_descuento
            };
            preventaItems.push(n);
          })
        })
      });

      let data = {
        preventa: preventaConfirmada,
        preventaProductos: preventaItems,
        preventaFormaPago: formaPagoItems,
        sucursalActual: this.sucursalActual.sucursalId
      }

      this.cargando = true;

      console.log("data", data);

      //return;
      
      this.apiService.postPreventaConfirmada(data).subscribe(x => {
        this.cargando = false;
        console.log("x", x);
        if (x){
          this.snackBarService.successMessage("Se confirmo la preventa");
          this.router.navigateByUrl(`/ventas/venta/lista`,);
        }else{
          this.snackBarService.errorMessage("Ocurrio un error al confirmar la preventa");
        }
                
      }, e => {
        console.log("e", e);
        this.snackBarService.errorMessage("Ocurrio un error al generar la preventa.");
        this.cargando = false;
      });
        
    }

    if (debe){
      this.dialogMsg("Información", "Los pagos de la propuesta de pagos no cubren el costo total de la compra, desea confirmar la venta de todos modos?", fc)
    }else{
      this.dialogMsg("Información", "Confirma que desea CONFIRMAR la preventa?", fc)
    }

  }

  clickBack(){
    this._location.back();
  }

  abrirDialogConfirmar(preventa: any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    /*
    dialogConfig.width = '90vw';
    dialogConfig.maxWidth = "560px;"
    dialogConfig.height = '90vh';
    */

    dialogConfig.data = {
      preventa: preventa
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(PreventaConfirmarComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventa confirmar", rr);
      
    });
  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {

      if (!this.rolAdmin){
        if (x.idsucursal == this.sucursalActual.sucursalId){
          let item = {
            id: x.idvendedor,
            nombre: x.nombre
          }
          coleccion.push(item);
        }
      }else{
        let item = {
          id: x.idvendedor,
          nombre: x.nombre
        }
        coleccion.push(item);
      }

    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }
        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }
      }
    });
  }

  clickSeleccionarLocalidad(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listLocalidades.forEach((x: any) => {
      let item = {
        id: x.idlocalidad,
        nombre: `${x.nombre} (${x.codigo_postal})`
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Localidad",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Localidad"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.localidad.idlocalidad,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.localidad = {
            nombre: rr.seleccion.nombre,
            idlocalidad: rr.seleccion.id 
          }
        }else{
          this.localidad = {nombre: "", idlocalidad: 0};
        }
      }
    });
  }

  clickSeleccionarCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listCliente.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: true,
      referenciaNuevo: "cliente"
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          let clienteSel = this.listCliente.find(x => x.idcliente == rr.seleccion.id);

          if (clienteSel == null){
            this.apiService.getClientesCompletoList().subscribe(x => {
              this.listCliente = [];
              this.listCliente = x;
              let cs = this.listCliente.find(x => x.idcliente == rr.seleccion.id);
              this.cliente = cs as ClientesCompletoModel;
            });
          }else{
            this.cliente = clienteSel as ClientesCompletoModel
          }

        }else{
          this.cliente = {
            idcliente: 0,
            nombre: "",
            domicilio: "",
            codigo_localidad: 0,
            nombre_localidad: "",
            codigo_postal: "",
            telefono: "",
            correo_electronico: "",
            observaciones: "",
          };
        }
        
      }
    });
  }

  clickAgregar(suc: any){

    console.log("suc", suc);

    this.dialogAgregar(suc);
  }

  clickAgregarSucursal(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Agregar Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: null,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          let existe = this.sucursalesCont.find(x => x.idsucursal == rr.seleccion.id);
          if (!existe){
            this.sucursalesCont.push({
              idsucursal: rr.seleccion.id,
              nombre: rr.seleccion.nombre,
              agregados: []
            });
          }
        }        
      }
    });
  }

  clickQuitarSucursal(suc){
    let sucIndex = this.sucursalesCont.findIndex(x => x.idsucursal == suc.idsucursal);
    if (sucIndex != -1 && this.sucursalesCont.length > 1){
      this.sucursalesCont.splice(sucIndex, 1);
    }
  }


  agregarSucursalActual(){
    let existe = this.sucursalesCont.find(x => x.idsucursal == this.sucursalActual.sucursalId);
    if (!existe){
      this.sucursalesCont.push({
        idsucursal: this.sucursalActual.sucursalId,
        nombre: this.sucursalActual.sucursalNombre,
        agregados: []
      });
    }
  }

  clickAgregarFormaPago(){
    this.dialogFormaPago()
  }


  /////dialogs calls

  dialogAgregar(suc: any) {
    const dialogConfig = new MatDialogConfig();

    //let agreg = suc.agregados;

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80vw';
    dialogConfig.minWidth = '560px';
    dialogConfig.data = {
      agregados: suc.agregados,
      muestraImporte: false,
      muestraPrecio: true,
      muestraSucursal: true,
      muestraStock: true,
      muestraDescuento: true,
      estadoProducto: null,
      sucursal: {
        idsucursal: suc.idsucursal,
        nombre: suc.nombre
      }
    };

    const dialogRef = this.dialog.open(ComprasProductosDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((r: any) => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          suc.agregados = [];
          suc.agregados = r.agregados;

          /*
          let total = 0.0;
          suc.agregados.forEach((x: any) => {
            x.presentaciones.forEach((z: any) => {
              total += z.importe * z.cantidad;
            });
          });
          */
          this.calcularImporte();
          this.recargarTablaProducto(suc);

          //this.preventa.importe = total;

        }
      }
    });

  }

  dialogFormaPago() {

    this.calcularImporte();

    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      formasDePago: this.formasDePago,
      importe: this.preventa.importe
    };

    const dialogRef = this.dialog.open(VentasFormasPagoDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.formasDePago = r.formasDePago;
          this.calcularImporteFormaPago();

          this.dataSourceFP.data = this.formasDePago;
          
        }
      }
    });

  }

  dialogMsg(titulo: string, mensaje: string, fc: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionB: 'Si',
      captionAccionC: 'No'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //Si
        fc();
      } 
    });

  }

  calcularImporte(){

    let total = 0.0;


    this.sucursalesCont.forEach((x: any) => {
      let subTotalSucursal = 0.0;
      x.agregados.forEach((y: any) => {
        let subTotalProducto = 0.0;
        y.presentaciones.forEach((z: any) => {
          subTotalProducto += (z.precio - z.importe_descuento) * z.cantidad;
          total += (z.precio - z.importe_descuento) * z.cantidad;
        });
        y.subTotalProducto = subTotalProducto;
        subTotalSucursal += subTotalProducto;
      });
      x.subTotalSucursal = subTotalSucursal;
    });

    let flete = this.preventa.flete;

    this.totalProductos = total;

    switch(this.accionPrecioGeneral){
      case 'normal':
        this.preventa.importe = total + flete;
        break;
      case 'descuento':
        this.preventa.importe = total + flete - this.preventa.descuentoGeneralImporte;
        this.preventa.descuentoGeneralPorcentaje = Number(((this.preventa.descuentoGeneralImporte / (this.totalProductos + this.preventa.flete)) * 100).toFixed(2));
        break;
      case 'recargo':
        this.preventa.importe = total + flete + this.preventa.recargoGeneralImporte;
        this.preventa.recargoGeneralPorcentaje = Number(((this.preventa.recargoGeneralImporte / (this.totalProductos + this.preventa.flete)) * 100).toFixed(2));
        break;
    }

    


  }

  calcularImporteFormaPago(){
    this.totalFormaPago = 0.0;
    this.totalFormaPagoConRecargo = 0.0;
    this.totalRecargo = 0.0;

    this.formasDePago.forEach(xx => {
      this.totalFormaPago = this.totalFormaPago + xx.importe;
      this.totalRecargo = this.totalRecargo + ((xx.importe * xx.recargo) / 100);
    });
    
    this.totalFormaPagoConRecargo = this.totalFormaPago + this.totalRecargo;
  }

  onChangeFlete(event: any){
    console.log("flete", this.preventa.flete);
    if (this.preventa.flete == null){
      this.preventa.flete = 0.0;
    }

    this.preventa.importe = this.totalProductos + this.preventa.flete - this.preventa.descuentoGeneralImporte;
    this.preventa.descuentoGeneralPorcentaje = Number(((this.preventa.descuentoGeneralImporte / (this.totalProductos + this.preventa.flete)) * 100).toFixed(2));
    
  }

  onChangeDescuentoGeneralImporte(event: any){
    console.log("descuento general", this.preventa.descuentoGeneral);
    if (this.preventa.descuentoGeneralImporte == null || this.preventa.descuentoGeneralImporte < 0){
      this.preventa.descuentoGeneralImporte = 0.0;
    }
    this.preventa.importe = this.totalProductos + this.preventa.flete - this.preventa.descuentoGeneralImporte;
    this.preventa.descuentoGeneralPorcentaje = Number(((this.preventa.descuentoGeneralImporte / (this.totalProductos + this.preventa.flete)) * 100).toFixed(2));

  }

  onChangeDescuentoGeneralPorcentaje(event: any){
    if (this.preventa.descuentoGeneralPorcentaje == null || this.preventa.descuentoGeneralPorcentaje < 0){
      this.preventa.descuentoGeneralPorcentaje = 0.0;
    }
    
    this.preventa.descuentoGeneralImporte =  Number(((this.preventa.descuentoGeneralPorcentaje / 100) * (this.totalProductos + this.preventa.flete)).toFixed(2));
    this.preventa.importe = this.totalProductos + this.preventa.flete - this.preventa.descuentoGeneralImporte;

  }

  onChangeRecargoGeneralImporte(event: any){
    console.log("descuento general", this.preventa.recargoGeneral);
    if (this.preventa.recargoGeneralImporte == null || this.preventa.recargoGeneralImporte < 0){
      this.preventa.recargoGeneralImporte = 0.0;
    }
    this.preventa.importe = this.totalProductos + this.preventa.flete + this.preventa.recargoGeneralImporte;
    this.preventa.recargoGeneralPorcentaje = Number(((this.preventa.recargoGeneralImporte / (this.totalProductos + this.preventa.flete)) * 100).toFixed(2));

  }

  onChangeRecargoGeneralPorcentaje(event: any){
    if (this.preventa.recargoGeneralPorcentaje == null || this.preventa.recargoGeneralPorcentaje < 0){
      this.preventa.recargoGeneralPorcentaje = 0.0;
    }
    
    this.preventa.recargoGeneralImporte =  Number(((this.preventa.recargoGeneralPorcentaje / 100) * (this.totalProductos + this.preventa.flete)).toFixed(2));
    this.preventa.importe = this.totalProductos + this.preventa.flete - this.preventa.recargoGeneralImporte;

  }

  onTabChanged(event: any){
    console.log("onTabChanged", event);
    console.log("sucursal", );
    this.recargarTablaProducto(this.sucursalesCont[event.index]);
  }

  onChangeAccionPrecio(event: any){
    console.log("event", event);
    //reseteamos todo

    this.preventa.recargoGeneralPorcentaje = 0.0;
    this.preventa.recargoGeneralImporte = 0.0;

    this.preventa.descuentoGeneralPorcentaje = 0.0;
    this.preventa.descuentoGeneralImporte = 0.0;

    this.calcularImporte();

  }

  recargarTablaProducto(sucursal: any){
    this.dataSource.data = sucursal.agregados;
  }

  recargarTablaFormaPago(){
    this.dataSourceFP.data = this.formasDePago;
  }
 

  ///

  /*
  calcularPrecios(){
    let sum = 0;
    this.listItems.forEach(p => {
      let sumProd = 0;
      p.items.forEach((i: any) => {
        let precioPresentacion = i.precio * i._agregados_cantidad;
        sumProd = sumProd + precioPresentacion;
      });
      p.totalPrecioFinal = sumProd;
      sum = sum + Number(p.totalPrecioFinal);
    });
    this.precioTotal = sum;
  }
  */


  private _filter(value: string): ClientesCompletoModel[] {
    const filterValue = value.toLowerCase();
    return this.listCliente.filter(cliente => cliente.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayProperty(value: ClientesCompletoModel) {
    return value.nombre;
  }

}


const ELEMENT_DATA: any[] = [];
