import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { toF } from 'src/app/utiles';
import { ComprasFormasPagoDialogComponent } from '../../compras/compras-formas-pago-dialog/compras-formas-pago-dialog.component';
import { ComprasProductosDialogComponent } from '../../compras/compras-productos-dialog/compras-productos-dialog.component';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { Location } from '@angular/common';
import { combineLatest } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';



@Component({
  selector: 'app-logistica-movimiento-nuevo',
  templateUrl: './logistica-movimiento-nuevo.component.html',
  styleUrls: ['./logistica-movimiento-nuevo.component.scss']
})
export class LogisticaMovimientoNuevoComponent implements OnInit {

  movimiento: any = {
    idlogisticamovimiento: 0,
    origen: 0,
    origenNombre: "",
    destino: 0,
    destinoNombre: "",
    obs: "",
    tipo_movimiento: 0
  }

  modo = 'nuevo'; //modos: 'nuevo', 'envio'

  seleccionTipo: 'envio' | 'pedido' = 'pedido';

  listSucursales: any[] = [];

  agregados: any[] = [];
  agregadosConfirmados: any[] = [];

  esperados: any[] = [];

  cargando = false;

  estado = 0;

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {

    this.sucursalActual = this.sharedService.getSucursalActual();
    
    this.movimiento.idlogisticamovimiento = Number(this.activatedRoute.snapshot.paramMap.get('id'));
    let tipo = this.activatedRoute.snapshot.paramMap.get('tipo')?.toLocaleLowerCase();

    console.log('tipo', tipo);

    switch(tipo){
      case 'nuevo':
        this.modo = 'nuevo';
        break;
      case 'solicitar':
        this.modo = 'solicitar';
        break;
      case 'enviar':
        this.modo = 'enviar';
        break;
      case 'recibir':
        this.modo = 'recibir';
        break;
      default:
        this.modo = 'nuevo';
        //404
        break;
      
    }

    console.log("modo", this.modo);

    if (this.movimiento.idlogisticamovimiento > 0 && this.modo != 'nuevo'){

      this.cargando = true;

      let sucursales = this.apiService.getSucursalesList();
      let movimientos = this.apiService.getLogisticaMovimientosById(this.movimiento.idlogisticamovimiento);

      let combinar = [sucursales, movimientos];
      
      if (this.modo == 'solicitar'){
        let solicitudes = this.apiService.getLogisticaMovimientosPresentacionesSolById(this.movimiento.idlogisticamovimiento);
        combinar.push(solicitudes);
      }

      if (this.modo == 'enviar'){
        let enviados = this.apiService.getLogisticaMovimientosPresentacionesEnvById(this.movimiento.idlogisticamovimiento);
        combinar.push(enviados);

        let solicitudes = this.apiService.getLogisticaMovimientosPresentacionesSolById(this.movimiento.idlogisticamovimiento);
        combinar.push(solicitudes);
      }


      if (this.modo == 'recibir'){
        let recibidos = this.apiService.getLogisticaMovimientosPresentacionesRecById(this.movimiento.idlogisticamovimiento);
        combinar.push(recibidos);

        let enviados = this.apiService.getLogisticaMovimientosPresentacionesEnvById(this.movimiento.idlogisticamovimiento);
        combinar.push(enviados);

      }
  
      combineLatest(combinar).subscribe((hh: any[]) =>{

        console.log("hh", hh);
  
        this.listSucursales = hh[0];
        let m: any[] = hh[1];
        let listIzquierda: any[] = hh[2];

        let listDerecha: any[] = [];
        if (hh.length > 3){
          listDerecha = hh[3];
        }        

        if (m.length > 0){
          this.movimiento.origen = m[0].origen;
          this.movimiento.destino = m[0].destino;
          this.movimiento.obs = m[0].obs;
          this.movimiento.tipo_movimiento = m[0].tipo_movimiento;
          this.estado = m[0].estado;

          if (m[0].tipo_movimiento == 0){
            this.seleccionTipo = 'pedido';
          }

          if (m[0].tipo_movimiento == 1){
            this.seleccionTipo = 'envio';
          }

        }

        if (
          (this.modo == 'enviar' && this.estado != 1) ||
          (this.modo == 'recibir' && this.estado != 2) ||
          (this.modo == 'solicitar' && this.estado != 0)
        ){
          //404
          this.router.navigateByUrl(`/logistica/movimientos`,);
          return;
        }
        
        console.log("hh", hh);

        let mapIzquierda: Map<number, any> = new Map();
        listIzquierda.forEach(x => {

          let item: any = {
            cantidad: x.cantidad,
            colorDescripcion: x.color_descripcion,
            descripcion: x.producto_descripcion,
            idproducto: x.idproducto,
            idproductopresentacion: x.idproductopresentacion,
            importe: 0.0,
            medidaDescripcion: x.medida_descripcion,
            precio: 0.0,
            productoDescripcion: x.producto_descripcion,
            stock: 0
          }

          if (mapIzquierda.has(x.idproducto)){
            let d = mapIzquierda.get(x.idproducto);
            d.presentaciones.push(item);
          }else{
            let c = {
              descripcion: x.producto_descripcion,
              idproducto: x.idproducto,
              presentaciones: [] as any[]
            }
            c.presentaciones.push(item);
            mapIzquierda.set(x.idproducto, c);
          }
        });

        let mapDerecha: Map<number, any> = new Map();
        listDerecha.forEach(x => {

          let item: any = {
            cantidad: x.cantidad,
            colorDescripcion: x.color_descripcion,
            descripcion: x.producto_descripcion,
            idproducto: x.idproducto,
            idproductopresentacion: x.idproductopresentacion,
            importe: 0.0,
            medidaDescripcion: x.medida_descripcion,
            precio: 0.0,
            productoDescripcion: x.producto_descripcion,
            stock: 0
          }

          if (mapDerecha.has(x.idproducto)){
            let d = mapDerecha.get(x.idproducto);
            d.presentaciones.push(item);
          }else{
            let c = {
              descripcion: x.producto_descripcion,
              idproducto: x.idproducto,
              presentaciones: [] as any[]
            }
            c.presentaciones.push(item);
            mapDerecha.set(x.idproducto, c);
          }
        });

        switch(this.modo){
          case 'solicitar':
            this.agregados = [...mapIzquierda.values()];
            break;
          case 'enviar':
            this.agregados = [...mapIzquierda.values()];
            this.esperados = [...mapDerecha.values()];
            break;
          case 'recibir':
            this.agregados = [...mapIzquierda.values()];
            this.esperados = [...mapDerecha.values()];
            break;
          default:
            this.esperados = [...mapIzquierda.values() ];
            break;
        }
        
        console.log("agregados", this.agregados);
        console.log("esperados", this.esperados);

        this.cargando = false;

      });
      
      
    }else{

      if (this.modo == 'nuevo' && this.movimiento.idlogisticamovimiento == 0){
        this.apiService.getSucursalesList().subscribe(x => {
          this.listSucursales = x;
          this.refrescarOrigenDestino();
        });
      }else{
        this.router.navigateByUrl(`/logistica/movimientos`,);
      }

    }
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    
    if (this.movimiento.obs.length <= 0){
      this.snackBarService.errorMessage("Debe dar una breve observación.");
      return;
    }    

    if (this.movimiento.origen == 0){
      this.snackBarService.errorMessage("Debe indicar sucursal de origen.");
      return;
    }

    if (this.movimiento.destiono == 0){
      this.snackBarService.errorMessage("Debe indicar sucursal de destino.");
      return;
    }

    console.log("agregados", this.agregados);

    if (this.agregados.length == 0){
      this.snackBarService.errorMessage("Debe agregar al menos un producto.");
      return;
    }

    if (this.movimiento.origen != this.sucursalActual.sucursalId && this.movimiento.destino != this.sucursalActual.sucursalId){
      this.snackBarService.errorMessage("La sucursal origen o destino debe ser la sucursal actual");
      return;
    }

    switch (this.modo) {
      case 'nuevo':
        this.guardarNuevo();    
        break;
      case 'enviar': 
        this.guardarEnvio();
        break;
      case 'recibir': 
        this.guardarRecepcion();
        break;
      case 'solicitar': 
        this.guardarSolicitud();
        break;

      default:
        break;
    }
    

  }

  guardarNuevo(){
    switch(this.seleccionTipo){
      case 'envio':
        this.guardarNuevoEnvio();
        break;
      case 'pedido':
        this.guardarNuevoSolicitud();
        break;
    }
  }

  guardarNuevoSolicitud(){
    let solicitudes: any[] = []
    this.agregados.forEach(x => {
      let p: any[] = x.presentaciones;
      p.forEach(pp => {
        let n = {
          idlogisticamovimientosolicitud: 0,
          idproductopresentacion: pp.idproductopresentacion,
          cantidad: pp.cantidad,
          idlogisticamovimiento: this.movimiento.idlogisticamovimiento
        }
        solicitudes.push(n);
      })
    });

    let data: any = {
      general: {
        idlogisticamovimiento: this.movimiento.idlogisticamovimiento,
        origen: this.movimiento.origen,
        destino: this.movimiento.destino,
        estado: 0,
        obs: this.movimiento.obs,
        tipoMovimiento: 0
      },
      solicitudes: solicitudes
    }

    this.apiService.postLogisticaMovimientoNuevoSolicitud(data).subscribe(x => {
      if (x){
        this.cargando = false;
        this.router.navigateByUrl(`/logistica/movimientos`,);
      }
    });
  }

  guardarNuevoEnvio(){
    let enviados: any[] = []
    this.agregados.forEach(x => {
      let p: any[] = x.presentaciones;
      p.forEach(pp => {
        let n = {
          idlogisticamovimientoenviado: 0,
          idproductopresentacion: pp.idproductopresentacion,
          cantidad: pp.cantidad,
          idlogisticamovimiento: this.movimiento.idlogisticamovimiento
        }
        enviados.push(n);
      })
    });

    let data: any = {
      general: {
        idlogisticamovimiento: this.movimiento.idlogisticamovimiento,
        origen: this.movimiento.origen,
        destino: this.movimiento.destino,
        estado: 1,
        obs: this.movimiento.obs,
        tipoMovimiento: 1
      },
      enviados: enviados
    }

    this.apiService.postLogisticaMovimientoNuevoEnvio(data).subscribe(x => {
      if (x){
        this.cargando = false;
        this.router.navigateByUrl(`/logistica/movimientos`,);
      }
    });
  }

  guardarSolicitud(){
    let listStock: any[] = [];
    let listSolicitados: any[] = []
    this.agregados.forEach(x => {
      let p: any[] = x.presentaciones;
      p.forEach(pp => {

        let n = {
          idlogisticamovimientosolicitud: 0,
          idproductopresentacion: pp.idproductopresentacion,
          cantidad: pp.cantidad,
          idlogisticamovimiento: this.movimiento.idlogisticamovimiento
        }
        listSolicitados.push(n);

        let s = {
          idsucursal: this.movimiento.origen,
          cantidad: pp.cantidad,
          idproductopresentacion: pp.idproductopresentacion
        }
        listStock.push(s);

      });

    });

    let data = {
      general: {
        idlogisticamovimiento: this.movimiento.idlogisticamovimiento,
        origen: this.movimiento.origen,
        destino: this.movimiento.destino,
        obs: this.movimiento.obs
      },
      solicitados: listSolicitados
    }

    this.apiService.postLogisticaMovimientoSolicitar(data).subscribe(x => {
      if (x){
        //this.apiService.postPresentacionesSucursalesRestaStock(listStock).subscribe( xx => {
          //if (xx){
            this.cargando = false;
            this.router.navigateByUrl(`/logistica/movimientos`,);
          //}
        //});
      }
    });
  }

  guardarEnvio(){
    let listStock: any[] = [];
    let listEnviados: any[] = []
    this.agregados.forEach(x => {
      let p: any[] = x.presentaciones;
      p.forEach(pp => {

        let n = {
          idlogisticamovimientoenviado: 0,
          idproductopresentacion: pp.idproductopresentacion,
          cantidad: pp.cantidad,
          idlogisticamovimiento: this.movimiento.idlogisticamovimiento
        }
        listEnviados.push(n);

        let s = {
          idsucursal: this.movimiento.origen,
          cantidad: pp.cantidad,
          idproductopresentacion: pp.idproductopresentacion
        }
        listStock.push(s);

      });

    });

    let data = {
      general: {
        idlogisticamovimiento: this.movimiento.idlogisticamovimiento,
      },
      enviados: listEnviados
    }

    this.apiService.postLogisticaMovimientoEnviar(data).subscribe(x => {
      if (x){
        //this.apiService.postPresentacionesSucursalesRestaStock(listStock).subscribe( xx => {
          //if (xx){
            this.cargando = false;
            this.router.navigateByUrl(`/logistica/movimientos`,);
          //}
        //});
      }
    });
  }

  guardarRecepcion(){
    let listStock: any[] = [];
    let listRecibidos: any[] = []
    this.agregados.forEach(x => {
      let p: any[] = x.presentaciones;
      p.forEach(pp => {
        let n = {
          idlogisticamovimientorecibido: 0,
          idproductopresentacion: pp.idproductopresentacion,
          cantidad: pp.cantidad,
          idlogisticamovimiento: this.movimiento.idlogisticamovimiento
        }
        listRecibidos.push(n);

        let s = {
          idsucursal: this.movimiento.destino,
          cantidad: pp.cantidad,
          idproductopresentacion: pp.idproductopresentacion
        }
        listStock.push(s);

      })
    });

    let data = {
      general: {
        idlogisticamovimiento: this.movimiento.idlogisticamovimiento,
      },
      recibidos: listRecibidos
    }

    this.apiService.postLogisticaMovimientoRecibir(data).subscribe(x => {
      if (x){
        //this.apiService.postPresentacionesSucursalesSumaStock(listStock).subscribe( xx => {
          //if (xx){
            this.cargando = false;
            this.router.navigateByUrl(`/logistica/movimientos`,);
          //}
        //});
      }
    });
  }

  clickBack(){
    this._location.back();
  }

  clickAgregar(){
    this.dialogAgregar();
  }

  dialogAgregar() {

    if (this.movimiento.origen == 0){
      this.snackBarService.errorMessage("Debe proporcionar la sucursal de origen");
    }

    const dialogConfig = new MatDialogConfig();

    let sucursalId = this.modo == 'recibir' ? this.movimiento.destino : this.movimiento.origen;

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80vw';
    dialogConfig.minWidth = '560px';
    dialogConfig.data = {
      agregados: this.agregados,
      muestraImporte: false,
      muestraSucursal: true,
      muestraStock: true,
      sucursal: {
        idsucursal: sucursalId,
        nombre: this.listSucursales.find(x => x.idsucursal == sucursalId).descripcion
      }
    };

    const dialogRef = this.dialog.open(ComprasProductosDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((r: any) => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.agregados = [];
          this.agregados = r.agregados;

          let total = 0.0;
          this.agregados.forEach((x: any) => {
            x.presentaciones.forEach((z: any) => {
              total += z.importe * z.cantidad;
            });
          });

        }
      }
    });

  }



  changeImporte(element: any, value: any){
    
  }

  changeCantidad(element: any, value: any){
    let cantidad = Number(value);
    if (Number.isInteger(cantidad)){
      if (cantidad == 0){
        //this.dialogBorrar("Información", "Al poner 0 de cantidad estaria indicando que no agregue la presentación. Desea eliminarla?", element)
      }
    }
  }

  clickPasar(){
    console.log("pasar");

    console.log("esperados", this.esperados);

    console.log("agregados", this.agregados);

    this.esperados.forEach(x => {

      let existeProducto: any = this.agregados.find(z => z.idproducto == x.idproducto);

      console.log("existeProducto", existeProducto);

      if (existeProducto){

        x.presentaciones.forEach((jj: any) => {
          let existePresentacion = existeProducto.presentaciones.find((xx: any) => xx.idproductopresentacion  == jj.idproductopresentacion);

          if (existePresentacion){
            if (existePresentacion.cantidad < jj.cantidad){
              existePresentacion.cantidad == jj.cantidad
            }
          }else{
            let nuevaPresentacion = JSON.parse(JSON.stringify(jj))
            existeProducto.presentaciones.push(nuevaPresentacion);
          }

        });

      }else{
        let nuevoProducto = JSON.parse(JSON.stringify(x))
        this.agregados.push(nuevoProducto);

      }

    });


    

  }

  radioChange(event: any){
    this.refrescarOrigenDestino();
  }

  refrescarOrigenDestino(){

    if (this.seleccionTipo == 'pedido'){
      this.movimiento.origen = 0;
      this.movimiento.origenNombre = "";

      this.movimiento.destino = this.sucursalActual.sucursalId;
      this.movimiento.destinoNombre = this.sucursalActual.sucursalNombre;
    }

    if (this.seleccionTipo == 'envio'){
      this.movimiento.origen = this.sucursalActual.sucursalId;
      this.movimiento.origenNombre = this.sucursalActual.sucursalNombre;

      this.movimiento.destino = 0;
      this.movimiento.destinoNombre = "";
    }
  }

  

}
