<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h2>{{nuevo.idsubrubroproducto == 0 ? 'Nuevo Sub Rubro' : ('Editar Sub Rubro #' + nuevo.idsubrubroproducto) }}</h2>
    </div>

    <form class="example-form" style="display: flex; flex-direction: column; max-width: 400px;">

        <mat-form-field appearance="outline">
            <mat-label>Rubro</mat-label>
            <mat-select [(ngModel)]="nuevo.idrubroproducto" name="rubro">
                <mat-option *ngFor="let rubro of listRubros" [value]="rubro.idrubroproducto">{{rubro.descripcion}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Descripción</mat-label>
            <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="nuevo.descripcion" name="descripcion">
        </mat-form-field>
        
    </form>

    <div>
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>