import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ventas-editar-productos',
  templateUrl: './ventas-editar-productos.component.html',
  styleUrls: ['./ventas-editar-productos.component.scss']
})
export class VentasEditarProductosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
