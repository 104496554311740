import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { CoreRoutingModule } from './core.router';
import { MAT_DATE_LOCALE } from '@angular/material/core';


@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        CoreRoutingModule,
    ],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'es-ES' }],
    declarations: []
})
export class CoreModule { }
