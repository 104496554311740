import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import * as moment from 'moment';
import { VendedoresModel } from 'src/app/models/vendedores-model';


@Component({
  selector: 'app-ventas-vendedor-consulta',
  templateUrl: './ventas-vendedor-consulta.component.html',
  styleUrls: ['./ventas-vendedor-consulta.component.scss']
})
export class VentasVendedorConsultaComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  displayedColumns: string[] = ['idvendedor', 'nombre', 'cantidad_productos', 'cantidad_valor', 'cantidad_ventas'];
  displayedColumns2: string[] = ['idtipo', 'tipo_descripcion', 'monto', 'recargo', 'cantidad_ventas'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  dataSource2 = new MatTableDataSource<any>(ELEMENT_DATA2);

  listVendedor: VendedoresModel[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog
  ) {
    
  }

  ngOnInit(): void {

    this.apiService.getVendedoresList().subscribe(x => {
      this.listVendedor = x;
    })

    this.cargarTabla();
  }

  cargarTabla(){
    
  }

  clickConsultar(){
    console.log("rango", this.range);

    if (this.range.value.start != null && this.range.value.end != null){
  
      let mDesde = moment(this.range.value.start);
      let mHasta = moment(this.range.value.end);

      mHasta.set({hour:23,minute:59,second:59,millisecond:0});

      let sDesde = mDesde.format("YYYY-MM-DD HH:mm:ss");
      let sHasta = mHasta.format("YYYY-MM-DD HH:mm:ss");

      console.log("desde", sDesde);
      console.log("hasta", sHasta);

      let fechas = {
        desde: sDesde,
        hasta: sHasta
      }

      this.apiService.postPreventasReporte(fechas).subscribe((col: any[]) => {
        console.log("col", col)
        var tablaMap = new Map();

        col.forEach(x => {
          let item = {
            idproductopresentacion: x.idproductopresentacion,
            precio_unitario: x.precio_unitario,
            cantidad: x.cantidad
          }
  
          if (tablaMap.has(x.idvendedor)){
            let dato = tablaMap.get(x.idvendedor);
  
            if (dato.items != null){
              dato.items.push(item)
            }

            dato.preventas.add(x.idpreventa);
  
          }else{
  
            let dato = {
              items: [] as any[],
              preventas: new Set()
            }
  
            dato.items.push(item)
            dato.preventas.add(x.idpreventa);

            tablaMap.set(x.idvendedor, dato);
  
          }
  
        });
        
        let array = Array.from(tablaMap, ([idvendedor, value]) => ({ idvendedor, value }));
        let tabla: any[] = [];

        array.forEach(v => {

          let totalDinero = 0.0;
          let totalProductos = 0;
          v.value.items.forEach((p:any) => {
            totalDinero += p.cantidad * p.precio_unitario
            totalProductos += p.cantidad
          });

          let totalVentas = v.value.preventas.size

          let info = {
            idvendedor : v.idvendedor,
            nombre: this.listVendedor.find(ven => ven.idvendedor == v.idvendedor)?.nombre,
            total_productos: totalProductos,
            total_dinero: totalDinero,
            total_ventas: totalVentas
          }

          tabla.push(info);
          
        });

        console.log("array", array);
        console.log("tabla final", tabla)

        this.dataSource.data = tabla;

      });

      this.apiService.postPreventaReporteFormaPago(fechas).subscribe((col: any[]) => {
        console.log("col forma pago", col);
        var tablaMap = new Map();
        col.forEach(x => {
  
          if (tablaMap.has(x.idtipo)){
            let dato = tablaMap.get(x.idtipo);
  
            dato.monto += x.importe;
            dato.recargo += x.recargo

            dato.preventas.add(x.idpreventa);
  
          }else{
  
            let dato = {
              monto: x.importe,
              recargo: x.recargo,
              preventas: new Set(),
              tipoDescripcion: x.tipo_descripcion
            }
  
            dato.preventas.add(x.idpreventa);

            tablaMap.set(x.idtipo, dato);
  
          }
  
        });

        let array = Array.from(tablaMap, ([idtipo, value]) => ({ idtipo, value }));

        let tabla: any[] = [];

        array.forEach(v => {

          let totalVentas = v.value.preventas.size

          let info = {
            idtipo : v.idtipo,
            tipo_descripcion: v.value.tipoDescripcion,
            monto: v.value.monto,
            recargo: v.value.recargo,
            total_ventas: totalVentas
          }

          tabla.push(info);

          this.dataSource2.data = tabla;
          
        });

      });


    }

  }

  

}

const ELEMENT_DATA: any[] = [];
const ELEMENT_DATA2: any[] = [];

