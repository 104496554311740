import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/slide-toggle';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, zip } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ColoresProductoModel } from 'src/app/models/colores-producto-model';
import { MedidasProductoModel } from 'src/app/models/medidas-producto-model';
import { PresentacionesSucursalesModel } from 'src/app/models/presentaciones-sucursales-model';
import { ProductosModel } from 'src/app/models/productos-model';
import { ProductosPresentacionesModel } from 'src/app/models/productos-presentaciones-model';
import { RubrosProductoModel } from 'src/app/models/rubros-producto-model';
import { SubRubrosProductoModel } from 'src/app/models/subrubros-producto-model';
import { SucursalesModel } from 'src/app/models/sucursales-model';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { PreventaCantidadComponent } from '../preventa-cantidad/preventa-cantidad.component';
import { PreventaSelTipoPrecioComponent } from '../preventa-sel-tipo-precio/preventa-sel-tipo-precio.component';

@Component({
  selector: 'app-preventa-nuevo-dialog',
  templateUrl: './preventa-nuevo-dialog.component.html',
  styleUrls: ['./preventa-nuevo-dialog.component.scss']
})
export class PreventaNuevoDialogComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;

  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourFormGroup: FormGroup;
  fiveFormGroup: FormGroup;

  nuevo = {
    idrubroproducto: 0,
    nombreRubro: "",
    idsubrubroproducto: 0,
    nombreSubRubro: "",
    idproducto: 0,
    nombreProducto: "",
    idcolorproducto: 0,
    nombreColor: "",
    idmedidaproducto: 0,
    nombreMedida: "",
    idtipo: 0,
    unitarioPrecio: 0,
    unitarioPrecioFinal: 0.0,
    unitarioRecargoPorcentaje: 0.0,
    unitarioRecargo: 0.0,
    cantidad: 0,
    totalPrecio: 0,
    totalPrecioFinal: 0.0,
    totalRecargoPorcentaje: 0.0,
    totalRecargo: 0.0,
    idsucursal: 0,
    nombreSucursal: "",
    items: [] as any[]
  }

  
  completedStep = [true, true, false];

  listRubros: RubrosProductoModel[] = [];
  listSubRubros: SubRubrosProductoModel[] = [];
  listSubRubrosAll: SubRubrosProductoModel[] = [];

  listProductos: ProductosModel[] = [];
  filteredProductos: Observable<ProductosModel[]> = new Observable();

  listColoresAll: ColoresProductoModel[] = [];
  listColores: ColoresProductoModel[] = [];

  listMedidasAll: MedidasProductoModel[] = [];
  listMedidas: MedidasProductoModel[] = [];

  listPresentaciones: any[] = [];

  listTipoPrecio: TiposDePrecioModel[] = [];

  listSucursales: SucursalesModel[] = [];

  listPresentacionesSucursales: PresentacionesSucursalesModel[] = [];

  listItems = [];
  modoEdicion = false;
  index = null;
  item;
  vendedor: any = null;

  displayedColumns: string[] = ['color_descripcion', 'medida_descripcion', 'cantidad' , 'precio', '_agregados_cantidad', '_agregados_tipo_descripcion', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private _formBuilder: FormBuilder,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    private dialogRef: MatDialogRef<PreventaNuevoDialogComponent>) 
  {

    this.listItems = this.data.items;
    this.modoEdicion = this.data.edicion;
    this.index = this.data.index;
    this.item = this.data.item;
    this.vendedor = this.data.vendedor;

    this.firstFormGroup = this._formBuilder.group({
      rubroCtrl: ['', Validators.required],
      subrubroCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      productoCtrl: ['', Validators.required],
      sucursalCtrl: ['', Validators.required]
    });

    this.thirdFormGroup = this._formBuilder.group({
      colorCtrl: ['', Validators.required],
      medidaCtrl: ['', Validators.required]
    });

    this.fourFormGroup = this._formBuilder.group({
      cantidadCtrl: ['', [Validators.required, Validators.min(1)]]
    });

    this.fiveFormGroup = this._formBuilder.group({
      precioCtrl: ['', [Validators.required]]
    })
    
    this.filteredProductos = this.secondFormGroup.get("productoCtrl")!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    
  }

  ngOnInit() {
    this.apiService.getRubrosList().subscribe(x => {
      this.listRubros = x;

      if (this.modoEdicion){
        this.firstFormGroup.get("rubroCtrl")?.setValue(this.item.idrubroproducto);
        this.selectRubro(this.item.idrubroproducto);
      }
    });

    this.apiService.getSubRubrosList().subscribe(x => {
      this.listSubRubrosAll = x;

      if (this.modoEdicion){
        this.selectRubroConSubrubro(this.item.idrubroproducto);
        this.firstFormGroup.get("subrubroCtrl")?.setValue(this.item.idsubrubroproducto);
      }
  
    });

    this.apiService.getPresentacionesSucursales().subscribe(x => {
      this.listPresentacionesSucursales = x;
      console.log("listPresentacionesSucursales", this.listPresentacionesSucursales);
    });

    this.apiService.getSucursalesList().subscribe(x => {
      this.listSucursales = x;
      if (this.vendedor !== null && this.vendedor !== undefined){
        let sucursal = this.listSucursales.find(x => x.idsucursal == this.vendedor.idsucursal);
        if (sucursal != null){
          this.secondFormGroup.get("sucursalCtrl")?.setValue(this.vendedor!.idsucursal);
          this.nuevo.nombreSucursal = sucursal.descripcion;
          this.nuevo.idsucursal = sucursal.idsucursal
        }
      }
      if (this.modoEdicion){
        this.secondFormGroup.get("sucursalCtrl")?.setValue(this.item.idsucursal);
        this.selectSucursal(this.item.idsucursal);
      }

    });

    this.apiService.getColoresList().subscribe(x => {
      this.listColoresAll = x;
    });

    this.apiService.getMedidasList().subscribe(x => {
      this.listMedidasAll = x;
    });

    this.apiService.getTiposDePrecioList().subscribe(x => {
      this.listTipoPrecio = x;
    })
   
    if (this.modoEdicion){
      this.nuevo.idproducto = this.item.idproducto;
      this.nuevo.idcolorproducto = this.item.idcolorproducto;
      this.nuevo.cantidad = this.item.cantidad;
      this.nuevo.idsucursal = this.item.idsucursal;

      this.fourFormGroup.get("cantidadCtrl")?.setValue(this.nuevo.cantidad);

    }
    
  }


  selectRubro(value: any){

    let idrubrosel = value;

    if (idrubrosel != this.nuevo.idrubroproducto){

      this.nuevo.idrubroproducto = idrubrosel;

      this.listSubRubros = [];
      this.listSubRubros = this.listSubRubrosAll.filter(x => x.idrubroproducto == idrubrosel);
      this.nuevo.idsubrubroproducto = 0;

    }

  }

  selectSubRubro(value: any){
    this.nuevo.idsubrubroproducto = value;
  }

  selectColor(value: any){

    //this.nuevo.idcolorproducto = value;


    
    let idcolorsel = value;

    if (idcolorsel != this.nuevo.idcolorproducto){

      this.nuevo.idcolorproducto = idcolorsel;

      
      let presentacionesConColor = this.listPresentaciones.filter(x => x.idcolorproducto == idcolorsel);
      let idsMedidas = presentacionesConColor.map(x => x.idmedidaproducto);

      this.listMedidas = [];
      this.listMedidas = this.listMedidasAll.filter(x => idsMedidas.includes(x.idmedidaproducto));
      this.nuevo.idmedidaproducto = 0;

    }
    
  }

  selectMedida(value: any){
    this.nuevo.idmedidaproducto = value;
  }

  selectTipoPrecio(event: any){
    this.nuevo.idtipo = event.value;

    let tipoPrecio = this.listTipoPrecio.find(x => x.idtipo == this.nuevo.idtipo);

    if (tipoPrecio){
      this.nuevo.unitarioRecargoPorcentaje = tipoPrecio.recargo;
      this.nuevo.unitarioRecargo = (tipoPrecio.recargo * this.nuevo.unitarioPrecio) / 100;
      this.nuevo.unitarioPrecioFinal = ((tipoPrecio.recargo * this.nuevo.unitarioPrecio) / 100) + this.nuevo.unitarioPrecio;

      this.nuevo.totalRecargoPorcentaje = tipoPrecio.recargo;
      this.nuevo.totalRecargo = (tipoPrecio.recargo * (this.nuevo.unitarioPrecio * this.nuevo.cantidad)) / 100;
      this.nuevo.totalPrecioFinal = ((tipoPrecio.recargo * (this.nuevo.unitarioPrecio * this.nuevo.cantidad)) / 100) + (this.nuevo.unitarioPrecio * this.nuevo.cantidad);


    }
    

  }

  selectRubroConSubrubro(value: any){

    let idrubrosel = value;

    this.nuevo.idrubroproducto = idrubrosel;

    this.listSubRubros = [];
    this.listSubRubros = this.listSubRubrosAll.filter(x => x.idrubroproducto == idrubrosel);
    this.nuevo.idsubrubroproducto = 0;

    this.nuevo.idsubrubroproducto = this.item.idsubrubroproducto;
    this.firstFormGroup.get("subrubroCtrl")?.setValue(this.item.idsubrubroproducto);

  }

  selectSucursal(value: any){
    let sucursal = this.listSucursales.find(x => x.idsucursal == value);
    if (sucursal != null){
      this.secondFormGroup.get("sucursalCtrl")?.setValue(sucursal!.idsucursal);
      this.nuevo.nombreSucursal = sucursal.descripcion;
      this.nuevo.idsucursal = sucursal.idsucursal
    }
  }

  public onStepChange(event: any): void {
    console.log(event.selectedIndex);
    console.log(event);

    let step = event.selectedIndex;

    switch (step) {
      case 1:
      
        let rubro = this.nuevo.idrubroproducto;
        let subrubro = this.nuevo.idsubrubroproducto;
        
        console.log("step 1 - rubro", rubro);
        console.log("step 1 - subrubro", subrubro);

        this.listProductos = [];
        this.apiService.getProductosListByRubroSubrubro(rubro, subrubro).subscribe(x => {
          this.listProductos = [];
          this.listProductos = x;

          let existe = this.listProductos.find(z => z.idrubroproducto == rubro && z.idsubrubroproducto == subrubro && z.idproducto == this.nuevo.idproducto);

          console.log("existe", existe);

          if (existe != null){
            this.secondFormGroup.get("productoCtrl")!.setValue(existe);  
          }else{
            this.secondFormGroup.get("productoCtrl")!.setValue('');
            this.nuevo.idproducto = 0;
          }

          this.filteredProductos = this.secondFormGroup.get("productoCtrl")!.valueChanges.pipe(
            tap(value => {
              this.nuevo.idproducto = value.idproducto;
              this.nuevo.nombreProducto = value.descripcion;
            }),
            startWith(''),
            map(value => this._filter(value)),
          );
         

        });

        break;

      case 2:

        let producto = this.secondFormGroup.get("productoCtrl")?.value;
        let cambio = false;

        if (producto.idproducto != this.nuevo.idproducto){
          this.nuevo.idproducto = producto.idproducto;
          this.nuevo.nombreProducto = producto.descripcion;
          cambio = true
        }

        let idProducto = producto.idproducto;

        let funCalculoStock = (datos: any) => {

          let x = datos;

          console.log("presentaciones", x);

            x.forEach((p: any) => {
              p._agregados_cantidad = 0;
              p._stock_sucursal = 0;

              let pp = this.listPresentacionesSucursales.find(k => k.idproductopresentacion == p.idproductopresentacion && k.idsucursal == this.nuevo.idsucursal);

              if (pp != null){
                p._stock_sucursal = pp.cantidad;
              }
            });

            if (this.item != null){
              x.forEach((p: any) => {
                let j = this.item.items.find((q: any) => q.idproductopresentacion == p.idproductopresentacion);
                if (j != null){
                  p._agregados_cantidad = j._agregados_cantidad;
                }
              });
            }

            this.dataSource.data = x;

            this.listPresentaciones = [];
            this.listPresentaciones = x;

            let idsColores: number[] = x.map((z: any) => z.idcolorproducto);

            this.listColores = [];
            this.listMedidas = [];

            this.listColores = this.listColoresAll.filter(z => idsColores.includes(z.idcolorproducto));

            console.log("list colores", this.listColores);

            let existeColor = this.listColores.find(z => z.idcolorproducto == this.nuevo.idcolorproducto);

            if (existeColor){
              this.thirdFormGroup.get("colorCtrl")?.setValue(existeColor.idcolorproducto);
            }else{
              this.thirdFormGroup.get("colorCtrl")?.setValue('');
              this.nuevo.idcolorproducto = 0;
            }

        }

        if (!cambio && this.dataSource.data.length != 0 && this.dataSource.data[0].idproducto == idProducto){
          funCalculoStock(this.listPresentaciones);
          break;
        }else{
          this.apiService.getProductoPresentacionesListByIdProducto(idProducto).subscribe(x => {
            funCalculoStock(x);
          }, e=> {
            console.error("getProductoPresentacionesListByIdProducto", e);
          });
        }

        break;

      case 3:
        this.nuevo.idrubroproducto = this.firstFormGroup.get("rubroCtrl")?.value;
        this.nuevo.idsubrubroproducto = this.firstFormGroup.get("subrubroCtrl")?.value;
                
        let pr =  this.secondFormGroup.get("productoCtrl")?.value;
        this.nuevo.idproducto = pr.idproducto;
        this.nuevo.nombreProducto = pr.descripcion;

        this.nuevo.nombreRubro = this.listRubros.find(x => x.idrubroproducto == this.nuevo.idrubroproducto)!.descripcion;
        this.nuevo.nombreSubRubro = this.listSubRubrosAll.find(x => x.idsubrubroproducto == this.nuevo.idsubrubroproducto)!.descripcion;

        let agregados = this.dataSource.data.filter(x => x._agregados_cantidad > 0);
        this.nuevo.items = agregados;

        break;
    
      default:
        break;
    }

  }

  private _filter(value: string): ProductosModel[] {
    const filterValue = value.toLowerCase();
    return this.listProductos.filter(producto => producto.descripcion.toLowerCase().indexOf(filterValue) === 0);
  }

  public displayProperty(value: ProductosModel) {
      return value.descripcion;
  }

  public clickConfirmar(){

    
    let existe = false;
    let k = 0;
    this.listItems.forEach((x: any) => {
      if ((x.idproducto == this.nuevo.idproducto && x.idsucursal == this.nuevo.idsucursal) && this.index != k) {
        existe = true;
      }
      k++;
    });

    if (existe){
      this.snackBarService.errorMessage("El producto ya se encuentra agregado.");
      return;
    }
    

    if (!(this.nuevo.idrubroproducto > 0)){
      this.snackBarService.errorMessage("El rubro no es valido.");
      return;
    }

    if (!(this.nuevo.idsubrubroproducto > 0)){
      this.snackBarService.errorMessage("El subrubro no es valido.");
      return;
    }

    if (!(this.nuevo.idproducto > 0)){
      this.snackBarService.errorMessage("El producto no es valido.");
      return;
    }

    if (this.nuevo.items.length == 0){
      this.snackBarService.errorMessage("Debe agregar alguna presentación.");
      return;
    }

    this.dialogRef.close({item: this.nuevo, index: this.index});

  }

  clickAgregarPre(element: any){

    console.log("element", element)

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      listTipoPrecio: this.listTipoPrecio
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(PreventaCantidadComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr", rr);
      if (rr != null){

        if (rr.cantidad > 0){
          element._agregados_cantidad = rr.cantidad;
        }else{
          element._agregados_cantidad = 0;
        }

        let agregados = this.dataSource.data.filter(x => x._agregados_cantidad > 0);
        if (agregados.length == 0){
          this.stepper.selected.completed = false;
        }
        
      }
    });

  }

  clickNext(step: number){
    switch (step) {
      case 2:
      
        let agregados = this.dataSource.data.filter(x => x._agregados_cantidad > 0);

        console.log("agregados", agregados);
        
        if (agregados.length > 0){
          this.stepper.selected.completed = true;
          this.stepper.next();
        }

        break;
    
      default:
        break;
    }
  }

}

const ELEMENT_DATA: any[] = [];
