import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreventaSelTipoPrecioComponent } from '../preventa-sel-tipo-precio/preventa-sel-tipo-precio.component';

@Component({
  selector: 'app-preventa-cantidad',
  templateUrl: './preventa-cantidad.component.html',
  styleUrls: ['./preventa-cantidad.component.scss']
})
export class PreventaCantidadComponent implements OnInit {

  item = {
    cantidad: 0
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PreventaCantidadComponent>,
  ) {

    
  }

  ngOnInit(): void {
  }

  clickCerrar(){
    this.dialogRef.close();
  }

  clickGuardar(){
    console.log("item salida", this.item);
    this.dialogRef.close(this.item);
  }

}
