import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { toF } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { ComprasFormasPagoDialogComponent } from '../compras-formas-pago-dialog/compras-formas-pago-dialog.component';
import { ComprasProductosDialogComponent } from '../compras-productos-dialog/compras-productos-dialog.component';
import { Location } from '@angular/common';


@Component({
  selector: 'app-compras-egreso',
  templateUrl: './compras-egreso.component.html',
  styleUrls: ['./compras-egreso.component.scss']
})
export class ComprasEgresoComponent implements OnInit {

  compra: any = {
    idcompra: 0,
    idproveedor: 0,
    idtipocompra: 3, //egreso de mercaderia
    proveedorNombre: "",
    idsucursal: 0,
    sucursalNombre: "",
    importe: 0.0,
    descripcion: "",
    fecha: ""
  }

  modo = 'nuevo'; //modos: 'nuevo', 'edicion', 'confirmar'

  listSucursales: any[] = [];
  listProveedores: any[] = [];

  agregados: any[] = [];
  formasDePago: any[] = [];
  agregadosConfirmados: any[] = [];

  cargando = false;

  estadoCompra = null;

  ingresarImporte = true;

  //form: FormGroup;

  constructor(
    public router: Router,
    private apiService: ApiService,
    private formBuilder: FormBuilder,    
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private dialog: MatDialog

  ) {

    this.buildForm();
    
    this.compra.idcompra = Number(this.activatedRoute.snapshot.paramMap.get('idcompra'));
    let tipo = Number(this.activatedRoute.snapshot.paramMap.get('tipo'));
    if (Number.isInteger(tipo)){
      //chequeo permisos y si el estado previo es valido
      switch(tipo){
        case 1:
          this.modo = 'edicion';
          break;
        case 2:
          this.modo = 'confirmar';
          break;
        default:
          this.modo = 'nuevo';
          break;
      }
      
    }

    console.log("modo", this.modo);

    if (this.compra.idcompra > 0){

      this.cargando = true;
      
      this.apiService.getComprasCompleto(this.compra.idcompra).subscribe((x:any) => {

        this.estadoCompra = x.estado;

        if (x != null){
          this.compra = {
            idcompra: x.idcompra,
            idproveedor: x.idproveedor,
            idtipocompra: x.idtipocompra,
            proveedorNombre: x.proveedor_descripcion,
            idsucursal: x.idsucursal,
            sucursalNombre: x.sucursal_descripcion,
            importe: x.importe,
            descripcion: x.descripcion,
            fecha: x.fecha
          }

          if (this.modo == 'edicion'){
            this.apiService.getComprasProductoSemiCompleto(this.compra.idcompra).subscribe((xx: any[]) => {
              
              let lista: any[] = [];
              xx.forEach(y => {
                let a = {
                  cantidad: y.cantidad,
                  colorDescripcion: y.color_descripcion,
                  descripcion: y.color_descripcion + " | " + y.medida_descripcion,
                  idproducto: y.idproducto,
                  idproductopresentacion: y.idproductopresentacion,
                  importe: y.importe_presentacion,
                  medidaDescripcion: y.medida_descripcion,
                  productoDescripcion: y.producto_descripcion
                
                }
                lista.push(a);
              });

              this.agregados = [];
              lista.forEach(x => {
                let a = this.agregados.find(z => z.idproducto == x.idproducto);
                if (a != null){
                  a.presentaciones.push(x);
                }else{
                  let nuevo = {
                    idproducto: x.idproducto,
                    descripcion: x.productoDescripcion,
                    presentaciones: [x]
                  }
                  this.agregados.push(nuevo);
                }
              });

              this.cargando = false;

            });
          }

          if (this.modo == 'confirmar'){
            this.apiService.getComprasProductoRecepcionSemiCompleto(this.compra.idcompra).subscribe((xx: any[]) => {

              console.log("xx getComprasProductoRecepcionSemiCompleto", xx);
              
              let lista: any[] = [];
              xx.forEach(y => {
                let a = {
                  cantidad: y.cantidad,
                  colorDescripcion: y.color_descripcion,
                  descripcion: y.color_descripcion + " | " + y.medida_descripcion,
                  idproducto: y.idproducto,
                  idproductopresentacion: y.idproductopresentacion,
                  importe: y.importe_presentacion,
                  medidaDescripcion: y.medida_descripcion,
                  productoDescripcion: y.producto_descripcion
                
                }
                lista.push(a);
              });

              this.agregados = [];
              lista.forEach(x => {
                let a = this.agregados.find(z => z.idproducto == x.idproducto);
                if (a != null){
                  a.presentaciones.push(x);
                }else{
                  let nuevo = {
                    idproducto: x.idproducto,
                    descripcion: x.productoDescripcion,
                    presentaciones: [x]
                  }
                  this.agregados.push(nuevo);
                }
              });

              this.cargando = false;

            }, e => {
              console.log("error", e);
            });
          }

          this.apiService.getComprasFormaPagoSemiCompleto(this.compra.idcompra).subscribe((xx: any[]) => {
            console.log("xx", xx);
            xx.forEach(fp =>{
              let nfp = {
                idcompra: fp.idcompra,
                idcompraformapago: fp.idcompraformapago,
                idcompratipoprecio: fp.idcompratipoprecio,
                importe: fp.importe,
                observaciones: fp.observaciones,
                tipoPrecioDescripcion: fp.compratipoprecio_descripcion,
                _obs: fp.observaciones.length > 0
              }
              this.formasDePago.push(nfp);
            });
          });

        }
        
      });
      
    }

    this.apiService.getSucursalesList().subscribe((x: any) => {
      this.listSucursales = x;
    });

    this.apiService.getProveedoresList().subscribe((x: any[]) => {
      this.listProveedores = x;
    });
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    
    console.log("nuevo", this.compra);
    console.log("ingresar importe", this.ingresarImporte);

    let arrAgregados: any[] = []

    let importesProductosCompletos = true;
    let importeSimpleCompleto = true;

      
    this.agregados.forEach(x => {
      let presentaciones: any[] = x.presentaciones;
      presentaciones.forEach(y => {
        if (y.importe <= 0){
          importesProductosCompletos = false;
        }

        arrAgregados.push({
          idproductopresentacion: y.idproductopresentacion,
          cantidad: y.cantidad,
          importe: y.importe
        });

      });
    });

    if (Number(this.compra.importe) < 0){
      importeSimpleCompleto = false;
    }

    if (this.compra.descripcion.length <= 0){
      this.snackBarService.errorMessage("Debe dar una breve descripción.");
      return;
    }    

    if (this.compra.idsucursal == 0){
      this.snackBarService.errorMessage("Debe proporcionar una sucursal.");
      return;
    }

    if (this.agregados.length == 0){
      this.snackBarService.errorMessage("Debe agregar al menos un producto.");
      return;
    }

    let arrFormasPago: any[] = [];
    this.formasDePago.forEach(x => {
      arrFormasPago.push({
        idcompratipoprecio: x.idcompratipoprecio,
        importe: x.importe,
        observaciones: x.observaciones
      });
    });

    let itemCompra: any = {
      idproveedor: 9999,
      idtipocompra: this.compra.idtipocompra,
      idsucursal: this.compra.idsucursal,
      importe: 0,
      descripcion: this.compra.descripcion,
      estado: 0
    }

    if (this.compra.fecha){
      itemCompra.fecha = toF(new Date);
    }

    let loteStock: any[] = [];
    arrAgregados.forEach(x => {
      let stock = {
        cantidad: x.cantidad,
        idproductopresentacion: x.idproductopresentacion,
        idsucursal: itemCompra.idsucursal
      }
      loteStock.push(stock);
    });

    this.cargando = true;
    switch (this.modo) {
      case 'nuevo':
        this.guardarNuevo(itemCompra, arrFormasPago, arrAgregados, loteStock);  
        break;
      case 'edicion':
        //this.guardarEditar(itemCompra, arrFormasPago, arrAgregados, loteStock, this.compra.idcompra);  
        break;
      case 'confirmar':
        //this.guardarConfirmar(itemCompra, arrFormasPago, arrAgregados, loteStock, this.compra.idcompra);  
        break;
      default:
        this.cargando = false;
        break;
    }

  }

  guardarNuevo(itemCompra: any, arrFormasPago: any[], arrAgregados: any[], loteStock: any[]){

    let data = {
      egreso: itemCompra,
      egresoProductos: arrAgregados,
      egresoLoteStock: loteStock
    }

    console.log("data", data);

    this.apiService.postComprasEgresoCompletoNuevo(data).subscribe(x => {
      console.log("x", x);
      this.cargando = false;
      if (x){
        this.router.navigateByUrl(`/compras/lista-egresos`, {});
      }else{
        this.snackBarService.errorMessage("Ocurrio un error interno al generar la nueva compra");
      }

    }, e => {
      this.cargando = false;
      this.snackBarService.errorMessage("Ocurrio un error al generar la nueva compra");
    });

  }

  clickBack(){
    this._location.back();
  }

  clickAgregar(){
    this.dialogAgregar();
  }

  dialogAgregar() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '80vw';
    dialogConfig.minWidth = '560px';
    dialogConfig.data = {
      agregados: this.agregados,
      muestraImporte: false
    };

    const dialogRef = this.dialog.open(ComprasProductosDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((r: any) => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.agregados = [];
          this.agregados = r.agregados;
        }
      }
    });

  }

  changeCantidad(element: any, value: any){
    let cantidad = Number(value);
    if (Number.isInteger(cantidad)){
      if (cantidad == 0){
        //this.dialogBorrar("Información", "Al poner 0 de cantidad estaria indicando que no agregue la presentación. Desea eliminarla?", element)
      }
    }
  }

  dialogFormaPago() {

    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      formasDePago: this.formasDePago,
      importe: this.compra.importe
    };

    const dialogRef = this.dialog.open(ComprasFormasPagoDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r != null){
        if (r.cierre){
          this.formasDePago = r.formasDePago;
        }
      }
    });

  }

  buildForm(){
    /*
    this.form = this.formBuilder.group({

    })
    */
  }


}
