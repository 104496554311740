<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Nuevo Cliente
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>
    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
  
      <div style="margin: 0px 0px 4px 0px; display: flex; flex-direction: column; flex-wrap: wrap; width: 80%;">
        <mat-form-field appearance="outline">
            <mat-label>Nombre</mat-label>
            <input matInput placeholder="Nombre" value="" [(ngModel)]="nuevo.nombre" name="nombre">
          </mat-form-field>
  
          <mat-form-field appearance="outline">
              <mat-label>Domicilio</mat-label>
              <input matInput placeholder="Domicilio" value="" [(ngModel)]="nuevo.domicilio" name="domicilio">
          </mat-form-field>
  
          <mat-form-field appearance="outline">
              <mat-label>Localidad</mat-label>
              <mat-select [(ngModel)]="nuevo.codigo_localidad" name="localidad">
                  <mat-option *ngFor="let localidad of listLocalidades" [value]="localidad.idlocalidad">{{localidad.nombre}}</mat-option>
              </mat-select>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
              <mat-label>Telefono</mat-label>
              <input matInput placeholder="Telefono" value="" [(ngModel)]="nuevo.telefono" name="telefono">
          </mat-form-field>
  
          <mat-form-field appearance="outline">
              <mat-label>Correo Electronico</mat-label>
              <input matInput placeholder="Correo Electronico" value="" [(ngModel)]="nuevo.correo_electronico" name="correo_electronico">
          </mat-form-field>
  
          <mat-form-field appearance="outline">
              <mat-label>Observaciones</mat-label>
              <input matInput placeholder="Observaciones" value="" [(ngModel)]="nuevo.observaciones" name="observaciones">
          </mat-form-field>
      </div>
  
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCancelar()">Cancelar</button>
        <button mat-button (click)="clickCrear()" [disabled]="cargando">Crear</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>