import { Inject, Injectable, NgZone, inject } from '@angular/core';
//import { AngularFireAuth } from "@angular/fire/auth";
import { Auth, authState, signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, signOut } from '@angular/fire/auth';

import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  //private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);


  constructor(
    //public afAuth: AngularFireAuth, // Inject Firebase auth service
    //private auth: Auth,
    @Inject(Auth) public auth: Auth,
    public router: Router,  
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {    
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    
    this.authState$.subscribe(user => {
      if (user) {
        console.log("observer", user);
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        //JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', "");
        //JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Sign in with email/password
  SignIn(email: string, password: string) {

    //return this.afAuth.signInWithEmailAndPassword(email, password)
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((result) => {
        console.log("result", result);
        localStorage.setItem('user', JSON.stringify(result.user));
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Sign up with email/password
  SignUp(email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
    //return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        //this.SendVerificationMail();
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  /*
  SendVerificationMail() {
    //this.afAuth.ema
    return this.afAuth.currentUser.sendEmailVerification()
    .then(() => {
      this.router.navigate(['verify-email-address']);
    })
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }
  */

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {

    let userStore = localStorage.getItem('user');
    if (userStore != null && userStore != ""){
      console.log("userdata", userStore);
      const user = JSON.parse(userStore);
      //return (user !== null && user.emailVerified !== false) ? true : false;
      return (user !== null) ? true : false;
    }else{
      return false;
    }
  }

  userInfo(): any {

    let userStore = localStorage.getItem('user');
    if (userStore != null && userStore != ""){
      return JSON.parse(userStore);
    }
    return null;
    
  }

  userFullInfo(){
    
  }

  // Sign in with Google
  /*
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  */

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    /*
    return this.afAuth.signInWithPopup(provider)
    //signInWithPopup(this.auth, )
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
      this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error)
    })
    */
  }

  /* Setting up user data when sign in with username/password, 
  sign up with username/password and sign in with social auth  
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user: any) {
    /*
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: UsuarioAuthModel = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
    */
  }

  // Sign out 
  SignOut() {
    
    //return this.afAuth.signOut().then(() => {
    return signOut(this.auth).then(() => {
      localStorage.removeItem('user');
      console.log("auth out");
      //this.router.navigate(['sign-in']);
    })
    
  }

}