<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">
    <h2>{{descripcion}}</h2>

    <div style="display: flex; flex-direction: column;">
        <div style="display: flex; align-items: baseline; flex-direction: row; justify-content: space-between;">

            <mat-form-field appearance="outline" style="flex-grow: 1; padding-right: 8px;">
                <mat-label>Ingrese un rango de fechas</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Fecha Desde">
                    <input matEndDate formControlName="end" placeholder="Fecha Hasta">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Fecha Desde Incorrecta</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Fecha Hasta Incorrecta</mat-error>
            </mat-form-field>
    
            <mat-form-field appearance="outline" style="flex-grow: 1; padding-right: 8px;">
                <mat-label>Sucursal Origen</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="filtro.sucursalOrigenNombre" name="sucursal_origen" readonly>
                <button *ngIf="filtro.sucursalOrigenNombre != ''" matSuffix mat-icon-button (click)="clickClear('origen')">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="clickSeleccionarSucursal('origen')">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
    
            <mat-form-field appearance="outline"style="flex-grow:1; padding-right: 8px;" >
                <mat-label>Sucursal Destino</mat-label>
                <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="filtro.sucursalDestinoNombre" name="sucursal_destino" readonly (dblclick)="clickSeleccionarSucursal('destino')">
                <button *ngIf="filtro.sucursalDestinoNombre != ''" matSuffix mat-icon-button (click)="clickClear('destino')">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="clickSeleccionarSucursal('destino')">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
    
            <button mat-raised-button color="primary" (click)="clickConsultar()">Consultar</button>
    
        </div>
        <div style="display: flex; align-items: baseline; flex-direction: row; justify-content: space-between; margin-bottom: 8px;">
            <button mat-raised-button color="primary" (click)="clickImprimir()">Imprimir</button>

        </div>
    </div>


    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idlogisticasolicitud}} </td>
        </ng-container>

        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element._tipo}} </td>
        </ng-container>


        <ng-container matColumnDef="producto">
            <th mat-header-cell *matHeaderCellDef> Producto </th>
            <td mat-cell *matCellDef="let element"> {{element.producto_descripcion}} | {{element.color_descripcion}} | {{element.medida_descripcion}}</td>
        </ng-container>

        <ng-container matColumnDef="cantidad">
            <th mat-header-cell *matHeaderCellDef> Cantidad </th>
            <td mat-cell *matCellDef="let element"> {{element.cantidad}}</td>
        </ng-container>

        <ng-container matColumnDef="origen">
            <th mat-header-cell *matHeaderCellDef> Origen </th>
            <td mat-cell *matCellDef="let element"> {{element.origen_descripcion}}</td>
        </ng-container>

        <ng-container matColumnDef="destino">
            <th mat-header-cell *matHeaderCellDef> Destino </th>
            <td mat-cell *matCellDef="let element"> {{element.destino_descripcion}}</td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
        <th mat-header-cell *matHeaderCellDef> Observaciones </th>
        <td mat-cell *matCellDef="let element"> {{element.obs}} </td>
        </ng-container>

        <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef> Estado </th>
        <td mat-cell *matCellDef="let element"> {{element._estado}} </td>
        </ng-container>

        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef> Fecha </th>
            <td mat-cell *matCellDef="let element"> <div *ngIf="element._fecha != ''"  matTooltip="{{element._fechax}}">{{element._fecha}}</div> </td>
        </ng-container>

        <ng-container matColumnDef="venta">
            <th mat-header-cell *matHeaderCellDef> Venta<br>Asociada </th>
            <td mat-cell *matCellDef="let element"> {{element._venta}} </td>
        </ng-container>
        
        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button class="boton-celda" *ngIf="element.estado == 0" (click)="clickAtendido(element, $event)">Marcar como Atendido</button>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="expandedDetalle">
            
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                <div style="display: flex; flex-direction: column;">

                </div>
            
              </div>
            </td>
          </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>
