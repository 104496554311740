<div>

    <div mat-dialog-title style="display:flex; flex-direction: row;">
      Agregar Formas de Pago
    </div>
  
    <div style="height: 2px;">
      <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
    </div>

    
    <mat-dialog-content style="min-height: 400px; padding-top: 4px;">
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
            <div style="display: flex;">
                <span>Total: ${{fcNum(importe)}}</span>&nbsp;&nbsp;&nbsp;
                <span>Restante: ${{fcNum(restante)}}</span>
            </div>
            <button mat-raised-button color="primary" (click)="clickAgregar()">Agregar</button>
        </div>
        <div>
            <mat-card  *ngFor="let fp of listFormaPago; let i = index" style="margin: 4px;">
                <div style="display: flex; flex-direction: column; font-size: 10pt;">
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;align-items: baseline;">
                        <mat-slide-toggle [(ngModel)]="fp._obs"><span style="font-size: 9pt;">Obs.</span></mat-slide-toggle>
                        <button mat-flat-button (click)="clickBorrarFormaPago(fp, i, $event)">Quitar</button>
                    </div>
                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                        <mat-form-field appearance="outline" style="flex-grow: 1;">
                            <mat-label>Forma de Pago</mat-label>
                            <mat-select [(ngModel)]="fp.idcompratipoprecio"  (selectionChange)="onChange(fp, $event)">
                                <mat-option *ngFor="let ctp of tipoPrecioList" [value]="ctp.idcompratipoprecio">{{ctp.descripcion}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" style="flex-grow: 1;">
                            <mat-label>Importe</mat-label>
                            <input 
                                (change)='changeImporte(fp, $event.target.value)'
                                matInput placeholder="Importe" value="" min="0" type="number" [(ngModel)]="fp.importe" name="importe">
                        </mat-form-field>
                       
                    </div>
                    <div style="display: flex; flex-direction: row; margin-top: 4px;" *ngIf="fp._obs">
                        <mat-form-field appearance="outline" style="width: 100%;">
                            <mat-label>Observaciones</mat-label>
                            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" [(ngModel)]="fp.observaciones"></textarea>
                          </mat-form-field>
                    </div>
                </div>
            </mat-card>
        </div>
    </mat-dialog-content>
  
    <mat-dialog-actions>
  
      <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
      </div>
      
    </mat-dialog-actions>
  
  </div>