import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';


@Component({
  selector: 'app-tipo-de-precio-nuevo',
  templateUrl: './tipo-de-precio-nuevo.component.html',
  styleUrls: ['./tipo-de-precio-nuevo.component.scss']
})
export class TipoDePrecioNuevoComponent implements OnInit {
  nuevo: TiposDePrecioModel = {
    idtipo: 0,
    recargo: 0.0,
    descripcion: ""
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private _location: Location

  ) {
    this.nuevo.idtipo = Number(this.activatedRoute.snapshot.paramMap.get('idtipo'));

    if (this.nuevo.idtipo > 0){
      this.apiService.getTiposDePrecio(this.nuevo.idtipo).subscribe(x => {
        this.nuevo = x;
      })
    }
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);
    if (this.nuevo.descripcion.length > 2){
      if (this.nuevo.idtipo == 0){
        this.apiService.postTiposDePrecio(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el tipo de precio correctamente");
          this.router.navigateByUrl('/productos/tipos-de-precio/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        
        this.apiService.putTiposDePrecio(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el tipo de precio correctamente");
          this.router.navigateByUrl('/productos/tipos-de-precio/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo descripción debe tener mas de 2 caracteres");
    }

  }

  clickBack(){
    this._location.back();
  }

}
