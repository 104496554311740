<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2>Nuevo Presupuesto</h2>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Observaciones</mat-label>
                <input matInput placeholder="Observaciones" value="" [disabled]="modo == 'confirmar-edicion'"  type="text" [(ngModel)]="preventa.observaciones" name="descripcion">
            </mat-form-field>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Vendedor</mat-label>
                    <input matInput type="text" [(ngModel)]="vendedor.nombre" [disabled]="modo == 'confirmar-edicion'" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarVendedor()" [disabled]="modo == 'confirmar-edicion'">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                <mat-form-field style="flex-grow: 1;" appearance="outline" >
                    <mat-label>Cliente</mat-label>
                    <input matInput type="text" [(ngModel)]="cliente.nombre" [disabled]="modo == 'confirmar-edicion'" readonly>
                    <button matSuffix mat-icon-button (click)="clickSeleccionarCliente()" [disabled]="modo == 'confirmar-edicion'">
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <div style="display: flex; flex-direction: column; border: 1px solid #bbbbbb; padding: 16px; border-radius: 4px; margin-bottom: 16px;">

                <div style="font-size: 12pt; margin: 4px 0px 8px 0px;">Datos de la Entrega</div>

                <mat-form-field appearance="outline" >
                    <mat-label>Domicilio</mat-label>
                    <input matInput placeholder="Domicilio" value=""  type="text" [(ngModel)]="preventa.domicilio" name="domicilio" [disabled]="modo == 'confirmar-edicion'">
                </mat-form-field>

                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
                    <mat-form-field style="flex-grow: 1;" appearance="outline" >
                        <mat-label>Localidad</mat-label>
                        <input matInput type="text" [(ngModel)]="localidad.nombre" [disabled]="modo == 'confirmar-edicion'" readonly>
                        <button matSuffix mat-icon-button (click)="clickSeleccionarLocalidad()" [disabled]="modo == 'confirmar-edicion'">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <mat-form-field appearance="outline" >
                    <mat-label>Flete</mat-label>
                    <input matInput placeholder="Flete" value="0" min="0" type="number" [(ngModel)]="preventa.flete" (ngModelChange)="onChangeFlete($event)" name="flete" [disabled]="modo == 'confirmar-edicion'">
                </mat-form-field>

            </div>

            <div>
                <h3 style="font-weight: bold;">Importe Total ${{(preventa.importe).toFixed(2)}} (Incluye Flete)</h3>
                <div style="font-weight: bold;">Importe Solo Productos ${{(totalProductos).toFixed(2)}}</div>
                <div style="font-weight: bold;">Total en Formas de Pago ${{(totalFormaPago).toFixed(2)}} (con Recargo ${{(totalFormaPagoConRecargo).toFixed(2)}})</div>
            </div>

        </div>

        <mat-divider style="margin-top: 16px;"></mat-divider>

        <div style="margin-top: 16px; margin-bottom: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <div style="flex-grow: 1;">
                    <h2 *ngIf="modo=='edicion' || modo=='nuevo'">Productos a Vender</h2>
                    <h2 *ngIf="modo=='confirmar'">Productos Vendidos</h2>

                    <div style="display: flex; flex-direction: column;">
                        <div *ngFor="let suc of sucursalesCont" style="margin-top: 8px; margin-bottom: 8px; border-top: 1px solid #0000001f; padding-top: 8px;">
                            <div style="display: flex; flex-direction: row; align-items: baseline;">
                                <div style="flex-grow: 1; font-size: 12pt;">Sucursal: <b>{{suc.nombre}}</b></div>
                                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregar(suc)">Agregar y Editar Productos</button>
                            </div>
                            <div style="margin-left: 24px;">
                                <mat-accordion multi *ngIf="suc.agregados.length > 0" style="">
                                    <mat-expansion-panel [expanded]="true" *ngFor="let p of suc.agregados" style="width: 95vw; min-width: 250px; max-width: 600px;" hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title style="align-items: center;">
                                                {{p.descripcion}}
                                            </mat-panel-title>
                                            <mat-panel-description style="justify-content: flex-end;">
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div style="padding: 8px;">
                                            <mat-card  *ngFor="let pp of p.presentaciones" style="margin: 4px; ">
                                                <div style="display: flex; flex-direction: column; font-size: 10pt;">
                                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
                                                        <div>Color: <b>{{pp.colorDescripcion}}</b> | Medida: <b>{{pp.medidaDescripcion}}</b></div>
                                                    </div>
                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">Stock en Sucursal</div>
                                                        <div>{{pp.stock}}</div>
                                                    </div>
                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">Cantidad a Vender</div>
                                                        <div>{{pp.cantidad}}</div>
                                                    </div>
                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">Precio Unitario</div>
                                                        <div>$ {{pp.precio}}</div>
                                                    </div>
                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">Descuento Unitario</div>
                                                        <div>${{pp.importe_descuento}} ({{pp.porcentaje_descuento}}%)</div>
                                                    </div>

                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">Precio Unitario<br>(Con Descuento)</div>
                                                        <div>$ {{pp.precio - pp.importe_descuento}}</div>
                                                    </div>

                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">Descuento SubTotal</div>
                                                        <div>$ {{(pp.importe_descuento * pp.cantidad).toFixed(2)}}</div>
                                                    </div>

                                                    <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                                        <div style="width: 120px;">SubTotal</div>
                                                        <div>$ {{((pp.precio - pp.importe_descuento) * pp.cantidad).toFixed(2)}}</div>
                                                    </div>
                                                </div>
                                                
                                            </mat-card>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            <div *ngIf="suc.agregados.length == 0">
                                <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos Agregados En La Sucursal</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="display: flex; flex-direction: row; align-items: baseline;">
                    <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarSucursal()">Agregar Sucursal</button>
                </div>
            </div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Descuento General</h2>
            </div>

            <div style="font-size: 10pt; margin: 8px 0px 16px 16px;">
                Aplica sobre el total de la venta (Importe de Productos + Flete)<br>
                *El importe de productos a su vez puede contener descuentos de producto.
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                <mat-form-field appearance="outline" >
                    <mat-label>$ Descuento</mat-label>
                    <span matPrefix>$&nbsp;</span>
                    <input matInput placeholder="$ Descuento" value="0" min="0" type="number" [(ngModel)]="preventa.descuentoGeneralImporte" (ngModelChange)="onChangeDescuentoGeneralImporte($event)" name="descuento_general_importe">
                </mat-form-field>


                <mat-form-field appearance="outline" >
                    <mat-label>% Descuento</mat-label>
                    <span matSuffix>%</span>
                    <input matInput placeholder="% Descuento" value="0" min="0" type="number" [(ngModel)]="preventa.descuentoGeneralPorcentaje" (ngModelChange)="onChangeDescuentoGeneralPorcentaje($event)" name="descuento_general_porcentaje">
                </mat-form-field>

                

            </div>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px;">
            <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                <h2>Forma de Pago</h2>
                <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar y Editar Formas de Pago</button>
            </div>

            <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">

                <mat-card  *ngFor="let fp of formasDePago; let i = index" style="margin: 4px;">
                    <div style="display: flex; flex-direction: column; font-size: 10pt;">
                        <div style="display: flex; flex-direction: row; margin-top: 4px;" *ngIf="fp.senia">
                            <div>
                                <b>Este Pago Corresponde a una Seña</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Forma de Pago: <b>{{fp.tipoDescripcion}}</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                Importe: <b>$ {{(fp.importe).toFixed(2)}}</b>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                <div>Recargo: ${{((fp.importe * fp.recargo) / 100).toFixed(2)}}</div>
                            </div>
                        </div>
                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                            <div>
                                <div>Total con Recargo: ${{((fp.importe * fp.recargo) / 100 + fp.importe).toFixed(2) }}</div>
                            </div>
                        </div>

                    </div>
                </mat-card>

                <div *ngIf="formasDePago.length == 0">
                    <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Formas de Pago Agregadas</div>
                </div>
            </div>

        </div>
        
    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
    </div>

</div>