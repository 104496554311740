import { Component, Inject, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosModel } from 'src/app/models/usuarios-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Location } from '@angular/common';
//import { AngularFireAuth } from '@angular/fire/auth';
import { Auth } from '@angular/fire/auth';
import { Utiles } from 'src/app/utiles';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


@Component({
  selector: 'app-usuarios-nuevo',
  templateUrl: './usuarios-nuevo.component.html',
  styleUrls: ['./usuarios-nuevo.component.scss']
})
export class UsuariosNuevoComponent implements OnInit {

  nuevo: UsuariosModel = {
    uid: "",
    nombre: "",
    apellido: "",
    email: "",
    rol: 0,
    idsucursal: 0,
    idvendedor: 0,
    obs: "",
    activo: true
  }

  password = {
    pass: "",
    passrep: ""
  }

  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  listLocalidades: any[] = [];

  listSucursales: any[] = [];

  listVendedores: VendedoresModel[] = [];

  edicion = false;

  //private auth: Auth = inject(Auth);

  constructor(
    @Inject(Auth) public auth: Auth,
    private apiService: ApiService,
    private dialog: MatDialog,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    //public afAuth: AngularFireAuth,

  ) {
    this.nuevo.uid = this.activatedRoute.snapshot.paramMap.get('uid') || "";

    console.log("usuario nuevo", this.nuevo);

    

  }

  ngOnInit(): void {


    if (this.nuevo.uid != ""){
      let usuarioData = this.apiService.getUsuarios(this.nuevo.uid).subscribe(x => {
        this.nuevo = x;
        this.edicion = true

        this.precargar();

      });
      
    }else{
      this.precargar();
    }

  }

  precargar(){
    let sucursales = this.apiService.getSucursalesList();
    sucursales.subscribe(x => {
      this.listSucursales = x;
    });

    let vendedores = this.apiService.getVendedoresList().subscribe(x => {
      this.listVendedores = x;

      if (this.nuevo.idvendedor != 0){
        let v = this.listVendedores.find(k => k.idvendedor == this.nuevo.idvendedor);
        if (v != null){
          this.vendedor.idvendedor = v.idvendedor;
          this.vendedor.nombre = v.nombre;
        }
      }

    })

  }

  clickGuardar(){

    this.nuevo.nombre = this.nuevo.nombre.trim();
    this.nuevo.apellido = this.nuevo.apellido.trim();
    this.nuevo.email = this.nuevo.email.trim();

    if (this.nuevo.nombre.length <= 3){
      this.snackBarService.errorMessage("Error al guardar. Nombre muy corto, debe tener mas de 3 caracteres");

      return;
    }

    if (this.nuevo.apellido.length <= 3){
      this.snackBarService.errorMessage("Error al guardar. Apellido muy corto, debe tener mas de 3 caracteres");
      return;
    }

    if (this.password.pass.length < 6 && this.password.passrep.length < 6 && !this.edicion){
      this.snackBarService.errorMessage("Error al guardar. Password muy corto, debe tener mas de 5 caracteres");
      return;
    }

    if (this.nuevo.idsucursal == 0){
      this.snackBarService.errorMessage("Error al guardar. Debe indicar una sucursal");
      return;
    }

    if (this.vendedor.idvendedor == 0){
      this.snackBarService.errorMessage("Error al guardar. Debe indicar un vendedor");
      return;
    }

    if (this.password.pass != this.password.passrep && this.edicion){
      this.snackBarService.errorMessage("Error al guardar. Password no coinciden");
      return;
    }

    if (!Utiles.regexEMail.test(this.nuevo.email)){
      this.snackBarService.errorMessage("Error al guardar. Email no valido");
      return;
    }

    this.nuevo.idvendedor = this.vendedor.idvendedor;

    if (!this.edicion){

      /*
      this.auth.createUserWithEmailAndPassword(this.nuevo.email, this.password.pass)
      //this.afAuth.createUserWithEmailAndPassword(this.nuevo.email, this.password.pass)
        .then((result) => {
          if (result != null){
            let email = result.user?.email;
            let uid = result.user?.uid
            if (email?.toLowerCase() == this.nuevo.email.toLowerCase()){
              if (uid != null && uid != ""){
                //guardar usuario
                this.nuevo.uid = uid;

                console.log("nuevo item", this.nuevo);

                if (this.nuevo.obs == ''){
                  this.nuevo.obs = "--";
                }

                this.apiService.postUsuarios(this.nuevo).subscribe(x => {
                  this.snackBarService.successMessage("Se Genero el usuario correctamente");
                  this.router.navigateByUrl('/usuarios/abm', {});
                }, (e: any) => {
                  this.snackBarService.errorMessage("Error al generar. " + e.message);
                });

              }
            }
          }
        }).catch((error) => {
          //window.alert(error.message);
          this.snackBarService.errorMessage(error.message);
        });

        */

    }else{

      if (this.nuevo.obs == '' || this.nuevo.obs == null){
        this.nuevo.obs = "--";
      }

      this.apiService.putUsuarios(this.nuevo).subscribe(x => {
        this.snackBarService.successMessage("Se actualizo el usuario correctamente");
        this.router.navigateByUrl('/usuarios/abm', {});
      }, (e: any) => {
        this.snackBarService.errorMessage("Error al generar. " + e.message);
      });


    }

  }

  generarUsuario(email: string, password: string){

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){


          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }

        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }
        
      }
    });
  }


}
