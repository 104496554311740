<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <ng-template [ngxPermissionsOnly]="['10']">
        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">
            <button mat-raised-button color="primary" (click)="clickNuevo()">Nuevo Producto</button>
            <div>
                <button mat-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="clickModificarMasivo()">Modificar Precios Masivo</button>
                </mat-menu>
            </div>
        </div>
    </ng-template>

    <div style="display: flex; flex-direction: row;">
    
        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="Rubro" matInput readonly [(ngModel)]="rubroSelected.nombre">
                <button *ngIf="rubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearRubro()">
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarRubro()">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <input type="text" placeholder="SubRubro" matInput readonly [(ngModel)]="subRubroSelected.nombre">
                <button *ngIf="subRubroSelected.nombre" matSuffix mat-icon-button aria-label="Clear" (click)="clickClearSubRubro()" >
                    <mat-icon>close</mat-icon>
                </button>
                <button matSuffix mat-icon-button (click)="seleccionarSubRubro()"  [disabled]="rubroSelected.id == 0">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <mat-form-field appearance="outline" style="flex-grow: 1;">
                <mat-label>Estado</mat-label>
                <mat-select [(ngModel)]="estadoSel" (selectionChange)="onChangeEstado(estadoSel, $event)">
                    <mat-option *ngFor="let estado of listEstado" [value]="estado">{{estado.nombre}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!--
        <div style="display: flex; flex-grow: 0.2; flex-direction: row; align-items: baseline; padding: 6px;">
            <button mat-raised-button color="primary" (click)="clickBuscar()">Buscar</button>
        </div>
        -->

    </div>

    <div style="display: flex; flex-direction: row;">    
        <div style="flex-grow: 0.4; padding:6px;">
            <mat-form-field appearance="outline" style="width: 100%;" >
                <input type="text" placeholder="Filtro Rápido" matInput (keyup)="filtroRapido($event)" autocomplete="nope">
            </mat-form-field>
        </div>
    </div>

    <div style="display: flex; font-size: 9pt; margin-bottom: 12px; justify-content: space-between;">
        <div>
            Filtrados por Rubro y SubRubro&nbsp;<span>{{dataSource.data.length}}</span>&nbsp;| Mostrando {{dataSource.filteredData.length}}
        </div>
        <div>
            Total de Productos&nbsp;<span>{{dataCompleta.length}}</span>
        </div>
        
    </div>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" style="width: 100%;">

        <!-- Position Column -->
        <ng-container matColumnDef="idproducto">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> id </th>
        <td mat-cell *matCellDef="let element"> {{element.idproducto}} </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="productoDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Descripción </th>
        <td mat-cell *matCellDef="let element"> {{element.productoDescripcion}} <span class="tip-desactivado" *ngIf="!element.activo">DESACTIVADO</span></td>
        </ng-container>

        <ng-container matColumnDef="rubroDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Rubro </th>
        <td mat-cell *matCellDef="let element"> {{element.rubroDescripcion}} </td>
        </ng-container>
        
        <ng-container matColumnDef="subrubroDescripcion">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> SubRubro </th>
        <td mat-cell *matCellDef="let element"> {{element.subrubroDescripcion}} </td>
        </ng-container>
        
        <ng-container matColumnDef="activo">
            <th mat-header-cell mat-sort-header *matHeaderCellDef> Activo </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="!element.activo" class="tip-desactivado">NO</span>
                <span *ngIf="element.activo" class="tip-activado">SI</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="opciones">
            <th mat-header-cell *matHeaderCellDef> Opciones </th>
            <td mat-cell *matCellDef="let element">
                <ng-template [ngxPermissionsOnly]="['10']">
                    <button mat-raised-button class="boton-celda" (click)="clickEditar(element, $event)">Editar</button> 
                    <button mat-raised-button class="boton-celda" (click)="clickEliminar(element, $event)" *ngIf="element.activo">Eliminar</button> 
                </ng-template>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetalle">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
              <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                <mat-progress-bar mode="indeterminate" *ngIf="cargandoElemento"></mat-progress-bar>

                <div *ngIf="element.presentaciones.length > 0 && element == expandedElement;else sinpresentaciones_content">
                    <mat-tab-group style="width: 100%; margin-bottom: 16px;" animationDuration="0ms">
                        <mat-tab label="Precios">
                            <div>
                                <div *ngIf="element.presentaciones.length > 0" style="width: 100%;">
            
                                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;align-items: baseline;">
                                        <div>
                                            <mat-form-field style="margin: 16px 0px 8px 8px;" appearance="outline">
                                                <mat-label>Tipo de Precio</mat-label>
                                                <mat-select [(ngModel)]="tipoPrecio">
                                                    <mat-option *ngFor="let tipo of listTipoPrecio" [value]="tipo">{{tipo.descripcion}}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <ng-template [ngxPermissionsOnly]="['10']">
                                                <button mat-raised-button color="primary" (click)="clickModificarProductoMasivo(element)">Modificar Precios</button>
                                            </ng-template>
                                        </div>
                                    </div>
            
                                    <table class="tabla-interna">
                                        <tr>
                                            <th rowspan="2">Color</th>
                                            <th rowspan="2">Medida</th>
                                            <th rowspan="2">Precio</th>
                                            <th colspan="3" *ngIf="tipoPrecio != null">{{tipoPrecio.descripcion}}</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngIf="tipoPrecio != null">
                                                <th>% Recargo</th>
                                                <th>$ Recargo</th>
                                                <th>$ Final</th>
                                            </ng-container>
                                        </tr>
                                        <tr *ngFor="let item of element.presentaciones">
                                            <td>{{item.colorDescripcion}}</td>
                                            <td style="text-align: left;">{{item.medidaDescripcion}}</td>
                                            <td style="text-align: right;">{{fcNum(item.precio)}}</td>
                                            <ng-container *ngIf="tipoPrecio != null">
                                                <td style="text-align: right;">{{tipoPrecio.recargo}} %</td>
                                                <td style="text-align: right;">$ {{fcNum((tipoPrecio.recargo * item.precio) / 100)}}</td>
                                                <td style="text-align: right;">$ {{fcNum(((tipoPrecio.recargo * item.precio) / 100) + item.precio)}}</td>
                                            </ng-container>
                                            
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                        
                        <mat-tab label="Stock por Sucursales">
                            <div>*Se ocultan los stock en 0</div>
                            <div style="margin-top: 16px;">
                                <div *ngIf="element.presentaciones.length > 0" style="width: 100%;">
                                    <table class="tabla-interna">
                                        <tr>
                                            <th>Color</th>
                                            <th>Medida</th>
                                            <th *ngFor="let suc of listSucursales">{{suc.descripcion}}</th>
                                        </tr>                                       
                                        <tr *ngFor="let p of element.presentaciones">
                                            <td>{{p.colorDescripcion}}</td>
                                            <td style="text-align: left;">{{p.medidaDescripcion}}</td>
                                            <td style="text-align: right;" *ngFor="let ps of p.stock">
                                                <span [ngClass]="{'stock-cero': (ps.cantidad == 0), 'stock-positivo': (ps.cantidad > 0), 'stock-negativo': (ps.cantidad < 0)}">{{ps.cantidad}}</span></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </mat-tab>
                    
                    </mat-tab-group>
                </div>
                <ng-template #sinpresentaciones_content>
                    <div *ngIf="!cargandoElemento && expandedElement != null" class="msj-sin-presentaciones">El producto no posee presentaciones</div>
                </ng-template>
              </div>
            </td>
          </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                    class="element-row"
                    [class.expanded-row]="expandedElement === element"
                    (click)="clickProductoRow(element)">
                </tr>

        <tr mat-row *matRowDef="let row; columns: ['expandedDetalle']" class="detail-row"></tr>

        
    </table>
    

</div>
