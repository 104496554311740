import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ProveedoresModel } from 'src/app/models/proveedores-model';
import { ApiService } from 'src/app/services/api.service';
import { Location } from '@angular/common';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';


@Component({
  selector: 'app-proveedores-nuevo',
  templateUrl: './proveedores-nuevo.component.html',
  styleUrls: ['./proveedores-nuevo.component.scss']
})
export class ProveedoresNuevoComponent implements OnInit {

  nuevo: ProveedoresModel = {
    idproveedor: 0,
    nombre: "",
    domicilio: "",
    telefono: "",
    correo_electronico: "",
    codigo_localidad: 0,
    CUIT: "",
    CBU: ""
  }

  listLocalidades: any[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private _location: Location

  ) {
    this.nuevo.idproveedor = Number(this.activatedRoute.snapshot.paramMap.get('idproveedor'));

    if (this.nuevo.idproveedor > 0){
      this.apiService.getProveedores(this.nuevo.idproveedor).subscribe(x => {
        this.nuevo = x;
        
      })
    }

    this.apiService.getLocalidadesList().subscribe(x => {
      this.listLocalidades = x as any[];
    });

  }

  ngOnInit(): void {

    

  }

  clickGuardar(){
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idproveedor == 0){
        this.apiService.postProveedores(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el proveedor correctamente");
          this.router.navigateByUrl('/proveedores/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        this.apiService.putProveedores(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el proveedor correctamente");
          this.router.navigateByUrl('/proveedores/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo nombre debe tener mas de 2 caracteres.");
    }

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

}
