import { Injectable } from '@angular/core';

import * as moment from 'moment';


import * as pdfMake  from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { toFechaTimeStamp, Utiles } from '../utiles';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(

  ) { }


  generarPDF_(titulo: any, filas: any): void {

    let fechaHora = this.getFechaHora(new Date());
    
    const pdfDefinition: TDocumentDefinitions = {
      info: { title: `verdemanzana.pdf` },
      header: { text: `Verde Manzana Muebles - ${fechaHora}`, margin: [ 5, 2, 10, 20 ], fontSize: 9 },
      footer: (currentPage: any, pageCount: any) => {
        return [
          {
            text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
            style: 'text',
            alignment: 'right',
            margin: [0, 0, 8, 8],
          },
        ];
      },
       content: [
        { text: titulo, fontSize: 15, margin:[0, 0, 0, 10] },
        {
          table: {
            headerRows: 1,
            body: filas
          }
        }
       ]
     }
    
     const pdf = pdfMake.createPdf(pdfDefinition).download(`hola.pdf`);
     


  }

  getFechaHora(f: Date){
    let m = moment(f);
    return m.format("DD-MM-YYYY HH:mm:ss");
  }

  generarPDF(content: any): void {

    let fechaHora = this.getFechaHora(new Date());
    
    const pdfDefinition: TDocumentDefinitions = {
      info: { title: `verdemanzana.pdf` },
      header: { text: `Verde Manzana Muebles - ${fechaHora}`, margin: [ 5, 2, 10, 20 ], fontSize: 9 },
      footer: (currentPage: any, pageCount: any) => {
        return [
          {
            text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
            style: 'text',
            alignment: 'right',
            margin: [0, 0, 8, 8],
          },
        ];
      },
      content: content
     }

     let times = toFechaTimeStamp(new Date());

     console.log("pdf def", pdfDefinition);
    
     const pdf = pdfMake.createPdf(pdfDefinition).download(`vm-${times}.pdf`);
     


  }

}
