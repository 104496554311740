import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RubrosProductoModel } from 'src/app/models/rubros-producto-model';
import { SubRubrosProductoModel } from 'src/app/models/subrubros-producto-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-subrubros-nuevo',
  templateUrl: './subrubros-nuevo.component.html',
  styleUrls: ['./subrubros-nuevo.component.scss']
})
export class SubrubrosNuevoComponent implements OnInit {

  nuevo: SubRubrosProductoModel = {
    idsubrubroproducto: 0,
    idrubroproducto: 0,
    descripcion: ""
  }

  listRubros: RubrosProductoModel[] = [];

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {

    
    this.nuevo.idsubrubroproducto = Number(this.activatedRoute.snapshot.paramMap.get('idsubrubroproducto'));

    if (this.nuevo.idsubrubroproducto > 0){
      this.apiService.getSubRubros(this.nuevo.idsubrubroproducto).subscribe(x => {
        this.nuevo = x
      })
    }

    this.apiService.getRubrosList().subscribe(x => {
      this.listRubros = [];
      this.listRubros = x;
    });
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){

    
    console.log("nuevo", this.nuevo);

    
    if (this.validar(this.nuevo)){
      if (this.nuevo.idsubrubroproducto == 0){
        this.apiService.postSubRubros(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero SubRubro correctamente");
          this.router.navigateByUrl('/subrubros/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        
        this.apiService.putSubRubros(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el SubRubro correctamente");
          this.router.navigateByUrl('/subrubros/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo descripción debe tener mas de 2 caracteres");
      
    }
    

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

}
