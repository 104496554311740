import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { VentasFormasPagoDialogComponent } from '../ventas-formas-pago-dialog/ventas-formas-pago-dialog.component';

@Component({
  selector: 'app-entregas-confirmar-dialog',
  templateUrl: './entregas-confirmar-dialog.component.html',
  styleUrls: ['./entregas-confirmar-dialog.component.scss']
})
export class EntregasConfirmarDialogComponent implements OnInit {

  checkedGlobal = false;
  selectedElement: any | null;

  displayedColumns: string[] = ['linea', 'producto', 'cantidad', 'estado', 'acciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  cargando = false;

  venta: any = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VentasFormasPagoDialogComponent>,
    private dialog: MatDialog,
    private dialogInfo: MatDialog,
    private apiService: ApiService
  ) {

    console.log("data", this.data);

    this.venta = this.data.venta;

    if (this.venta != null){
      let entregas: any[] = this.venta.lineas.entregas;
      entregas = entregas.map(x => {
        x._cantidad = x.cantidad;
        return x;
      });
      this.dataSource.data = entregas;
    }

  }

  ngOnInit(): void {

    
  }

  clickCerrar() {

    this.dialogRef.close(
      {
        cierre: true,
        //formasDePago: this.listFormaPago
      });
  }

  selectRow(r: any){

  }

  onChangeSelectItem(event: any, element: any) {
    //element.selected = event.checked;
  }

  onChangeSelectGlobal(event: any) {
  }

  clickIncrementar(e: any, event: any){
    if (e._cantidad < e.cantidad){
      e._cantidad++
    }
  }

  clickDecrementar(e: any, event: any){
    if (e._cantidad > 0){
      e._cantidad--
    }
  }

  changeCantidad(e: any, value: any){
    let n = Number(value);
    if (Number.isInteger(n)){
      if (n >= 0 && n <= e.cantidad){
        e._cantidad = n;
        return;
      }
    }
    e._cantidad = 0;
  }

  clickConfirmar(){
    let data: any[] = this.dataSource.data;
    
    let aviso = false;
    let error = false;
    data.forEach(x => {
      if (x._cantidad < x.cantidad){
        aviso = true
      }
      if (x._cantidad > x.cantidad || x._cantidad < 0 || !Number.isInteger(x._cantidad)){
        error = true;
      }
    });

    if(error){
      return;
    }

    if (aviso){
      this.dialogConfirmar("Alerta", "Hay productos que quedaran pendientes de entrega, desea continuar?");
    }else{
      this.dialogConfirmar("Alerta", "Se van a marcar los productos como entregados, desea continuar?");
    }

  }

  dialogConfirmar(titulo: string, mensaje: string) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //confirmar
        //generamos el nuevo arreglo de como va a quedar
        let lote: any[] = [];
        let data: any[] = this.dataSource.data;
        data.forEach(x => {
          if (x._cantidad > 0){
            let dif = x.cantidad - x._cantidad;
            if (dif > 0){
              let n1 = {
                idlinea: x.idlinea,
                idventa: x.idventa,
                idproductopresentacion: x.idproductopresentacion,
                cantidad: x._cantidad,
                estado: 2,
                idsucursal: x.idsucursal
              }
              let n2 = {
                idlinea: 0,
                idventa: x.idventa,
                idproductopresentacion: x.idproductopresentacion,
                cantidad: dif,
                estado: 0,
                idsucursal: x.idsucursal
              }
              lote.push(n1);
              lote.push(n2);
            }else{
              let n3 = {
                idlinea: x.idlinea,
                idventa: x.idventa,
                idproductopresentacion: x.idproductopresentacion,
                cantidad: x._cantidad,
                estado: 2,
                idsucursal: x.idsucursal
              }
              lote.push(n3);
            }
          }
        });

        if (lote.length > 0){
          this.apiService.postEntregaProductosBulk(lote).subscribe(x => {
            if (x){
              this.dialogRef.close({
                cierre: true,
                recargar: true
              });
            }
          });
        }else{
          this.dialogRef.close({
            cierre: true,
            recargar: false
          });
        }

      } 
    });

  }


}

const ELEMENT_DATA: any[] = [];
