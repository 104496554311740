<div style="width: 40vw; min-width: 650px; display: flex; flex-direction: column; height: 100%; ">

    <div style="display: flex; flex-direction: row; height: 100%; background-color: #ffffc3;">
        <div style="width: 180px;">

            <div style="margin-top: 4px; padding: 4px;">

                <mat-form-field appearance="outline" style="width: 100%; margin-bottom: 4px;">
                    <mat-select [(ngModel)]="sucursalActual.idsucursal" (ngModelChange)="onChangeSucursal($event)">
                        <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>       

                <button mat-raised-button (click)="clickNuevaNota()" style="width: 100%;">Nueva Nota</button>    
            </div>
            
            <mat-nav-list style="width: 100%;" class="list-notas">
                <mat-list-item *ngFor="let nota of listNotasFiltrada" (click)="clickNota(nota)" style="font-size: 10pt;" [ngClass]="{'item-activo': notaActual != null ? nota.key == notaActual.key : false}">
                    <div style="display: flex; flex-direction: column;">
                        <div style="display: inline-grid;">
                            <span *ngIf="nota.value.titulo.length == 0" style="font-style: italic;">
                                SIN TITULO
                            </span>
                            <span *ngIf="nota.value.titulo.length > 0" style="text-overflow: ellipsis; white-space:nowrap; overflow:hidden">
                                {{nota.value.titulo}}
                            </span>
                        </div>
                        <div>
                            <span *ngIf="nota.value.sucursalNombre?.length > 0" style="text-overflow: ellipsis; white-space:nowrap; overflow:hidden; font-size: 8pt;">
                                {{nota.value.sucursalNombre}}
                            </span>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                </mat-list-item>
                
            </mat-nav-list>
    
        </div>
        <div style="width: 70%; background-color: #ffffc3; flex-grow: 1;">
            <div style="display: flex; flex-direction: column; padding: 8px;" *ngIf="notaActual" class="container-nota">
                <div style="display: flex; align-items: center;">
                    <div style="flex-grow: 1; margin-right: 4px;">
                        <div style="width: 100%; display: flex;">
                            <input style="flex-grow: 1;" type="text" placeholder="Titulo" maxlength="50" class="titulo-input" [(ngModel)]="notaActual.value.titulo" (ngModelChange)="notaTituloOnChange($event, notaActual)">    
                        </div>
                    </div>
                    <button mat-raised-button (click)="clickBorrarNota(notaActual)" [disabled]="notaActual != null ? notaActual.value.contenido.length > 0 : false" [matTooltip]="notaActual.value.contenido.length == 0 ? '' : 'Solo se pueden borrar notas sin contenido'">Borrar Nota</button>
                </div>

                <!--
                <div class="textwrapper"  style="flex-grow: 1; margin-top: 8px">
                    <textarea class="boxsizingBorder texta" style="width: 100%; height: 100%;" [(ngModel)]='notaActual.value.contenido' (ngModelChange)="notaContenidoOnChange($event, notaActual)">
                    </textarea>
                </div>
                -->

                <angular-editor class="texta" [(ngModel)]="notaHtmlContent" (ngModelChange)="notaContenidoOnChange($event, notaActual)" [config]="editorConfig"></angular-editor>

            </div>
            <div *ngIf="notaActual == null" style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center;">
                Seleccione una nota
            </div>
        </div>

    </div>

    

    

</div>

