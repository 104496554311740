import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { formatNumeroNacional } from 'src/app/utiles';

@Component({
  selector: 'app-compras-formas-pago-dialog',
  templateUrl: './compras-formas-pago-dialog.component.html',
  styleUrls: ['./compras-formas-pago-dialog.component.scss']
})
export class ComprasFormasPagoDialogComponent implements OnInit {

  cargando = false;

  tipoPrecioList: any[] = [];

  listFormaPago: any[] = [];

  importe = 0.0;
  restante = 0.0;

  fcNum = formatNumeroNacional;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ComprasFormasPagoDialogComponent>,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {

    console.log("data", this.data);

    this.listFormaPago = this.data.formasDePago;
    this.importe = this.data.importe;

    this.listFormaPago.forEach(x => {
      x._obs = x.observaciones.length > 0;
    })

    this.calcularRestante();
    
  }

  ngOnInit(): void {

    this.apiService.getComprasTipoPrecioList().subscribe(x => {
      this.tipoPrecioList = x;
    });

  }

  clickCerrar() {

    this.listFormaPago.forEach(x => {
      x.importe = Number(x.importe);
    });

    this.dialogRef.close(
      {
        cierre: true,
        formasDePago: this.listFormaPago
      });
  }

  clickAgregar(){

    if(this.tipoPrecioList.length > 0){
      this.listFormaPago.push({
        idcompraformapago: 0,
        idcompra: 0,
        idcompratipoprecio: this.tipoPrecioList[0].idcompratipoprecio,
        tipoPrecioDescripcion: this.tipoPrecioList[0].descripcion,
        importe: JSON.parse(JSON.stringify(this.restante)),
        observaciones: "",
        _obs: false
      });

      this.calcularRestante();

    }

  }

  clickBorrarFormaPago(fp: any, i: number, event: any){
    this.listFormaPago.splice(i, 1);
    this.calcularRestante();
  }

  onChange(fp: any, e: any){
    console.log("fp", fp);
    console.log("e.value", e.value);

    let a = this.tipoPrecioList.find(x => x.idcompratipoprecio == e.value);
    if (a != null){
      fp.tipoPrecioDescripcion = a.descripcion;
    }

  }

  calcularRestante(){
    let sum = 0.0;
    this.listFormaPago.forEach(x => {
      sum += x.importe;
    });
    this.restante = this.importe - sum;
  }

  changeImporte(element: any, v: number){
    this.calcularRestante();
  }

}
