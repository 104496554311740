import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  getSucursalActual(){
    let json = localStorage.getItem('sucursal') || "";
    if (json != ""){
      return JSON.parse(json);
    }else{
      return {
        sucursalId: 0,
        sucursalNombre: ""
      }
    }
  }

  setSucursalActual(sucursalId: number, sucursalNombre: string){
    let sucursal = {
      sucursalId: sucursalId,
      sucursalNombre: sucursalNombre
    }
    localStorage.setItem('sucursal', JSON.stringify(sucursal));
  }


}
