import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-colores-producto-abm',
  templateUrl: './colores-producto-abm.component.html',
  styleUrls: ['./colores-producto-abm.component.scss']
})
export class ColoresProductoAbmComponent implements OnInit {

  displayedColumns: string[] = ['idcolorproducto', 'nombre', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  constructor(
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    public router: Router
  ) {
    
  }

  ngOnInit(): void {
    this.cargarTabla();
  }

  cargarTabla(){

    this.apiService.getColoresList().subscribe( (o: any[]) => {
      this.dataSource.data = o;
    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/productos/colores/nuevo', {});
  }

  clickRow(row: any){
    //console.log("row", row);
    this.router.navigateByUrl(`/productos/colores/editar/${row.idcolorproducto}`,);
  }

  clickEditar(element: any){
    //console.log(element);
    this.router.navigateByUrl(`/productos/colores/editar/${element.idcolorproducto}`,);
  }

  clickEliminar(element: any){

    console.log(element);
    
    this.apiService.deleteColores(element).subscribe(r => {
      console.log("result", r);
      this.cargarTabla();
    }, e => {
      this.snackBarService.errorMessage("Error al eliminar el Color. " + e.message);
    });
    
    //this.router.navigateByUrl(`/productos/editar/${element.idproducto}`,);
  }

}

const ELEMENT_DATA: any[] = [];

