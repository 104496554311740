import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone

  ) {
    //this.isOpen = new BehaviorSubject<boolean>(true);
  }

  public open(message: any, action = 'success', duration = 50000) {
    this.zone.run(() => {
        this.snackBar.open(message, action, { duration });
    });
  }

  public errorMessage(message: string){

    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar-error'];
    config.duration = 6000;
     

    this.zone.run(() => {
      this.snackBar.open(message, "", config);
    });
  }

  public successMessage(message: string){

    const config = new MatSnackBarConfig();
    config.panelClass = ['snackbar-success'];
    config.duration = 5000;
     

    this.zone.run(() => {
      this.snackBar.open(message, "", config);
    });
  }


  public commentMessage(message: string){
    const config = new MatSnackBarConfig();
    //config.panelClass = ['snackbar-success'];
    config.duration = 5000;
     

    this.zone.run(() => {
      this.snackBar.open(message, "", config);
    });
  }

  /*
  toggle() {
    this.isOpen.next(!this.isOpen.getValue());
  }
  close() {
    this.isOpen.next(false);
  }
  open(){
    this.isOpen.next(true);
  }
  */
  
}


