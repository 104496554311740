import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { formatNumeroNacional } from 'src/app/utiles';

@Component({
  selector: 'app-ventas-formas-pago-dialog',
  templateUrl: './ventas-formas-pago-dialog.component.html',
  styleUrls: ['./ventas-formas-pago-dialog.component.scss']
})
export class VentasFormasPagoDialogComponent implements OnInit {

  cargando = false;

  tipoPrecioList: any[] = [];

  listFormaPago: any[] = [];

  listFormaPagoOriginal: any[] = [];

  importe = 0.0;
  restante = 0.0;

  bloquearSenia = false;

  esPropuesta = true;
  esPagoConfirmado = false;

  nombreId = 'idpreventaformapago';

  fcNum = formatNumeroNacional;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VentasFormasPagoDialogComponent>,
    private dialog: MatDialog,
    private apiService: ApiService
  ) {
    this.listFormaPago = this.data.formasDePago;
    this.listFormaPagoOriginal = JSON.parse(JSON.stringify(this.listFormaPago));
    this.esPropuesta = this.data.esPropuesta == null ? true : this.data.esPropuesta;
    this.esPagoConfirmado = this.data.esPagoConfirmado == null ? false : this.data.esPagoConfirmado;

    if (this.data.bloquearSenia){
      this.bloquearSenia = this.data.bloquearSenia;
    }

    this.importe = this.data.importe;

    this.calcularRestante();
    
  }

  ngOnInit(): void {

    this.apiService.getTiposDePrecioList().subscribe(x => {
      console.log("xxxx", x);
      this.tipoPrecioList = x;
    })

  }

  clickConfirmar() {

    this.listFormaPago.forEach(x => {
      x.importe = Number(x.importe);
    });

    this.dialogRef.close(
      {
        cierre: true,
        formasDePago: this.listFormaPago
      });
  }

  clickCancelar(){
    this.listFormaPago = this.listFormaPagoOriginal;
    this.dialogRef.close(
      {
        cierre: true,
        formasDePago: this.listFormaPago
      });
  }

  clickAgregar(){

    if(this.tipoPrecioList.length > 0){
      let item: any = {
        idpreventa: 0,
        idtipo: this.tipoPrecioList[0].idtipo,
        tipoDescripcion: this.tipoPrecioList[0].descripcion,
        importe: Number((JSON.parse(JSON.stringify(this.restante))).toFixed(2)),
        recargo: 0.0,
        senia: false
      }
      item[this.nombreId] = 0

      this.listFormaPago.push(item);

      this.calcularRestante();

    }

  }

  clickBorrarFormaPago(fp: any, i: number, event: any){
    this.listFormaPago.splice(i, 1);
    this.calcularRestante();
  }

  onChange(fp: any, e: any){
    console.log("fp", fp);
    console.log("e.value", e.value);

    let a = this.tipoPrecioList.find(x => x.idtipo == e.value);
    if (a != null){
      fp.tipoDescripcion = a.descripcion;
      fp.recargo = a.recargo;
    }

  }

  calcularRestante(){
    let sum = 0.0;
    this.listFormaPago.forEach(x => {
      sum += x.importe;
    });
    this.restante = this.importe - sum;
  }

  changeImporte(element: any, v: number){
    this.calcularRestante();
  }

}
