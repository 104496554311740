import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ExcelService } from 'src/app/services/excel.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import * as moment from 'moment';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { formatNumeroNacional } from 'src/app/utiles';


@Component({
  selector: 'app-clientes-deudores',
  templateUrl: './clientes-deudores.component.html',
  styleUrls: ['./clientes-deudores.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientesDeudoresComponent implements OnInit {

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  displayedColumns: string[] = ['idcliente', 'nombre', 'ventas', 'pagos', 'debe', 'porcentaje_deuda', 'cantidad', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  cargandoElemento = false;

  dataOrig: any[] = [];

  cargando = false;

  expandedElement: any | null;

  lastParam: any = null;

  @ViewChild(MatSort)
  sort!: MatSort;

  listSucursales = [];

  sucursalSelected = {
    id: 0,
    nombre: ""
  }

  fcNum = formatNumeroNacional;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private _excelService: ExcelService,
  ) {
    moment.locale('es');
  }

  ngOnInit(): void {

    let mDesde = moment().startOf('month');
    let mHasta = moment().endOf('month');

    mHasta.set({hour:23,minute:59,second:59,millisecond:0});

    this.range.controls.start.setValue(mDesde.toDate());
    this.range.controls.end.setValue(mHasta.toDate());

    let obsSucursales = this.apiService.getSucursalesList();

    combineLatest([obsSucursales]).subscribe(hh => {
      this.listSucursales = hh[0];
    })

    this.cargarTabla();
  }

  cargarTabla(){

    this.dataOrig = [];
    this.dataSource.data = this.dataOrig;

    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    mHasta.set({hour:23,minute:59,second:59,millisecond:0});

    let sDesde = mDesde.format("YYYY-MM-DD HH:mm:ss");
    let sHasta = mHasta.format("YYYY-MM-DD HH:mm:ss");

    let param = {
      fecha: {
        desde: sDesde != 'Fecha inválida' ? sDesde : null,
        hasta: sHasta != 'Fecha inválida' ? sHasta : null
      },
      idsucursal: this.sucursalSelected.id != 0 ? this.sucursalSelected.id : null
    }

    if (param.fecha.desde == null || param.fecha.hasta == null) {
      if (!(param.fecha.desde == null && param.fecha.hasta == null)){
        //this.snackBarService.errorMessage("Debe proporcionar un rango de fechas completo");
        param.fecha = null;
      }
    }

    this.lastParam = JSON.parse(JSON.stringify(param));

    this.cargando = true;

    this.apiService.postClientesDeudores(param).subscribe( (o: any[]) => {

      o = o.map(x => {
        x.porcentaje_deuda = ((x.debe * 100) / x.ventas_totales).toFixed(2);
        x.detalle = null;
        return x;
      })

      this.dataOrig = o;
      this.dataSource.data = this.dataOrig;
      this.dataSource.sort = this.sort;

      this.cargando = false;

    }, e => {
      this.cargando = true;
    });
  }

  filtroLocal(){
    let filtrado =this.dataOrig;
    this.dataSource.data = filtrado;
  }

  /*
  exportarExcel(){

    let exporta: any[] = [];

    let data = this.dataSource.data;

    if (data.length == 0){
      return;
    }

    data.forEach(r => {
      let row: any = {
        "Id": r.idcliente,
        "Nombre": r.nombre,
        "Domicilio": r.domiclio,
        "Localidad": r.nombre_localidad,
        "Codigo Postal": r.codigo_postal,
        "Telefono": r.telefono,
        "Correo Electronico": r.correo_electronico,
        "Observaciones": r.observaciones
      };
      exporta.push(row);
    });

    if (exporta.length > 0) {
      this._excelService.exportAsExcelFile(
        exporta,
        'lista-clientes',
        false,
        [],
        ''
      );
    } else {
      
      
      Swal.fire({
        title: 'No hay datos para exportar',
        text: 'Los datos que se muestran son los que se exportan a Excel, actualmente no hay datos',
        icon: 'info',
      });
      
    }
  }
  */

  filtroRapido(filterValue: any) {

    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
    
  }

  sortData(sort: any){
    console.log("event sort", sort);

    this.dataSource.data.sort((a: any, b: any) => {

      console.log("active", sort.active);
      console.log("a", a);
      console.log("b", b);

      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) {
        case 'idcliente': return compareNumber(a.idcliente, b.idcliente, isAsc);
        case 'nombre': return compareString(a.nombre, b.nombre, isAsc);
        case 'ventas': return compareNumber(a.ventas_totales, b.ventas_totales, isAsc);
        case 'pagos': return compareNumber(a.pagos_totales, b.pagos_totales, isAsc);
        case 'debe': return compareNumber(a.debe, b.debe, isAsc);
        case 'porcentaje_deuda': return compareString(a.porcentaje_deuda, b.porcentaje_deuda, isAsc);
        case 'cantidad': return compareNumber(a.cantidad_deudas, b.cantidad_deudas, isAsc);
        
        default: return 0;
      }
      
      
   });

  }

  clickRow(e){
    console.log("element", e);

    this.expandedElement = this.expandedElement == e ? null : e;

    if (this.expandedElement == null){
      return;
    }

    this.cargandoElemento = true;
    this.apiService.postClienteDeudorDetalle(e.idcliente, this.lastParam).subscribe(x => {
      x = x.map(xx => {
        let m = moment(xx.fecha);
        xx._fecha = m.format("DD-MM-YYYY HH:mm");
        return xx;        
      });
      e.detalle = x;
      this.cargandoElemento = false;
    }, e => {
      this.cargandoElemento = false;
    });
  }

  clickConsultar(){
    this.cargarTabla();
  }

  exportarExcel(){

  }

  exportarPDF(){

  }

  seleccionarSucursal(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.sucursalSelected.id,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.sucursalSelected.id != rr.seleccion.id){
            this.sucursalSelected.id = 0;
            this.sucursalSelected.nombre = "";
          }
          this.sucursalSelected = {
            id: rr.seleccion.id,
            nombre: rr.seleccion.nombre
          }
        }else{
          this.sucursalSelected = {nombre: "", id: 0};
        }
        //this.cargarFiltradoRubroSubRubro();
      }
    });
  }

  clickClearSucursal(){
    this.sucursalSelected = {id: 0, nombre: ""};

  }


}

const ELEMENT_DATA: any[] = [];

function compareString(a: string, b: string, isAsc: boolean){
  return a.localeCompare(b)
}


function compareNumber(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};
