import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatest } from 'rxjs';
import { ClientesCompletoModel } from 'src/app/models/clientes-completo-model';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SharedService } from 'src/app/services/shared.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { formatNumeroNacional, toFechaCorta, toFechaCortaSoloFecha } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { DialogSeleccionTipoReporteComponent } from './dialog-seleccion-tipo-reporte/dialog-seleccion-tipo-reporte.component';
import { fill } from 'lodash';

@Component({
  selector: 'app-ventas-reporte',
  templateUrl: './ventas-reporte.component.html',
  styleUrls: ['./ventas-reporte.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class VentasReporteComponent implements OnInit {

  expandedElement: any | null;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  estados = ['Pendiente', 'Confirmado', 'Pagado', 'Cancelado', 'Presupuesto'];

  displayedColumns: string[] = ['idpreventa', 'sucursal', 'fecha', 'cliente', 'preventaEstado', 'vendedor', 'importeVenta', 'totalPagado', 'importeSujetoComision', 'comision', 'pagado'];
  displayedColumnsTotal: string[] = ['total'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  listVendedores: VendedoresModel[] = [];
  
  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  listCliente: ClientesCompletoModel[] = [];

  cliente:ClientesCompletoModel = {
    idcliente: 0,
    nombre: "",
    domicilio: "",
    codigo_localidad: 0,
    nombre_localidad: "",
    codigo_postal: "",
    telefono: "",
    correo_electronico: "",
    observaciones: "",
  };

  total = 0.0;
  totalSujetoComision = 0.0;
  totalComision = 0.0;
  totalComisionAcumulada = 0.0;
  totalPagado = 0.0;

  lastParam: any = null;

  detalleArticulo = false;
  detalleFormaPago = false;
  detalleComision = false;
  detallePagoTotal = true;

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  sucursalId = 0;
  sucursalNombre = "";

  listFormaPago: any[] = [];

  listTipoPrecio = [];

  listSucursales: any[] = [];

  listProductosSucursal: any[] = [];

  listProductoCompleto = [];

  cargando = false;

  listRubro = [];
  listSubRubro = [];

  tablaOrig = [];

  fcNum = formatNumeroNacional;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private pdfService: PdfService,
    private sharedService: SharedService,
    private snackBarService: SnackBarService,
    private _permissionsService: NgxPermissionsService
  ) {
    moment.locale('es');

    this.sucursalActual = this.sharedService.getSucursalActual();
  }

  ngOnInit(): void {

    this.apiService.getVendedoresList().subscribe((x: any[]) => {
      this.listVendedores = x;
    });

    this.apiService.getClientesCompletoList().subscribe((x: any[]) => {
      this.listCliente = x;
    });

    this.apiService.getSucursalesList().subscribe((x: any[]) => {
      this.listSucursales = x;
    });

    this.apiService.getRubrosList().subscribe(x => {
      this.listRubro = x;
    });

    this.apiService.getSubRubrosList().subscribe(x => {
      this.listSubRubro = x;
    });

    
    
  }

  clickConsultar(){

 
    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    mHasta.set({hour:23,minute:59,second:59,millisecond:0});

    let sDesde = mDesde.format("YYYY-MM-DD HH:mm:ss");
    let sHasta = mHasta.format("YYYY-MM-DD HH:mm:ss");

    let sId = this.sucursalActual.sucursalId;
    let sNom = this.sucursalActual.sucursalNombre;

    let p = this._permissionsService.getPermissions();

    if ('10' in p){
      sId = this.sucursalId;
      sNom = this.sucursalNombre;
    }

    let param = {
      vendedorId: this.vendedor.idvendedor,
      clienteId: this.cliente.idcliente,
      sucursalId: sId,
      sucursalNombre: sNom,
      fecha: {
        desde: sDesde != 'Fecha inválida' ? sDesde : null,
        hasta: sHasta != 'Fecha inválida' ? sHasta : null
      },
      estados: [1, 2]
    }

    if (param.fecha.desde == null || param.fecha.hasta == null) {
      if (!(param.fecha.desde == null && param.fecha.hasta == null)){
        this.snackBarService.errorMessage("Debe proporcionar un rango de fechas completo");
        return;
      }
    }

    const obsPreventasReporteGeneral = this.apiService.postPreventasReporteGeneral(param);
    const obsPreventasReporteFormaPago = this.apiService.postPreventasReporteFormaPago(param);
    const obsTiposDePrecioList = this.apiService.getTiposDePrecioList();

    this.cargando = true;

    this.listProductosSucursal = [];
    this.listProductoCompleto = [];
    
    combineLatest([obsPreventasReporteGeneral, obsPreventasReporteFormaPago, obsTiposDePrecioList]).subscribe((h: any[]) => {
    //this.apiService.postPreventasReporteGeneral(param).subscribe((col: any[]) => {

      if (this.detallePagoTotal){
        this.listFormaPago = [];
        let tablaMapFP = new Map();
        h[1].forEach((f: any) => {
          if (tablaMapFP.has(f.idpreventa)){
            let d = tablaMapFP.get(f.idpreventa);
            d.pagos.push(f);
            d.totalPagado = d.totalPagado + f.importe;
            //tablaMapFP.set(f.idpreventa, d);
          }else{
            let d = {
              pagos: [] as any[],
              totalVenta: 0,
              totalPagado: 0
            }
            d.pagos.push(f);
            d.totalVenta = f.total_productos_venta + f.flete,
            d.totalPagado = f.importe
            tablaMapFP.set(f.idpreventa, d);
          }
        });

        for (const entry of tablaMapFP.entries()) {

          if (entry[1].totalVenta <= entry[1].totalPagado){
            entry[1].pagos.forEach((xxx: any) => {
              this.listFormaPago.push(xxx);
            })            
          }
        }

      }else{
        this.listFormaPago = h[1];
      }

      this.listTipoPrecio = h[2];

      let col = h[0];

      this.total = 0.0;
      this.totalSujetoComision = 0.0;
      this.totalComision = 0.0;
      this.totalComisionAcumulada = 0.0;
      this.totalPagado = 0.0;
      
      let tablaMap = new Map();
      let tabla: any[] = [];

      col.forEach((c: any) => {

        //los registros son por producto, se repiten los datos generales de la venta por eso armamos un map

        let descuentoGeneralAplicado = (c.porcentajeDescuentoGeneral / 100) * ((c.precio_unitario - c.importe_descuento) * c.cantidad);
        let precioTotal = ((c.precio_unitario - c.importe_descuento) * c.cantidad) - descuentoGeneralAplicado;
        let precioTotalProductoSinDG = ((c.precio_unitario - c.importe_descuento) * c.cantidad);
        let precioTotalProductoConDG = ((c.precio_unitario - c.importe_descuento) * c.cantidad) - descuentoGeneralAplicado;
        let comisionProducto = (c.vendedorComisionEspecial / 100) * precioTotalProductoConDG;


        if (tablaMap.has(c.idpreventa)){

          //ya existe la venta agregamos el producto

          let preventa = tablaMap.get(c.idpreventa);

          preventa.datosGeneralesVenta.importe = preventa.datosGeneralesVenta.importe + ((c.precio_unitario - c.importe_descuento) * c.cantidad);
          preventa.datosGeneralesVenta.importeProductosFinalConDG = preventa.datosGeneralesVenta.importeProductosFinalConDG + precioTotalProductoConDG;
          preventa.datosGeneralesVenta.importeProductosFinalSinDG = preventa.datosGeneralesVenta.importeProductosFinalSinDG + precioTotalProductoSinDG;
          preventa.datosGeneralesVenta.importeFinalConDG = preventa.datosGeneralesVenta.importeFinalConDG + precioTotalProductoConDG;
          preventa.datosGeneralesVenta.importeFinalSinDG = preventa.datosGeneralesVenta.importeFinalSinDG + precioTotalProductoSinDG;

          preventa.datosGeneralesVenta.importeDescuento = (c.porcentajeDescuentoGeneral / 100) * preventa.datosGeneralesVenta.importeFinalSinDG;
          preventa.datosGeneralesVenta.pagoTotal = (preventa.datosGeneralesVenta.importeFinalConDG <= preventa.datosGeneralesVenta.totalPagado);
          preventa.datosGeneralesVenta.importeSujetoComision = preventa.datosGeneralesVenta.importeSujetoComision + precioTotalProductoConDG;
          preventa.datosGeneralesVenta.comision = preventa.datosGeneralesVenta.comision + comisionProducto;

          preventa.articulos.push({
            articulo: `${c.productoDescripcion} || ${c.medidaDescripcion} || ${c.colorDescripcion}`,
            rubroDescripcion: c.rubroDescripcion,
            cantidad: c.cantidad,
            precioTotal: precioTotal,
            precioUnitario: c.precio_unitario,
            descuentoUnitario: c.importe_descuento,
            descuentoGeneralAplicado: descuentoGeneralAplicado,
            comisionEspecial: c.vendedorComisionEspecial,
            montoComisionEspecial: comisionProducto,
            productoIdRubro: c.productoIdRubro,
            productoIdSubRubro: c.productoIdSubRubro,
          });
        }else{

          let data = {
            idpreventa: c.idpreventa,
            datosGeneralesVenta: {
              sucursalNombre: c.ventaSucursalNombre,
              fecha: c.fecha,
              _fecha: toFechaCorta(c.fecha),
              _pcomision: `${c.vendedorComision.toFixed(2)}%`,
              cliente: c.clienteNombre,
              vendedor: c.vendedorNombre,
              importe: ((c.precio_unitario - c.importe_descuento) * c.cantidad),
              //importeProductosFinal: precioTotalProductoSinDG, //inicializa
              importeProductosFinalConDG: precioTotalProductoConDG,
              importeProductosFinalSinDG: precioTotalProductoSinDG,
              porcentajeDescuentoGeneral: c.porcentajeDescuentoGeneral,
              importeDescuento: 0,
              importeSujetoComision: 0,
              importeFinalConDG: 0,
              importeFinalSinDG: 0,
              comision: 0,
              preventaEstado: c.preventaEstado,
              preventaEstadoNombre: this.estados[c.preventaEstado],
              totalPagado: c.totalPagado,
              totalRecargo: c.totalRecargo,
              flete: c.flete,
              pagoTotal: false
            },
            articulos: [] as any[],            
          }

          
          data.datosGeneralesVenta.importeSujetoComision = data.datosGeneralesVenta.importeSujetoComision + precioTotalProductoConDG;
          data.datosGeneralesVenta.comision = comisionProducto;

          data.articulos.push({
            articulo: `${c.productoDescripcion} || ${c.medidaDescripcion} || ${c.colorDescripcion}`,
            rubroDescripcion: c.rubroDescripcion,
            cantidad: c.cantidad,
            precioTotal: precioTotalProductoConDG,
            precioUnitario: c.precio_unitario,
            descuentoUnitario: c.importe_descuento,
            descuentoGeneral: 0,
            descuentoGeneralAplicado: descuentoGeneralAplicado,
            comisionEspecial: c.vendedorComisionEspecial,
            montoComisionEspecial: comisionProducto,
            productoIdRubro: c.productoIdRubro,
            productoIdSubRubro: c.productoIdSubRubro,
          });

          data.datosGeneralesVenta.importeFinalConDG = precioTotalProductoConDG + data.datosGeneralesVenta.flete;
          data.datosGeneralesVenta.importeFinalSinDG = precioTotalProductoSinDG + data.datosGeneralesVenta.flete;

          data.datosGeneralesVenta.importeDescuento = (c.porcentajeDescuentoGeneral / 100) * data.datosGeneralesVenta.importeFinalSinDG;

          data.datosGeneralesVenta.pagoTotal = (data.datosGeneralesVenta.importeFinalConDG <= data.datosGeneralesVenta.totalPagado);

          tablaMap.set(c.idpreventa, data);

        }

      });
      
      
      for (const entry of tablaMap.entries()) {

        if (this.detallePagoTotal){
          if (entry[1].datosGeneralesVenta.pagoTotal){
            tabla.push(entry[1]);
            this.total = this.total + entry[1].datosGeneralesVenta.importeFinalConDG;
            this.totalPagado = this.totalPagado + (entry[1].datosGeneralesVenta.totalPagado + entry[1].datosGeneralesVenta.totalRecargo);
            this.totalSujetoComision = this.totalSujetoComision + entry[1].datosGeneralesVenta.importeSujetoComision;
            this.totalComision = this.totalComision + entry[1].datosGeneralesVenta.comision
            this.totalComisionAcumulada = this.totalComisionAcumulada + entry[1].datosGeneralesVenta.comision;
          }
        }else{
          tabla.push(entry[1]);
          this.total = this.total + entry[1].datosGeneralesVenta.importeFinalConDG;
          this.totalPagado = this.totalPagado + (entry[1].datosGeneralesVenta.totalPagado + entry[1].datosGeneralesVenta.totalRecargo);
          this.totalSujetoComision = this.totalSujetoComision + entry[1].datosGeneralesVenta.importeSujetoComision;
          this.totalComision = this.totalComision + entry[1].datosGeneralesVenta.comision;
          this.totalComisionAcumulada = this.totalComisionAcumulada + entry[1].datosGeneralesVenta.comision;
        }
        
      }
      ////

      this.tablaOrig = tabla;

      this.tablaOrig.forEach(x => {
        x.articulos.forEach(art => {
          let nuevo = {...art};
          nuevo.sucursalNombre = x.datosGeneralesVenta.sucursalNombre;
          this.listProductoCompleto.push(nuevo);
        });
      });

      this.agruparParaReporteSucursal(true);

      this.dataSource.data = this.tablaOrig;

      this.lastParam = param;

      if (col.length == 0){
        this.snackBarService.errorMessage("No hay datos para los parametros ingresados");
      }

      this.cargando = false;

    });

    

  }


  agruparParaReporteSucursal(subAgruparPorRubro = false){
    //haremos una tabla donde tengamos la cantidad de producto presentacion por sucursal
    let mapSucursal = new Map();

    //let campoClave = 'ventaSucursalNombre';
    let campoClave = 'sucursalNombre';

    this.tablaOrig.forEach((c: any) => {
      //if (mapSucursal.has(c.idsucursal)){
      if (mapSucursal.has(c.datosGeneralesVenta[campoClave])){
        //si ya tenemos la sucursal agregamos el producto a la lista
        //let dato = mapSucursal.get(c.idsucursal);
        let dato = mapSucursal.get(c.datosGeneralesVenta[campoClave]);
        //la lista de productos esta dentro de dato.productos

        // dentro de c.articulos tenemos el producto y la cantidad a pasar a la lista
        c.articulos.forEach((p: any) => {
          dato.productos.push({
            producto: p.articulo,
            cantidad: p.cantidad,
            idRubro: p.productoIdRubro,
            idSubRubro: p.productoIdSubRubro
          });
        });

        //la lista puede tener productos repetidos, por lo que los productos repetidos los agrupamos y sumamos la cantidad
        // el campo clave es producto
        //creamos un map para agrupar los productos

        let mapProductos = new Map();
        dato.productos.forEach((p: any) => {
          if (mapProductos.has(p.producto)){
            let datoProducto = mapProductos.get(p.producto);
            datoProducto.cantidad = datoProducto.cantidad + p.cantidad;
            mapProductos.set(p.producto, datoProducto);
          }else{
            mapProductos.set(p.producto, p);
          }
        });
        //convertimos el map en un array
        let arrayProductos = Array.from(mapProductos.values());
        //reemplazamos la lista de productos por la lista de productos agrupados
        dato.productos = arrayProductos;
        //reemplazamos el dato de la sucursal
        
        //mapSucursal.set(c.idsucursal, dato);
        mapSucursal.set(c.datosGeneralesVenta[campoClave], dato);
        
      }else{
        //si no tenemos la sucursal en el map agregamos la sucursal y el producto a la lista
        //let dato = {sucursal: c.ventaSucursalNombre, productos: []};
        let dato = {sucursal: c.datosGeneralesVenta[campoClave], productos: []};
        // dentro de c.articulos tenemos el producto y la cantidad a pasar a la lista
        c.articulos.forEach((p: any) => {
          dato.productos.push({
            producto: p.articulo,
            cantidad: p.cantidad,
            idRubro: p.productoIdRubro,
            idSubRubro: p.productoIdSubRubro
          });
        });

        //lo agregamos al map
        
        mapSucursal.set(c.datosGeneralesVenta[campoClave], dato);
        
      }
    });

    //convertimos el map en un array
    let arraySucursal = Array.from(mapSucursal.values());
    //lo vemos por consola

    this.listProductosSucursal = arraySucursal;

    if (subAgruparPorRubro){
      arraySucursal.forEach(suc => {
        suc.rubros = []
        this.listRubro.forEach(rr => {
          let rubro = [];
          let productosRubro = suc.productos.filter(p => p.idRubro == rr.idrubroproducto);
          if (productosRubro.length > 0){
            let subRubros = []
            this.listSubRubro.forEach(sr => {
              let productosSubRubro = productosRubro.filter(p => p.idSubRubro == sr.idsubrubroproducto);
              if (productosSubRubro.length > 0){
                subRubros.push({
                  idSubRubro: sr.idsubrubroproducto,
                  subRubro: sr.descripcion,
                  productos: productosSubRubro
                })
              }
            });
            if (subRubros.length > 0){
              rubro.push({
                idRubro: rr.idrubroproducto,
                rubro: rr.descripcion,
                subRubros: subRubros
              })
            }
          };
          if (rubro.length > 0){
            suc.rubros.push(rubro);
          }
        });
        
      });
    }

    return arraySucursal;
  }

  clickSeleccionarCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listCliente.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: true,
      referenciaNuevo: "cliente"
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){

          let clienteSel = this.listCliente.find(x => x.idcliente == rr.seleccion.id);

          if (clienteSel == null){
            this.apiService.getClientesCompletoList().subscribe(x => {
              this.listCliente = [];
              this.listCliente = x;
              let cs = this.listCliente.find(x => x.idcliente == rr.seleccion.id);
              this.cliente = cs as ClientesCompletoModel;
            });
          }else{
            this.cliente = clienteSel as ClientesCompletoModel
          }

        }else{
          this.cliente = {
            idcliente: 0,
            nombre: "",
            domicilio: "",
            codigo_localidad: 0,
            nombre_localidad: "",
            codigo_postal: "",
            telefono: "",
            correo_electronico: "",
            observaciones: "",
          };
        }
        
      }
    });
  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }
        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }
      }
    });
  }

  clickExportar(){

    if (this.detalleArticulo){
      if (this.detalleComision){
        this.exportarDetalleConComision();
        return;
      }else{
        this.exportarDetalle();
        return;
      }
    }

    if (this.detalleComision){
      this.exportarSimpleConComision();
      return;
    }else{
      this.exportarSimple();
      return;
    }
    

  }

  exportarSimple(){

    let param = this.lastParam;

    const sizeTextoDatoTabla = 9;
    const sizeTextoTituloTabla = 10;
    const margenIzq = 5;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    let preventas: any = [
      [
        {text: 'id', fontSize: sizeTextoTituloTabla}, 
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, 
        //{text: 'Cliente', fontSize: sizeTextoTituloTabla}, 
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla},
        {text: 'Importe Venta', fontSize: sizeTextoTituloTabla},
        {text: 'Total Pagado (CR)', fontSize: sizeTextoTituloTabla},
        //{text: 'Importe Sujeto a Comisión', fontSize: sizeTextoTituloTabla}
      ]
    ];

    let data = this.dataSource.data;

    data.forEach(x => {
      let _fecha = toFechaCortaSoloFecha(x.datosGeneralesVenta.fecha);

      let c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let c1 = {text: _fecha, fontSize: sizeTextoDatoTabla};
      //let c2 = {text: x.datosGeneralesVenta.cliente, fontSize: sizeTextoDatoTabla};
      let c3 = {text: x.datosGeneralesVenta.vendedor, fontSize: sizeTextoDatoTabla};
      let c4 = {text: this.fcNum(x.datosGeneralesVenta.importeFinalConDG), alignment: 'right', fontSize: sizeTextoDatoTabla};
      let c5 = {text: this.fcNum((x.datosGeneralesVenta.totalPagado + x.datosGeneralesVenta.totalRecargo)), alignment: 'right', fontSize: sizeTextoDatoTabla};
      //let c6 = {text: this.fcNum(x.datosGeneralesVenta.importeSujetoComision), alignment: 'right', fontSize: sizeTextoDatoTabla};

      //let r = [c0, c1, c2, c3, c4, c5, c6];
      let r = [c0, c1, c3, c4, c5];
      
      preventas.push(r);
    });

    //let tableWidths = [35, '*', '*', '*', '*', '*', '*'];
    //let tableWidths = [35, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
    let tableWidths = [35, 'auto', 'auto', 'auto', 'auto'];

    preventas.push(
      [
        '', 
        '', 
        {text: 'TOTAL', bold: true}, 
        {text: this.fcNum(this.total), alignment: 'right', bold: true},
        {text: this.fcNum(this.totalPagado), alignment: 'right', bold: true},
      ]);
    
    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, margenIzq] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, margenIzq] },

      preventas.length > 1 ? 
      {
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: tableWidths,
          body: preventas
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Ventas`, fontSize: 11, margin:[10, 0, 0, margenIzq] },
     

    ]

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 5, 0, 0 ],
          table: {
            headerRows: 1,
            
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }

    this.pdfService.generarPDF(content);

  }

  exportarSimpleConComision(){

    let param = this.lastParam;

    const sizeTextoDatoTabla = 9;
    const sizeTextoTituloTabla = 10;
    const margenIzq = 5;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    let preventas: any = [
      [
        {text: 'id', fontSize: sizeTextoTituloTabla}, 
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, 
        //{text: 'Cliente', fontSize: sizeTextoTituloTabla}, 
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla},
        {text: 'Importe Venta', fontSize: sizeTextoTituloTabla},
        {text: 'Total Pagado (CR)', fontSize: sizeTextoTituloTabla},
        //{text: 'Importe Sujeto a Comisión', fontSize: sizeTextoTituloTabla},
        {text: 'Comisión', fontSize: sizeTextoTituloTabla}
      ]
    ];

    let data = this.dataSource.data;

    //let comisionTotal = 0;

    data.forEach(x => {
      let _fecha = toFechaCortaSoloFecha(x.datosGeneralesVenta.fecha);

      let c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let c1 = {text: _fecha, fontSize: sizeTextoDatoTabla};
      //let c2 = {text: x.datosGeneralesVenta.cliente, fontSize: sizeTextoDatoTabla};
      let c3 = {text: x.datosGeneralesVenta.vendedor, fontSize: sizeTextoDatoTabla};
      let c4 = {text: this.fcNum(x.datosGeneralesVenta.importeFinalConDG), alignment: 'right', fontSize: sizeTextoDatoTabla};
      let c5 = {text: this.fcNum((x.datosGeneralesVenta.totalPagado + x.datosGeneralesVenta.totalRecargo)), alignment: 'right', fontSize: sizeTextoDatoTabla};
      //let c6 = {text: this.fcNum(x.datosGeneralesVenta.importeSujetoComision), alignment: 'right', fontSize: sizeTextoDatoTabla};
      let c7 = {text: this.fcNum(x.datosGeneralesVenta.comision), alignment: 'right', fontSize: sizeTextoDatoTabla};

      //let r = [c0, c1, c2, c3, c4, c5, c6, c7];
      let r = [c0, c1, c3, c4, c5, c7];

      //comisionTotal += x.comision;
      
      preventas.push(r);
    });

    let tableWidths = [35, 'auto', 'auto', 'auto', 'auto', 'auto'];

    preventas.push(
      [
        '', 
        '', 
        //'', 
        {text: 'TOTAL', bold: true}, 
        {text: this.fcNum(this.total), alignment: 'right', bold: true}, 
        {text: this.fcNum(this.totalPagado), alignment: 'right', bold: true},
        //{text: this.fcNum(this.totalSujetoComision), alignment: 'right', bold: true}, 
        {text: this.fcNum(this.totalComisionAcumulada), alignment: 'right', bold: true}
      ]);
    
    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, margenIzq] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, margenIzq] },

      preventas.length > 1 ? 
      {
        table: {
          headerRows: 1,
          widths: tableWidths,
          body: preventas,
          dontBreakRows: true,

        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Ventas`, fontSize: 11, margin:[10, 0, 0, margenIzq] },
     

    ]

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 5, 0, 0 ],
          table: {
            headerRows: 1,
            
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }

    this.pdfService.generarPDF(content);

  }

  exportarDetalle(){
    let param = this.lastParam;

    const sizeTextoDatoTabla = 10;
    const sizeTextoTituloTabla = 11;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${toFechaCortaSoloFecha(param.fecha.desde)} Hasta: ${toFechaCortaSoloFecha(param.fecha.hasta)}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = this.sucursalActual.sucursalNombre;

    let data = this.dataSource.data;

    let tablaComun: any[] = []

    data.forEach((x: any) => {

      tablaComun.push([
        {text: 'id', fontSize: sizeTextoTituloTabla}, //1
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, //2
        {text: 'Cliente', fontSize: sizeTextoTituloTabla, colSpan: 2}, //3
        '', //4
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla, colSpan: 2}, //5
        '', //6
        {text: 'Importe', fontSize: sizeTextoTituloTabla, colSpan: 2}, //7
        '' //8
      ]);

      let t0c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let t0c1 = {text: toFechaCorta(x.datosGeneralesVenta.fecha), fontSize: sizeTextoDatoTabla};
      let t0c2 = {text: x.datosGeneralesVenta.cliente, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c3 = {text: x.datosGeneralesVenta.vendedor, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c4 = {text: (x.datosGeneralesVenta.importeDescuento != "0" ? `(Desc.: $${this.fcNum(x.datosGeneralesVenta.importeDescuento)})` : ``) +" $" + this.fcNum(x.datosGeneralesVenta.importeFinalConDG), fontSize: sizeTextoDatoTabla, alignment: 'right', colSpan: 2};
     
      tablaComun.push([t0c0, t0c1, t0c2, '', t0c3, '', t0c4, '']);

      tablaComun.push([
        '', 
        {text: 'Articulo', fontSize: sizeTextoTituloTabla, colSpan: 4},
        '',
        '',
        '',
        {text: 'Cantidad', fontSize: sizeTextoTituloTabla}, 
        {text: 'Precio Total', fontSize: sizeTextoTituloTabla},
        ''
      ]);
      x.articulos.forEach((y: any) => {

        let t1c2 = {text: y.articulo, fontSize: sizeTextoDatoTabla, colSpan: 4};
        let t1c3 = {text: y.cantidad, fontSize: sizeTextoDatoTabla};
        let t1c4 = {text: this.fcNum(y.precioTotal), fontSize: sizeTextoDatoTabla};
        

        tablaComun.push(['', t1c2, '', '', '', t1c3, t1c4, '']);
      });

    });

    tablaComun.push([
      '', 
      '', 
      '', 
      '',
      '',
      '',
      {text: 'TOTAL', bold: true}, 
      {text: this.fcNum(this.total), alignment: 'right', bold: true}
    ]);

    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, 10] }
    ]

    content.push(
      {
        table: {
          headerRows: 1,
          widths: [25, 55, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: tablaComun,
          dontBreakRows: true,
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      });

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 5, 0, 0 ],
          table: {
            headerRows: 1,
            dontBreakRows: true,
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }
    

    this.pdfService.generarPDF(content);

  }

  exportarDetalleConComision(){
    let param = this.lastParam;

    const sizeTextoDatoTabla = 10;
    const sizeTextoTituloTabla = 11;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${toFechaCortaSoloFecha(param.fecha.desde)} Hasta: ${toFechaCortaSoloFecha(param.fecha.hasta)}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = this.sucursalActual.sucursalNombre;

    let data = this.dataSource.data;

    let tablaComun: any[] = []

    data.forEach((x: any) => {

      tablaComun.push([
        {text: 'id', fontSize: sizeTextoTituloTabla}, //1
        {text: 'Fecha', fontSize: sizeTextoTituloTabla}, //2
        {text: 'Cliente', fontSize: sizeTextoTituloTabla, colSpan: 2}, //3
        '', //4
        {text: 'Vendedor', fontSize: sizeTextoTituloTabla, colSpan: 2}, //5
        '', //6
        {text: 'Comisión', fontSize: sizeTextoTituloTabla}, //5
        {text: 'Importe', fontSize: sizeTextoTituloTabla, colSpan: 2}, //7
        '' //8
      ]);

      let t0c0 = {text: x.idpreventa, fontSize: sizeTextoDatoTabla};
      let t0c1 = {text: toFechaCortaSoloFecha(x.fecha), fontSize: sizeTextoDatoTabla};
      let t0c2 = {text: x.cliente, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c3 = {text: x.vendedor, fontSize: sizeTextoDatoTabla, colSpan: 2};
      let t0c4 = {text: this.fcNum(x.comisionAcumulada), fontSize: sizeTextoDatoTabla};
      let t0c5 = {text: this.fcNum(x.importe), fontSize: sizeTextoDatoTabla, alignment: 'right', colSpan: 2};
     
      tablaComun.push([t0c0, t0c1, t0c2, '', t0c3, '', t0c4, t0c5, '']);

      tablaComun.push([
        '', 
        {text: 'Articulo', fontSize: sizeTextoTituloTabla, colSpan: 4},
        '',
        '',
        '',
        '',
        {text: 'Cantidad', fontSize: sizeTextoTituloTabla}, 
        {text: 'Precio Total', fontSize: sizeTextoTituloTabla},
        ''
      ]);
      x.articulos.forEach((y: any) => {

        let t1c2 = {text: y.articulo, fontSize: sizeTextoDatoTabla, colSpan: 4};
        let t1c3 = {text: y.cantidad, fontSize: sizeTextoDatoTabla};
        let t1c4 = {text: this.fcNum(y.precioTotal), fontSize: sizeTextoDatoTabla};
        

        tablaComun.push(['', t1c2, '', '', '', '', t1c3, t1c4, '']);
      });

    });

    tablaComun.push([
      '', 
      '', 
      '', 
      '',
      '',
      '',
      '',
      {text: 'TOTAL', bold: true}, 
      {text: this.fcNum(this.total), alignment: 'right', bold: true}
    ]);

    tablaComun.push([
      '', 
      '', 
      '', 
      '',
      '',
      '',
      '',
      {text: 'TOTAL COMISION', bold: true}, 
      {text: this.fcNum(this.totalComisionAcumulada), alignment: 'right', bold: true}
    ]);

    let content: any[] = [
      { text: `Reporte de Ventas - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Ventas: `, fontSize: 11, margin:[0, 0, 0, 10] }
    ]

    content.push(
      {
        table: {
          headerRows: 1,
          widths: [25, 55, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: tablaComun,
          dontBreakRows: true,

        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      });

    if (this.detalleFormaPago){

      content.push(
        {
          margin: [ 0, 5, 0, 0 ],
          table: {
            headerRows: 1,
            
            widths: ['*', '*', '*'],
            body: this.generarTablaDetalleFormaPago(sizeTextoTituloTabla, sizeTextoDatoTabla)
          },
          layout: {
            hLineWidth: function (i: any, node: any) {
              return 0.0;
            },
            vLineWidth: function (i: any, node: any) {
              return 0.0;
            }
          }
        });

    }
    

    this.pdfService.generarPDF(content);

  }

  exportarReporteProductosPorSucursal(){
    let param = this.lastParam;

    const sizeTextoDatoTabla = 9;
    const sizeTextoTituloTabla = 10;
    const margenIzq = 5;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    let sucursales = [];

    let data = this.listProductosSucursal;

    data.forEach(x => {

      let tProductos: any = [
        [
          {text: 'Producto', fontSize: sizeTextoTituloTabla}, 
          {text: 'Cantidad', fontSize: sizeTextoTituloTabla}, 
        ]
      ];

      x.productos.forEach(y => {

        let c0 = {text: y.producto, fontSize: sizeTextoDatoTabla};
        let c1 = {text: y.cantidad, alignment: 'right', fontSize: sizeTextoDatoTabla};

        let r = [c0, c1];
        
        tProductos.push(r);

      });

      sucursales.push(
        {
          //sucursal: x.ventaSucursal,
          sucursal: x.sucursal,
          productos: tProductos
        }
      );

    });

    let tableWidths = ['*', 40];

    let content: any[] = [
      { text: `Reporte de Productos - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, margenIzq] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },

      /*
      productos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          widths: tableWidths,
          body: preventas
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return 0.0;
          }
        }
      } : { text: `Sin Ventas`, fontSize: 11, margin:[10, 0, 0, margenIzq] },
     */

    ]

    sucursales.forEach(x => {
      content.push(
        {
          text: `Sucursal: ${x.sucursal}`,
          fontSize: 12,
          margin:[0, 25, 0, margenIzq]
        }
      );
      if (x.productos.length > 0){
        content.push(
          {
            table: {
              headerRows: 1,
              widths: tableWidths,
              body: x.productos
            },
            layout: {
              hLineWidth: function (i: any, node: any) {
                return 0.0;
              },
              vLineWidth: function (i: any, node: any) {
                return 0.0;
              }
            }
          }
        );
      }else{
        content.push(
          {
            text: `Sin Productos`,
            fontSize: 11,
            margin:[10, 0, 0, margenIzq]
          }
        );
      }
    });

    this.pdfService.generarPDF(content);
  }

  exportarReporteProductosPorSucursal2(agruparPorRubro = true){
    let param = this.lastParam;

    const sizeTextoDatoTabla = 9;
    const sizeTextoTituloTabla = 10;
    const margenIzq = 5;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    let sucursales = [];

    let data = this.listProductosSucursal;

    //recorremos los rubros y subrubros para armar un arbol, dentro agregamos los productos que le corresponden

    let dataAgrupadaRubroSubRubro = [];

    

    data.forEach(x => {

      let tProductos: any = [
        [
          {text: 'Producto', fontSize: sizeTextoTituloTabla}, 
          {text: 'Cantidad', fontSize: sizeTextoTituloTabla}, 
        ]
      ];

      x.productos.forEach(y => {

        let c0 = {text: y.producto, fontSize: sizeTextoDatoTabla};
        let c1 = {text: y.cantidad, alignment: 'right', fontSize: sizeTextoDatoTabla};

        let r = [c0, c1];
        
        tProductos.push(r);

      });

      sucursales.push(
        {
          //sucursal: x.ventaSucursal,
          sucursal: x.sucursal,
          productos: tProductos
        }
      );

    });

    let tableWidths = ['*', 40];

    let content: any[] = [
      { text: `Reporte de Productos - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, margenIzq] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenIzq] },

    ]

    if (!agruparPorRubro){
      sucursales.forEach(x => {
        content.push(
          {
            text: `Sucursal: ${x.sucursal}`,
            fontSize: 12,
            margin:[0, 25, 0, margenIzq]
          }
        );
        if (x.productos.length > 0){
          content.push(
            {
              table: {
                headerRows: 1,
                widths: tableWidths,
                body: x.productos
              },
              layout: {
                hLineWidth: function (i: any, node: any) {
                  return 0.0;
                },
                vLineWidth: function (i: any, node: any) {
                  return 0.0;
                }
              }
            }
          );
        }else{
          content.push(
            {
              text: `Sin Productos`,
              fontSize: 11,
              margin:[10, 0, 0, margenIzq]
            }
          );
        }
      });
    }

    if (agruparPorRubro){
      dataAgrupadaRubroSubRubro.forEach(x => {
        content.push(
          {
            text: `Sucursal: ${x.sucursal}`,
            fontSize: 12,
            margin:[0, 25, 0, margenIzq]
          }
        );
      });
    }

    //this.pdfService.generarPDF(content);

    //this.internoAgruparProductosPorSucursal(this.listProductoCompleto);
    //this.internoAgruparProductosPorRubro(this.listProductoCompleto);

    //this.reporteProductos("sucursales", "rubros");
    this.reporteProductos("rubros", "sucursales");

  }

  generarTablaDetalleFormaPago(sizeTextoTituloTabla: number, sizeTextoDatoTabla: number){
    let tiposId: number[] = [];
    this.listTipoPrecio.forEach((x: any) => {
      tiposId.push(x.idtipo);
    });

    let tablaFormaPago: any[] = [
      [
        {text: 'Forma de Pago', fontSize: sizeTextoTituloTabla}, 
        {text: 'Cantidad de Operaciones', fontSize: sizeTextoTituloTabla}, 
        {text: 'Importe', fontSize: sizeTextoTituloTabla, alignment: 'right'}
      ]];
    
    let totalFP = 0;

    tiposId.forEach(tid => {
      let fps = this.listFormaPago.filter((fp: any) => fp.idtipo == tid);
      
      if (fps.length > 0){
        let k = 0;
        let sum = 0;
        let nombre = "";
        fps.forEach((fp: any) => {
          nombre = fp.tipo_descripcion;
          sum = sum + fp.importe;
          k++;
        });

        let row = [
          {text: nombre, fontSize: sizeTextoDatoTabla}, 
          {text: k, fontSize: sizeTextoDatoTabla}, 
          {text: this.fcNum(sum), fontSize: sizeTextoDatoTabla, alignment: 'right'}
        ];

        totalFP = totalFP + sum;

        tablaFormaPago.push(row);
      }
    });
    
    return tablaFormaPago;
  }

  clickSeleccionarSucursal(){

    let idSeleccionPrevia  = 0;
    idSeleccionPrevia = this.sucursalId;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listSucursales.forEach(x => {
      let item = {
        id: x.idsucursal,
        nombre: x.descripcion
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Sucursal",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nueva Sucursal"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: idSeleccionPrevia,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if (rr.seleccion != null){
          if (this.sucursalId != rr.seleccion.id){
            this.sucursalId = 0;
            this.sucursalNombre = "";
          }
          this.sucursalId = rr.seleccion.id;
          this.sucursalNombre = rr.seleccion.nombre;
          
        }else{
          this.sucursalId = 0;
          this.sucursalNombre = "";
        }
      }
    });
  }

  clickClear(){
    this.sucursalId = 0;
    this.sucursalNombre = "";
  }

  clickSeleccionReporte(){
    

    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = null;

    const dialogRef = this.dialog.open(DialogSeleccionTipoReporteComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      if (r != null){
        if (r.cierre){


          let seleccion = r.seleccion;
          
          if (seleccion.tipoReporte == 'producto'){

            switch (seleccion.tipoReporteProducto) {
              case "simple":
                this.reporteProductos();
                break;
              case "sucursal":
                switch(seleccion.subAgrupar){
                  case "no_subagrupar":
                    //this.reporteProductos("sucursales");
                    this.reporteTablaProducto("sucursales", null);
                    break;
                  case "subagrupar_rubro":
                    //this.reporteProductos("sucursales", "rubros");
                    this.reporteTablaProducto("sucursales", "rubros");
                    break;
                  case "subagrupar_rubro_subrubro":
                    //this.reporteProductos("sucursales", "subrubros");
                    this.reporteTablaProducto("sucursales", "subrubros");
                    break;
                }
                break;
              case "rubro":
                switch(seleccion.subAgrupar){
                  case "no_subagrupar":
                    //this.reporteProductos("rubros");
                    this.reporteTablaProducto("rubros", null);
                    break;
                  case "subagrupar_sucursal":
                    //this.reporteProductos("rubros", "sucursales");
                    this.reporteTablaProducto("rubros", "sucursales");
                    break;
                }
                break;
              case "rubrosubrubro":
                switch(seleccion.subAgrupar){
                  case "no_subagrupar":
                    //this.reporteProductos("subrubros");
                    this.reporteTablaProducto("subrubros", null);
                    break;
                  case "subagrupar_sucursal":
                    //this.reporteProductos("subrubros", "sucursales");
                    this.reporteTablaProducto("subrubros", "sucursales");
                    break;
                }
                break;
            
              default:
                break;
            }

            

          }

          if (seleccion.tipoReporte == 'vendedor'){
            this.detalleArticulo = seleccion.detalleArticulo,
            this.detalleComision = seleccion.detalleComision,
            this.detalleFormaPago = seleccion.detalleFormaPago

            this.clickExportar();



          }

        }
      }
    });
  
    
  }

  internoAgruparProductosPorRubroSubRubro(listaArt){

    //recorremos la lista de productos dados y agregamos los productos en el rubro que le corresponde
    let map = new Map<string, any[]>();

    listaArt.forEach((p: any) => {
      let k = `${p.productoIdRubro}_${p.productoIdSubRubro}`;
      if (map.has(k)){
        let item = map.get(k);
        item.push(p);
      }else{
        map.set(k, [p]);
      }
    });

    let arrRubroSubRubro = []

    this.listRubro.forEach(x => {
      let subRubros = [];
      this.listSubRubro.forEach(y => {
        if (y.idrubroproducto == x.idrubroproducto){
          let k = `${x.idrubroproducto}_${y.idsubrubroproducto}`;
          let productos = map.get(k);
          if (productos != null){
            subRubros.push({
              descripcionSubRubro: y.descripcion,
              productos: productos ? productos : []
            })
          }
        }
      });
      if (subRubros.length > 0){
        arrRubroSubRubro.push({
          descripcionRubro: x.descripcion,
          subRubros: subRubros
        });
      }
    });

    return arrRubroSubRubro;

  }

  internoAgruparProductosPorRubro(listaArt){

    //recorremos la lista de productos dados y agregamos los productos en el rubro que le corresponde
    let mapArticulos = new Map<number, any[]>();

    listaArt.forEach((p: any) => {
      let k = p.productoIdRubro;
      if (mapArticulos.has(k)){
        let item = mapArticulos.get(k);
        item.push(p);
      }else{
        mapArticulos.set(k, [p]);
      }
    });

    let arrRubro = []

    this.listRubro.forEach(x => {
      let productos = [];

      let k = x.idrubroproducto;
      let p = mapArticulos.get(k);
      if (p != null){
        productos.push(...p);
      }      

      if (productos.length > 0){
        arrRubro.push({
          descripcionRubro: x.descripcion,
          productos: productos
        });
      }
    });

    return arrRubro;

  }

  internoAgruparProductosPorSucursal(listaArt){

    let mapSucursal = new Map();

    //recorremos la lista de productos y los agregamos en la sucursal que le corresponde

    listaArt.forEach(p => {
      if (mapSucursal.has(p.sucursalNombre)){
        let item = mapSucursal.get(p.sucursalNombre);
        item.push(p);
      }else{
        mapSucursal.set(p.sucursalNombre, [p]);
      }
    });

    let arraySucursal = Array.from(mapSucursal.values());

    return arraySucursal;

  }

  reporteProductos(primerCriterio: string = null, segundoCriterio: string = null){

    let configT: configTablaProducto = {
      margenIzq: null,
      sizeTextoDatoTabla: 9,
      sizeTextoTituloTabla: 10
    }

    let fontSizeAgrupado = 11;
    
    let param = this.lastParam;

    //const margenIzq = 5;
    const margenBottom = 5;

    if (param == null){
      return;
    }

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    let tableWidths = ['*', 40];

    let content: any[] = [
      { text: `Reporte de Productos - Sucursal: ${sucursal}`, fontSize: 14, margin:[0, 0, 0, margenBottom] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },

    ]

    switch (primerCriterio) {
      case "sucursales":

        let d0 = this.internoAgruparProductosPorSucursal(this.listProductoCompleto);
        let totalSucursal = 0;

        let mostrarTotalFinal = true;

        d0.forEach(dd0 => {
          let acumuladorSucursal = 0;
          content.push(
            {
              text: `Sucursal: ${dd0[0].sucursalNombre}`,
              fontSize: fontSizeAgrupado,
              margin:[0, 5, 0, margenBottom]
            }
          );

          if (segundoCriterio == 'subrubros'){
            let r0 = this.internoAgruparProductosPorRubroSubRubro(dd0);

            if (r0.length > 0){
              
              r0.forEach(rr => {
                let acumuladorRubro = 0;
                content.push({
                  text: `Rubro: ${rr.descripcionRubro}`,
                  fontSize: fontSizeAgrupado,
                  margin:[25, 5, 0, margenBottom]
                });
                
                rr.subRubros.forEach(ss => {
                  content.push({
                    text: `SubRubro: ${ss.descripcionSubRubro}`,
                    fontSize: fontSizeAgrupado,
                    margin:[50, 5, 0, margenBottom]
                  });

                  configT.margenIzq = 50;
                  let tcProducto = this.obtenerTablaProducto(ss.productos, configT);
                  acumuladorRubro = acumuladorRubro + tcProducto.total;
                  content.push(tcProducto.tabla);
                  content.push(this.linea(45));

                });

                acumuladorSucursal = acumuladorSucursal + acumuladorRubro;

                configT.margenIzq = 25;
                let ts = this.tablaTotalSeccion(configT, `TOTAL Rubro: ${rr.descripcionRubro}`, acumuladorRubro);
                content.push(ts);
                content.push(this.linea(20, 10));

              });

            }

          }

          if (segundoCriterio == 'rubros'){
            let r0 = this.internoAgruparProductosPorRubro(dd0);

            if (r0.length > 0){
              
              r0.forEach(rr => {
                
                content.push({
                  text: `Rubro: ${rr.descripcionRubro}`,
                  fontSize: fontSizeAgrupado,
                  margin:[25, 5, 0, margenBottom]
                });

                configT.margenIzq = 25;
                let tcProducto = this.obtenerTablaProducto(rr.productos, configT);
                acumuladorSucursal = acumuladorSucursal + tcProducto.total;
                content.push(tcProducto.tabla);
                content.push(this.linea(20));
              });

              //content.push(this.linea(0, 10));

            }

          }

          if (segundoCriterio == null){
            let mostrarTotalFinal = false;
            configT.margenIzq = null;
            let tcProducto = this.obtenerTablaProducto(dd0, configT);
            content.push(tcProducto.tabla);
          }

          //total seccion
          if (mostrarTotalFinal){
            configT.margenIzq = null;
            let ts = this.tablaTotalSeccion(configT, `TOTAL Sucursal: ${dd0[0].sucursalNombre}`, acumuladorSucursal);
            content.push(ts);
          }
          content.push(this.linea(0, 10));

        });

        
        break;
      case "subrubros":

        let d1 = this.internoAgruparProductosPorRubroSubRubro(this.listProductoCompleto);

        console.log("d1", d1);

        if (d1.length > 0){
            
          d1.forEach(rr => {
            content.push({
              text: `Rubro: ${rr.descripcionRubro}`,
              fontSize: fontSizeAgrupado,
              margin:[0, 5, 0, margenBottom]
            });

            let totalSeccion = 0;

            rr.subRubros.forEach(ss => {
              content.push({
                text: `SubRubro: ${ss.descripcionSubRubro}`,
                fontSize: fontSizeAgrupado,
                margin:[25, 5, 0, margenBottom]
              });

              let totalSubSeccion = 0;

              if (segundoCriterio == null){
                configT.margenIzq = 25;
                let tcProducto = this.obtenerTablaProducto(ss.productos, configT);
                content.push(tcProducto.tabla);
                totalSubSeccion = totalSubSeccion + tcProducto.total;
                totalSeccion = totalSeccion + totalSubSeccion;
                //content.push(this.linea(20));
              }

              if (segundoCriterio == 'sucursales'){

                let sucs = this.internoAgruparProductosPorSucursal(ss.productos);

                sucs.forEach(sss => {

                  content.push(
                    {
                      text: `Sucursal: ${sss[0].sucursalNombre}`,
                      fontSize: fontSizeAgrupado,
                      margin:[50, 5, 0, margenBottom]
                    }
                  );

                  configT.margenIzq = 50;
                  let tcProducto = this.obtenerTablaProducto(sss, configT);
                  content.push(tcProducto.tabla);

                  totalSubSeccion = totalSubSeccion + tcProducto.total;
                  
                  content.push(this.linea(45));

                });

                totalSeccion = totalSeccion + totalSubSeccion;

              }

              //total subseccion
              configT.margenIzq = 25;
              let ts = this.tablaTotalSeccion(configT, `TOTAL SubRubro: ${ss.descripcionSubRubro}`, totalSubSeccion);
              content.push(ts);

              content.push(this.linea(20, 10));

            });

            //total seccion
            configT.margenIzq = null;
            let ts = this.tablaTotalSeccion(configT, `TOTAL Rubro: ${rr.descripcionRubro}`, totalSeccion);
            content.push(ts);

            content.push(this.linea(0, 10));

          });

        }


        break;
      case "rubros":

        let d2 = this.internoAgruparProductosPorRubro(this.listProductoCompleto);

        console.log("d2", d2);

        if (d2.length > 0){
            
          d2.forEach(rr => {
            content.push({
              text: `Rubro: ${rr.descripcionRubro}`,
              fontSize: fontSizeAgrupado,
              margin:[0, 5, 0, margenBottom]
            });

            let totalSeccion = 0;

            if (segundoCriterio == null){
              let tcProducto = this.obtenerTablaProducto(rr.productos, configT);
              totalSeccion = totalSeccion + tcProducto.total;
              content.push(tcProducto.tabla);
            }

            if (segundoCriterio == 'sucursales'){

              let sucs = this.internoAgruparProductosPorSucursal(rr.productos);

              sucs.forEach(sss => {

                content.push(
                  {
                    text: `Sucursal: ${sss[0].sucursalNombre}`,
                    fontSize: fontSizeAgrupado,
                    margin:[25, 5, 0, margenBottom]
                  }
                );

                configT.margenIzq = 25;
                let tcProducto = this.obtenerTablaProducto(sss, configT);

                totalSeccion = totalSeccion + tcProducto.total;

                content.push(tcProducto.tabla);
                content.push(this.linea(20));
              });

            }

            //total seccion
            configT.margenIzq = null;
            let ts = this.tablaTotalSeccion(configT, `TOTAL Rubro: ${rr.descripcionRubro}`, totalSeccion);
            content.push(ts);
            //linea
            content.push(this.linea(0, 10));

          });

        }

        break;
      case null:
        let tcProducto = this.obtenerTablaProducto(this.listProductoCompleto, configT);
        content.push(tcProducto.tabla);
        break;
      default:
        break;
    }

    this.pdfService.generarPDF(content);

  }

  //obtenerTablaProducto(listaP, sizeTextoDatoTabla, sizeTextoTituloTabla, margenIzq = 0){
  obtenerTablaProducto(listaP, config: configTablaProducto, mostrarTotal = true){

    let margenIzq = config.margenIzq ? config.margenIzq : 0;
    let sizeTextoDatoTabla = config.sizeTextoDatoTabla;
    let sizeTextoTituloTabla = config.sizeTextoTituloTabla;

    let left = margenIzq;
    let top = 0;
    let right = 0;
    let bottom = 0;

    let tableWidths = ['*', 'auto'];

    let tProductos: any = [
      [
        {text: 'Producto', fontSize: sizeTextoTituloTabla, bold: true}, 
        {text: 'Cant.', fontSize: sizeTextoTituloTabla, bold: true}, 
      ]
    ];

    let total = 0;
    listaP.forEach(y => {
      let c0 = {text: y.articulo, fontSize: sizeTextoDatoTabla};
      let c1 = {text: `${y.cantidad}`, alignment: 'right', fontSize: sizeTextoDatoTabla};
      let r = [c0, c1];
      tProductos.push(r);
      total = total + y.cantidad;
    });

    if (mostrarTotal){
      let c0 = {text: "TOTAL", fontSize: sizeTextoDatoTabla, bold: true};
      let c1 = {text: `${total}`, alignment: 'right', fontSize: sizeTextoDatoTabla, bold: true};
      let r = [c0, c1];
      tProductos.push(r);
    }

    return {
      total: total,
      tabla: {
        table: {
          dontBreakRows: true,
          headerRows: 1,
          widths: tableWidths,
          body: tProductos
        },
        layout: {
          hLineWidth: function (i: any, node: any) {
            return i == 0 || i == 1|| i == (tProductos.length - 1) || i == tProductos.length ? 0.3 : 0.0;
          },
          vLineWidth: function (i: any, node: any) {
            return i == 0 || i == 2? 0.3 : 0.0;
          }
        },
        margin: [left, top, right, bottom]
      }
    }

  }

  tablaTotalSeccion(config: configTablaProducto, tituloSeccion, total){

    let margenIzq = config.margenIzq ? config.margenIzq : 0;
    let sizeTextoDatoTabla = config.sizeTextoDatoTabla;
    let sizeTextoTituloTabla = config.sizeTextoTituloTabla;

    let left = margenIzq;
    let top = 0;
    let right = 0;
    let bottom = 0;

    let tableWidths = ['*', 'auto'];
    let tbody = [[
      {text: tituloSeccion, fontSize: sizeTextoDatoTabla, bold: true},
      {text: `${total}`, alignment: 'right', fontSize: sizeTextoDatoTabla, bold: true}
    ]];
    
    return {
      table: {
        dontBreakRows: true,
        headerRows: 1,
        widths: tableWidths,
        body: tbody
      },
      layout: {
        hLineWidth: function (i: any, node: any) {
          //return 0.3;
          return 0.0;
        },
        vLineWidth: function (i: any, node: any) {
          //return i == 0 || i == 2? 0.3 : 0.0;
          return 0.0;
        }
      },
      margin: [left, top, right, bottom]
    }

  }

  linea(izq = 0, topx = 5){
    return {
      canvas: [ { type: 'line', x1: izq, y1: 0, x2: 515, y2: 0, lineWidth: 0.5, dash: {length: 2, space: 5} } ],
      margin: [0, topx, 0, 0]
    };
  }

  clickSeleccionReporteTest2(){

    let fillColor = 'grey';

    let sizeTextoTituloTabla = 10;

    let param = this.lastParam;

    let margenBottom = 0;

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    //let tableWidths = ['*', 40];

    let content: any[] = [
      { text: `Reporte de Productos - Sucursal: ${sucursal}`, fontSize: 14, margin:[0, 0, 0, margenBottom] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
    ]


    let tableWidths = [10, 10, 10, '*', 'auto'];

    console.log("columnas", tableWidths);

    let linea0 = [
      {text: 'Sucursal', colSpan: 5},
      {text: ''},
      {text: ''},
      {text: ''},
      {text: ''},
    ]

    let linea1 = [
      {text: '', fillColor: fillColor},
      {text: 'Rubro', colSpan: 4},
      {text: ''},
      {text: ''},
      {text: ''},
    ]

    let linea2 = [
      {text: '', fillColor: fillColor},
      {text: '', fillColor: fillColor},
      {text: 'SubRubro', colSpan: 3},
      {text: ''},
      {text: ''},
    ]

    let linea3 = [
      {text: '', fillColor: fillColor},
      {text: '', fillColor: fillColor},
      {text: '', fillColor: fillColor},
      {text: 'Producto'},
      {text: 'Cantidad'},
    ]

    let linea3Total = [
      {text: '', fillColor: fillColor},
      {text: '', fillColor: fillColor},
      {text: '', fillColor: fillColor},
      {text: 'Total'},
      {text: 'X'},
    ];


    let tProductos: any = [
      linea0,
      linea1,
      linea2,
      linea3,
      linea3Total,

    ];

    let tabla = {
      table: {
        dontBreakRows: true,
        //headerRows: 1,
        widths: tableWidths,
        body: tProductos
      },
      layout: {
        hLineWidth: function (i: any, node: any) {
          //return i == 0 || i == 1|| i == (tProductos.length - 1) || i == tProductos.length ? 0.3 : 0.0;
          return 0.3;
          //return 0.0;
        },
        vLineWidth: function (i: any, node: any) {
          //return i == 0 || i == 2? 0.3 : 0.0;
          return 0.3;
          //return 0.0;
        }
      },
      //margin: [left, top, right, bottom]
    }

    content.push(tabla);

    this.pdfService.generarPDF(content);


  }

  //segundoCriterio = 'subrubros'
  reporteTablaProducto(primerCriterio, segundoCriterio){

    let fillColor = 'grey';
    let bordersColor = ['grey', 'grey', 'grey', 'grey'];

    let sizeTextoTabla = 10;
    

    let param = this.lastParam;

    let margenBottom = 0;

    let periodo = `Sin especificar`;
    if (param.fecha.desde != null && param.fecha.hasta != null){
      periodo = `Desde: ${param.fecha.desde} Hasta: ${param.fecha.hasta}`;
    }

    let vendedor = param.vendedorId != 0 ? this.listVendedores.find(x => x.idvendedor == param.vendedorId)?.nombre : 'Todos';
    let cliente = param.clienteId != 0 ? this.listCliente.find(x => x.idcliente == param.clienteId)?.nombre : 'Todos'

    let sucursal = param.sucursalNombre != "" ? param.sucursalNombre : "Sin Especificar";

    //let tableWidths = ['*', 40];

    let content: any[] = [
      { text: `Reporte de Productos - Sucursal: ${sucursal}`, fontSize: 14, margin:[0, 0, 0, margenBottom] },
      { text: `Sucursal: ${sucursal}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Periodo: ${periodo}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, margenBottom] },
    ]

    let tableWidths = [];

    let tableWidths_5 = [10, 10, 10, '*', 'auto'];
    let tableWidths_4 = [10, 10, '*', 'auto'];
    let tableWidths_3 = [10, '*', 'auto'];

    /// 3

    let funLinea0_3 = (titulo, align = 'left', negrita = false) => {
      return [
        {text: `${titulo}`, alignment: align, colSpan: 3, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
      ]
    }

    let funLinea1_3 = (producto, cantidad, negrita = false) => {
      return [
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: `${producto}`, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: `${cantidad}`, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
      ]
    }

    /// 4

    let funLinea0_4 = (titulo, align = 'left', negrita = false) => {
      return [
        {text: `${titulo}`, alignment: align, colSpan: 4, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
      ]
    }

    let funLinea1_4 = (titulo, align = 'left', negrita = false) => {
      return [
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: `${titulo}`, alignment: align, colSpan: 3, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
      ]
    }

    let funLinea2_4 = (producto, cantidad, negrita = false) => {
      return [
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: `${producto}`, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: `${cantidad}`, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
      ]
    }

    ///  5

    let funLinea0_5 = (titulo, align = 'left', negrita = false) => {
      return [
        {text: `${titulo}`, alignment: align, colSpan: 5, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
      ]
    }

    let funLinea1_5 = (titulo, align = 'left', negrita = false) => {
      return [
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: `${titulo}`, alignment: align, colSpan: 4, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
      ]
    }

    let funLinea2_5 = (titulo, align = 'left', negrita = false) =>  {
      return [
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: `${titulo}`, alignment: align, colSpan: 3, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', borderColor: bordersColor, fontSize: sizeTextoTabla},
      ]
    }

    let funLinea3_5 = (producto, cantidad, negrita = false) => {
      return [
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: '', fillColor: fillColor, borderColor: bordersColor, fontSize: sizeTextoTabla},
        {text: `${producto}`, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
        {text: `${cantidad}`, borderColor: bordersColor, fontSize: sizeTextoTabla, bold: negrita},
      ]
    }

    let tProductos: any = [
    ];

    // 5 columnas
    if (primerCriterio == 'sucursales' && segundoCriterio == 'subrubros'){
      tableWidths = tableWidths_5;
      let d0 = this.internoAgruparProductosPorSucursal(this.listProductoCompleto);
      d0.forEach(dd0 => {
        let acumuladorSucursal = 0;
        tProductos.push(funLinea0_5(`Sucursal: ${dd0[0].sucursalNombre}`));
        let r0 = this.internoAgruparProductosPorRubroSubRubro(dd0);

        r0.forEach(rr => {
          let acumuladorRubro = 0;

          tProductos.push(funLinea1_5(`Rubro: ${rr.descripcionRubro}`));

          rr.subRubros.forEach(ss => {

            tProductos.push(funLinea2_5(`SubRubro: ${ss.descripcionSubRubro}`));

            if (ss.productos.length > 0){
              tProductos.push(funLinea3_5("Producto", "Cant.", true));
              let acumuladorSubRubro = 0;
              ss.productos.forEach(y => {
                tProductos.push(funLinea3_5(y.articulo, `${y.cantidad}`));
                acumuladorSubRubro = acumuladorSubRubro + y.cantidad;
              });
             tProductos.push(funLinea2_5(`Total SubRubro: ${acumuladorSubRubro}`, 'right'));
             acumuladorRubro = acumuladorRubro + acumuladorSubRubro
            }

          });

          tProductos.push(funLinea1_5(`Total Rubro: ${acumuladorRubro}`, 'right'));

          acumuladorSucursal = acumuladorSucursal + acumuladorRubro;
        });

        tProductos.push(funLinea0_5(`Total Sucursal: ${acumuladorSucursal}`, 'right'));

      });
    }

    //4 columnas
    if (primerCriterio == 'sucursales' && segundoCriterio == 'rubros'){
      tableWidths = tableWidths_4;
      let d0 = this.internoAgruparProductosPorSucursal(this.listProductoCompleto);
      d0.forEach(dd0 => {
        let acumuladorSucursal = 0;
        tProductos.push(funLinea0_4(`Sucursal: ${dd0[0].sucursalNombre}`));
        let r0 = this.internoAgruparProductosPorRubro(dd0);

        r0.forEach(rr => {
          let acumuladorRubro = 0;

          tProductos.push(funLinea1_4(`Rubro: ${rr.descripcionRubro}`));

          if (rr.productos.length > 0){
            tProductos.push(funLinea2_4("Producto", "Cant.", true));
            let acumuladorSubRubro = 0;
            rr.productos.forEach(y => {
              tProductos.push(funLinea2_4(y.articulo, `${y.cantidad}`));
              acumuladorSubRubro = acumuladorSubRubro + y.cantidad;
            });
            acumuladorRubro = acumuladorRubro + acumuladorSubRubro
          }
         
          tProductos.push(funLinea1_4(`Total Rubro: ${acumuladorRubro}`, 'right'));

          acumuladorSucursal = acumuladorSucursal + acumuladorRubro;
        });

        tProductos.push(funLinea0_4(`Total Sucursal: ${acumuladorSucursal}`, 'right'));

      });
    }

    //3 columnas
    if (primerCriterio == 'sucursales' && segundoCriterio == null){
      tableWidths = tableWidths_3;
      let d0 = this.internoAgruparProductosPorSucursal(this.listProductoCompleto);
      console.log("d0", d0);
      
      d0.forEach(dd0 => {
        let acumuladorSucursal = 0;
        tProductos.push(funLinea0_3(`Sucursal: ${dd0[0].sucursalNombre}`));
        tProductos.push(funLinea1_3("Producto", "Cant.", true));

        dd0.forEach(x => {
          tProductos.push(funLinea1_3(x.articulo, `${x.cantidad}`));
          acumuladorSucursal = acumuladorSucursal + x.cantidad;
        });

        tProductos.push(funLinea0_3(`Total Sucursal: ${acumuladorSucursal}`, 'right'));
        
      });
      
    }

    //3 columnas
    if (primerCriterio == 'rubros' && segundoCriterio == null){
      tableWidths = tableWidths_3;
      let listaRubros = this.internoAgruparProductosPorRubro(this.listProductoCompleto);
      console.log("d0", listaRubros);
      
      listaRubros.forEach(rubro => {
        let acumuladorRubro = 0;
        tProductos.push(funLinea0_3(`Rubro: ${rubro.descripcionRubro}`));
        tProductos.push(funLinea1_3("Producto", "Cant.", true));

        rubro.productos.forEach(x => {
          tProductos.push(funLinea1_3(x.articulo, `${x.cantidad}`));
          acumuladorRubro = acumuladorRubro + x.cantidad;
        });

        tProductos.push(funLinea0_3(`Total Rubro: ${acumuladorRubro}`, 'right'));
        
      });
    }

    //
    if (primerCriterio == 'rubros' && segundoCriterio == 'sucursales'){
      tableWidths = tableWidths_4;
      let listaRubros = this.internoAgruparProductosPorRubro(this.listProductoCompleto);

      let acumuladorRubro = 0;
            
      listaRubros.forEach(rubro => {
        acumuladorRubro = 0;
        
        tProductos.push(funLinea0_4(`Rubro: ${rubro.descripcionRubro}`));

        let sucursalArrProd = this.internoAgruparProductosPorSucursal(rubro.productos);
        //es un arreglo que contiene arreglo de productos, dentro del producto esta el dato sucursal
        let acumuladorSucursal = 0;
        sucursalArrProd.forEach(arrProd => {
          acumuladorSucursal = 0;
          tProductos.push(funLinea1_4(`Sucursal: ${arrProd[0].sucursalNombre}`));
          tProductos.push(funLinea2_4("Producto", "Cant.", true));
          arrProd.forEach(p => {
            console.log("p", p);
            tProductos.push(funLinea2_4(p.articulo, `${p.cantidad}`));
            acumuladorSucursal = acumuladorSucursal + p.cantidad;
          });
          tProductos.push(funLinea1_4(`Total Sucursal: ${acumuladorSucursal}`, 'right'));
          acumuladorRubro = acumuladorSucursal + acumuladorRubro;
        });
        
        tProductos.push(funLinea0_4(`Total Rubro: ${acumuladorRubro}`, 'right'));
      });

    }

    //
    if (primerCriterio == 'subrubros' && segundoCriterio == null){
      tableWidths = tableWidths_4;
            
      let r0 = this.internoAgruparProductosPorRubroSubRubro(this.listProductoCompleto);

      r0.forEach(rr => {
        let acumuladorRubro = 0;

        tProductos.push(funLinea0_4(`Rubro: ${rr.descripcionRubro}`));

        rr.subRubros.forEach(ss => {

          tProductos.push(funLinea1_4(`SubRubro: ${ss.descripcionSubRubro}`));

          if (ss.productos.length > 0){
            tProductos.push(funLinea2_4("Producto", "Cant.", true));
            let acumuladorSubRubro = 0;
            ss.productos.forEach(y => {
              tProductos.push(funLinea2_4(y.articulo, `${y.cantidad}`));
              acumuladorSubRubro = acumuladorSubRubro + y.cantidad;
            });
            tProductos.push(funLinea1_4(`Total SubRubro: ${acumuladorSubRubro}`, 'right'));
            acumuladorRubro = acumuladorRubro + acumuladorSubRubro
          }

        });

        tProductos.push(funLinea0_4(`Total Rubro: ${acumuladorRubro}`, 'right'));
        
      });
      
    }

    //5 columnas
    if (primerCriterio == 'subrubros' && segundoCriterio == 'sucursales'){
      tableWidths = tableWidths_5;
            
      let r0 = this.internoAgruparProductosPorRubroSubRubro(this.listProductoCompleto);

      r0.forEach(rr => {
        let acumuladorRubro = 0;

        tProductos.push(funLinea0_5(`Rubro: ${rr.descripcionRubro}`));

        rr.subRubros.forEach(ss => {
          let acumuladorSubRubro = 0;
          tProductos.push(funLinea1_5(`SubRubro: ${ss.descripcionSubRubro}`));

          let acumuladorSucursal = 0;
          if (ss.productos.length > 0){
            let arrSucProd = this.internoAgruparProductosPorSucursal(ss.productos);
            arrSucProd.forEach(arrProd => {
              acumuladorSucursal = 0;
              tProductos.push(funLinea2_5(`Sucursal: ${arrProd[0].sucursalNombre}`));
              tProductos.push(funLinea3_5("Producto", "Cant.", true));
              arrProd.forEach(p => {
                console.log("p", p);
                tProductos.push(funLinea3_5(p.articulo, `${p.cantidad}`));
                acumuladorSucursal = acumuladorSucursal + p.cantidad;
              });
              tProductos.push(funLinea2_5(`Total Sucursal: ${acumuladorSucursal}`, 'right'));
              acumuladorSubRubro = acumuladorSucursal + acumuladorSubRubro;
            });
          }

          tProductos.push(funLinea1_5(`Total SubRubro: ${acumuladorSubRubro}`, 'right'));

          acumuladorRubro = acumuladorRubro + acumuladorSubRubro;
        });

        tProductos.push(funLinea0_5(`Total Rubro: ${acumuladorRubro}`, 'right'));
        
      });
    }

    let tabla = {
      table: {
        dontBreakRows: true,
        widths: tableWidths,
        body: tProductos
      },
      layout: {
        hLineWidth: function (i: any, node: any) {
          //return i == 0 || i == 1|| i == (tProductos.length - 1) || i == tProductos.length ? 0.3 : 0.0;
          return 0.3;
          //return 0.0;
        },
        vLineWidth: function (i: any, node: any) {
          //return i == 0 || i == 2? 0.3 : 0.0;
          return 0.3;
          //return 0.0;
        }
      },
      //margin: [left, top, right, bottom]
    }

    content.push(tabla);

    this.pdfService.generarPDF(content);


  }


}

const ELEMENT_DATA: any[] = [];

interface configTablaProducto{
  sizeTextoDatoTabla: number, 
  sizeTextoTituloTabla: number, 
  margenIzq: number
}