import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TiposDePrecioModel } from 'src/app/models/tipos-de-precio-model';

@Component({
  selector: 'app-preventa-sel-tipo-precio',
  templateUrl: './preventa-sel-tipo-precio.component.html',
  styleUrls: ['./preventa-sel-tipo-precio.component.scss']
})
export class PreventaSelTipoPrecioComponent implements OnInit {

  item = {
    idtipo: 0,
    tipo_descripcion: "",
    recargo: 0,
    cantidad: 0
  }


  listTipoPrecio: TiposDePrecioModel[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PreventaSelTipoPrecioComponent>,
  ) {
    this.listTipoPrecio = this.data.listTipoPrecio;

    if (this.listTipoPrecio.length > 0){
      this.item.idtipo = this.listTipoPrecio[0].idtipo;
      this.item.tipo_descripcion = this.listTipoPrecio[0].descripcion + " / " + this.listTipoPrecio[0].recargo + "%";
      this.item.recargo = this.listTipoPrecio[0].recargo;
    }
   }

  ngOnInit(): void {
  }

  selectTipoPrecio(event: any){
    console.log("event", event);
    let id = event.value;
    let elto = this.listTipoPrecio.find(x => x.idtipo == id)!

    this.item.idtipo = elto.idtipo;
    this.item.tipo_descripcion = elto.descripcion + " / " + elto.recargo + "%";
    this.item.recargo = elto.recargo;
  }

  clickCerrar(){
    this.dialogRef.close();
  }

  clickGuardar(){
    console.log("item salida", this.item);
    this.dialogRef.close(this.item);
  }

}
