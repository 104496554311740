<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: flex-end; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div>
        <h2>{{nuevo.idrubroproducto == 0 ? 'Nuevo Rubro' : ('Editar Rubro #' + nuevo.idrubroproducto) }}</h2>
    </div>

    <form style="display: flex; flex-direction: column; max-width: 400px;">

        <mat-form-field appearance="outline">
            <mat-label>Descripción</mat-label>
            <input matInput placeholder="Descripción" value=""  type="text" [(ngModel)]="nuevo.descripcion" name="descripcion">
        </mat-form-field>
        
    </form>

    <div>
        <button mat-raised-button color="primary" (click)="clickGuardar()">Guardar</button>
    </div>

</div>