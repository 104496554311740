import { Component, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiService } from './services/api.service';
import { AuthService } from './shared/services/auth.service';

import { Database, getDatabase, object, onValue } from '@angular/fire/database';
import { ref } from 'firebase/database';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'webvm';
  isLoggedIn = false

  rolesCargados = false;

  //private database: Database = inject(Database);

  constructor(
    public auth: AuthService,
    public router: Router,
    public api: ApiService,
    private _permissionsService: NgxPermissionsService,

  ) {

    //this._permissionsService.flushPermissions();

    this.isLoggedIn = this.auth.isLoggedIn;
    console.log("app component isloggedin", this.isLoggedIn);

    if (!this.isLoggedIn){
      this.router.navigateByUrl('/login', {});
      //return;
    }

    console.log("current rout", this.router.url); //  /routename

    if (this.isLoggedIn){
      let userInfo: any = this.auth.userInfo();
      if (userInfo != null){
        let uid = userInfo.uid;
        this.api.getUsuarios(uid).subscribe((x: any) => {
          console.log("x", x);
          if (x != null){
            
            let rol = x.rol ? x.rol : 0;
            const permissions = [`${rol}`];

            console.log("permissions", permissions);
            _permissionsService.loadPermissions(permissions);

            this.rolesCargados = true;
          }
        }, e => {
          console.log("error", e.message);
          this.rolesCargados = true;
          this.auth.SignOut();
        });
      }

      console.log("user info", userInfo);
    }
    

  }

  ngOnInit(): void {

    /*
    object(ref(this.database)).subscribe(x => {
      console.log("x", x);
    });    
    */

  }

}
