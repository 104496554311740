import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { ShareReplayConfig } from 'rxjs/internal/operators/shareReplay';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { ApiService } from 'src/app/services/api.service';
import { PdfService } from 'src/app/services/pdf.service';
import { SharedService } from 'src/app/services/shared.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { formatNumeroNacional, toFechaCorta } from 'src/app/utiles';
import { SelectorGenericoComponent } from '../../generico/selector-generico/selector-generico.component';
import { EntregasConfirmarDialogComponent } from '../entregas-confirmar-dialog/entregas-confirmar-dialog.component';
import { DialogImprimirEntregasComponent } from './dialog-imprimir-entregas/dialog-imprimir-entregas.component';
import { DialogImprimirMercaderiaComponent } from './dialog-imprimir-mercaderia/dialog-imprimir-mercaderia.component';

@Component({
  selector: 'app-entregas',
  templateUrl: './entregas.component.html',
  styleUrls: ['./entregas.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EntregasComponent implements OnInit {

  idStorage = "--entregas_";

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }
  
  expandedElement: any | null;

  // 0 pendiente, 1 confirmado, 2 pagados
  estados = ['Pendiente', 'Confirmado', 'Pagados'];

  estadosEntregas = ['Listo para Entregar', 'En Logística', 'Entregado']

  vals: any[] = [];

  seleccion: 'pagados' | 'confirmados' = 'confirmados';

  listVendedores: VendedoresModel[] = [];

  listLocalidades: any[] = [];

  listClientes: any[] = [];

  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  localidad = {
    nombre: "",
    idlocalidad: 0
  }

  cliente = {
    nombre: "",
    idcliente: 0
  }

  opcionesFlete = [
    {id: 0, nombre: "Todos"},
    {id: 1, nombre: "Con Flete"},
    {id: 2, nombre: "Sin Flete"},
  ]

  selectorFlete = this.opcionesFlete[0].id;

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  displayedColumns: string[] = ['select', 'idpreventa', 'opciones'];
  columnaList = [
    {id: "cliente_descripcion", titulo: "Cliente", activado: true},
    {id: "vendedor_descripcion", titulo: "Vendedor",  activado: true},
    {id: "sucursal", titulo: "Sucursal",  activado: true},
    {id: "localidad", titulo: "Localidad Entrega",  activado: true},
    {id: "domicilio", titulo: "Domicilio Entrega",  activado: true},
    {id: "cliente_telefono", titulo: "Telefono Cliente",  activado: true},
    {id: "fecha", titulo: "Fecha",  activado: true},
    {id: "estado_pago", titulo: "Estado Pago",  activado: true},
    {id: "flete", titulo: "Flete",  activado: true},
    {id: "observaciones", titulo: "Observaciones",  activado: true},
    {id: "estado_descripcion", titulo: "Estado",  activado: true},
  ];

  uid = "";

  cargando = false;

  //solicitudes

  solicitudesTipo = ['Movimiento', 'Reposición'];
  solicitudesEstados = ['Pendiente', 'Atendido'];

  estadosMovimiento = [
    'Pendiente de Solicitar', 
    'Solicitado / Pendiente de Envio', 
    'Pendiente Recepcion / Enviado', 
    'Recibido / Finalizado', 
    '',
    '',
    '',
    '',
    '',
    '',
    'Solicitud Cancelada',
    'Envio Cancelado'];

  fcNum = formatNumeroNacional;

  checkedGlobal = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private pdfService: PdfService,
    private auth: AuthService
  ) {
    moment.locale('es');

    this.uid = this.auth.userInfo() ? this.auth.userInfo().uid : "-";

    this.sucursalActual = this.sharedService.getSucursalActual();

    if (this.uid != "-"){
      let listStr = sessionStorage.getItem(`${this.uid}${this.idStorage}`);
      if (listStr != null){
        this.columnaList = JSON.parse(listStr);
      }
    }

    this.inicializarColumnas();

    let vendedoresObs = this.apiService.getVendedoresList();
    let localidadesObs = this.apiService.getLocalidadesList();
    let clientesObs = this.apiService.getClientesList();

    this.cargando = true;
    combineLatest([vendedoresObs, localidadesObs, clientesObs]).subscribe((hh: any[]) =>{
      this.listVendedores = hh[0];
      this.listLocalidades = hh[1];
      this.listClientes = hh[2];
      this.cargando = false;
    });

  }

  ngOnInit(): void {
    this.cargarTabla(1);
  }

  cargarTabla(estado?: number){

    this.vals = [];
    this.dataSource.data = this.vals;

    let preventas = this.apiService.getPreventasExtendido(1, this.sucursalActual.sucursalId);
    let preventasLineas = this.apiService.getPreventasLineasCompleto();
    let preventasPagos = this.apiService.getPreventasPago();

    this.cargando = true;

    combineLatest([preventas, preventasLineas, preventasPagos]).subscribe((hh: any[]) =>{

      console.log("hh", hh);

      let p: any[] = hh[0];
      let l: any[] = hh[1];
      let vp: any[] = hh[2];
      
      //let mapList: Map<number, any> = new Map();

      p.forEach(x => {

        let ll = l.map(z => {
          z._estado = this.estadosEntregas[z.estado];
          return z;
        });

        let lineasVenta = ll.filter(i => i.idventa == x.idpreventa);

        let entregas = ll.filter(i => i.idventa == x.idpreventa && i.estado == 0);
        let logistica = ll.filter(i => i.idventa == x.idpreventa && i.estado == 1);
        let entregados = ll.filter(i => i.idventa == x.idpreventa && i.estado == 2);

        x._fecha = moment(x.fecha).format('DD/MM/YYYY HH:mm');
        x._fechaLarga = moment(x.fecha).format('lll');

        x.estado_descripcion = "";
        if (entregas.length > 0){
          x.estado = 0;
          x.estado_descripcion = "Entregas Pendientes";
        }else{
          if (logistica.length > 0){
            x.estado = 1;
            x.estado_descripcion = "Logistica";
          }else{
            x.estado = 2;
            x.estado_descripcion = "Entregado";
          }
        }

        x._entregable = entregas.length > 0;
        x._logiqueable = logistica.length > 0;
        x._flete = x.flete > 0 ? "Si" : "No";

        x.lineas = {
          entregas: entregas,
          entregados: entregados,
          logistica: logistica
        };

        x.pagos = vp.filter(vp => vp.idpreventa == x.idpreventa);

        x.solicitudes = [];
        x.movimientos = [];


        ///

        // calculamos los pagos

        let sum = 0
        lineasVenta.forEach((p: any) => {
          sum = sum + (p.producto_cantidad * (p.producto_precio_unitario - p.producto_importe_descuento));
        })

        x._total_productos = sum;
        x._total = sum + x.flete - x.importe_descuento + x.importe_recargo;

        let pagado = 0.0;
        let recargo = 0.0;
        
        x.pagos.forEach(x => {
          pagado = pagado + x.importe;
        });

        let debe = x._total - pagado;
        let aFavor = 0;

        if (debe < 0){
          debe = 0.0;
          aFavor = debe * -1;
        }

        x._pagado = Number(pagado.toFixed(2));
        x._debe = Number(debe.toFixed(2));
        x._recargo = Number(recargo.toFixed(2));
        x._aFavor = Number(aFavor.toFixed(2));


      //


        ///
        //this.listClientes.push({clienteNombre: x.cliente_descripcion, clienteId: cliente })
      });

      let validos = p.filter(x => x._entregable || x._logiqueable);

      validos.sort(function (a, b) {
        if (a.idpreventa < b.idpreventa) {
          return 1;
        }
        if (a.idpreventa > b.idpreventa) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      
      this.vals = validos;

      console.log("data", this.vals);

      this.dataSource.data = this.vals;

      this.cargando = false;


    }, e => {
      console.log("error e", e);
      console.log("error", e.message);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);

      this.cargando = false;

    })

    
  }

  clickPagar(e: any, event: any){
    event.stopPropagation();
    this.router.navigateByUrl(`/ventas/pagos/confirmar/${e.idpreventa}`, {});
  }

  clickEditar(e: any, event: any){
    event.stopPropagation();

  }

  clickCancelar(e: any, event: any){
    event.stopPropagation();
    this.dialogCancelar("Alerta", `¿Confirma que desea cancelar la venta #id ${e.idpreventa}?`, e.idpreventa);
  }

  dialogCancelar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar
        this.apiService.getPreventaCancelar(elemento).subscribe(x => {
          if (x){
            let index = this.vals.findIndex(z => z.idpreventa == elemento);
            if (index != -1){
              this.vals.splice(index, 1);
              this.dataSource.data = this.vals;
            }
          }
        });
      } 
    });

  }

  radioChange(event: any){
    console.log("seleccion", this.seleccion);

    this.vendedor = {nombre: "", idvendedor: 0};

    if (this.seleccion == 'confirmados'){
      this.cargarTabla(1);
    }

    if(this.seleccion == 'pagados'){
      this.cargarTabla(2);
    }

  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }
        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }

        if (this.vendedor.idvendedor == 0){
          this.dataSource.data = this.vals;
        }else{
          let filtrado = this.vals.filter(v => v.idvendedor == this.vendedor.idvendedor)
          this.dataSource.data = filtrado;
        }
        
      }
    });
  }

  clickSeleccionarLocalidad(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listLocalidades.forEach(x => {
      let item = {
        id: x.idlocalidad,
        nombre: `${x.nombre} (${x.codigo_postal})`
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Localidad",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Localidad"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.localidad.idlocalidad,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.localidad = {
            nombre: rr.seleccion.nombre,
            idlocalidad: rr.seleccion.id 
          }
        }else{
          this.localidad = {nombre: "", idlocalidad: 0};
        }

        this.filtroLocal();
        
      }
    });
  }

  clickSeleccionarCliente(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listClientes.forEach(x => {
      let item = {
        id: x.idcliente,
        nombre: `${x.nombre}`
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Cliente",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Cliente"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.cliente.idcliente,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.cliente = {
            nombre: rr.seleccion.nombre,
            idcliente: rr.seleccion.id 
          }
        }else{
          this.cliente = {nombre: "", idcliente: 0};
        }

        this.filtroLocal();
        
      }
    });
  }

  filtroLocal(){

    let filtrado = this.vals;
    if (this.cliente.idcliente != 0){
      filtrado = filtrado.filter(v => v.idcliente == this.cliente.idcliente)
    }

    if(this.localidad.idlocalidad != 0){
      filtrado = filtrado.filter(v => v.idlocalidad == this.localidad.idlocalidad)
    }

    if(this.selectorFlete != 0){
      if (this.selectorFlete == 1){
        filtrado = filtrado.filter(v => v.flete > 0);
      }
      if (this.selectorFlete == 2){
        filtrado = filtrado.filter(v => v.flete == 0);
      }
    }
    
    this.dataSource.data = filtrado;
  }

  clickEntregar(e: any, event: any){
    event.stopPropagation();
    this.confirmarEntregaDialog(e)
  }

  clickForzarEntrega(item: any){
    console.log("item", item);

    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: "Pasara este producto a listo para entregar",
      titulo: "Información",
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar
        this.apiService.postLineaCambiarEstado(item.idlinea, 0).subscribe(x => {
          this.cargarTabla(1);
        });
      } 
    });

  }

  confirmarEntregaDialog(e: any){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    dialogConfig.data = {
      venta: e
    };

    const dialogRef = this.dialog.open(EntregasConfirmarDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      if (rr.cierre){
        if(rr.recargar){
          this.cargarTabla(1);
        }
      }
    });

  }

  clickExportarPdf(e: any){

    console.log("element", e);

    let id = e.idpreventa;
    let vendedor = `${e.vendedor_descripcion}`;
    let cliente = `${e.cliente_descripcion}`;
    let clienteTelefono = `${e.cliente_telefono}`;
    let clienteCorreoElectronico = `${e.cliente_correo_electronico}`;
    let fecha = `${e._fecha}`;
    let sucursal = `${e.preventa_sucursal}`;

    let entregas: any = [['Producto', 'Color', 'Medida', 'Cantidad', 'Sucursal', 'Estado']];
    let entregados: any = [['Producto', 'Color', 'Medida', 'Cantidad', 'Sucursal', 'Estado']];
    let logistica: any = [['Producto', 'Color', 'Medida', 'Cantidad', 'Sucursal', 'Estado']];

    let ee: any = e.lineas.entregas.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`,
        `${r.sucursal_descripcion}`,
        `${r._estado}`
      ]
      return x;
    })

    ee.forEach((x: any) => {
      entregas.push(x);
    })

    let ss: any = e.lineas.entregados.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`,
        `${r.sucursal_descripcion}`,
        `${r._estado}`
      ]
      return x;
    });

    ss.forEach((x: any) => {
      entregados.push(x);
    });

    let ll: any = e.lineas.logistica.map((r: any) => {
      let x: any[] = [
        `${r.producto_descripcion}`,
        `${r.color_descripcion}`,
        `${r.medida_descripcion}`,
        `${r.cantidad}`,
        `${r.sucursal_descripcion}`,
        `${r._estado}`
      ]
      return x;
    });

    ll.forEach((x: any) => {
      logistica.push(x);
    });

    let content: any[] = [
      { text: `Entrega - #${id} | Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Fecha Venta: ${fecha}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Cliente: ${cliente}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Telefono: ${clienteTelefono}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Correo Electronico: ${clienteCorreoElectronico}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Para Entregar: `, fontSize: 11, margin:[0, 0, 0, 10] },
      entregas.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: entregas
        }
      } : { text: `Sin Productos Para Entregar`, fontSize: 11, margin:[10, 0, 0, 10] },
      { text: `Entregados: `, fontSize: 11, margin:[0, 0, 0, 10] },
      entregados.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: entregados
        }
      }: { text: `Sin Productos Entregados`, fontSize: 11, margin:[10, 0, 0, 10] },
      { text: `En Logística : `, fontSize: 11, margin:[0, 0, 0, 10] },
      logistica.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: logistica
        }
      }: { text: `Sin Productos en Logística`, fontSize: 11, margin:[10, 0, 0, 10] },
    ]

    this.pdfService.generarPDF(content);

  }

  clickShowColumna(event: any, item: any){
    //  event.stopPropagation();
    //console.log("event", event); 

    item.activado = event.target.checked;

    if (event.checked){

      this.displayedColumns = ['idpreventa', 'opciones'];
      this.inicializarColumnas();

    }else{
      let columnaIndex = this.displayedColumns.findIndex(x => x == item.id);
      if (columnaIndex != -1){
        this.displayedColumns.splice(columnaIndex, 1);
      }
    }

    sessionStorage.setItem(`${this.uid}${this.idStorage}`, JSON.stringify(this.columnaList));

  }

  obtenerEntregas(elemento: any){
    console.log("elemento", elemento);
    //this.apiService.getPreventasSolicitudes(elemento.)
  }

  inicializarColumnas(){
    this.columnaList.forEach(c => {
      if (c.activado){
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, c.id);
      }
    });
  }

  expandir(element: any){
    this.expandedElement = this.expandedElement === element ? null : element;
    if (this.expandedElement != null){
      //element.solicitudes = [];

      let oPreventasSolicitudes = this.apiService.getPreventasSolicitudes(element.idpreventa);
      let oLogisticaMovimientosVenta = this.apiService.getLogisticaMovimientosVenta(element.idpreventa);

      combineLatest([oPreventasSolicitudes, oLogisticaMovimientosVenta]).subscribe((hh: any[]) =>{
        let s: any[] = hh[0];
        s.forEach(x => {
          x._fecha = toFechaCorta(x.fecha);
          x._estado = this.solicitudesEstados[x.estado];
          x._tipo = this.solicitudesTipo[x.tipo];
        });
        element.solicitudes = s;

        let m: any[] = hh[1];
        m.forEach(x => {
          x._fecha = toFechaCorta(x.fecha);
          x._estado = this.estadosMovimiento[x.estado];
        });


        element.movimientos = m;

      });

      //getLogisticaMovimientosVenta
    }
  }

  clickAtenderSolicitud(e: any, event: any){
    event.stopPropagation();

    let fc = () => {
      this.apiService.postLogisticaSolicitudesEstado(e.idlogisticasolicitud, 1).subscribe(x => {
        if (x){
          e.estado = 1;
          e._estado = this.solicitudesEstados[e.estado];
        }
      });
    }

    this.dialogAccion("Alerta", "Va a marcar la solicitud como atendida, esto indica que ya tomo las medidas correspondientes relacionadas con la solicitud. Desea continuar?", fc);

  }
  
  dialogAccion(titulo: string, mensaje: string, fc: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //borrar
        fc()
      } 
    });

  }

  selectFlete(){
    this.filtroLocal();
  }

  clickImprimir(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '65vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    coleccion = this.dataSource.data.filter(x => x._seleccionado == true);

    if (coleccion.length > 0){
      //controlamos que sean del mismo cliente
      let clientes = new Set();

      coleccion.forEach(x => {
        clientes.add(x.idcliente);
      });

      if (clientes.size > 1){
        //no se puede tiene mas de un cliente
        this.dialogMensaje("Alerta", "Para generar un recibo de entrega las ventas deben ser del mismo Cliente", null);
        return;
      }

    }else{
      //no hay nada seleccionado
      this.dialogMensaje("Alerta", "Para generar un recibo de entrega debe seleccionar al menos una venta.", null);
      return;
    }

    dialogConfig.data = {
      listVentas: coleccion
    };

    const dialogRef = this.dialog.open(DialogImprimirEntregasComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      
    });
  }

  clickImprimirMercaderia(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.width = '65vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    coleccion = this.dataSource.data.filter(x => x._seleccionado == true);

    if (coleccion.length == 0){
      //no hay nada seleccionado
      this.dialogMensaje("Alerta", "Para generar la impresion generalizada de entregas debe seleccionar al menos una venta.", null);
      return;
    }

    dialogConfig.data = {
      listVentas: coleccion
    };

    const dialogRef = this.dialog.open(DialogImprimirMercaderiaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      
    });
  }

  onChangeSelectGlobal(event){

  }

  onChangeSelectItem(event, element){
    console.log("element", element);
    console.log("event", event);

    element._seleccionado = event.checked;

  }

  dialogMensaje(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      
    });

  }

}

const ELEMENT_DATA: any[] = [];

