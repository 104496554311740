<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw; display: flex; flex-direction: column;">

    <div>
        <h1>Nueva Preventa</h1>
    </div>

    <div style="display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: flex-start;
        align-items: flex-start;">
        <div style="flex-grow: 1;">
            <h2>Información</h2>
        </div>

        <mat-form-field style="width: 60vw; max-width: 350px;" appearance="outline">
            <mat-label>Observaciones</mat-label>
            <textarea matInput [(ngModel)]="observaciones"></textarea>
          </mat-form-field>
        
        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
            <mat-form-field style="width: 60vw; max-width: 350px;" appearance="outline" >
                <mat-label>Vendedor</mat-label>
                <input matInput type="text" [(ngModel)]="vendedor.nombre" readonly>
            </mat-form-field>

            <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickSeleccionarVendedor()">Seleccionar Vendedor</button>
        </div>

        <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; align-content: center;">
            <mat-form-field style="width: 60vw; max-width: 350px;" appearance="outline">
                <mat-label>Cliente</mat-label>
                <input matInput type="text" [(ngModel)]="cliente.nombre" readonly>
            </mat-form-field>

            <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickSeleccionarCliente()">Seleccionar Cliente</button>
        </div>


        <!--
        <form [formGroup]="formGroup">
            <mat-form-field style="width: 60vw; max-width: 350px;">
                <mat-label>Cliente</mat-label>
                <input type="text"
                    placeholder="Cliente"
                    matInput
                    formControlName="clienteCtrl"
                    [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayProperty" >
                <mat-option *ngFor="let cliente of filteredClientes | async" [value]="cliente" >
                    {{cliente.nombre}}
                </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            </form>
        -->
        
    </div>

    

    <div style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: baseline;">
        <h2 style="width: 250px;">Productos</h2>
        <button mat-raised-button color="primary" (click)="clickAgregarProducto()">Agregar Producto</button>
    </div>

    

    <div>
        <div *ngFor="let item of listItems; let i = index" style="padding: 4px;">
            <mat-card style="max-width: 450px;" (click)="clickProducto(item, i)">
                <div style="display: flex; flex-direction: row;">
                    <div style="display: flex; flex-direction: column; flex-grow: 1; font-size: small;">
                        <div>Producto: {{item.nombreProducto}}</div>
                        <div style="border:1px solid white; border-style: inset;"></div>
                        <div>Sucursal: {{item.nombreSucursal}}</div>
                        <div style="border:1px solid white; border-style: inset;"></div>
                        <div *ngFor="let i of item.items">
                            <div>Color: {{i.color_descripcion}}</div>
                            <div>Medida: {{i.medida_descripcion}}</div>
                            <div>
                                Cantidad: {{i._agregados_cantidad}}
                            </div>
                            <div>Precio Unitario: ${{i.precio}}</div>
                            <div>Precio Total: ${{i.precio * i._agregados_cantidad}}</div>
                            <div style="border:1px solid white; border-style: inset;"></div>
                        </div>
                        <div>
                            <div>Subtotal: ${{item.totalPrecioFinal}}</div>
                        </div>

                    </div>
                    
                    <div>
                        <button mat-icon-button (click)="clickEliminar($event, item, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>

        <div *ngIf="listItems.length == 0" style="display: flex; flex-direction: column; justify-content: center; height: 100px;">
            <div>Sin Productos</div>
        </div>

        <div>
            <mat-card style="max-width: 450px; background-color: #dedede; margin: 4px;">
                <span style="font-weight: bold;">Precio Total: ${{precioTotal}}</span>
            </mat-card>
        </div>

    </div>

    <div style="margin-top: 16px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Confirmar</button>
    </div>

</div>