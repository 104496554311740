import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RubrosProductoModel } from 'src/app/models/rubros-producto-model';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-rubros-nuevo',
  templateUrl: './rubros-nuevo.component.html',
  styleUrls: ['./rubros-nuevo.component.scss']
})
export class RubrosNuevoComponent implements OnInit {

  nuevo: RubrosProductoModel = {
    idrubroproducto: 0,
    descripcion: ""
  }

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private _location: Location

  ) {

    
    this.nuevo.idrubroproducto = Number(this.activatedRoute.snapshot.paramMap.get('idrubroproducto'));

    if (this.nuevo.idrubroproducto > 0){
      this.apiService.getRubros(this.nuevo.idrubroproducto).subscribe(x => {
        this.nuevo = x
      });
    }

    
   
  }

  ngOnInit(): void {

    

  }

  clickGuardar(){

    
    console.log("nuevo", this.nuevo);
    if (this.validar(this.nuevo)){
      if (this.nuevo.idrubroproducto == 0){
        this.apiService.postRubros(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Genero el Rubro correctamente");
          this.router.navigateByUrl('/rubros/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }else{
        
        this.apiService.putRubros(this.nuevo).subscribe(x => {
          this.snackBarService.successMessage("Se Guardo el Rubro correctamente");
          this.router.navigateByUrl('/rubros/abm', {});
        }, (e: any) => {
          this.snackBarService.errorMessage("Error al guardar. " + e.message);
        });
      }
    }else{
      this.snackBarService.errorMessage("Campo descripción debe tener mas de 2 caracteres");
      
    }
    

  }

  validar(item: any): boolean{
    console.log("validar", item);
    return true
  }

  clickBack(){
    this._location.back();
  }

}
