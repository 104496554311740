import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ClientesNuevoDialogComponent } from '../../clientes/clientes-nuevo-dialog/clientes-nuevo-dialog.component';

@Component({
  selector: 'app-selector-generico',
  templateUrl: './selector-generico.component.html',
  styleUrls: ['./selector-generico.component.scss']
})
export class SelectorGenericoComponent implements OnInit {

  checkedGlobal = false;


  displayedColumns: string[] = [];

  selectedElement: any | null;

  ventana = {
    titulo: "",
    columnaNombre: "Nombre",
    columnaCantidad: "Cantidad",
    tituloBotonNuevo: ""
  }

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  coleccion: any[] = [];

  cargando = false;   

  permitirNuevo = false;

  cierreAutomatico = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SelectorGenericoComponent>,
    private dialog: MatDialog,

  ) {
    this.ventana = this.data.ventana;
    this.coleccion = this.data.coleccion;

    let col = this.coleccion.slice();
    this.coleccion = col.map(x => {
      x._busqueda = x.id + " " + x.nombre.toLocaleLowerCase();
      return x;
    });

    if(this.data.idSeleccionPrevia != 0 && this.data.idSeleccionPrevia != null){
      let item = this.coleccion.find(x => x.id == this.data.idSeleccionPrevia);
      this.selectedElement = item;
    }

    if (this.data.permitirNuevo){
      this.permitirNuevo = true;
    }

    if (this.data.multiple){
      this.displayedColumns.push("select")
    }

    if (this.data.muestraId){
      this.displayedColumns.push("id");
    }

    this.displayedColumns.push("nombre");

    if (this.data.muestraCantidad){
      this.displayedColumns.push("cantidad");
    }

    if (this.data.cierreAutomatico != null){
      this.cierreAutomatico = this.data.cierreAutomatico
    }
    
  }

  ngOnInit(): void {
    this.cargando = false;
    this.dataSource = new MatTableDataSource(this.coleccion);
  }

  clickCerrar() {
    this.dialogRef.close(
      {
        seleccion: this.selectedElement,
        cierre: true
      });
  }

  clickNuevo(){
    switch (this.data.referenciaNuevo) {
      case "cliente":
        this.nuevoCliente();
        break;
    
      default:
        break;
    }
  }

  selectRow(element: any){
    console.log("row", element);

    if (this.selectedElement == null){
      this.selectedElement = element;
    }else{
      if (this.selectedElement == element){
        this.selectedElement = null;
      }else{
        this.selectedElement = element;
      }
    }

    //this.selectedElement = this.selectedElement === element ? null : element;

    if (this.cierreAutomatico){
      this.clickCerrar();
    }

    console.log("selected", this.selectedElement);
  }

  nuevoCliente(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    dialogConfig.data = {
      
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(ClientesNuevoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr", rr);
      if (rr.nuevo != null){
        this.coleccion.push(rr.nuevo);
        this.dataSource = new MatTableDataSource(this.coleccion.slice());
      }

    });
  }

  ///////////

  onChangeSelectItem(event: any, element: any) {
    //element.selected = event.checked;
  }

  onChangeSelectGlobal(event: any) {
    /*
    this.tecnicos.forEach(x => {
      x.selected = event.checked;
    });
    */
  }

  filtroRapido(filterValue: any){
    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
  }

}

const ELEMENT_DATA: any[] = [];
