import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SharedService } from 'src/app/services/shared.service';
import { PdfService } from 'src/app/services/pdf.service';
import { toFechaApiRequest, toFechaCorta, toFechaLarga } from 'src/app/utiles';


@Component({
  selector: 'app-compras-abm',
  templateUrl: './compras-abm.component.html',
  styleUrls: ['./compras-abm.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ComprasAbmComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  vals: any[] = [];

  cargando = false;

  displayedColumns: string[] = ['idcompra', 'descripcion', 'sucursal', 'proveedor', 'importe', 'fecha', 'estado', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private pdfService: PdfService
  ) {
    moment.locale('es');

    this.sucursalActual = this.sharedService.getSucursalActual();


  }

  ngOnInit(): void {
    this.cargarTabla(0);
  }

  cargarTabla(estado?: number){

    this.vals = [];
    this.dataSource.data = this.vals;

    this.cargando = true;

    let obsComprasProductoCompleto = this.apiService.getComprasProductoCompleto(this.sucursalActual.sucursalId);
    let obsComprasFormaPagoCompleto = this.apiService.getComprasFormaPagoCompleto(this.sucursalActual.sucursalId);
    let obsComprasProductoRecibidoCompleto = this.apiService.getComprasProductoRecibidoCompleto(this.sucursalActual.sucursalId);

    combineLatest([obsComprasProductoCompleto, obsComprasFormaPagoCompleto, obsComprasProductoRecibidoCompleto]).subscribe((hh: any[]) =>{
      let x:any[] = hh[0];
      let y:any[] = hh[1];
      let j:any[] = hh[2];
      //para x
      
      let mapList: Map<number, any> = new Map();

      x.forEach(z => {
        if (mapList.has(z.idcompra)){
          let cont = mapList.get(z.idcompra);

          let item = {
            cantidad: z.cantidad,
            colorDescripcion: z.color_descripcion,
            idproductopresentacion: z.idproductopresentacion,
            importePresentacion: z.importe_presentacion,
            medidaDescripcion: z.medida_descripcion,
            productoDescripcion: z.producto_descripcion,
            productoActivo: z.producto_activo,
            idproducto: z.idproducto,
          }
          cont.presentaciones.push(item);
        }else{
          let cont: any = {
            idcompra: z.idcompra,
            idproveedor: z.idproveedor,
            idtipocompra: z.idtipocompra,
            idsucursal: z.idsucursal,
            importe: z.importe,
            fecha: z.fecha,
            descripcion: z.descripcion,
            proveedorDescripcion: z.proveedor_descripcion,
            sucursalDescripcion: z.sucursal_descripcion,
            estado: z.estado,
            _fecha: toFechaCorta(z.fecha),
            _fechax: toFechaLarga(z.fecha),
            presentaciones: [],
            recibidos: [],
            formasPago: []
          };
          let item = {
            cantidad: z.cantidad,
            colorDescripcion: z.color_descripcion,
            idproductopresentacion: z.idproductopresentacion,
            importePresentacion: z.importe_presentacion,
            medidaDescripcion: z.medida_descripcion,
            productoDescripcion: z.producto_descripcion,
            productoActivo: z.producto_activo,
            idproducto: z.idproducto
          }

          cont.presentaciones.push(item);
          mapList.set(z.idcompra, cont);
        }
      });

      y.forEach(yy => {
        if (mapList.has(yy.idcompra)){
          let cont = mapList.get(yy.idcompra);
          let nn = {
            idcompraformapago: yy.idcompraformapago,
            idcompra: yy.idcompra,
            idcompratipoprecio: yy.idcompratipoprecio,
            importe: yy.importe,
            observaciones: yy.observaciones,
            tipoPrecioDescripcion: yy.tipo_precio_descripcion
          }
          cont.formasPago.push(nn);
        }
      });

      j.forEach(jj => {
        if (mapList.has(jj.idcompra)){
          let cont = mapList.get(jj.idcompra);
          let nn = {
            idcompra: jj.idcompra,
            importePresentacion: jj.importe_presentacion,
            cantidad: jj.cantidad,
            idproductopresentacion: jj.idproductopresentacion,
            //idcomprarecepcionproductopresentacion,
            idproducto: jj.idproducto,
            productoDescripcion: jj.producto_descripcion,
            colorDescripcion: jj.color_descripcion,
            medidaDescripcion: jj.medida_descripcion,
          }
          cont.recibidos.push(nn);
        }
      })

      //para y
      
      console.log("y", y);


      this.vals =[ ...mapList.values() ];

      this.dataSource.data = this.vals;
      
      this.cargando = false;

    });
  }

  clickNuevo(){
    this.router.navigateByUrl('/compras/nueva', {});
  }

  clickEditar(e: any, event: any){
    event.stopPropagation();
    let valores = this.vals.find(x => x.idcompra == e.idcompra);
    if (valores != null){
      this.router.navigateByUrl(`/compras/editar/${e.idcompra}/1`, {});
    }
  }

  clickCancelar(e: any, event: any){
    event.stopPropagation();
    this.dialogCancelar("Alerta", `¿Confirma que desea cancelar la compra #id ${e.idcompra}?`, e.idcompra);
  }

  clickPendiente(e: any, event: any){
    event.stopPropagation();
    this.dialogPendiente("Alerta", `Si pasa la compra a "Pendiente" se perderan los registros guardados en recepción. ¿Confirma que desea pasar a Pendiente la compra #id ${e.idcompra}?`, e.idcompra);
  }

  clickRecepcionar(e: any, event: any){
    event.stopPropagation();
    let valores = this.vals.find(x => x.idcompra == e.idcompra);
    if (valores != null){
      this.router.navigateByUrl(`/compras/recepcion/${e.idcompra}`, {});
    }
  }

  clickConfirmar(e: any, event: any){
    event.stopPropagation();
    let valores = this.vals.find(x => x.idcompra == e.idcompra);
    if (valores != null){
      this.router.navigateByUrl(`/compras/confirmar/${e.idcompra}/2`, {});
    }
    //this.dialogConfirmar(e);
  }

  dialogCancelar(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar

        this.apiService.postComprasCancelar(elemento).subscribe(x => {
          this.cargarTabla(0);
        });
        
      } 
    });

  }

  dialogPendiente(titulo: string, mensaje: string, elemento: any) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar

        this.apiService.postComprasPasarAPendiente(elemento).subscribe(x => {
          this.cargarTabla(0);
        });
        
      } 
    });

  }

  filtroRapido(filterValue: any) {

    if (filterValue.target != null){
      let filter = filterValue.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, "").toLocaleLowerCase('en-US');
      this.dataSource.filter = filter.trim();
    }
    
  }

  sortData(sort: any){
    console.log("event sort", sort);

    this.dataSource.data.sort((a: any, b: any) => {

      console.log("active", sort.active);
      console.log("a", a);
      console.log("b", b);

      const isAsc = sort.direction === 'asc';
      
      switch (sort.active) {
        default: return 0;
      }
      
      
   });

  }

  clickExportarPdf(e: any){

    let estados = ['Pendiente', 'Recibido', 'Confirmado']

    console.log("element", e);

    let id = e.idcompra;
    let descripcion = `${e.descripcion}`;
    let proveedor = `${e.proveedorDescripcion}`;
    let fecha = `${e._fecha}`;
    let sucursal = `${e.sucursalDescripcion}`;
    let estado = estados[e.estado];


    let productos: any = [['Producto', 'Color', 'Medida', 'Cantidad', 'Importe']];
    let recibidos: any = [['Producto', 'Color', 'Medida', 'Cantidad', 'Importe']];
    let formasPago: any = [['Tipo', 'Importe', 'Observaciones']];


    let p: any = e.presentaciones.map((r: any) => {
      let x: any[] = [
        `${r.productoDescripcion}`,
        `${r.colorDescripcion}`,
        `${r.medidaDescripcion}`,
        `${r.cantidad}`,
        `${r.importePresentacion}`
      ]
      return x;
    });

    p.forEach((x: any) => {
      productos.push(x);
    });

    let rr: any = e.recibidos.map((r: any) => {
      let x: any[] = [
        `${r.productoDescripcion}`,
        `${r.colorDescripcion}`,
        `${r.medidaDescripcion}`,
        `${r.cantidad}`,
        `${r.importePresentacion}`
      ]
      return x;
    })

    rr.forEach((x: any) => {
      recibidos.push(x);
    })

    let f: any = e.formasPago.map((r: any) => {
      let x: any[] = [
        `${r.tipoPrecioDescripcion}`,
        `${r.importe}`,
        `${r.observaciones}`
      ]
      return x;
    })

    f.forEach((x: any) => {
      formasPago.push(x);
    })

    let content: any[] = [
      { text: `Compra - #${id} - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] },
      { text: `Proveedor: ${proveedor}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Fecha: ${fecha}`, fontSize: 11, margin:[0, 0, 0, 10] },
      { text: `Estado: ${estado}`, fontSize: 11, margin:[0, 0, 0, 10] },

      { text: `Descripción: ${descripcion}`, fontSize: 11, margin:[0, 0, 0, 10] },
      
      { text: `Productos Solicitados: `, fontSize: 11, margin:[0, 0, 0, 10] },
      productos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: productos
        }
      } : { text: `Sin Productos Solicitados`, fontSize: 11, margin:[10, 0, 0, 10] },

      { text: `Productos Recibidos: `, fontSize: 11, margin:[0, 0, 0, 10] },
      recibidos.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: recibidos
        }
      } : { text: `Sin Productos Recibidos`, fontSize: 11, margin:[10, 0, 0, 10] },
      
      { text: `Formas de Pago:`, fontSize: 11, margin:[0, 0, 0, 10] },
      formasPago.length > 1 ? 
      {
        table: {
          headerRows: 1,
          body: formasPago
        }
      } : { text: `Sin Formas de Pago`, fontSize: 11, margin:[10, 0, 0, 10] },

    ]

    this.pdfService.generarPDF(content);

  }




}

const ELEMENT_DATA: any[] = [];

