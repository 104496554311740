<div style="display: flex; flex-direction: row;">
    <div class="venta-borde-izquierdo" style="width: 20px;">

    </div>
    <div style="display: flex; flex-direction: column; padding-top: 10px; flex-grow: 1; padding-bottom: 10px;">

        <div *ngIf="venta._diferenciaPrecio == true" class="alerta">
            Existe una variación en los precios de los productos al momento de la venta en comparación con los precios actuales.<br>
            Recomendamos efectuar una revisión.<br>
            <button mat-raised-button color="warn" (click)="clickActualizarPrecios(venta)">Actualizar Precios de Venta</button>
        </div>

        <div>
            Información de Entrega
        </div>
    
        <div style="display: flex; flex-direction: column; margin: 8px 16px;">
            <div class="entrega">
                <div><b>Localidad</b></div>
                <div>{{venta.localidad_descripcion}}</div>
                <div><b>Código Postal</b></div>
                <div>{{venta.localidad_codigo_postal}}</div>
                <div><b>Domicilio</b></div>
                <div>{{venta.domicilio}}</div>
            </div>
        </div>
    
        <div style="border:1px solid rgb(204, 204, 204); margin: 8px 0px;"></div>
    
        <div>
            Observaciones
        </div>
    
        <div style="display: flex; flex-direction: column; margin: 8px 16px;">
            <div>{{venta.observaciones}}</div>
        </div>
    
        <div style="border:1px solid rgb(204, 204, 204); margin: 8px 0px;"></div>
    
        <div style="display: flex;">
            <div>Productos</div>
        </div>
    
        <div *ngIf="venta.productos.length > 0">
            <table class="styled-table">
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Color</th>
                        <th>Medida</th>
                        <th>Cantidad</th>
                        <th>Sucursal del Producto</th>
                        <th>Precio Unitario</th>
                        <th>Descuento Unitario</th>
                        <th>Descuento SubTotal</th>
                        <th>Precio</th>
                        <th class="th-alerta" *ngIf="venta._diferenciaPrecio">Precio U. Actual</th>
                        <th class="th-alerta" *ngIf="venta._diferenciaPrecio">Precio Actual S/D</th>
                        <th class="th-alerta" *ngIf="venta._diferenciaPrecio">Precio Actual</th>
                        <th *ngIf="">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of venta.productos">
                        <td style="text-align: right;">{{item.producto_descripcion}} <span class="tip-desactivado" *ngIf="!item.producto_activo">DESACTIVADO</span></td>
                        <td style="text-align: right;">{{item.color_descripcion}}</td>
                        <td style="text-align: right;">{{item.medida_descripcion}}</td>
                        <td style="text-align: right;">{{item.cantidad}}</td>
                        <td style="text-align: right;">{{item.producto_sucursal_descripcion}}</td>
                        <td style="text-align: right;">$ {{fcNum((item.precio))}}</td>
                        <td style="text-align: right;">$ {{fcNum((item.importe_descuento))}}</td>
                        <td style="text-align: right;">$ {{fcNum((item.importe_descuento * item.cantidad))}}</td>
                        <td style="text-align: right;">$ {{fcNum(((item.precio - item.importe_descuento) *  item.cantidad))}}</td>
                        <td class="td-alerta" *ngIf="venta._diferenciaPrecio">$ {{fcNum((item._precio_actual))}} </td>
                        <td class="td-alerta" *ngIf="venta._diferenciaPrecio">$ {{fcNum(((item._precio_actual ? item._precio_actual : item.precio) * item.cantidad))}}</td>
                        <td class="td-alerta" *ngIf="venta._diferenciaPrecio">$ {{fcNum((((item._precio_actual ? item._precio_actual : item.precio) - item.importe_descuento ) * item.cantidad))}}</td>
                    </tr>
                    <tr style="background-color: #bdbdbd;">
                        <td colspan="8" style="font-weight: bold;">TOTAL</td>
                        <td colspan="3" style="font-weight: bold;">$ {{fcNum(venta._total_productos)}}</td>
                        <td class="td-foot-alerta" style="font-weight: bold;" *ngIf="venta._diferenciaPrecio">$ {{fcNum(venta._total_productos_actual)}}</td>
                    </tr>
                </tbody>
            </table>
    
            <div style="font-weight: bold; margin: 4px 0px 8px 16px; ">
                <div>
                    Precio Productos: $ {{fcNum(venta._total_productos)}}
                </div>
                <div *ngIf="venta._diferenciaPrecio" style="color: #cf0000;">
                    Precio Productos Actual: $ {{fcNum(venta._total_productos_actual)}}
                </div>
                <div *ngIf="venta._diferenciaPrecio" style="color: #cf0000;">
                    Existe una diferencia de: $ {{fcNum(venta._total_productos_actual  - venta._total_productos)}}
                </div>
            </div>
        </div>
    
    
        <div *ngIf="venta.productos.length == 0" style="margin: 12px;">
            No hay productos
        </div>
        
        <div>
            Extras
        </div>
        <div style="font-weight: bold; margin: 4px 0px 8px 16px; ">
            Flete: $ {{fcNum(venta.flete)}}
        </div>
    
        <div>
            Descuento General
        </div>
        <div style="font-weight: bold; margin: 4px 0px 8px 16px; ">
            Descuento: $ {{fcNum(venta.importe_descuento)}}
        </div>

        <div>
            Recargo General
        </div>
        <div style="font-weight: bold; margin: 4px 0px 8px 16px; ">
            Recargo: $ {{fcNum(venta.importe_recargo)}}
        </div>
    
        <div>
            Total
        </div>
        <div style="font-weight: bold; margin: 4px 0px 8px 16px; ">
            Precio Total Final: $ {{fcNum(venta._total)}}
        </div>
    
        <div>
            Forma de Pago ({{estadoFormaPago[venta.estado]}})
        </div>
        <div>
            <table class="styled-table">
                <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Importe</th>
                        <th>Recargo</th>
                        <th>Total</th>
                        <th>Es Seña</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of venta.formaPago">
                        <td style="text-align: left;">{{item.tipo_descripcion}}</td>
                        <td style="text-align: right;">$ {{fcNum(item.importe)}}</td>
                        <td style="text-align: right;">$ {{fcNum(item._recargo)}} ({{item.recargo}}%)</td>
                        <td style="text-align: right;">$ {{fcNum(item._conRecargo)}}</td>
                        <td style="text-align: center;">{{item.senia ? 'Si' : 'No'}}</td>
                        <td style="text-align: center;">
                            <button *ngIf="item.id_venta_pago != null" type="button" (click)="clickImprimirPago(item)">Imprimir Recibo de Pago Independiente</button>
                            <button *ngIf="item.idpreventaformapago != null" type="button" (click)="clickImprimirPropuestaPago(item)">Imprimir Recibo de Propuesta de Pago Independiente</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <button mat-raised-button color="primary" (click)="clickImprimirPagoConsolidado()" *ngIf="venta.estado == 1 || venta.estado == 2" >Imprimir Recibo de Pagos Consolidado</button>
            <button mat-raised-button color="primary" (click)="clickImprimirPropuestaPagoConsolidado()" *ngIf="venta.estado == 0 || venta.estado == 4" >Imprimir Recibo de Propuesta de Pagos Consolidado</button>
            
            
        </div>
    
    </div>
</div>
