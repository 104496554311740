import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ClientesNuevoDialogComponent } from 'src/app/features/clientes/clientes-nuevo-dialog/clientes-nuevo-dialog.component';
import { SelectorGenericoComponent } from 'src/app/features/generico/selector-generico/selector-generico.component';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';

@Component({
  selector: 'app-preventa-sel-sucursal-dialog',
  templateUrl: './preventa-sel-sucursal-dialog.component.html',
  styleUrls: ['./preventa-sel-sucursal-dialog.component.scss']
})
export class PreventaSelSucursalDialogComponent implements OnInit {

  displayedColumns: string[] = ['id', 'nombre', 'cantidad'];

  selectedElement: any | null;

  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);

  coleccion: any[] = []

  cargando = false;   

  cantidadActual = 0;
  cantidadMover = 0;
  sucursalDescripcionActual = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PreventaSelSucursalDialogComponent>,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog
  ) {
    this.coleccion = this.data.coleccion;
    this.cantidadActual = this.data.cantidadActual;
    this.sucursalDescripcionActual = this.data.sucursalDescripcionActual;

    if(this.data.idSeleccionPrevia != 0 && this.data.idSeleccionPrevia != null){
      let item = this.coleccion.find(x => x.id == this.data.idSeleccionPrevia);
      this.selectedElement = item;
    }

  }

  ngOnInit(): void {
    this.cargando = false;
    this.dataSource = new MatTableDataSource(this.coleccion.slice());
  }

  clickCerrar() {
    this.dialogRef.close(
      {
        seleccion: this.selectedElement,
        cierre: true
      });
  }

  selectRow(element: any){
    console.log("row", element);

    if (this.selectedElement == null){
      this.selectedElement = element;
    }else{
      if (this.selectedElement == element){
        this.selectedElement = null;
      }else{
        this.selectedElement = element;
      }
    }

    //this.selectedElement = this.selectedElement === element ? null : element;

    console.log("selected", this.selectedElement);
  }


  ///////////

  onChangeSelectItem(event: any, element: any) {
    //element.selected = event.checked;
  }

  onChangeSelectGlobal(event: any) {
    /*
    this.tecnicos.forEach(x => {
      x.selected = event.checked;
    });
    */
  }

  clickConfirmar(){

    if (this.data.idSeleccionPrevia == this.selectedElement.id){
      this.snackBarService.errorMessage("No se puede mover al mismo destino");
      return;
    }

    if ((0 < this.cantidadMover) && (this.cantidadMover <= this.cantidadActual)){

      let msj = `
      Se van a mover ${this.cantidadMover} de sucursal ${this.sucursalDescripcionActual} a la sucursal ${this.selectedElement.nombre}.
      Los pedidos quedaran:
      ${this.sucursalDescripcionActual} con ${this.cantidadActual - this.cantidadMover} y se agregaran ${this.cantidadMover} a sucursal ${this.selectedElement.nombre}.\n
      Continuar?
      `

      this.dialogConfirmar("Alerta", msj);

    }else{
      this.snackBarService.errorMessage("La cantidad a mover debe ser mayor a 0 y menor o igual a la cantidad actual");
    }


    
  }

  dialogConfirmar(titulo: string, mensaje: string) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //SI
        
        let movimiento: any[] = [];

        movimiento.push({idsucursal: this.data.idSeleccionPrevia, cantidad: (this.cantidadActual - this.cantidadMover)});
        movimiento.push({idsucursal: this.selectedElement.id, cantidad: this.cantidadMover})

        this.dialogRef.close(
          {
            seleccion: this.selectedElement,
            movimiento: movimiento,
            cierre: true
          });
      } 
    });

  }

}

const ELEMENT_DATA: any[] = [];
