import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { MensajeDialogComponent } from 'src/app/shared/components/mensaje-dialog/mensaje-dialog.component';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import * as moment from 'moment';
import { Observable, combineLatest, concat } from 'rxjs';
import { SelectorGenericoComponent } from 'src/app/features/generico/selector-generico/selector-generico.component';
import { VendedoresModel } from 'src/app/models/vendedores-model';
import { SharedService } from 'src/app/services/shared.service';
import { PdfService } from 'src/app/services/pdf.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { formatNumeroNacional, toFechaApiRequest, toFechaCorta, toFechaLarga } from 'src/app/utiles';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-preventa-abm',
  templateUrl: './preventa-abm.component.html',
  styleUrls: ['./preventa-abm.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PreventaAbmComponent implements OnInit {

  sucursalActual = {
    sucursalId: 0,
    sucursalNombre: ""
  }

  displayedColumns: string[] = ['idpreventa', 'opciones'];
  dataSource = new MatTableDataSource<any>(ELEMENT_DATA);
  
  expandedElement: any | null;

  estados = ['Pendiente', 'Confirmado', 'Pagado', 'Cancelado', 'Presupuesto'];

  vals: any[] = [];

  seleccion: 'presupuesto' | 'confirmados' | 'pendientes' = 'pendientes';

  listVendedores: VendedoresModel[] = [];
  listSucursales: any[] = [];

  vendedor = {
    nombre: "",
    idvendedor: 0
  };

  uid = "";

  cargando = false;

  columnaList = [
    {id: "cliente_nombre", titulo: "Cliente", activado: true},
    {id: "vendedor_nombre", titulo: "Vendedor",  activado: true},
    {id: "sucursal", titulo: "Sucursal",  activado: true},
    {id: "localidad", titulo: "Localidad Entrega",  activado: true},
    {id: "domicilio", titulo: "Domicilio Entrega",  activado: true},
    {id: "fecha", titulo: "Fecha",  activado: true},
    {id: "observaciones", titulo: "Observaciones",  activado: true},
    {id: "estado_descripcion", titulo: "Estado",  activado: true},
    {id: "total", titulo: "Total",  activado: true},
    //{id: "debe", titulo: "A Pagar",  activado: true}
  ];

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  fcNum = formatNumeroNacional;

  constructor(
    private apiService: ApiService,
    public router: Router,
    private snackBarService: SnackBarService,
    private dialogInfo: MatDialog,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private pdfService: PdfService,
    private auth: AuthService,
  ) {
    moment.locale('es');

    this.uid = this.auth.userInfo() ? this.auth.userInfo().uid : "-";

    this.sucursalActual = this.sharedService.getSucursalActual();

    if (this.uid != "-"){
      let listStr = sessionStorage.getItem(`${this.uid}--ventas`);
      if (listStr != null){
        this.columnaList = JSON.parse(listStr);
      }
    }

    this.inicializarColumnas();

    this.apiService.getVendedoresList().subscribe(x => {
      this.listVendedores = x;
    });

    this.apiService.getSucursalesCompletoList().subscribe(x => {
      this.listSucursales = x;
    });

   

    //let fcHasta: FormControl = this.range.value.end;
    //fcHasta.setValue(sHasta);

  }

  ngOnInit(): void {

    let mDesde = moment().startOf('month');
    let mHasta = moment().endOf('month');

    mHasta.set({hour:23,minute:59,second:59,millisecond:0});

    this.range.controls.start.setValue(mDesde.toDate());
    this.range.controls.end.setValue(mHasta.toDate());

    this.cargarTabla(0);
  }

  cargarTabla(estado?: number){

    this.dataSource.data = [];

    let mDesde = moment(this.range.value.start);
    let mHasta = moment(this.range.value.end);

    let rDesde = toFechaApiRequest(mDesde.toDate());
    let rHasta = toFechaApiRequest(mHasta.toDate());

    let preventasProductos = this.apiService.getPreventasProductosListCompleto(estado, this.sucursalActual.sucursalId, rDesde, rHasta);
    let preventasFormaPago = this.apiService.getPreventasFormaPago();
    let preventasPagos = this.apiService.getPreventasPago();


    this.cargando = true;

    combineLatest([preventasProductos, preventasFormaPago, preventasPagos]).subscribe((hh: any[]) =>{

      console.log("hh", hh);

      let o: any[] = hh[0];

      let fp: any[] = hh[1];

      let pc: any[] = hh[2];

      fp.forEach(xx => {
        xx._recargo = ((xx.importe * xx.recargo) /100);
        xx._conRecargo = xx.importe + xx._recargo;
        xx._fecha = xx.fecha ? toFechaCorta(moment(xx.fecha).toDate()) : "";
      });

      pc.forEach(xx => {
        xx._recargo = ((xx.importe * xx.recargo) /100);
        xx._conRecargo = xx.importe + xx._recargo;
        xx._fecha = xx.fecha ? toFechaCorta(moment(xx.fecha).toDate()) : "";
      })

      let mapList: Map<number, any> = new Map();

      o.forEach(x => {
        let contenido: any = null;
        if (mapList.has(x.idpreventa)){

          //ya existe y se actualiza

          contenido = mapList.get(x.idpreventa);
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento
          };
          contenido.productos.push(producto);
          
        }else{

          //no existe y se genera

          contenido = {
            idpreventa: x.idpreventa,
            observaciones: x.observaciones,
            vendedor_nombre: x.vendedor_nombre,
            idvendedor: x.idvendedor,
            idcliente: x.idcliente,
            cliente_nombre: x.cliente_nombre,
            idsucursal: x.preventa_idsucursal,
            preventa_idsucursal: x.preventa_idsucursal,
            preventa_sucursal: x.preventa_sucursal,
            domicilio: x.domicilio,
            localidad_descripcion: x.localidad_descripcion,
            localidad_codigo_postal: x.localidad_codigo_postal,
            fecha: x.fecha,
            senia: x.senia,
            flete: x.flete,
            estado: x.estado,
            estado_descripcion: this.estados[x.estado],
            importe_descuento: x.preventa_importe_descuento,
            porcentaje_descuento: x.preventa_porcentaje_descuento,
            importe_recargo: x.preventa_importe_recargo,
            porcentaje_recargo: x.preventa_porcentaje_recargo,
            _fecha: toFechaCorta(x.fecha),
            _fechax: toFechaLarga(x.fecha),
            productos: [],
            formaPago: [],
            _diferenciaPrecio: false
          };
          let producto: any = {
            cantidad: x.cantidad,
            color_descripcion: x.color_descripcion,
            medida_descripcion: x.medida_descripcion,
            precio: x.precio,
            producto_descripcion: x.producto_descripcion,
            producto_activo: x.producto_activo,
            producto_sucursal_descripcion: x.producto_sucursal_descripcion,
            idsucursal: x.idsucursal,
            idproductopresentacion: x.idproductopresentacion,
            idproducto: x.idproducto,
            idpreventaproducto: x.idpreventaproducto,
            importe_descuento: x.producto_importe_descuento,
            porcentaje_descuento: x.producto_porcentaje_descuento
          };

          //se agrega solo cuando es nuevo, no tiene sentido en cada pasada
          if (contenido.estado == 2){
            contenido.formaPago = pc.filter(ff => ff.idpreventa == x.idpreventa);
          }else{
            contenido.formaPago = fp.filter(ff => ff.idpreventa == x.idpreventa);
          }
          
          contenido.productos.push(producto);
          
        }

        // actualizamos los totales en cada pasada de producto

        let sum = 0
        contenido.productos.forEach((p: any) => {
          sum = sum + (p.cantidad * (p.precio - p.importe_descuento));
        })
        contenido._total_productos = sum;
        contenido._total = sum + contenido.flete - contenido.importe_descuento + contenido.importe_recargo;

        let pagado = 0.0

        contenido.formaPago.forEach((p: any) => {
          pagado = pagado + p.importe
        });

        let debe = contenido._total - pagado;
        contenido._debe = debe;
        
        mapList.set(x.idpreventa, contenido);

      });

      ///////

      this.vals =[ ...mapList.values() ];

      this.dataSource.data = this.vals;


      // //control de precio asincrona
      // let calls = [];
      // this.vals.forEach(vvv => {
      //   vvv._diferenciaPrecio = null;
      //   calls.push(this.resolverControlPrecios(vvv));
      // });
      // concat(...calls).subscribe((r: any) => {
      //   let val = this.vals.find(val => val.idpreventa == r.idpreventa);
      //   if (val){
      //     val.productos = r.productos;
      //     val._diferenciaPrecio = r._diferenciaPrecio;
      //     val._total_productos_actual = r._total_productos_actual;
      //   }
      // })
      // //


      this.cargando = false;

    }, e => {
      console.log("error", e);
      this.snackBarService.errorMessage("Error al obtener productos. " + e.message);
    })

    
  }

  /*
  clickControlPrecios(venta, event){

    event.stopPropagation();
    this.resolverControlPrecios(venta).subscribe(nuevaVenta => {
      venta.productos = nuevaVenta.productos;
      venta._diferenciaPrecio = nuevaVenta._diferenciaPrecio;
      venta._total_productos_actual = nuevaVenta._total_productos_actual;
    });

  }


  clickControlPrecios_(venta, event){

    event.stopPropagation();
    console.log("venta", venta);

    

    let obs = [];
    venta.productos.forEach(p => {
      obs.push(this.apiService.getProductosPresentaciones(p.idproductopresentacion));
    });

    let diferenciaPrecio = false;

    combineLatest(obs).subscribe(h => {
      console.log("h", h);
      let sumaTotalActual = 0;
      venta.productos.forEach(p => {
        let prodActual: any = h.find((x: any) => x.idproductopresentacion == p.idproductopresentacion);
        if (prodActual){
          p._precio_actual = prodActual.precio;
          if (prodActual.precio != p.precio){
            diferenciaPrecio = true;
          }
        }else{
          p._precio_actual = p.precio;
        }

        sumaTotalActual = sumaTotalActual + (p._precio_actual - p.importe_descuento) * p.cantidad;

      });

      venta._diferenciaPrecio = diferenciaPrecio;
      venta._total_productos_actual = sumaTotalActual;

    });

    

  }
  */

  clickBuscar(){
    if (this.seleccion == 'confirmados'){
      this.cargarTabla(-1);
    }

    if(this.seleccion == 'pendientes'){
      this.cargarTabla(0);
    }

    if(this.seleccion == 'presupuesto'){
      this.cargarTabla(4);
    }
  }

  clickNuevo(){
    this.router.navigateByUrl('/ventas/nueva-venta', {});
  }

  clickNuevoPresupuesto(){
    this.router.navigateByUrl('/ventas/nuevo-presupuesto', {});
  }

  clickConfirmar(e: any, event: any){

    event.stopPropagation();
    // confirmar/:idpreventa/:tipo
    this.router.navigateByUrl(`/ventas/confirmar/${e.idpreventa}/2`, {});

  }

  clickEditar(e: any, event: any){
    event.stopPropagation();
    console.log("elemento", e);
    if (e.estado == 0){
      //edicion sobre pendiente
      this.router.navigateByUrl(`/ventas/editar/${e.idpreventa}/1`, {});
    }
    if (e.estado == 1){
      //edicion sobre confirmada
      this.router.navigateByUrl(`/ventas/editar/${e.idpreventa}/3`, {});
    }
  }

  clickCancelar(e: any, event: any){
    event.stopPropagation();

    console.log("estado", e.estado);

    let sujeto = ""

    switch(e.estado){
      case 0: 
        sujeto = "la venta pendiente (preventa)";
        break
      case 1:
        sujeto = "la venta";
        break
      case 4:
        sujeto = "el presupuesto";
        break
    }

    this.dialogCancelar("Alerta", `¿Confirma que desea cancelar ${sujeto} #id ${e.idpreventa}?`, e.idpreventa, e.estado);
  }

  clickCrearVenta(e: any, event: any){
    //event.stopPropagation();
    //this.dialogCancelar("Alerta", `¿Confirma que desea cancelar la preventa #id ${e.idpreventa}?`, e.idpreventa, e.estado);

    event.stopPropagation();
    console.log("elemento", e);
    if (e.estado == 4){
      //edicion sobre presupuesto
      this.router.navigateByUrl(`/ventas/editar/${e.idpreventa}/1`, {});
    }
  }

  dialogCancelar(titulo: string, mensaje: string, id: any, estado: number) {
    const dialogInfoConfig = new MatDialogConfig();

    dialogInfoConfig.disableClose = true;
    dialogInfoConfig.autoFocus = false;
    dialogInfoConfig.width = '60vw';
    dialogInfoConfig.minWidth = '240px';
    dialogInfoConfig.maxWidth = '500px';
    dialogInfoConfig.data = {
      id: 'alerta',
      mensaje: mensaje,
      titulo: titulo,
      mostrarAccionC: true,
      mostrarAccionB: true,
      captionAccionC: 'No',
      captionAccionB: 'Si'
    };

    const dialogRef = this.dialogInfo.open(MensajeDialogComponent, dialogInfoConfig);

    dialogRef.afterClosed().subscribe(r => {
      console.log("respuesta", r);
      if (r.opcion == 'accion_b'){ //cancelar

        if (estado == 0 || estado == 4){
          
          this.apiService.getPreventaCancelar(id).subscribe(x => {
            if (x){
              let index = this.vals.findIndex(z => z.idpreventa == id);
              if (index != -1){
                this.vals.splice(index, 1);
                this.dataSource.data = this.vals;
              }
            }
          });
        }

        if (estado == 1){
          this.apiService.cancelarPreventaConfirmada(id).subscribe(x => {
            if (x){
              let index = this.vals.findIndex(z => z.idpreventa == id);
              if (index != -1){
                this.vals.splice(index, 1);
                this.dataSource.data = this.vals;
              }
            }
          });
        }

        
      } 
    });

  }

  radioChange(event: any){
    console.log("seleccion", this.seleccion);

    this.vendedor = {nombre: "", idvendedor: 0};

    if (this.seleccion == 'confirmados'){
      this.cargarTabla(-1);
    }

    if(this.seleccion == 'pendientes'){
      this.cargarTabla(0);
    }

    if(this.seleccion == 'presupuesto'){
      this.cargarTabla(4);
    }

  }

  clickSeleccionarVendedor(){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '45vw';
    dialogConfig.minWidth = '450px';
    dialogConfig.minHeight = '350px';

    let coleccion: any[] = [];

    this.listVendedores.forEach(x => {
      let item = {
        id: x.idvendedor,
        nombre: x.nombre
      }
      coleccion.push(item);
    })

    dialogConfig.data = {
      ventana: {
        titulo: "Selección de Vendedor",
        columnaNombre: "Nombre",
        tituloBotonNuevo: "Nuevo Vendedor"
      },
      muestraId: true,
      coleccion: coleccion,
      multiple: false,
      idSeleccionPrevia: this.vendedor.idvendedor,
      permitirNuevo: false,
      referenciaNuevo: null
    };

    console.log('data dialog', dialogConfig.data);

    const dialogRef = this.dialog.open(SelectorGenericoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(rr => {
      console.log("rr preventad", rr);
      if (rr.cierre){
        if (rr.seleccion != null){
          this.vendedor = {
            nombre: rr.seleccion.nombre,
            idvendedor: rr.seleccion.id 
          }
        }else{
          this.vendedor = {nombre: "", idvendedor: 0};
        }

        if (this.vendedor.idvendedor == 0){
          this.dataSource.data = this.vals;
        }else{
          let filtrado = this.vals.filter(v => v.idvendedor == this.vendedor.idvendedor)
          this.dataSource.data = filtrado;
        }
        
      }
    });
  }

  clickExportarPdf(e: any){

    console.log("element", e);

    let datoSucursal = this.listSucursales.find(x => x.idsucursal == e.idsucursal);
    if (datoSucursal == null){
      return;
    }

    console.log("dato sucursal", datoSucursal);

    let tipoVenta = e.estado == 4 ? "Presupuesto" : "Venta"

    let id = e.idpreventa;
    let clienteNombre = `${e.cliente_nombre}`;
    let vendedorNombre = `${e.vendedor_nombre}`;
    let fecha = `${e._fecha}`;
    let sucursal = `${e.preventa_sucursal}`;
    let estado = `${e.estado_descripcion}`;
    let localidad = `${e.localidad_descripcion}`;
    let codigoPostal = `${e.localidad_codigo_postal}`;
    let domicilio = `${e.domicilio}`;
    let flete = `${e.flete}`;

    let productos: any = [
      [
        {text: `Producto`, fontSize: 11}, 
        {text: `Color`, fontSize: 11}, 
        {text: `Medida`, fontSize: 11}, 
        {text: `Cantidad`, fontSize: 11}, 
        {text: `Sucursal`, fontSize: 11}, 
        {text: `Precio Unitario`, fontSize: 11}, 
        {text: `Descuento Unitario`, fontSize: 11}, 
        {text: `Precio`, fontSize: 11}
      ]
    ];

    let precioProductos = e._total_productos.toFixed(2);
    let total = (e._total).toFixed(2);

    let formaPagoWidths = ['*', '*', '*', '*', '*'];

    let formasPago: any = [
      [
        {text: `Tipo`, fontSize: 11}, 
        {text: `Importe`, fontSize: 11}, 
        {text: `Recargo`, fontSize: 11}, 
        {text: `Total`, fontSize: 11}, 
        {text: `Es Seña`, fontSize: 11}
      ]
    ];

    if (tipoVenta == 'Presupuesto'){
      formasPago = [
        [
          {text: `Tipo`, fontSize: 11}, 
          {text: `Total`, fontSize: 11}, 
        ]
      ];
      formaPagoWidths = ['*', '*']
    }


    let p: any = e.productos.map((r: any) => {
      let x: any[] = [
        {text: `${r.producto_descripcion}`, fontSize: 10},
        {text: `${r.color_descripcion}`, fontSize: 10},
        {text: `${r.medida_descripcion}`, fontSize: 10},
        {text: `${r.cantidad}`, fontSize: 10, alignment: 'right'},
        {text: `${r.producto_sucursal_descripcion}`, fontSize: 10},
        {text: `$${r.precio.toFixed(2)}`, fontSize: 10, alignment: 'right'},
        {text: `$${r.importe_descuento.toFixed(2)}`, fontSize: 10, alignment: 'right'},
        {text: `$${((r.precio - r.importe_descuento) * r.cantidad).toFixed(2)}`, fontSize: 10, alignment: 'right'},
      ]
      return x;
    })

    p.forEach((x: any) => {
      productos.push(x);
    })

    let f: any = e.formaPago.map((r: any) => {
      let x: any[] = [];
      if (tipoVenta == 'Presupuesto'){
        x = [
          {text: `${r.tipo_descripcion}`, fontSize: 10},
          {text: `$${r._conRecargo.toFixed(2)}`, fontSize: 10},
        ]
        return x;
      }else{
        x = [
          {text: `${r.tipo_descripcion}`, fontSize: 10},
          {text: `${r.importe.toFixed(2)}`, fontSize: 10},
          {text: `$${r._recargo.toFixed(2)} (${r.recargo}%)`, fontSize: 10},
          {text: `$${r._conRecargo.toFixed(2)}`, fontSize: 10},
          {text: `${r.senia? 'Si' : 'No'}`, fontSize: 10}
        ]
        return x;
      }

      
    })

    f.forEach((x: any) => {
      formasPago.push(x);
    });

    let titulo = { text: `${tipoVenta} -  #${id} - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] };
    if (tipoVenta == 'Presupuesto'){
      titulo = { text: `Presupuesto - Sucursal: ${sucursal}`, fontSize: 15, margin:[0, 0, 0, 10] };
    }

    let content: any[] = [
      titulo,

      { text: `Domicilio: ${datoSucursal.domicilio}`, fontSize: 10, margin:[0, 0, 0, 10] },
      { text: `Localidad: ${datoSucursal.nombre_localidad} (${datoSucursal.codigo_postal})`, fontSize: 10, margin:[0, 0, 0, 10] },

      { text: `Cliente: ${clienteNombre}`, fontSize: 10, margin:[0, 10, 0, 10] },
      { text: `Cliente Domicilio Entrega: ${domicilio}`, fontSize: 10, margin:[0, 0, 0, 10] },
      { text: `Cliente Localidad Entrega: ${localidad} (${codigoPostal})`, fontSize: 10, margin:[0, 0, 0, 10] },
      { text: `Vendedor: ${vendedorNombre}`, fontSize: 10, margin:[0, 10, 0, 10] },
      { text: `Fecha: ${fecha}`, fontSize: 10, margin:[0, 0, 0, 10] },
      { text: `Estado: ${estado}`, fontSize: 10, margin:[0, 0, 0, 10] },

      { text: `Flete: $${flete}`, fontSize: 10, margin:[0, 10, 0, 10] },
      
      { text: `Productos: `, fontSize: 10, margin:[0, 0, 0, 10] },
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*', '*', '*', '*'],
          body: productos
        }
      },
      { text: `Precio Productos: $${precioProductos}`, fontSize: 10, margin:[0, 10, 0, 10] },
      { text: `Descuento General: $${e.importe_descuento}`, fontSize: 10, margin:[0, 0, 0, 10] },
      { text: `Total: $${total}`, fontSize: 10, margin:[0, 0, 0, 10] },
    ]

  
    if (e.formaPago.length > 0){
      content.push({ text: `Formas de Pago:`, fontSize: 10, margin:[0, 0, 0, 10] });
      content.push(
      {
        table: {
          headerRows: 1,
          widths: formaPagoWidths,
          body: formasPago
        }
      });
    }

    console.log("content", content);

    this.pdfService.generarPDF(content);

  }

  clickShowColumna(event: any, item: any){
    //  event.stopPropagation();
    //console.log("event", event); 

    item.activado = event.checked;

    if (event.checked){

      this.displayedColumns = ['idpreventa', 'opciones'];
      this.inicializarColumnas();

    }else{
      let columnaIndex = this.displayedColumns.findIndex(x => x == item.id);
      if (columnaIndex != -1){
        this.displayedColumns.splice(columnaIndex, 1);
      }
    }

    sessionStorage.setItem(`${this.uid}--ventas`, JSON.stringify(this.columnaList));

  }

  inicializarColumnas(){
    this.columnaList.forEach(c => {
      if (c.activado){
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, c.id);
      }
    });
  }


}

const ELEMENT_DATA: any[] = [];

