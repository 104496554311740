<mat-dialog-content>

    <div style="display: flex; flex-direction: column; max-width: 350px;">

        <div>
            Total Articulos: $ {{lote.totalArticulos}}
        </div>
   
        <mat-form-field class="example-full-width">
            <mat-label>Flete</mat-label>
            <input matInput  placeholder="Flete" type="number" [(ngModel)]="lote.flete" (keyup)="calcularSaldo()">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Seña</mat-label>
            <input matInput  placeholder="Seña" type="number" [(ngModel)]="lote.senia" (keyup)="calcularSaldo()">
        </mat-form-field>

        <div>
            Saldo: $ {{lote.saldo}}
        </div>

        <div style="margin-top: 16px;">
            <button mat-raised-button color="primary" (click)="clickAgregarFormaPago()">Agregar Forma de Pago</button>
        </div>

        <div *ngFor="let formaPago of lote.listFormaPago; let i = index">
            <mat-card style="max-width: 450px; margin-top: 4px; margin-bottom: 4px;">
                <div style="display: flex; flex-direction: row;">
                    <div style="display: flex; flex-direction: column; flex-grow: 1; font-size: small;">
                        <mat-form-field class="example-full-width">
                            <mat-label>Importe</mat-label>
                            <input matInput  placeholder="Seña" type="number" min="0" [(ngModel)]="formaPago.importe" (keyup)="calcularPrecio(formaPago)" autocomplete="nope">
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Tipo de Precio</mat-label>
                            <mat-select [(ngModel)]="formaPago.tipoId" (selectionChange)="selectTipoPrecio(i, formaPago, $event)">
                                <mat-option *ngFor="let tipo of listTipoPrecio" [value]="tipo.idtipo">{{tipo.descripcion}} / {{tipo.recargo}}%</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            Recargo: $ {{formaPago.recargo}}
                        </div>
                        <div>
                            Total: $ {{formaPago.total}}
                        </div>
                    </div>
                    <div>
                        <button mat-icon-button (click)="clickEliminar($event, formaPago, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card>
        </div>
    
    </div>
    
    </mat-dialog-content>
    
    <mat-dialog-actions>
    
    <div style="display: flex; flex-direction: row-reverse; width: 100%;">
        <button mat-button (click)="clickCerrar()">Cerrar</button>
        <button mat-button (click)="clickConfirmar()">Confirmar</button>
    </div>
        
</mat-dialog-actions>