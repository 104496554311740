import * as moment from 'moment';

export class Utiles {
    public static regexHHMM = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; // 00:00 -> 23:59
    public static regexEMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //public static regexEMail = /^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5}){1,25}(,[ ]{0,1}([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5}){1,25})*$/;
    
}

export function renameKey ( obj:any, oldKey: string, newKey: string ) {
    obj[newKey] = obj[oldKey];
    delete obj[oldKey];
    return obj;
}

/*
export function toFechaServidor(f: Date){
    let m = moment.utc(f);
    return m.format("DD-MM-YYYY HH:mm:ss");
}
*/

export function toF(f: Date){
    return f.toISOString().replace(/T/, ' ').replace(/\..+/, '');
}

//YYYY-MM-DD HH:MM:SS
/*
export function toFechaMySql(f: Date){
    let m = moment.utc(f);
    return m.format("YYYY-MM-DD HH:mm:ss");
}
*/

export function toFechaTimeStamp(f: Date, forzarUTC?: boolean){
    if (forzarUTC){
        let m = moment.utc(f);
        return m.format("YYYYMMDDHHmmss");
    }else{
        let m = moment(f);
        return m.format("YYYYMMDDHHmmss");
    }
    
}

export function toFechaCorta(f: Date, forzarUTC?: boolean){
    if (forzarUTC){
        let m = moment.utc(f);
        return m.format("DD-MM-YYYY HH:mm");
    }else{
        let m = moment(f);
        return m.format("DD-MM-YYYY HH:mm");
    }
    
}

export function toFechaCortaSoloFecha(f: any, forzarUTC?: boolean){
    if (forzarUTC){
        let m = moment.utc(f);
        return m.format("DD-MM-YYYY");
    }else{
        let m = moment(f);
        return m.format("DD-MM-YYYY");
    }
    
}

export function toFechaLarga(f: any, forzarUTC?: boolean){
    if (forzarUTC){
        let m = moment.utc(f);
        return m.format("dddd D [de] MMMM [de] YYYY, HH:mm");
    }else{
        let m = moment(f);
        return m.format("dddd D [de] MMMM [de] YYYY, HH:mm");
    }
}

export function toFechaApiRequest(f: Date, forzarUTC?: boolean){
    if (forzarUTC){
        let m = moment.utc(f);
        return m.format("YYYY-MM-DD[T]HH:mm:ss");
    }else{
        let m = moment(f);
        return m.format("YYYY-MM-DD[T]HH:mm:ss");
    }
    
}

export function formatNumeroNacional(floatValue = 0, decimals = 2, multiplier = 1) {
    let floatMultiplied = floatValue * multiplier;
    let stringFloat = floatMultiplied + "";
    let arraySplitFloat = stringFloat.split(".");
    let decimalsValue = "0";
    if (arraySplitFloat.length > 1) {
        decimalsValue = arraySplitFloat[1].slice(0, decimals);
    }
    let integerValue = arraySplitFloat[0];
    let arrayFullStringValue = [integerValue, decimalsValue];
    let FullStringValue = arrayFullStringValue.join(".")
    //let floatFullValue = parseFloat(FullStringValue) + "";
    let floatFullValue = parseFloat(FullStringValue);
    let formatFloatFullValue = new Intl.NumberFormat('es-ES', { minimumFractionDigits: decimals }).format(floatFullValue);
    return formatFloatFullValue;

}

