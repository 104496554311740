<div style="height: 2px;">
    <mat-progress-bar class="progress" mode="indeterminate" *ngIf="cargando"></mat-progress-bar>
</div>
<div style="padding-top: 1vh; padding-bottom: 1vh; padding-left: 1vw; padding-right: 1vw;">

    <div style="display: flex; justify-content: space-between; align-content: center; flex-wrap: wrap;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
        <button mat-raised-button color="primary" (click)="clickBack()">Volver</button>
    </div>

    <div style="margin-top: 8px;">
        <h2 *ngIf="modo=='nuevo'">Nuevo Movimiento</h2>
        <h2 *ngIf="modo=='solicitar'">Editar Solicitud de Movimiento</h2>
        <h2 *ngIf="modo=='enviar'">Editar Envío</h2>
        <h2 *ngIf="modo=='recibir'">Editar Recepción</h2>
    </div>

    <div style="margin: 8px 0px 8px 0px;">
        <section style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: baseline;">
            <mat-radio-group [(ngModel)]="seleccionTipo" (change)="radioChange($event)" style="display: flex; flex-direction: row;" [disabled]="modo != 'nuevo'">
                <mat-radio-button style="margin: 6px;" value="pedido">Pedido de Productos</mat-radio-button>
                <mat-radio-button style="margin: 6px;" value="envio">Envío de Productos</mat-radio-button>
            </mat-radio-group>
        </section>
    </div>

    <div style="display: flex; flex-direction: column;">

        <div style="width: 95vw; min-width: 250px; max-width: 500px; display: flex; flex-direction: column;">

            <mat-form-field appearance="outline">
                <mat-label>Observaciones</mat-label>
                <input matInput placeholder="Observaciones" value=""  type="text" [(ngModel)]="movimiento.obs" name="observaciones" [disabled]="modo != 'nuevo' && modo != 'solicitar'">
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Sucursal Origen</mat-label>
                <mat-select [(ngModel)]="movimiento.origen" [disabled]="modo != 'nuevo' && modo != 'solicitar' || seleccionTipo == 'envio'" >
                    <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>

            
            <mat-form-field appearance="outline">
                <mat-label>Sucursal Destino</mat-label>
                <mat-select [(ngModel)]="movimiento.destino" [disabled]="modo != 'nuevo' && modo != 'solicitar' || seleccionTipo == 'pedido'">
                    <mat-option *ngFor="let sucursal of listSucursales" [value]="sucursal.idsucursal">{{sucursal.descripcion}}</mat-option>
                </mat-select>
            </mat-form-field>

        </div>

        <mat-divider></mat-divider>

        <div style="margin-top: 16px; margin-bottom: 16px; display: flex; flex-direction: row;">

            <div style="flex-grow: 1; padding:8px; ">

                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                    <h2 *ngIf="modo=='solicitar' || modo=='nuevo'">Productos a {{seleccionTipo == 'envio' ? 'Enviar' : 'Solicitar'}}</h2>
                    <h2 *ngIf="modo=='enviar'">Productos a Enviar</h2>
                    <h2 *ngIf="modo=='recibir'">Productos Recibidos</h2>
                    <button style="margin-left: 16px;" mat-raised-button color="primary" (click)="clickAgregar()">Agregar y Editar Productos</button>
                </div>

                <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                    <mat-accordion multi *ngIf="agregados.length > 0">
                        <mat-expansion-panel [expanded]="true" *ngFor="let p of agregados" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="align-items: center;">
                                    {{p.descripcion}}
                                </mat-panel-title>
                                <mat-panel-description style="justify-content: flex-end;">
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div style="padding: 8px;">
                                <mat-card  *ngFor="let pp of p.presentaciones" style="margin: 4px;">
                                    <div style="display: flex; flex-direction: column; font-size: 10pt;">
                                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
                                            <div>Color: <b>{{pp.colorDescripcion}}</b> | Medida: <b>{{pp.medidaDescripcion}}</b></div>
                                        </div>
                                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                            <div style="width: 120px;">Cantidad</div>
                                            <div>{{pp.cantidad}}</div>
                                        </div>
                                    </div>
                                    
                                </mat-card>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <div *ngIf="agregados.length == 0">
                        <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos Agregados</div>
                    </div>
                </div>

            </div>

            <div *ngIf="((modo == 'enviar' && movimiento.tipo_movimiento == 0) || modo == 'recibir')" style="flex-grow: 1; padding: 8px; border-left: 1px solid gainsboro;">

                <button mat-raised-button color="primary" (click)="clickPasar()">Agregar al Listado</button>

                <div style="display: flex; flex-direction: row; align-items: baseline; flex-wrap: wrap; justify-content: space-between;">
                    <h2 *ngIf="modo=='enviar'">Productos Solicitados</h2>
                    <h2 *ngIf="modo=='recibir'">Productos Enviados</h2>
                </div>
    
                <div style="width: 95vw; min-width: 250px; max-width: 600px; display: flex; flex-direction: column;">
                    <mat-accordion multi *ngIf="esperados.length > 0">
                        <mat-expansion-panel [expanded]="true" *ngFor="let p of esperados" hideToggle>
                            <mat-expansion-panel-header>
                                <mat-panel-title style="align-items: center;">
                                    {{p.descripcion}}
                                </mat-panel-title>
                                <mat-panel-description style="justify-content: flex-end;">
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div style="padding: 8px;">
                                <mat-card  *ngFor="let pp of p.presentaciones" style="margin: 4px;">
                                    <div style="display: flex; flex-direction: column; font-size: 10pt;">
                                        <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: baseline;">
                                            <div>Color: <b>{{pp.colorDescripcion}}</b> | Medida: <b>{{pp.medidaDescripcion}}</b></div>
                                        </div>
                                        <div style="display: flex; flex-direction: row; margin-top: 4px;">
                                            <div style="width: 120px;">Cantidad</div>
                                            <div>{{pp.cantidad}}</div>
                                        </div>
                                    </div>
                                    
                                </mat-card>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <div *ngIf="esperados.length == 0">
                        <div style="height: 50px; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center;">Sin Productos Solicitados</div>
                    </div>
                </div>

            </div>

        </div>

     
        
    </div>

    <div style="margin-top: 24px;">
        <button mat-raised-button color="primary" (click)="clickGuardar()" [disabled]="cargando">Guardar</button>
    </div>

</div>