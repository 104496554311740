import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';

import { HeaderComponent } from './components/header/header.component';
import { MaterialModule } from '../material/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from '../app-routing.module';
import { ProfilemenuComponent } from './components/profilemenu/profilemenu.component';
import { SideBarService } from './services/side-bar.service';
import { LoginComponent } from './components/auth/login/login.component';
import { MensajeDialogComponent } from './components/mensaje-dialog/mensaje-dialog.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ErrorProhibidoComponent } from './components/error-prohibido/error-prohibido.component';
import { NotasComponent } from './components/notas/notas.component';
import { AngularEditorModule } from '@kolkov/angular-editor';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}
@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPermissionsModule,
        AngularEditorModule,
        HttpClientModule,
        /*
        JwtModule.forRoot({
          config: {
            tokenGetter: tokenGetter,
            whitelistedDomains: ['localhost:4200'],
            blacklistedRoutes: ['localhost:4200']
          }
        })
        */
    ],
    providers: [
        /*
        AuthenticationGuard,
        ObservadoresService,
        ApiPlantillasService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        */
        SideBarService
    ],
    exports: [
        HeaderComponent,
        SidebarComponent,
        LoginComponent,
        NgxPermissionsModule
    ],
    declarations: [
        HeaderComponent,
        SidebarComponent,
        ProfilemenuComponent,
        LoginComponent,
        MensajeDialogComponent,
        ErrorProhibidoComponent,
        NotasComponent
    ]
})
export class SharedModule { }
