import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email = ""
  password = ""

  isLoggedIn = false

  constructor(
    private auth: AuthService,
    private sharedService: SharedService,
    private apiService: ApiService,
    public router: Router,
    private _permissionsService: NgxPermissionsService,

  ) { 

    this.isLoggedIn = this.auth.isLoggedIn;
    console.log("app component isloggedin", this.isLoggedIn)

    if (this.isLoggedIn){
      this.router.navigateByUrl('', {});
    }

  }

  ngOnInit(): void {

  }

  clickIngresar(){
    this.auth.SignIn(this.email, this.password).then(
      x => {
        console.log("login x", x);

        if (this.auth.isLoggedIn){

          let userInfo = this.auth.userInfo();
          this.apiService.getUsuarios(userInfo.uid).subscribe(u => {

            console.log("u", u);
            
            if (u != null){
              let rol = u.rol ? u.rol : 0;
              const permissions = [`${rol}`]
    
              console.log("permissions", permissions);
              this._permissionsService.loadPermissions(permissions);
            }
            

            this.apiService.getSucursales(u.idsucursal).subscribe(s => {
              this.sharedService.setSucursalActual(s.idsucursal, s.descripcion);
              this.router.navigateByUrl('', {});
            });
          }, e => {

            if (!environment.production){

              let u = {
                idsucursal: 1,
                rol: 10
              }
  
              //hardcodeo para no usar api
              if (u != null){
                let rol = u.rol ? u.rol : 0;
                const permissions = [`${rol}`]
      
                console.log("permissions", permissions);
                this._permissionsService.loadPermissions(permissions);
              }
  
              this.apiService.getSucursales(u.idsucursal).subscribe(s => {
                this.sharedService.setSucursalActual(s.idsucursal, s.descripcion);
                this.router.navigateByUrl('', {});
              }, e =>{
  
                this.sharedService.setSucursalActual(1, "neuquen");
                this.router.navigateByUrl('', {});
  
              });

            }

          });

        }
        
      },
      e => {
        console.log("login e", e);
      }
    )
  }

  registrarLogin(){

  }

}
